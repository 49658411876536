import React, { useState, useEffect } from 'react'
import { View, Text, StyleSheet, Pressable, TextInput} from 'react-native'
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { API_URL, BASE_URL } from '../config/config';
import { getWebsiteWidth } from '../components/utilities';
import { style, grey2 } from '../styles/style';
import {Button as ButtonPaper} from 'react-native-paper';
import { Overlay } from 'react-native-elements';

function Address_create(params) {
    const [WIDTH, set_WIDTH] = useState(getWebsiteWidth());

    // for message box
    const [state_MsgBox, set_state_MsgBox] = useState({
        title: "",
        message: "",
        show: false
    });
    const MessageBox = function(title, message, message2 = ""){
        if(typeof(message) == "undefined")
        set_state_MsgBox({title:title, message:message2, show:true});
        else
        set_state_MsgBox({title:title, message:message, show:true});
    }
    const closeMessageBox = () => {
        set_state_MsgBox({show:false});
    };
    
    useEffect(() => {
        window.addEventListener('resize', () => { set_WIDTH(getWebsiteWidth()); });
    }, [])
    const { register, setValue, handleSubmit, control, formState: { errors } } = useForm({
        defaultValues: {
            name: '',
            email:'',
            contact:'',
            fax:'',
            address1:'',
            address2:'',
            city:'',
            zipcode:'',
            state:'',
            country:'Malaysia',
        }
    });

    const onSubmit = async(data) => {
        let url = API_URL + '/client/addressbook';
        let token = window.localStorage.getItem('token');
        let headers = {
            Authorization: `Bearer ${token}` 
        }

        let payload = {
            "mode":'Add',
            "email":data.email,
            "name":data.name,
            "contact":data.contact,
            "fax":data.fax,
            "address1":data.address1,
            "address2":data.address2,
            "city":data.city,
            "zipcode":data.zipcode,
            "state":data.state,
            "country":data.country,
            'clientID': params.clientID
        }

        await axios({
            method:'post',
            url: url, 
            headers: headers,
            data:payload
        }).then(()=>{
            MessageBox("", "Address added.")
            if(params.sendDataToParent)
                params.sendDataToParent({created: true});
            // else
            //     window.location.href = `${BASE_URL}client`;
        }).catch(err=>{
            if(typeof(err.response.data.result.errors) != 'undefined')
            {
                let msg = '';
                for(let i=0; i<err.response.data.result.errors.length; i++)
                {   
                    if(msg != '')   msg += '\n';
                    msg += err.response.data.result.errors[i].param + ': ' + err.response.data.result.errors[i].msg;
                }
                MessageBox('Error', msg);
            }
            else
                MessageBox("Error", "Failed to add address.");
        }); 
    }

    const onChange = arg => {
        return {
            value: arg.nativeEvent.text,
        };
    };
    
    return (
        <View style={[style(WIDTH).container]}>
            <View style={style(WIDTH).container_create_col}>   
                <View style={{marginBottom:WIDTH*0.025}}><Text style={[style(WIDTH).create_title, style(WIDTH).fontSize4]}>Add Address</Text></View>

                <View>
                    <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Name {errors.name?
                            <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                    <Controller
                        control={control}
                        render={({field:{onChange, onBlur, value}}) => (
                            <TextInput
                                style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}    
                                onBlur={onBlur}
                                onChangeText={value => onChange(value)}
                                value={value}
                                placeholder='Enter name...'
                                placeholderTextColor = {grey2}
                            />
                        )}
                        name="name"
                        rules={{ required: true}}
                    />
                </View>

                <View>
                    <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Email {errors.email?
                            <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                    <Controller
                        control={control}
                        render={({field:{onChange, onBlur, value}}) => (
                            <TextInput
                                onBlur={onBlur}
                                onChangeText={value => onChange(value)}
                                value={value}
                                placeholder='Enter email...'
                                placeholderTextColor = {grey2}
                                style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                            />
                        )}
                        name="email"
                        rules={{ required: false}}
                    />
                </View>
                    
                <View style={{flexDirection:'row'}}>
                    <View style={style(WIDTH).create_col_space}>
                        <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Contact No. {errors.contact?
                            <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                        <Controller
                            control={control}
                            render={({field:{onChange, onBlur, value}}) => (
                                <TextInput
                                    style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                    onBlur={onBlur}
                                    onChangeText={value => onChange(value)}
                                    value={value}
                                    placeholder='Enter contact no...'
                                    keyboardType="numeric"
                                    placeholderTextColor = {grey2}
                                />
                            )}
                            name="contact"
                            rules={{required: false}}
                        />
                    </View>
                        
                    <View style={style(WIDTH).container_icon} />
                    
                    <View style={style(WIDTH).create_col_space}>
                        <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Fax</Text>
                        <Controller
                            control={control}
                            render={({field:{onChange, onBlur, value}}) => (
                                <TextInput
                                    style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                        onBlur={onBlur}
                                        onChangeText={value => onChange(value)}
                                        value={value}
                                        placeholder='Enter fax...'
                                        placeholderTextColor = {grey2}
                                    />
                            )}
                            name="fax"
                            rules={{ required: false}}
                        />
                    </View>
                </View>
                
                <View>
                <View>
                    <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Address 1 {errors.address1?
                            <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                    <Controller
                        control={control}
                        render={({field:{onChange, onBlur, value}}) => (
                            <TextInput
                                style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}    
                                onBlur={onBlur}
                                onChangeText={value => onChange(value)}
                                value={value}
                                placeholder='Enter address...'
                                placeholderTextColor = {grey2}
                                multiline={true}
                            />
                        )}
                        name="address1"
                        rules={{ required: false}}
                    />
                </View>

                <View>
                    <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Address 2 (optional)</Text>
                    <Controller
                        control={control}
                        render={({field:{onChange, onBlur, value}}) => (
                            <TextInput
                                onBlur={onBlur}
                                onChangeText={value => onChange(value)}
                                value={value}
                                placeholder='Enter address...'
                                placeholderTextColor = {grey2}
                                style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                multiline={true}
                            />
                        )}
                        name="address2"
                        rules={{ required: false}}
                    />
                </View>
                
                <View style={{flexDirection:'row'}}>
                    <View style={style(WIDTH).create_col_space}>
                        <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>City {errors.city?
                            <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                        <Controller
                            control={control}
                            render={({field:{onChange, onBlur, value}}) => (
                                <TextInput
                                    style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                    onBlur={onBlur}
                                    onChangeText={value => onChange(value)}
                                    value={value}
                                    placeholder='Enter city...'
                                    keyboardType="numeric"
                                    placeholderTextColor = {grey2}
                                />
                            )}
                            name="city"
                            rules={{required: false}}
                        />
                    </View>
                    <View style={style(WIDTH).container_icon} />
                    <View style={style(WIDTH).create_col_space}>
                        <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Zipcode {errors.zipcode?
                            <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                        <Controller
                            control={control}
                            render={({field:{onChange, onBlur, value}}) => (
                                <TextInput
                                    style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                    onBlur={onBlur}
                                    onChangeText={value => onChange(value)}
                                    value={value}
                                    placeholder='Enter zipcode...'
                                    placeholderTextColor = {grey2}
                                />
                            )}
                            name="zipcode"
                            rules={{ required: false}}
                        />
                    </View>
                </View>
                    
                <View style={{flexDirection:'row'}}>
                    <View style={style(WIDTH).create_col_space}>
                        <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>State {errors.state?
                            <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                        <Controller
                            control={control}
                            render={({field:{onChange, onBlur, value}}) => (
                                <TextInput
                                    style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                    onBlur={onBlur}
                                    onChangeText={value => onChange(value)}
                                    value={value}
                                    placeholder='Enter state...'
                                    keyboardType="numeric"
                                    placeholderTextColor = {grey2}
                                />
                            )}
                            name="state"
                            rules={{required: false}}
                        />
                    </View>
                    <View style={style(WIDTH).container_icon} />
                    <View style={style(WIDTH).create_col_space}>
                        <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Country {errors.country?
                            <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                        <Controller
                            control={control}
                            render={({field:{onChange, onBlur, value}}) => (
                                <TextInput
                                    style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                    onBlur={onBlur}
                                    onChangeText={value => onChange(value)}
                                    value={value}
                                    placeholder='Enter country...'
                                    placeholderTextColor = {grey2}
                                />
                            )}
                            name="country"
                            rules={{ required: false}}
                        />
                    </View>
                </View>
                </View>
                
                <ButtonPaper
                    style={[style(WIDTH).create_btn, {right: -WIDTH*0.015}]} 
                    labelStyle={style(WIDTH).create_btn_label} 
                    onPress={handleSubmit(onSubmit)}
                >
                    Create
                </ButtonPaper>
            </View>   
            <Overlay isVisible={state_MsgBox.show} onBackdropPress={closeMessageBox} animationType='fade' style={{position:'absolute', zIndex:1000}}
                overlayStyle={[style(WIDTH).overlay_container]}>
                <Text style={style(WIDTH).msg_box_title}>{state_MsgBox.title}</Text>
                <Text style={style(WIDTH).msg_box_message}>{state_MsgBox.message}</Text>
                <ButtonPaper
                style={[style(WIDTH).msg_box_btn]} 
                labelStyle={style(WIDTH).create_btn_label} 
                onPress={closeMessageBox}>
                    Close
                </ButtonPaper>  
            </Overlay>          
        </View>
    )
}

export default Address_create
