import React, {useEffect,useState} from 'react'
import { View, Text, StyleSheet, Pressable, TextInput} from 'react-native'
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { API_URL, BASE_URL } from '../config/config';
import { useParams} from 'react-router';
import { getWebsiteWidth } from '../components/utilities';
import { style, grey2, option } from '../styles/style';
import {Button as ButtonPaper} from 'react-native-paper';
import { Overlay, Icon, Tooltip, Button } from 'react-native-elements';
import { truncate } from 'lodash';

function Address_Edit(params) {
    const token = window.localStorage.getItem('token');
    const [WIDTH, set_WIDTH] = useState(getWebsiteWidth());
    const [counter, setCounter] = useState(0);
    const [confirmCounter, setConfirmCounter] = useState(0);
    const [is_deleted, set_is_deleted] = useState(false);
    const [last_edit_name, set_last_edit_name] = useState('');
    const is_admin = window.localStorage.getItem("role") != 1;
    const [editable, set_editable] = useState(0);

    // for message box
    const [state_MsgBox, set_state_MsgBox] = useState({
        title: "",
        message: "",
        show: false
    });
    const MessageBox = function(title, message, message2 = ""){
        if(typeof(message) == "undefined")
        set_state_MsgBox({title:title, message:message2, show:true});
        else
        set_state_MsgBox({title:title, message:message, show:true});
    }
    const closeMessageBox = () => {
        set_state_MsgBox({show:false});
    };

    useEffect(() => {
        window.addEventListener('resize', () => { set_WIDTH(getWebsiteWidth()); });
        onLoad();
    }, [])
    
    const onLoad = async() => {
        ShowLoading();
        await getAddress();
        HideLoading();
    };
    const { register, setValue, handleSubmit, control, reset, formState: { errors } } = useForm({
        defaultValues: {
            name: '',
            email:'',
            contact:'',
            fax:'',
            term:'',
            code:'',
            address1:'',
            address2:'',
            city:'',
            zipcode:'',
            state:'',
            country:'',
        }
    });

    const onSubmit = async(data) => {
        if(!editable) return;

        let url = API_URL + 'client/addressbook';
        let token = window.localStorage.getItem('token');
        let headers = {
            Authorization: `Bearer ${token}` 
        }

        let payload = {
            "mode":'Edit',
            "id":params.addressID,
            "email":data.email,
            "name":data.name,
            "contact":data.contact,
            "term":data.term,
            "code":data.code,
            "fax":data.fax,
            "address1":data.address1,
            "address2":data.address2,
            "city":data.city,
            "zipcode":data.zipcode,
            "state":data.state,
            "country":data.country
        }

        await axios({
            method:'post',
            url: url, 
            headers: headers,
            data:payload
        }).then(()=>{
            MessageBox("","Saved");
            if(params.sendDataToParent)
                params.sendDataToParent(true);
        }).catch (err => {
            if(typeof(err.response.data.result.errors) != 'undefined')
            {
                let msg = '';
                for(let i=0; i<err.response.data.result.errors.length; i++)
                {   
                    if(msg != '')   msg += '\n';
                    msg += err.response.data.result.errors[i].param + ': ' + err.response.data.result.errors[i].msg;
                }
                MessageBox('Error', msg);
            }
            else
                MessageBox("Error", "Failed to edit client info.");
            console.log(err);
        });
    }

    // const onChange = arg => {
    //     return {
    //         value: arg.nativeEvent.text,
    //     };
    // };

    const getAddress = async() => {
        let url = API_URL + `client/addressbook/${params.addressID}`;
        const headers = {
            Authorization: `Bearer ${token}` 
        }

        await axios({
            method:'get',
            url: url,
            headers: headers,
        }).then(res => {
            if(res.data.result.is_deleted){
                set_is_deleted(true);
                set_last_edit_name(res.data.result.last_edit_name);                    
                if(!is_admin)
                    window.location.href = BASE_URL + "client";     
            }                
            reset(res.data.result)
            set_editable(1);
        }).catch (err => {
            MessageBox("Error", "Failed to get address.");
            console.log(err);
        });     
    }

    const deleteClient = async() =>{
        if(!editable) return;

        if(counter < 1 && !is_deleted){
            setCounter(counter + 1);
            if(params.isClient)
                MessageBox("Warning", "Do you want to delete this client's data? If yes, click the delete button again.");
            else
                MessageBox("Warning", "Do you want to delete this non-client's data? If yes, click the delete button again.");
        }else{
            let url = API_URL + 'utils/delete/addressbook';
            let token = window.localStorage.getItem('token');
            let headers = {
                Authorization: `Bearer ${token}` 
            }

            await axios({
                method:'post',
                url: url, 
                headers: headers,
                data: {id: params.addressID}
            }).then(() => {
                MessageBox("Address deleted.");
                if(is_admin && !is_deleted) {
                    getAddress();
                    if(params.sendDataToParent)
                        params.sendDataToParent({refresh: true});
                }
                else if(params.sendDataToParent)
                    params.sendDataToParent(true);
            }).catch(err => {
                MessageBox("Error", "Fail to delete address");
                console.log(err);
        });      
        }
    }

    const undoDeleteClient = async() => {
        if(!editable) return;

        let url = API_URL + '/client/addressbook/restoreRecord';
            let token = window.localStorage.getItem('token');
            let headers = {
                Authorization: `Bearer ${token}` 
            }

            try {
                const res = await axios({
                    method:'post',
                    url: url, 
                    headers: headers,
                    data: {id: params.addressID}
                })
                if(res.status === 200){
                    MessageBox("Success","Restored address.");
                    if(params.sendDataToParent)
                        params.sendDataToParent(true);
                }
            } catch (error) {
                MessageBox("Error", "Failed to restore address.");
                console.log(error.message);
            }
    }

    return (
        <View style={[style(WIDTH).container]}>        
        {editable?<View>
            {is_deleted && is_admin? (
                    <View style={[style(WIDTH).container_row2,style().table_row_deleted]}>
                        <Text style={[style(WIDTH).fontSize2, {alignSelf:'center', fontWeight:'bold'}]}>Deleted by: {last_edit_name}</Text>
                        <View style={style(WIDTH).container_icon} />
                        <Button icon={<Icon name='check' type="antdesign" size={option(WIDTH).iconSize1}/>} type="clear" 
                        style={{backgroundColor:'#11CD01'}}
                        onPress={()=>{
                            if(confirmCounter == 0) 
                            {
                                setConfirmCounter(confirmCounter + 1);
                                MessageBox("Warning", "You are deleting this client permanently. If this is your intention, kindly click the delete button again.");
                            }    
                            else deleteClient();}}/>
                    <View style={style(WIDTH).container_icon}></View>
                    <Button icon={<Icon name='close' type="antdesign" size={option(WIDTH).iconSize1}/>} type="clear"
                        style={{backgroundColor:'#ff2100'}}
                        onPress={()=>{undoDeleteClient();}}/>
                    </View> 
                ):null}   
            <View style={style(WIDTH).container_create_col} pointerEvents={is_deleted? "none":"auto"}>
                <View style={{marginBottom:WIDTH*0.025}}>
                    <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize4]}>Edit Address</Text></View>

                <View>
                    <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Name {errors.name?
                        <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                    <Controller
                        control={control}
                        render={({field:{onChange, onBlur, value}}) => (
                            <TextInput
                                style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}    
                                onBlur={onBlur}
                                onChangeText={value => onChange(value)}
                                value={value}
                                placeholder='Enter name...'
                                placeholderTextColor = {grey2}
                            />
                        )}
                        name="name"
                        rules={{ required: true}}
                    />
                </View>

                {params.isClient? 
                <View>
                    <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Code {errors.code?
                        <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                    <Controller
                        control={control}
                        render={({field:{onChange, onBlur, value}}) => (
                            <TextInput
                                onBlur={onBlur}
                                onChangeText={value => onChange(value)}
                                value={value}
                                placeholder='Enter code...'
                                placeholderTextColor = {grey2}
                                style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                            />
                        )}
                        name="code"
                        rules={{ required: params.isClient}}
                    />
                </View>: null}

                {params.isClient? 
                <View>
                <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Terms</Text>
                    <Controller
                        control={control}
                        render={({field:{onChange, onBlur, value}}) => (
                            <TextInput
                                style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}    
                                onBlur={onBlur}
                                onChangeText={value => onChange(value)}
                                value={value}
                                placeholder='Enter payment term...'
                                placeholderTextColor = {grey2}
                            />
                        )}
                        name="term"
                        rules={{ required: false}}
                    />
                </View>: null}

                <View>
                    <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Email {errors.email?
                        <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                    <Controller
                        control={control}
                        render={({field:{onChange, onBlur, value}}) => (
                            <TextInput
                                onBlur={onBlur}
                                onChangeText={value => onChange(value)}
                                value={value}
                                placeholder='Enter email...'
                                placeholderTextColor = {grey2}
                                style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                            />
                        )}
                        name="email"
                        rules={{ required: false}}
                    />
                </View>
                    
                <View style={{flexDirection:'row'}}>
                    <View style={style(WIDTH).create_col_space}>
                        <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Contact No. {errors.contact?
                            <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                        <Controller
                            control={control}
                            render={({field:{onChange, onBlur, value}}) => (
                                <TextInput
                                    style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                    onBlur={onBlur}
                                    onChangeText={value => onChange(value)}
                                    value={value}
                                    placeholder='Enter contact no...'
                                    keyboardType="numeric"
                                    placeholderTextColor = {grey2}
                                />
                            )}
                            name="contact"
                            rules={{required: false}}
                        />
                    </View>
                        
                    <View style={style(WIDTH).container_icon} />
                    
                    <View style={style(WIDTH).create_col_space}>
                        <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Fax (optional)</Text>
                        <Controller
                            control={control}
                            render={({field:{onChange, onBlur, value}}) => (
                                <TextInput
                                    style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                        onBlur={onBlur}
                                        onChangeText={value => onChange(value)}
                                        value={value}
                                        placeholder='Enter fax...'
                                        placeholderTextColor = {grey2}
                                    />
                            )}
                            name="fax"
                            rules={{ required: false}}
                        />
                    </View>
                </View>
                
                <View>
                    <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Address 1 {errors.address1?
                        <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                    <Controller
                        control={control}
                        render={({field:{onChange, onBlur, value}}) => (
                            <TextInput
                                style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}    
                                onBlur={onBlur}
                                onChangeText={value => onChange(value)}
                                value={value}
                                placeholder='Enter address...'
                                placeholderTextColor = {grey2}
                                multiline={true}
                            />
                        )}
                        name="address1"
                        rules={{ required: false}}
                    />
                </View>

                <View>
                <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Address 2 (optional)</Text>
                    <Controller
                        control={control}
                        render={({field:{onChange, onBlur, value}}) => (
                            <TextInput
                                onBlur={onBlur}
                                onChangeText={value => onChange(value)}
                                value={value}
                                placeholder='Enter address...'
                                placeholderTextColor = {grey2}
                                style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                multiline={true}
                            />
                        )}
                        name="address2"
                        rules={{ required: false}}
                    />
                </View>
                
                <View style={{flexDirection:'row'}}>
                    <View style={style(WIDTH).create_col_space}>
                        <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>City {errors.city?
                        <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                        <Controller
                            control={control}
                            render={({field:{onChange, onBlur, value}}) => (
                                <TextInput
                                    style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                    onBlur={onBlur}
                                    onChangeText={value => onChange(value)}
                                    value={value}
                                    placeholder='Enter city...'
                                    keyboardType="numeric"
                                    placeholderTextColor = {grey2}
                                />
                            )}
                            name="city"
                            rules={{required: false}}
                        />
                    </View>
                    <View style={style(WIDTH).container_icon} />
                    <View style={style(WIDTH).create_col_space}>
                        <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Zipcode {errors.zipcode?
                        <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                        <Controller
                            control={control}
                            render={({field:{onChange, onBlur, value}}) => (
                                <TextInput
                                    style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                    onBlur={onBlur}
                                    onChangeText={value => onChange(value)}
                                    value={value}
                                    placeholder='Enter zipcode...'
                                    placeholderTextColor = {grey2}
                                />
                            )}
                            name="zipcode"
                            rules={{ required: false}}
                        />
                    </View>
                </View>
                    
                <View style={{flexDirection:'row'}}>
                    <View style={style(WIDTH).create_col_space}>
                        <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>State {errors.state?
                        <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                        <Controller
                            control={control}
                            render={({field:{onChange, onBlur, value}}) => (
                                <TextInput
                                    style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                    onBlur={onBlur}
                                    onChangeText={value => onChange(value)}
                                    value={value}
                                    placeholder='Enter state...'
                                    keyboardType="numeric"
                                    placeholderTextColor = {grey2}
                                />
                            )}
                            name="state"
                            rules={{required: false}}
                        />
                    </View>
                    <View style={style(WIDTH).container_icon} />
                    <View style={style(WIDTH).create_col_space}>
                        <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Country {errors.country?
                        <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                        <Controller
                            control={control}
                            render={({field:{onChange, onBlur, value}}) => (
                                <TextInput
                                    style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                    onBlur={onBlur}
                                    onChangeText={value => onChange(value)}
                                    value={value}
                                    placeholder='Enter country...'
                                    placeholderTextColor = {grey2}
                                />
                            )}
                            name="country"
                            rules={{ required: truncate}}
                        />
                    </View>
                </View>
                <View style={{flexDirection:'row', justifyContent:'flex-end'}}>
                <ButtonPaper
                    style={[style(WIDTH).create_btn_red, {right: -WIDTH*0.015}]} 
                    labelStyle={style(WIDTH).create_btn_label} 
                    onPress={deleteClient}
                >
                    Delete
                </ButtonPaper>

                <ButtonPaper
                    style={[style(WIDTH).create_btn, {right: -WIDTH*0.015}]} 
                    labelStyle={style(WIDTH).create_btn_label} 
                    onPress={handleSubmit(onSubmit)}
                >
                    Save
                </ButtonPaper>
            </View>
            </View>

            {/* <View style={{flexDirection:'row', justifyContent:'flex-end'}}>
                <ButtonPaper
                    style={style(WIDTH).create_btn_red} 
                    labelStyle={style(WIDTH).create_btn_label} 
                    onPress={deleteClient}
                >
                    Delete
                </ButtonPaper>

                <ButtonPaper
                    style={style(WIDTH).create_btn} 
                    labelStyle={style(WIDTH).create_btn_label} 
                    onPress={handleSubmit(onSubmit)}
                >
                    Edit
                </ButtonPaper>
            </View> */}
        </View>: null}
        <Overlay isVisible={state_MsgBox.show} onBackdropPress={closeMessageBox} animationType='fade' style={{position:'absolute', zIndex:1000}}
                overlayStyle={[style(WIDTH).overlay_container]}>
                <Text style={style(WIDTH).msg_box_title}>{state_MsgBox.title}</Text>
                <Text style={style(WIDTH).msg_box_message}>{state_MsgBox.message}</Text>
                <ButtonPaper
                style={[style(WIDTH).msg_box_btn]} 
                labelStyle={style(WIDTH).create_btn_label} 
                onPress={closeMessageBox}>
                    Close
                </ButtonPaper>  
            </Overlay> 
        </View>
    )
}

export default Address_Edit
