import { StatusBar } from 'expo-status-bar';
import { useState, useEffect } from 'react';
import { Modal, Text, View, TextInput, Pressable,Dimensions, useWindowDimensions, Image } from 'react-native';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import { style } from './styles/style';
import { maxWidth } from './constants';
import { Button, Overlay, Icon } from 'react-native-elements';
import { API_URL, BASE_URL } from './config/config';
import {Button as ButtonPaper} from 'react-native-paper';
import axios from 'axios';

import Login from './components/Login';
import Navbar from './components/Navbar.js';
import ErrorPage from './components/ErrorPage';
import ChangePassword from './components/ChangePassword';
import Profile from './pages/Profile';

import SalesOrder_List from './pages/SalesOrder_list.js';
import SalesOrder_Create from './pages/SalesOrder_create.js';
import SalesOrder_View from './pages/SalesOrder_view.js';

import DeliveryOrder from './pages/DeliveryOrder.js';
import DeliveryOrder_Edit from './pages/DeliveryOrder_edit.js';

import Client_List from './pages/Client_list.js';
import Client_Create from './pages/Client_create.js';
import Client_Edit from './pages/Client_edit.js';

import SalesPerson_List from './pages/SalesPerson_list.js';
import SalesPerson_Create from './pages/SalesPerson_create.js';
import SalesPerson_Edit from './pages/SalesPerson_edit.js';

import Report from './pages/Report.js';
import Report_Company from './pages/Report_Company';
import Report_Sales from './pages/Report_Sales';
import Report_Client from './pages/Report_Client';
import Report_Staff from './pages/Report_Staff';

import Accounting from './pages/Accounting.js';
import Category_List from './pages/Category_list';

import Staff_List from './pages/Staff_list.js';
import Staff_Create from './pages/Staff_create.js';
import Staff_Edit from './pages/Staff_edit.js';

import Backup from './pages/Backup.js';

import { getWebsiteWidth, normalize } from './components/utilities';
import Address_create from './pages/Address_create';

export default function App() {
  const [WIDTH, set_WIDTH] = useState(getWebsiteWidth());
  const handleResize = () => { set_WIDTH(getWebsiteWidth()); }       

  const token = window.localStorage.getItem('token');
  const change_password = window.localStorage.getItem('change_password');
  const expiredDT = Number(window.localStorage.getItem('expired'));

  const [isLogin, setIsLogin] = useState(0);
  const [isChangePassword, setChangePassword] = useState(0);

  useEffect(() => {
    // if have token -> user login already
    // if change password = 0 -> has changed password already
    // if change password = 1 -> redirect to change password page
    token === null?setIsLogin(0):setIsLogin(1);
    change_password == 0? setChangePassword(1):setChangePassword(0);
    window.addEventListener('resize',handleResize);
    const dt = new Date().getTime();

    if(expiredDT)
    {
      if(dt > expiredDT) logout();
      else
      {  
        // console.log(new Date(expiredDT));
        // console.log("Life: " + ((expiredDT - dt)/1000/60/60).toFixed(2) + " hour(s)");
        window.setTimeout(() => {
          logout();
        }, expiredDT - dt);
      }
    }
  }, []);
  

  const logout = () => { 
    window.localStorage.clear();
    window.location.href = BASE_URL;
  }

  window.API_CALL_GET = async(url, payload) => {
    ShowLoading();
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: API_URL + url,
            headers: {Authorization: `Bearer ${window.localStorage.getItem('token')}`},
            params: payload
        }).then(res => {
            resolve(res.data.result);
            HideLoading();
        }).catch(err => { 
            if(err.response?.data?.comment === 'Invalid token, please login')
              logout();
            HideLoading();  
            console.log(err);
            reject(err.response?.data?.message || err.message); 
        })
    });        
  }
  
  window.API_CALL_POST = async(url, payload) => {
    ShowLoading();
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: API_URL + url,
            headers: {Authorization: `Bearer ${window.sessionStorage.getItem('token')}`},
            data: payload
        }).then(res => {
            HideLoading();
            resolve(res.data.data);
        }).catch(err => { 
            if(err.response?.data?.message === 'Invalid token')
              window.logout();
            HideLoading();  
            console.log(err);
            reject(err.response?.data?.message || err.message); 
        })
    });        
  }
  
  // for message box
  const [state_MsgBox, set_state_MsgBox] = useState({
    title: "",
    message: "",
    show: false
  });
  window.MessageBox = function(title, message, message2 = ""){
    if(typeof(message) == "undefined")
      set_state_MsgBox({title:title, message:message2, show:true});
    else
      set_state_MsgBox({title:title, message:message, show:true});
  }
  const closeMessageBox = () => {
    set_state_MsgBox({show:false});
  };

  // for loading screen
  const [showLoading, set_showLoading] = useState(false);
  window.ShowLoading = function(){
    set_showLoading(true);
  }
  window.HideLoading = function(){
    set_showLoading(false);
  }

    return (
    <View style={isLogin && isChangePassword? style(WIDTH).background:[style(WIDTH).background, style(WIDTH).background2]}>
      <Router>
        {isLogin && isChangePassword? <Navbar />:null}
        <View style={isLogin && isChangePassword? style(WIDTH).bigContainer:[style(WIDTH).login_container1, style(WIDTH).background2]}>        
          <Routes>
            <Route path='/' element={isLogin? <SalesOrder_List />:<Login />} />
            <Route exact path='/change_password' element={<ChangePassword />} />

            <Route exact path='/sales_order' element={<SalesOrder_List />} />
            <Route exact path='/sales_order/create' element={<SalesOrder_Create />} />
            <Route exact path='/sales_order/:shipmentID' element={<SalesOrder_View />} />

            <Route exact path='/delivery_order' element={<DeliveryOrder />} />
            <Route exact path='/delivery_order/:doID' element={<DeliveryOrder_Edit />} />

            <Route exact path='/client' element={<Client_List is_client={1}/>} />
            <Route exact path='/nonclient' element={<Client_List is_client={0}/>} />
            <Route exact path='/client_create' element={<Client_Create />} />

            <Route exact path='/salesperson' element={<SalesPerson_List />} />
            <Route exact path='/salesperson_create' element={<SalesPerson_Create />} />
            <Route exact path='/salesperson/:salesID' element={<SalesPerson_Edit />} />

            <Route exact path='/report_company' element={<Report_Company />} />
            <Route exact path='/report_staff' element={<Report_Staff />} />
            <Route exact path='/report_salesperson' element={<Report_Sales />} />
            <Route exact path='/report_client' element={<Report_Client />} />

            <Route exact path='/accounting' element={<Accounting />} />
            <Route exact path='/category' element={<Category_List />} />

            <Route exact path='/staff' element={<Staff_List />} />
            <Route exact path='/staff_create' element={<Staff_Create />} />
            <Route exact path='/staff/:userID' element={<Staff_Edit />} />

            <Route exact path='/profile' element={<Profile />} />

            <Route exact path='/backup' element={<Backup />} />
            <Route path='*' element={<ErrorPage />} />
          </Routes>
        </View>
      </Router>

      <Overlay isVisible={showLoading} animationType='fade' 
        backdropStyle={style().overlayBackdrop}
        overlayStyle={[style().overlay]}>
        {/* <Text style={styles.loading}>Loading...</Text> */}
        {/* <Icon name='loading1' type="antdesign" size={100} color='white'/> */}
        <Image source={require('./assets/logo1.png')}  resizeMode={'cover'} style={{width: 401, height: 128}}/>
      </Overlay>    

      <Overlay isVisible={state_MsgBox.show} onBackdropPress={closeMessageBox} animationType='fade'
        overlayStyle={[style(WIDTH).overlay_container]}>
        <Text style={style(WIDTH).msg_box_title}>{state_MsgBox.title}</Text>
        <Text style={style(WIDTH).msg_box_message}>{state_MsgBox.message}</Text>
        <ButtonPaper
          style={[style(WIDTH).msg_box_btn]} 
          labelStyle={style(WIDTH).create_btn_label} 
          onPress={closeMessageBox}>
            Close
        </ButtonPaper>  
      </Overlay> 
    </View>
  );
}
