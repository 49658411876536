import React, { useEffect, useState } from 'react'
import { View, Linking, Text, Picker } from 'react-native'
import { API_URL, BASE_URL } from '../config/config';
import axios from 'axios';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import ReactExport from 'react-export-excel';
import { style, option } from '../styles/style';
import { en, registerTranslation, DatePickerModal } from 'react-native-paper-dates';
registerTranslation('en', en);
import { Button, Icon } from 'react-native-elements';
import { getWebsiteWidth } from '../components/utilities';
import { Popable } from 'react-native-popable';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import {Bar} from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import pdfConverter from 'jspdf';
import { width } from '@mui/system';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const token = window.localStorage.getItem('token');
const headers = { Authorization: `Bearer ${token}` };

const label = ["Jan","Feb","Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];

let currentYear = new Date().getFullYear();
let lastYear = (parseInt(currentYear) - 1).toString();
let lastTwoYear = (parseInt(lastYear) - 1).toString();
let yearList = [lastTwoYear, lastYear, currentYear];

const YearList = yearList.map((item)=>( <Picker.Item key={item} label={item} value={item} /> ));

function Report_Company() {
    /*
        1. get current year, last year & last 2 year
        2. request get profit loss using the current year (reponse = profitLossData)
        3. when picker year change -> request again
        4. when profitLossData change -> format the graph data -> change the graph data (plGraphData)
    */
    if(window.localStorage.getItem('role') == 1) window.location.href = `${BASE_URL}`;
    
    const [year, setYear] = useState(currentYear);
    const [profitLossData, setPLData] = useState([]);
    const [plGraphData, setPLGraphData] = useState([]);
    const [excelData, setExcelData] = useState([]);
    const [WIDTH, set_WIDTH] = useState(getWebsiteWidth());
    

    useEffect(()=>{
        window.addEventListener('resize', () => { set_WIDTH(getWebsiteWidth()); });
    },[]);
    
    useEffect(()=>{
        getProfitLoss(year);
    },[year]);

    useEffect(() => {
        let dollarUSLocale = Intl.NumberFormat('en-US');
        let data = [];
        let exceldt = [];``
        for(let a=0; a<profitLossData.length;a++){
            let obj = {month: label[a]}
            if(profitLossData[a].gross_profit.length>0){
                for(let b=0; b<profitLossData[a].gross_profit.length; b++){
                    let key = profitLossData[a].gross_profit[b].currency;
                    let value =  Number(profitLossData[a].gross_profit[b].value);
                    
                    let finalValue = dollarUSLocale.format(value);
                    obj[key] = value;
                }
            }
            exceldt.push(obj);
        }
        setExcelData(exceldt);

        profitLossData.map(item=>{
            if(item.gross_profit.length > 0){
                item.gross_profit.map(pl => {
                    if(pl.currency == 'RM'){
                        data.push(pl.value);
                    }
                })
            }else{
                data.push(0);
            }
            
        })

        // console.log(data);
        setPLGraphData(data);
    }, [profitLossData]);

    const getProfitLoss = async(year) => {
        window.API_CALL_GET('report/pl', { "year":year }).then(
          function(data) {    
            setPLData(data);
          },
          function(error) { MessageBox("Error", error); }
      );
    }

    const Download = (props) => {
        return (
            <ExcelFile {...props} filename={props.filename} element={
                <Button icon={<Icon name='microsoft-excel' type="material-community" size={option(WIDTH).iconSize2}/>} type="clear" style={[option(WIDTH).buttonIcon,{pointerEvents: 'none'}]}/>}>
                <ExcelSheet data={props.data} name={props.name}>
                    <ExcelColumn label="Month" value="month"/>
                    <ExcelColumn label="RM" value={(col)=>(col.RM? col.RM:'-')}/>
                    <ExcelColumn label="EUR" value={(col)=>(col.EUR? col.EUR:'-')}/>
                    <ExcelColumn label="USD" value={(col)=>(col.USD? col.USD:'-')}/>
                    <ExcelColumn label="SGD" value={(col)=>(col.SGD? col.SGD:'-')}/>
                </ExcelSheet>
            </ExcelFile>
        );
    }

    const div2PDF = e => {
        ShowLoading();
        const but = e.target;
        but.style.display = "none";
        let input = window.document.getElementsByClassName("chart")[0];
        let ratio = 748 / input.clientWidth
    
        html2canvas(input,{scale:ratio}).then(canvas => {
            const img = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "pt");
            pdf.addImage(
                img,
                "png",
                20,20
                // input.offsetLeft,
                // input.offsetTop,
                // input.clientWidth,
                // input.clientHeight
            );
            pdf.save(`CompanyReport_${year}`);
            but.style.display = "block";
        })
        .catch(() => MessageBox("Error", "Failed to export PDF."))
        .finally(() => HideLoading())
    };


    if(plGraphData.length > 0){
        const data = {
            labels: label,
            datasets: [{
                label: 'P/L (RM)',
                backgroundColor: '#fdc30e',
                borderColor: 'rgba(0,0,0,1)',
                borderWidth: WIDTH*0.0015,
                data: plGraphData
            }]
        }

        return(
            <View style={style(WIDTH).container}>
                <View style={{flexDirection:'row'}}>
                    <View style={{flexDirection:'row', flex:1}}>
                        <Text style={[style(WIDTH).fontSize2,style(WIDTH).report_attribute]}>Year</Text>
                        <Picker 
                            style={[style(WIDTH).report_searchInput,style(WIDTH).fontSize2]} 
                            selectedValue={year}
                            onValueChange={itemValue => setYear(itemValue)}>
                            {YearList}
                        </Picker>
                    </View>
                    
                    <View style={style(WIDTH).container_row}>
                        <Popable content='Export PDF' position="left" backgroundColor='#FF9F1D' action='hover'>
                        <Button icon={<Icon name='file-pdf-o' type="font-awesome" size={option(WIDTH).iconSize2}/>} type="clear" style={option(WIDTH).buttonIcon}
                        onPress={(e) => div2PDF(e)}/>
                        </Popable>
                        <View style={style(WIDTH).container_icon} />
                        <Popable content='Export Excel' position="left" backgroundColor='#FF9F1D' action="hover">
                            <Download data={excelData} name={'Company_Profit_Loss'} filename={`CompanyProfitLoss_${year}`} />
                        </Popable> 
                    </View>
                </View>
                <View style={{marginTop:WIDTH*0.025, width:WIDTH*0.65}}>
                    <div className='chart'> 
                        <Bar
                            data={data}
                            options={{
                                responsive:true,
                                plugins:{
                                    legend:{
                                        display:true,
                                        position:'top',
                                        labels:{ font:{ size:WIDTH*0.01 } }
                                    },
                                    tooltip:{
                                        bodyFont:{ size:WIDTH*0.01 },
                                        titleFont:{ size:WIDTH*0.01 }
                                    },
                                    title:{
                                        display:true,
                                        text:'Company P/L ' + year,
                                        font:{ size:WIDTH*0.01 }
                                    }
                                },
                                scales:{
                                    yAxes:{
                                        grid:{ display:false},
                                        ticks:{ font:{ size:WIDTH*0.01 } }
                                    },
                                    xAxes:{
                                        grid:{ display:false },
                                        ticks:{ font:{ size:WIDTH*0.01 } }
                                    }
                                }
                            }}
                        />
                    </div>
                </View>
            </View>
        )
    }else{
        return null;
    }
}

export default Report_Company