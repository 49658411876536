import React, { useEffect, useState } from 'react'
import { View, Image, TextInput, Linking, Text, Picker, Pressable, Switch, Dimensions, TouchableOpacity } from 'react-native'
import { API_URL, BASE_URL } from '../config/config';
import axios from 'axios';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import ReactExport from 'react-export-excel';
import DropDownPicker from 'react-native-dropdown-picker';
import { style, option } from '../styles/style';
import { en, registerTranslation, DatePickerModal } from 'react-native-paper-dates';
registerTranslation('en', en);
import { Button, Icon } from 'react-native-elements';
import { getWebsiteWidth } from '../components/utilities';
import { Popable } from 'react-native-popable';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import {Bar, Chart as Chart2} from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import pdfConverter from 'jspdf';

import prev from '../assets/prev-arrow.png';
import next from '../assets/next-arrow.png';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const token = window.localStorage.getItem('token');
const headers = {
    Authorization: `Bearer ${token}` 
}

const label = ["Jan","Feb","Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];

let currentYear = new Date().getFullYear();
let lastYear = (parseInt(currentYear) - 1).toString();
let lastTwoYear = (parseInt(lastYear) - 1).toString();
let yearList = [lastTwoYear, lastYear, currentYear];

const YearList = yearList.map((item)=>( <Picker.Item key={item} label={item} value={item} /> ));

function Report_Staff() {
    if(window.localStorage.getItem('role') == 1) window.location.href = `${BASE_URL}`;

    const [year, setYear] = useState(currentYear);
    const [allData, setAllData] = useState([]);
    const [list, setList]= useState([]);
    const [nameList,setNameList] = useState([]);
    const [excelData, setExcelData] = useState([]);
    const [excelKey, setExcelKey] = useState([]);
    const [isOpen, setOpen] = useState(false);
    const [value, setValue] = useState('');
    const [selectedList, setSelected] = useState(null);
    const [filename,setFilename] = useState(`StaffReport_${year}`);
    const [WIDTH, set_WIDTH] = useState(getWebsiteWidth());
    const [pdfFileName, setPdfFileName] = useState(`StaffReport_${year}`);

    let pageTimer;

    const [showPage, setShowPage] = useState(false);
    const [partOfList, setPartOfList] = useState([]);
    // for pagination
    const [tablePage, setCurrentPage] = useState({totalPages:0, currentPage:1});
    const [isFirst, setFirst] = useState(1);
    const [isLast, setLast] = useState(0);
    const limit = 5;

    const onChangePage = (value) => {
        if(value != '' && (value <= tablePage.totalPages) && value > 0){
            clearTimeout(pageTimer);
            pageTimer = setTimeout(() => {   
                let goTo = (value-1)*limit;
                tablePage.currentPage = parseInt(value);
                tablePage.currentPage == 1? setFirst(1):setFirst(0);
                tablePage.currentPage == tablePage.totalPages? setLast(1):setLast(0);       
                setPartOfList(list.slice(goTo, (goTo+limit)));
            }, 800);
        }
    }

    const previousPage = () => {
        if(tablePage.totalPages > 1) {
            tablePage.currentPage = tablePage.currentPage - 1;
            setLast(0);
            tablePage.currentPage == 1? setFirst(1):setFirst(0);
            // if(tablePage.currentPage == 0) setFirst(1);
            let from = (tablePage.currentPage-1) * limit;
            let to = from+limit;
            setPartOfList(list.slice(from, to));
        }
    }

    const nextPage = () => {
        if(tablePage.totalPages > 1) {
            tablePage.currentPage = tablePage.currentPage + 1;
            setFirst(0);
            // console.log(tablePage.currentPage);
            // console.log(tablePage.totalPages);
            (tablePage.currentPage) == tablePage.totalPages? setLast(1):setLast(0)
            let from = (tablePage.currentPage-1) * limit;
            let to = from+limit;
            // console.log(`from: ${from} to:${to}`)
            setPartOfList(list.slice(from, to));
        }
    }

    const getStaffReport = async() => {
        window.API_CALL_GET('report/staffReport', { "year":year }).then(
          function(data) {    
            let all_staff = data;
            let staff_name = [];
            let exceldt = [];
            let all_data = all_staff.map((item,index)=>{
                let so_keys = Object.keys(item.tol_shipment);
                let so_data = [];
                staff_name.push({label:item.name, value:index.toString(), tol: item.tol_shipment});

                for(let i =0; i<so_keys.length;i++){
                    so_data.push(item.tol_shipment[so_keys[i]]);}
                let dt = {
                    data: so_data,
                    name: item.name}
                return dt;             
            })
            setAllData(all_data);
            setNameList(staff_name);

            staff_name = staff_name.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase())? 1: -1);  
            let first_row = [];
            for(let i =0; i<staff_name.length;i++)
                first_row[staff_name[i].value] = staff_name[i].label;
            first_row['ID'] = 'Name';
            exceldt.push(first_row);

            for(let a=0; a<label.length; a++){
                let obj = {ID:label[a]};
                for(let b=0; b<staff_name.length; b++)
                    obj[staff_name[b].value] = staff_name[b].tol[a+1];
                exceldt.push(obj);
            }
            let key = staff_name.map(a => a.value);
            key.unshift('ID');
            setExcelKey(key);
            setExcelData(exceldt);     
          },
          function(error) { MessageBox("Error", error); }
      );
    }

    useEffect(async()=>{
        ShowLoading();
        await getStaffReport();
        HideLoading();
        setFilename(`StaffReport_${year}`);
        setPdfFileName(`StaffReport_${year}`);
        window.addEventListener('resize', () => { set_WIDTH(getWebsiteWidth()); });
    },[year])

    useEffect(()=>{
        let line_list = allData.map((item, index) => {
            let dtset2 = {
                labels:label,
                datasets:[{
                    data: item.data,
                    label: 'Number of S.O.',
                    backgroundColor: '#fdc30e',
                    borderColor: 'rgba(0,0,0,1)',
                    borderWidth: WIDTH*0.0015,
                }]
                
            }

            return(
                <View key={index} style={{marginBottom:WIDTH*0.05, width:WIDTH*0.65}}>
                {/* <Text style={[style(WIDTH).fontSize2,{fontWeight:'bold'}]}>{item.name}</Text> */}
                <div id={index} style={{backgroundColor:'#FFFFFF'}}>
                    <Bar
                        height={100}
                        data={dtset2}
                        options={{
                            responsive:true,
                            plugins:{
                                legend:{
                                    display:true,
                                    position:'top',
                                    labels:{ font:{ size:WIDTH*0.01 }}
                                },
                                tooltip:{
                                    bodyFont:{ size:WIDTH*0.01 },
                                    titleFont:{ size:WIDTH*0.01 }
                                },
                                title:{
                                    display:true,
                                    text:'Number of S.O. in ' + year + " - " + item.name,
                                    font:{ size:WIDTH*0.01 }
                                }
                            },
                            scales:{
                                yAxes:{
                                    grid:{ display:false},
                                    ticks:{ font:{ size:WIDTH*0.01 } }
                                },
                                xAxes:{
                                    grid:{ display:false },
                                    ticks:{ font:{ size:WIDTH*0.01 } }
                                }
                            }
                        }}
                    />
                </div>
                </View>
            )
        });

        setList(line_list);
    },[allData, WIDTH])

    const onChange = (index) =>{
        if(index!=undefined || index!=''){
            setSelected(list[index]);
        }
    }

    useEffect(()=>{
        if(list.length > limit && selectedList == null){
            let totalpage = Math.ceil(list.length/limit);
            tablePage['totalPages'] = totalpage;
            // setFirst(1);
            // setLast(0);
            setShowPage(true);
            setPartOfList(list.slice(0,limit));
        }else{
            setPartOfList(list)
        }
    },[list]);

    useEffect(()=>{
        if(selectedList!=null) {
            setShowPage(false);
            let name = allData[selectedList.key].name;
            setPdfFileName(`Staff_${name}_${year}`);
        }
    },[selectedList])

    const div2PDF = async(e)  => {
        ShowLoading();
        const but = e.target;
        but.style.display = "none";
        let input = window.document.getElementsByClassName("all_chart")[0];
        const pdf = new jsPDF("p", "pt");
        let ori_y = 20;
        let ratio = 748 / input.childNodes[0].clientWidth

        for(let a=0; a<input.childElementCount; a++){
            html2canvas(input.childNodes[a], {scale:ratio}).then(async(canvas) => {
                const img = canvas.toDataURL("image/png");
                if(canvas.height + ori_y > 840){
                    ori_y = 20;
                    pdf.addPage()
                        pdf.addImage(
                            img,
                            "png",
                            20,ori_y
                        );
                        ori_y = canvas.height + ori_y - 40;
                    
                }else{
                    pdf.addImage(
                        img,
                        "png",
                        20,ori_y
                    );
                    ori_y = canvas.height + ori_y - 40;
                }
            }).then(()=>{
                if(a == input.childElementCount-1){
                    pdf.save(pdfFileName+'.pdf');
                    but.style.display = "block";
                }
            }).catch(()=>{
                MessageBox("Error", "Failed to export PDF.");
                return;
            }).finally(()=>{
                HideLoading();
            })
        }
    };

    return(
        <View style={[style(WIDTH).container,{backgroundColor:'#ffffff'}]}>
            <View style={{flexDirection:'row'}}>
                <View style={{flexDirection:'row', flex:1}}>
                    <Text style={[style(WIDTH).fontSize2,style(WIDTH).report_attribute]}>Year</Text>
                    <Picker 
                        style={[style(WIDTH).report_searchInput,style(WIDTH).fontSize2]} 
                        selectedValue={year}
                        onValueChange={itemValue => setYear(itemValue)}
                    >
                        {YearList}
                    </Picker>
                </View>
                <View style={style(WIDTH).container_row}>
                    <Popable content='Export PDF' position="left" backgroundColor='#FF9F1D' action='hover'>
                        <Button icon={<Icon name='file-pdf-o' type="font-awesome" size={option(WIDTH).iconSize2}/>} type="clear"style={[option(WIDTH).buttonIcon]} 
                            onPress={async(e) => {
                                ShowLoading();
                                await div2PDF(e)
                                HideLoading();}}/>
                    </Popable>
                    <View style={style(WIDTH).container_icon} />
                    <Popable content='Export Excel' position="left" backgroundColor='#FF9F1D' action="hover">
                        <ExcelFile filename={filename} element={
                            <Button icon={<Icon name='microsoft-excel' type="material-community" size={option(WIDTH).iconSize2}/>} type="clear" style={[option(WIDTH).buttonIcon,{pointerEvents: 'none'}]}/>}>
                            <ExcelSheet data={excelData} name={`staff_report_${year}`}>
                            {
                                excelKey.map((key, index)=>(
                                    <ExcelColumn key={index} label={key} value={key} />
                                ))
                            }
                            </ExcelSheet>
                        </ExcelFile>  
                    </Popable> 
                </View>
            </View>
            <View style={style(WIDTH).container_icon} />
            <View style={{flexDirection:'row',width: 0.65*WIDTH,zIndex:10000}}>
                <View style={{flexDirection:'row', flex:1, zIndex:10000}}>
                    <Text style={[style(WIDTH).fontSize2,style(WIDTH).report_attribute]}>Staff</Text>
                    <View style={{flex:0.38}}>
                    <DropDownPicker
                        open={isOpen}
                        value={value}
                        items={nameList}
                        setOpen={setOpen}
                        setValue={setValue}
                        searchable={true}
                        closeOnBackPressed={true}
                        style={[style(WIDTH).report_searchInput, style(WIDTH).fontSize2]}
                        dropDownContainerStyle={style(WIDTH).dropDownContainer}
                        listMode="SCROLLVIEW"
                        mode="SIMPLE"
                        showTickIcon={false}
                        placeholder="Select staff..."
                        placeholderStyle={{color:'#808080'}}
                        textStyle={[style(WIDTH).fontSize2]}
                        showArrowIcon={false}
                        searchPlaceholder="Search..."
                        scroll
                        onChangeValue={(value) => { onChange(value) }}
                    /> 
                </View>
                    {/* <TouchableOpacity style={[style(WIDTH).searchInput,style(WIDTH).fontSize2, {flex:0.4}]} onPress={()=> showStaffOverlay()} >
                    <Text style={[style(WIDTH).fontSize2]}>{staff_name || '---'}</Text>
                    </TouchableOpacity> */}
                </View>
            </View>
            <View style={style(WIDTH).container_icon2} />
            <div className='all_chart'>
            {selectedList? selectedList:partOfList}
            </div>

        {showPage? 
            (<View style={style().container_first}>
                <Text style={[style(WIDTH).text_grey, style(WIDTH).text_total_item, style(WIDTH).fontSize2]}>{`Total items: ${list.length}`}</Text>
                <View style={style(WIDTH).container_second}>
                    <Pressable 
                        style={isFirst? style(WIDTH).paginationButtonDisabled:style(WIDTH).paginationButton} 
                        onPress={previousPage} 
                        disabled={isFirst}
                    >
                        <Image source={prev} resizeMode='contain' style={[style(WIDTH).pagination_arrow,isFirst && style().disableTint]} />
                    </Pressable>
                        
                    <Text style={[style(WIDTH).text_grey,style(WIDTH).text_page, style(WIDTH).fontSize2]}>
                        {`Page `}
                        <Text style={[style().text_current_page, style(WIDTH).fontSize3]}>{`${tablePage.currentPage}`}</Text>
                        {` of ${tablePage.totalPages}`}
                    </Text>
                        
                    <Pressable 
                        style={isLast? style(WIDTH).paginationButtonDisabled: style(WIDTH).paginationButton} 
                        onPress={nextPage}
                        disabled={isLast}
                    >
                        <Image source={next} resizeMode='contain' style={[style(WIDTH).pagination_arrow,isLast && style().disableTint]} />
                    </Pressable>
                </View>
                
                <View style={style(WIDTH).container_third}>
                    <Text style={[style(WIDTH).text_grey,style(WIDTH).text_page, style(WIDTH).fontSize2]}>Go to page: </Text>
                    <TextInput 
                        style={[style(WIDTH).fontSize2, style(WIDTH).go_to_input]}
                        onChangeText={(value) => onChangePage(value)}
                        placeholder={`${tablePage.currentPage}`}
                    />
                </View>
            </View>):null}
        </View>
    )
}

export default Report_Staff