import React, {useEffect, useState} from 'react'
import { Picker,View, Text, StyleSheet, Pressable, TextInput} from 'react-native'
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { API_URL, BASE_URL } from '../config/config';
import { useParams} from 'react-router';
import { getWebsiteWidth } from '../components/utilities';
import { style, grey2, option } from '../styles/style';
import {Button as ButtonPaper} from 'react-native-paper';
import { Overlay, Icon, Tooltip, Button } from 'react-native-elements';

function Staff_Edit(props) {
    const value = useParams();
    const userID = value.userID;
    const token = window.localStorage.getItem('token');
    // console.log(userID)
    const [WIDTH, set_WIDTH] = useState(getWebsiteWidth());
    const [roleOption, setRoleOption] = useState(null);
    const [roleList, setRoleList] = useState('');
    const [counter, setCounter] = useState(0);
    const [last_edit_name, set_last_edit_name] = useState('');
    const [confirmCounter, setConfirmCounter] = useState(0);
    const [is_deleted, set_is_deleted] = useState(false);
    const [isAdmin, setAdmin] = useState(false);
    const [editable, set_editable] = useState(0);

    useEffect(() => {
        window.localStorage.getItem('role') != 1? setAdmin(true): window.location.href = `${BASE_URL}`;
        window.addEventListener('resize', () => { set_WIDTH(getWebsiteWidth()); });
        onLoad();
    }, [])

    const onLoad = async() => {
        ShowLoading();
        await getOption();
        await getStaff();
        HideLoading();
    }

    const getOption = async() => {
        let url = API_URL + 'utils/options/role';
        let token = window.localStorage.getItem('token');
        let headers = {
            Authorization: `Bearer ${token}` 
        }

        try {
            const res = await axios({
                method:'get',
                url: url, 
                headers: headers,
            })
            if(res.status === 200){
                setRoleOption(res.data.result);
            }
        } catch (error) {
            MessageBox("Error", "Failed to role option.");
            console.log(error.message);
        }
    }

    useEffect(()=>{
        if(!roleOption) return;
        let rlist = roleOption.map(item => {
            return (item.id<=window.localStorage.getItem('role')||window.localStorage.getItem('role')==0? <Picker.Item key={item.id} label={item.title} value={item.id} />: null)
        });
        setRoleList(rlist);
    },[roleOption])

    const { register, setValue, handleSubmit, control, reset, formState: { errors } } = useForm({
        defaultValues: {
            roleID:1,
            name:'',
            email:'',
            contact:'',
            password:'',
            confirmPassword:'',
            status:''
        },
        mode: 'onBlur'
    });
    
    const onSubmit = async(data) => {
        if(!editable) return;

        ShowLoading();
        let url = API_URL + 'user';
        let token = window.localStorage.getItem('token');
        let headers = {
            Authorization: `Bearer ${token}` 
        }

        let payload;
        payload = {
            "mode":'Edit',
            "email":data.email,
            "name":data.name,
            "contact":data.contact,
            "roleID":data.roleID,
            "userID":userID,
            "status": data.status
        }       

        await axios({
            method:'post',
            url: url, 
            headers: headers,
            data:payload
        }).then(()=>{
            MessageBox("","Saved");
        }).catch (err => {
            if(typeof(err.response.data.result.errors) != "undefined")
            {
                let msg = '';
                for(let i=0; i<err.response.data.result.errors.length; i++)
                {   
                    if(msg != '')   msg += '\n';
                    msg += err.response.data.result.errors[i].param + ': ' + err.response.data.result.errors[i].msg;
                }
                MessageBox('Error', msg);
            }
            else
                MessageBox("Error", "Failed to edit staff info.");
        })
        .finally(() => HideLoading());  
    }

    const deleteStaff = async() => {
        if(!editable) return;

        if(counter < 1 && !is_deleted){
            setCounter(counter + 1);
            MessageBox("Warning", "Do you want to delete this staff's data? If yes, click the delete button again.");
        }else{
            let url = API_URL + 'utils/delete/user';
            let token = window.localStorage.getItem('token');
            let headers = {
                Authorization: `Bearer ${token}` 
            }
            ShowLoading();
            await axios({
                method:'post',
                url: url, 
                headers: headers,
                data: {id: userID}
            }).then(() => {
                if(isAdmin)
                    window.location.reload(false);
                else
                    window.location.href = `${BASE_URL}staff`;                
            })
            .catch(() => MessageBox("Error", "Failed to delete staff."))
            .finally(() => HideLoading());              
        }
        
    }

    const onChange = arg => {
        return {
            value: arg.nativeEvent.text,
        };
    };

    const getStaff = async() => {
        window.API_CALL_GET(`user/${value.userID}`).then(
          function(data) {        
            if(data.is_deleted){
              set_is_deleted(true);
              set_last_edit_name(data.last_edit_name);
            }
            reset(data)
            set_editable(true);
          },
          function(error) { MessageBox("Error", error); }
        );
    }

    const undoDeleteStaff = async() => {
        if(!editable) return;

        let url = API_URL + 'user/restoreRecord';
            let token = window.localStorage.getItem('token');
            let headers = {
                Authorization: `Bearer ${token}` 
            }

            try {
                const res = await axios({
                    method:'post',
                    url: url, 
                    headers: headers,
                    data: {userID:userID}
                })
                if(res.status === 200){
                    MessageBox("Success","Restored staff.");
                    window.location.reload();
                }
            } catch (error) {
                MessageBox("Error", "Failed to restore staff.");
                console.log(error.message);
            }
    }

    return (
        <View>
            {editable?  <View style={[style(WIDTH).container,{width:WIDTH/2}]}>
            {is_deleted && isAdmin? (
                    <View style={[style(WIDTH).container_row2,style().table_row_deleted]}>
                        <Text style={[style(WIDTH).fontSize2, {alignSelf:'center', fontWeight:'bold'}]}>Deleted by: {last_edit_name}</Text>
                        <View style={style(WIDTH).container_icon} />
                        <Button icon={<Icon name='check' type="antdesign" size={option(WIDTH).iconSize1}/>} type="clear" 
                        style={{backgroundColor:'#11CD01'}}
                        onPress={()=>{
                            if(confirmCounter == 0) 
                            {
                                setConfirmCounter(confirmCounter + 1);
                                MessageBox("Warning", "You are deleting this staff permanently. If this is intention, kindly click the delete button again.");
                            }    
                            else deleteStaff();}}/>
                    <View style={style(WIDTH).container_icon}></View>
                    <Button icon={<Icon name='close' type="antdesign" size={option(WIDTH).iconSize1}/>} type="clear"
                        style={{backgroundColor:'#ff2100'}}
                        onPress={()=>{undoDeleteStaff();}}/>
                    </View> 
                ):null}  

            <View style={style(WIDTH).container_create_col} pointerEvents={is_deleted? "none":"auto"}>
                <View style={{marginBottom:WIDTH*0.025}}>
                    <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize4]}>Edit Staff</Text></View>

                <View style={{flexDirection:'row'}}>
                    <View style={style(WIDTH).create_col_space}>
                        <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Name {errors.name?
                            <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                        <Controller
                            control={control}
                            render={({field:{onChange, onBlur, value}}) => (
                                <TextInput
                                    style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                    onBlur={onBlur}
                                    onChangeText={value => onChange(value)}
                                    value={value}
                                    placeholder='Enter name...'
                                    placeholderTextColor = {grey2}
                                />
                            )}
                            name="name"
                            rules={{required: true}}
                        />
                    </View>                    
                    <View style={style(WIDTH).container_icon} />
                    <View style={style(WIDTH).create_col_space}>
                        <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Role {errors.roleID?
                            <Text style={style(WIDTH).fontError}>is REQUIRED, please select!</Text>: null}</Text>
                        <Controller
                            control={control}
                            render={({field:{onChange, onBlur, value}}) => (
                                <Picker
                                    selectedValue={value}
                                    style = {[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                    onValueChange={(itemValue, itemIndex)=>onChange(itemValue)}
                                    onBlur={onBlur}
                                >
                                    {roleList}                         
                                </Picker>
                            )}
                            name="roleID"
                            rules={{ required: true}}
                        />
                    </View>
                </View>               
                
                <View style={{flexDirection:'row'}}>
                    <View style={style(WIDTH).create_col_space}>
                        <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Email {errors.email?
                            <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                        <Controller
                            control={control}
                            render={({field:{onChange, onBlur, value}}) => (
                                <TextInput
                                    style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                    onBlur={onBlur}
                                    onChangeText={value => onChange(value)}
                                    value={value}
                                    placeholder='Enter email...'
                                    placeholderTextColor = {grey2}
                                />
                            )}
                            name="email"
                            rules={{required: true}}
                        />
                    </View>
                    <View style={style(WIDTH).container_icon} />
                    <View style={style(WIDTH).create_col_space}>
                        <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Contact {errors.contact?
                            <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                        <Controller name="contact" control={control} rules={{required: true}}
                            render={({field:{onChange, onBlur, value, error}}) => (
                                <TextInput
                                    error={error}
                                    style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                    onBlur={onBlur}
                                    onChangeText={value => onChange(value)}
                                    value={value}
                                    placeholder='Enter contact...'
                                    placeholderTextColor = {grey2}
                                />
                            )}

                        />
                    </View>  
                </View>

                <View style={{flexDirection:'row'}}>
                    <View style={style(WIDTH).create_col_space}>
                        <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Status {errors.status?
                            <Text style={style(WIDTH).fontError}>is REQUIRED, please select!</Text>: null}</Text>
                        <Controller name="status" control={control} rules={{required: true}}
                             render={({field:{onChange, onBlur, value}}) => (
                                <Picker
                                    selectedValue={value}
                                    style = {[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                    onValueChange={(itemValue, itemIndex)=>onChange(itemValue)}
                                    onBlur={onBlur}
                                >
                                    <Picker.Item label="Active" value="1" />
                                    <Picker.Item label="Inactive" value="0" />                          
                                </Picker>
                            )}
                        />
                    </View>  
                    <View style={style(WIDTH).container_icon} />
                    <View style={style(WIDTH).create_col_space}></View>
                    </View>  

                <View style={{flexDirection:'row', justifyContent:'flex-end'}}>
                <ButtonPaper
                    style={[style(WIDTH).create_btn_red, {right: -WIDTH*0.015}]} 
                    labelStyle={style(WIDTH).create_btn_label} 
                    onPress={deleteStaff}
                >
                    Delete
                </ButtonPaper>

                <ButtonPaper
                    style={[style(WIDTH).create_btn, {right: -WIDTH*0.015}]} 
                    labelStyle={style(WIDTH).create_btn_label} 
                    onPress={handleSubmit(onSubmit)}
                >
                    Save
                </ButtonPaper> 
            </View>    
            </View>

            {/* <View style={{flexDirection:'row', justifyContent:'flex-end'}}>
                <ButtonPaper
                    style={style(WIDTH).create_btn_red} 
                    labelStyle={style(WIDTH).create_btn_label} 
                    onPress={deteleStaff}
                >
                    Delete
                </ButtonPaper>

                <ButtonPaper
                    style={style(WIDTH).create_btn} 
                    labelStyle={style(WIDTH).create_btn_label} 
                    onPress={handleSubmit(onSubmit)}
                >
                    Edit
                </ButtonPaper> 
            </View>                       */}
        </View>: null }
        </View>
        
       
    )
}

export default Staff_Edit