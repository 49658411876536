import React, { useState, useEffect, useRef } from 'react';
import { Image, Picker, View, Text, StyleSheet, TextInput, Pressable } from 'react-native';
import axios from 'axios';
import { API_URL, BASE_URL } from '../config/config';
import { ITEM_PER_TABLE_PAGE } from '../constants';
import { DataTable, Button as ButtonPaper} from 'react-native-paper';
import { style, option } from '../styles/style.js';
import { useHover, Hoverable } from 'react-native-web-hooks';

import prev from '../assets/prev-arrow.png';
import next from '../assets/next-arrow.png';
import { Button, Icon } from 'react-native-elements';
import { getWebsiteWidth } from '../components/utilities';
import { Popable } from 'react-native-popable';

function SalesPerson_List() {
    const [WIDTH, set_WIDTH] = useState(getWebsiteWidth());

    const initialSales = [{
        id:'',
        name:'',
        gender:'',
        contact:'',
        email:'',
        status:'',
    }]

    // for sales table
    const [sales, setSales] = useState(initialSales);
    const [salesList, setSalesList] = useState('');
    
    // for searching
    const [showSearch, setShowSearch] = useState(0);
    const [searchParams, setSearchParams] = useState([]);
    
    // for pagination
    const [tablePage, setCurrentPage] = useState({totalPages:0, currentPage:0});
    const [totalItems, setTotalItems] = useState(0);
    const [isFirst, setFirst] = useState(1);
    const [isLast, setLast] = useState(0);

    const isHovered = useHover(ref);
    const ref = useRef(null);

    const [isAdmin, setAdmin] = useState(false);

    let pageTimer;

    // redirect to edit page when click the row
    const handleClickRow = (id) => { if(isAdmin) window.location.href = `${BASE_URL}salesperson/${id}`};

    const getSales = async(params) => {
        let queryParams = {};
        for(const sp in searchParams){
            if(searchParams[sp] != '')
                queryParams['filter[' + sp + ']'] = searchParams[sp];
        }
        
        queryParams['size'] = ITEM_PER_TABLE_PAGE;
        queryParams['page'] = 0;
        if(params && params.page){
            queryParams['page'] = params.page;
        }

        if(!isAdmin)
            queryParams['filter[status]'] = '1'; // not admin only can see active staff

        window.API_CALL_GET('salesperson', queryParams).then(
          function(data) {        
            let salesData = data.dataList;
            let numOfSales = salesData.length;
            let totalPage = data.totalPages;
            let currentPage = data.currentPage;

            setTotalItems(data.totalItems);
            setCurrentPage({totalPages: totalPage, currentPage: currentPage});

            (currentPage+1) == totalPage? setLast(1):setLast(0);
            currentPage == 0? setFirst(1):setFirst(0);
            if(numOfSales == 0) setSales(initialSales);
            let all_sales = [];

            for(let a = 0; a < numOfSales; a++){
                let obj = {
                    id:salesData[a].salesID,
                    name:salesData[a].name,
                    gender:salesData[a].gender,
                    email:salesData[a].email,
                    contact:salesData[a].contact,
                    status:salesData[a].status,
                    is_deleted:salesData[a].is_deleted   
                };
                all_sales.push(obj);

                // a>0? setSales(oldSales => [...oldSales, obj]):setSales([obj]);
            }
            setSales(all_sales);
            
          },
          function(error) { MessageBox("Error", error); }
        );
    }

    useEffect(() => {
        let slist = sales.map((localState)=>{
            return(localState.is_deleted && !isAdmin? null:(
                <Hoverable key={localState.id}>
                {isHovered => (
                    <Pressable key={localState.id} onPress={() => {if(isAdmin) handleClickRow(localState.id)}} 
                    style={[style(WIDTH).table_row,, isHovered && style(WIDTH).hover, localState.is_deleted && style(WIDTH).table_row_deleted]}>
                        <DataTable.Row key={localState.id}>
                            <View style={[style(WIDTH).dataTable_content, {flex:1}]}><Text style={style(WIDTH).dataTable_content_flex}>{localState.name || '-'}</Text></View>
                            <View style={[style(WIDTH).dataTable_content, {flex:0.35}]}><Text style={style(WIDTH).dataTable_content_flex}>{localState.gender=='M'? 'Male': 'Female' || '-'}</Text></View>
                            <View style={[style(WIDTH).dataTable_content, {flex:0.5}]}><Text style={style(WIDTH).dataTable_content_flex}>{localState.contact || '-'}</Text></View>
                            <View style={[style(WIDTH).dataTable_content, {flex:1}]}><Text style={style(WIDTH).dataTable_content_flex}>{localState.email || '-'}</Text></View>
                            <View style={[style(WIDTH).dataTable_content, {flex:0.35}]}><Text style={style(WIDTH).dataTable_content_flex}>{localState.status? 'Active':'Inactive' || '-'}</Text></View>
                        </DataTable.Row>
                    </Pressable>
                )}
                </Hoverable>
            ))       
        })

        setSalesList(slist);
    },[sales, WIDTH]);

    useEffect(() => {
        let role = window.localStorage.getItem('role');
        role != 1? setAdmin(true): setAdmin(false);
        window.addEventListener('resize', () => { set_WIDTH(getWebsiteWidth()); });
        getSales();
    }, [])

    const [Name, setName] = useState('');
    const [Contact, setContact] = useState('');
    const [Email, setEmail] = useState('');
    const [Gender, setGender] = useState('');
    const [Status, setStatus] = useState('');

    const clearFilter = () => {
        setName('');
        setContact('');
        setGender('');
        setEmail('');
        setStatus('');
        setSearchParams([]);
    }

    const onChangeValue = (key, value) => {
        searchParams[key] = value;
    }

    const onChangePage = (value) => {
        if(value != '' && (value <= tablePage.totalPages) && value > 0){
            clearTimeout(pageTimer);
            pageTimer = setTimeout(() => {          
                getSales({ page:value-1 });
            }, 800);
        }
    }

    const previousPage = () => {
        if(tablePage.totalPages > 1) {
            tablePage.currentPage = tablePage.currentPage - 1;
            setLast(0);
            tablePage.currentPage == 0? setFirst(1):setFirst(0)
            // if(tablePage.currentPage == 0) setFirst(1);
            getSales({ page:tablePage.currentPage });
        }
    }

    const nextPage = () => {
        if(tablePage.totalPages > 1) {
            tablePage.currentPage = tablePage.currentPage + 1;
            setFirst(0);
            (tablePage.currentPage+1) == tablePage.totalPages? setLast(1):setLast(0)
            getSales({ page:tablePage.currentPage });
        }
    }

    return (
        <View style={style(WIDTH).container} >
            <DataTable style={{zIndex:1}}>
                <View style={style(WIDTH).container_row}>
                    <Popable content='Refresh' position="left" backgroundColor='#FF9F1D' action="hover"> 
                    <Button icon={<Icon name='refresh' type="simplelineicons" size={option(WIDTH).iconSize2}/>} type="clear" style={{padding: '5%'}} 
                        onPress={getSales}/></Popable>
                    <View style={style(WIDTH).container_icon}></View>
                    <Popable content='Search' position="left" backgroundColor='#FF9F1D' action="hover"> 
                    <Button icon={<Icon name='search1' type="antdesign" size={option(WIDTH).iconSize2}/>} type="clear" style={{padding: '5%'}}
                        onPress={()=>setShowSearch(!showSearch)}/></Popable>    
                    <View style={style(WIDTH).container_icon}></View>
                    {isAdmin? 
                        <Popable content='Create' position="left" backgroundColor='#FF9F1D' action="hover"> 
                        <Button icon={<Icon name='addfile' type="antdesign" size={option(WIDTH).iconSize2}/>} type="clear" style={{padding: '5%'}}
                        onPress={()=>{ window.location.href = `${BASE_URL}salesperson_create`}}/></Popable>: null}
                </View>

                <View style={[style().container_column, {marginTop:'2%'}]}>
                { showSearch? 
                        (<View style={style(WIDTH).container_search_input}>
                            <ButtonPaper
                                style={style(WIDTH).button_orange} 
                                labelStyle={style().button_label_white} 
                                onPress={clearFilter}
                            >
                                <Text style={style(WIDTH).fontSize2}>Clear Filter</Text>
                            </ButtonPaper>
                            <DataTable.Header style={style(WIDTH).dataTable_header2}>
                                <View style={style(WIDTH).scontainer}>
                                    <TextInput
                                        style={[style(WIDTH).searchInput,style(WIDTH).fontSize2]}
                                        value={Name}
                                        onChangeText={(value) => {
                                            onChangeValue("name",value);
                                            setName(value);
                                        }}
                                        placeholder="Name"
                                    />
                                </View>

                                <View style={style(WIDTH).scontainer}>
                                    <Picker
                                        style={[style(WIDTH).searchInput,style(WIDTH).fontSize2, {padding:1}]}
                                        selectedValue={Gender}
                                        onValueChange={(itemValue)=>{
                                            onChangeValue("gender",itemValue);
                                            setGender(itemValue);
                                        }}
                                    >
                                        <Picker.Item label='Gender' value='' />
                                        <Picker.Item label='Male' value='M' />
                                        <Picker.Item label='Female' value='F' />
                                    </Picker>
                                </View>

                                <View style={style(WIDTH).scontainer}>
                                    <TextInput
                                        style={[style(WIDTH).searchInput,style(WIDTH).fontSize2]}
                                        value={Contact}
                                        onChangeText={(value) => {
                                            onChangeValue("contact",value);
                                            setContact(value);
                                        }}
                                        placeholder="Contact"
                                    />
                                </View>

                                <View style={style(WIDTH).scontainer}>
                                    <TextInput
                                        style={[style(WIDTH).searchInput,style(WIDTH).fontSize2]}
                                        value={Email}
                                        onChangeText={(value) => {
                                            onChangeValue("email",value);
                                            setEmail(value);
                                        }}
                                        placeholder="Email"
                                    />
                                </View>

                                <View style={style(WIDTH).scontainer}>
                                    <Picker enabled={isAdmin}
                                        style={[style(WIDTH).searchInput,style(WIDTH).fontSize2, {padding:1}]}
                                        selectedValue={Status}
                                        onValueChange={(itemValue)=>{
                                            onChangeValue("status",itemValue);
                                            setStatus(itemValue);
                                        }}
                                    >
                                        <Picker.Item label='Status' value='' />
                                        <Picker.Item label='Active' value='1' />
                                        <Picker.Item label='Inactive' value='0' />
                                    </Picker>
                                </View>
                            </DataTable.Header>

                            <ButtonPaper 
                                style={style(WIDTH).button_orange} 
                                labelStyle={style().button_label_white}  
                                onPress={() => {getSales();}}> 
                                    <Text style={style(WIDTH).fontSize2}>Search</Text>
                            </ButtonPaper>
                        </View>):null
                    }
                </View>

                <DataTable.Header style={[style(WIDTH).dataTable_header_border, {marginTop:'2%'}]}>
                    <View style={[style(WIDTH).dataTable_header,{flex:1}]}><Text style={[style(WIDTH).dataTable_content, {fontWeight:'bold'}]}>Name</Text></View>
                    <View style={[style(WIDTH).dataTable_header,{flex:0.35}]}><Text style={[style(WIDTH).dataTable_content, {fontWeight:'bold'}]}>Gender</Text></View>
                    <View style={[style(WIDTH).dataTable_header,{flex:0.5}]}><Text style={[style(WIDTH).dataTable_content, {fontWeight:'bold'}]}>Contact</Text></View>
                    <View style={[style(WIDTH).dataTable_header,{flex:1}]}><Text style={[style(WIDTH).dataTable_content, {fontWeight:'bold'}]}>Email</Text></View>
                    <View style={[style(WIDTH).dataTable_header,{flex:0.35}]}><Text style={[style(WIDTH).dataTable_content, {fontWeight:'bold'}]}>Status</Text></View>
                </DataTable.Header>
                
                {salesList?salesList:null}

                <View style={style().container_first}>
                    <Text style={[style(WIDTH).text_grey, style(WIDTH).text_total_item, style(WIDTH).fontSize2]}>{`Total items: ${totalItems}`}</Text>
                    <View style={style(WIDTH).container_second}>
                        <Pressable 
                            style={isFirst? style(WIDTH).paginationButtonDisabled:style(WIDTH).paginationButton} 
                            onPress={previousPage} 
                            disabled={isFirst}
                        >
                            <Image source={prev} resizeMode='contain' style={[style(WIDTH).pagination_arrow,isFirst && style().disableTint]} />
                        </Pressable>
                    
                        <Text style={[style(WIDTH).text_grey,style(WIDTH).text_page, style(WIDTH).fontSize2]}>
                            {`Page `}
                            <Text style={[style().text_current_page, style(WIDTH).fontSize3]}>{`${tablePage.totalPages == 0? tablePage.currentPage:tablePage.currentPage+1}`}</Text>
                            {` of ${tablePage.totalPages}`}
                        </Text>
                        
                        <Pressable 
                            style={isLast? style(WIDTH).paginationButtonDisabled: style(WIDTH).paginationButton} 
                            onPress={nextPage}
                            disabled={isLast}
                        >
                            <Image source={next} resizeMode='contain' style={[style(WIDTH).pagination_arrow,isLast && style().disableTint]} />
                        </Pressable>
                    </View>
                
                    <View style={style(WIDTH).container_third}>
                        <Text style={[style(WIDTH).text_grey,style(WIDTH).text_page, style(WIDTH).fontSize2]}>Go to page: </Text>
                        <TextInput 
                            style={[style(WIDTH).fontSize2, style(WIDTH).go_to_input]}
                            onChangeText={(value) => onChangePage(value)}
                            placeholder={`${tablePage.totalPages == 0? tablePage.currentPage:tablePage.currentPage+1}`}
                        />
                    </View>
                </View>
            </DataTable>
        </View>
    )
}

export default SalesPerson_List
