import React, {useEffect, useState} from 'react'
import { Picker,View, Text, StyleSheet, Pressable, TextInput} from 'react-native'
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { API_URL, BASE_URL } from '../config/config';
import { useParams} from 'react-router';
import { getWebsiteWidth, isDigit } from '../components/utilities';
import { style, grey2,option } from '../styles/style';
import {Button as ButtonPaper} from 'react-native-paper';
import { Icon, Button } from 'react-native-elements';

function SalesPerson_Edit(props) {
    const value = useParams();
    const salesID = value.salesID;
    const token = window.localStorage.getItem('token');
    // console.log(salesID)
    const [WIDTH, set_WIDTH] = useState(getWebsiteWidth());
    const [counter, setCounter] = useState(0);
    const [confirmCounter, setConfirmCounter] = useState(0);
    const [is_deleted, set_is_deleted] = useState(false);
    const [isAdmin, setAdmin] = useState(false);
    const [last_edit_name, set_last_edit_name] = useState('');
    const [editable, set_editable] = useState(0);

    useEffect(async() => {
        window.localStorage.getItem('role') != 1? setAdmin(true): window.location.href = `${BASE_URL}salesperson`;
        window.addEventListener('resize', () => { set_WIDTH(getWebsiteWidth()); });
        onLoad();
    }, [])

    const onLoad = async() => {
        ShowLoading();
        await getSalesPerson();
        HideLoading();
    }
    const { register, setValue, handleSubmit, control, reset, formState: { errors } } = useForm({
        defaultValues: {
            name: '',
            gender:'F',
            email:'',
            contact:'',
            KPI:'',
            status:''
        }
    });

    const onSubmit = async(data) => {
        if(!editable) return;

        let url = API_URL + 'salesperson';
        let token = window.localStorage.getItem('token');
        let headers = {
            Authorization: `Bearer ${token}` 
        }

        let payload = {
            "mode":'Edit',
            "salesID":salesID,
            "email":data.email,
            "name":data.name,
            "contact":data.contact,
            "gender":data.gender,
            "kpi":data.KPI,
            "status":data.status
        }
        await axios({
            method:'post',
            url: url, 
            headers: headers,
            data:payload
        }).then(() => {
            MessageBox("","Saved");
        }).catch (err => {
            if(typeof(err.response.data.result.errors) != 'undefined')
            {
                let msg = '';
                for(let i=0; i<err.response.data.result.errors.length; i++)
                {   
                    if(msg != '')   msg += '\n';
                    msg += err.response.data.result.errors[i].param + ': ' + err.response.data.result.errors[i].msg;
                }
                MessageBox('Error', msg);
           }
            else
                MessageBox("Error", "Failed to save salesperson info.");
            console.log(err);
        });          
    }

    const onChange = arg => {
        return {
            value: arg.nativeEvent.text,
        };
    };

    const getSalesPerson = async() => {
        window.API_CALL_GET(`salesperson/${value.salesID}`).then(
          function(data) {        
            if(data.is_deleted){
              set_is_deleted(true);
              set_last_edit_name(data.last_edit_name);
            }        
            reset(data)
            set_editable(true);
          },
          function(error) { MessageBox("Error", error); }
        );
    }
    
    const deleteSalesPerson = async() =>{
        if(!editable) return;

        if(counter < 1 && !is_deleted){
            setCounter(counter + 1);
            MessageBox("Warning", "Do you want to delete this salesperson's data? If yes, click the delete button again.");
        }else{
            let url = API_URL + 'utils/delete/salesperson';
            let token = window.localStorage.getItem('token');
            let headers = {
                Authorization: `Bearer ${token}` 
            }

            try {
                const res = await axios({
                    method:'post',
                    url: url, 
                    headers: headers,
                    data: {id: salesID}
                })
                if(res.status === 200){
                    if(isAdmin)
                        window.location.reload(false);
                    else
                        window.location.href = `${BASE_URL}salesperson`;
                }
            } catch (error) {
                MessageBox('Error', 'Failed to delete salesperson.');
                console.log(error.message);
            }
        }
    }

    const undoDeleteSalesPerson = async() => {
        if(!editable) return;

        let url = API_URL + 'salesperson/restoreRecord';
            let token = window.localStorage.getItem('token');
            let headers = {
                Authorization: `Bearer ${token}` 
            }

            try {
                const res = await axios({
                    method:'post',
                    url: url, 
                    headers: headers,
                    data: {salesID:salesID}
                })
                if(res.status === 200){
                    MessageBox("Success","Restored salesperson.");
                    window.location.reload();
                }
            } catch (error) {
                MessageBox("Error", "Failed to restore salesperson.");
                console.log(error);
            }
    }

    return (
        <View>
            {editable? <View style={[style(WIDTH).container,{width:WIDTH/2}]}>
            {is_deleted && isAdmin? (
                    <View style={[style(WIDTH).container_row2,style().table_row_deleted]}>
                        <Text style={[style(WIDTH).fontSize2, {alignSelf:'center', fontWeight:'bold'}]}>Deleted by: {last_edit_name}</Text>
                        <View style={style(WIDTH).container_icon} />
                        <Button icon={<Icon name='check' type="antdesign" size={option(WIDTH).iconSize1}/>} type="clear" 
                        style={{backgroundColor:'#11CD01'}}
                        onPress={()=>{
                            if(confirmCounter == 0) 
                            {
                                setConfirmCounter(confirmCounter + 1);
                                MessageBox("Warning", "You are deleting this salesperson permanently. If this is your intention, kindly click the delete button again.");
                            }    
                            else deleteSalesPerson();}}/>
                    <View style={style(WIDTH).container_icon}></View>
                    <Button icon={<Icon name='close' type="antdesign" size={option(WIDTH).iconSize1}/>} type="clear"
                        style={{backgroundColor:'#ff2100'}}
                        onPress={()=>{undoDeleteSalesPerson();}}/>
                    </View> 
                ):null}   
            <View style={style(WIDTH).container_create_col} pointerEvents={is_deleted? "none":"auto"}>
                <View style={{marginBottom:WIDTH*0.025}}>
                    <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize4]}>Edit Salesperson</Text></View>

                <View style={{flexDirection:'row'}}>
                    <View style={{flexDirection:'column', flex:0.7}}>
                        <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Name {errors.name?
                            <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                        <Controller
                            control={control}
                            render={({field:{onChange, onBlur, value}}) => (
                                <TextInput
                                    style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                    onBlur={onBlur}
                                    onChangeText={value => onChange(value)}
                                    value={value}
                                    placeholder='Enter name...'
                                    placeholderTextColor = {grey2}
                                />
                            )}
                            name="name"
                            rules={{required: true}}
                        />
                    </View>
                    <View style={style(WIDTH).container_icon} />
                    <View style={{flexDirection:'column', flex:0.3}}>
                        <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Gender {errors.gender?
                            <Text style={style(WIDTH).fontError}>is REQUIRED, please select!</Text>: null}</Text>
                        <Controller 
                            control={control}
                            render={({field:{onChange, onBlur, value}})=>(
                                <Picker
                                    selectedValue={value}
                                    style = {[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                    onValueChange={(itemValue, itemIndex)=>onChange(itemValue)}
                                    onBlur={onBlur}
                                >
                                    <Picker.Item label="Female" value="F" />
                                    <Picker.Item label="Male" value="M" />                          
                                </Picker>
                            )}
                            name="gender"
                            rules={{ required: true }}
                        />
                    </View>
                </View>
                    
                <View>
                    <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Contact No. {errors.contact?
                        <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                    <Controller
                        control={control}
                        render={({field:{onChange, onBlur, value}}) => (
                            <TextInput
                                style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                onBlur={onBlur}
                                onChangeText={value => onChange(value)}
                                value={value}
                                placeholder='Enter contact no...'
                                placeholderTextColor = {grey2}
                                keyboardType="numeric"
                            />
                        )}
                        name="contact"
                        rules={{ required: true}}
                    />
                </View>

                <View>
                    <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Email {errors.email?
                        <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                    <Controller
                        control={control}
                        render={({field:{onChange, onBlur, value}}) => (
                            <TextInput
                                style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}    
                                onBlur={onBlur}
                                onChangeText={value => onChange(value)}
                                value={value}
                                placeholder='Enter email...'
                                placeholderTextColor = {grey2}
                            />
                        )}
                        name="email"
                        rules={{ required: true}}
                    />
                </View>
                
                <View style={{flexDirection:'row'}}>
                    <View style={style(WIDTH).create_col_space}>
                        <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>KPI (RM) {errors.KPI?
                            <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                        <Controller
                            control={control}
                            render={({field:{onChange, onBlur, value}}) => (
                                <TextInput
                                    style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                    onBlur={onBlur}
                                    onChangeText={(val) => {if(isDigit(val)) onChange(val)}} 
                                    value={value}
                                    placeholder='Enter KPI...'
                                    keyboardType="numeric"
                                    placeholderTextColor = {grey2}
                                />
                            )}
                            name="KPI"
                            rules={{required: true}}
                        />
                    </View>
                    <View style={style(WIDTH).container_icon} />
                    <View style={style(WIDTH).create_col_space}>
                        <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Status {errors.status?
                            <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                        <Controller
                            control={control}
                            render={({field:{onChange, onBlur, value}}) => (
                                <Picker
                                    selectedValue={value}
                                    style = {[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                    onValueChange={(itemValue, itemIndex)=>onChange(itemValue)}
                                    onBlur={onBlur}
                                >
                                    <Picker.Item label="Active" value="1" />
                                    <Picker.Item label="Inactive" value="0" />                          
                                </Picker>
                            )}
                            name="status"
                            rules={{ required: true}}
                        />
                    </View>
                </View>
                
                <View style={{flexDirection:'row', justifyContent:'flex-end'}}>
                <ButtonPaper
                    style={[style(WIDTH).create_btn_red, {right: -WIDTH*0.015}]} 
                    labelStyle={style(WIDTH).create_btn_label} 
                    onPress={deleteSalesPerson}
                >
                    Delete
                </ButtonPaper>

                <ButtonPaper
                    style={[style(WIDTH).create_btn, {right: -WIDTH*0.015}]} 
                    labelStyle={style(WIDTH).create_btn_label} 
                    onPress={handleSubmit(onSubmit)}
                >
                    Save
                </ButtonPaper>
            </View>

            </View>
        </View>: null}

        </View>
        
    )
}

export default SalesPerson_Edit