import React, { useEffect,useState,useRef } from 'react'
import { View, Text } from 'react-native';
import { getWebsiteWidth } from '../components/utilities';
import { style } from '../styles/style.js';

function ErrorPage() {
    const [WIDTH, set_WIDTH] = useState(getWebsiteWidth());
    useEffect(async() => {
        window.addEventListener('resize', () => { set_WIDTH(getWebsiteWidth()); });
    }, [])

    return (
        <View style={style(WIDTH).container}>
            <Text style={{color:'red', padding:WIDTH*0.02, fontSize:WIDTH*0.08}}>Page not found</Text>
        </View>
    )
}

export default ErrorPage
