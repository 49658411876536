import React, { useEffect,useState,useRef } from 'react'
import { View, Text, StyleSheet,Pressable, TextInput,Image } from 'react-native'
import { API_URL, BASE_URL } from '../config/config';
import {  ITEM_PER_TABLE_PAGE } from '../constants';
import axios from 'axios';
import { DataTable, Modal, Portal, Provider} from 'react-native-paper';
import { style, option, grey2 } from '../styles/style.js';
import { useHover, Hoverable } from 'react-native-web-hooks';

import { Button as ButtonElements, Icon } from 'react-native-elements';
import { getWebsiteWidth } from '../components/utilities';
import { Popable } from 'react-native-popable';

function Backup() {
    const [WIDTH, set_WIDTH] = useState(getWebsiteWidth());
    const [isAdmin, set_isAdmin] = useState(window.localStorage.getItem('role') == 0);
    const [data, set_data] = useState([]);
    const [tableContent, set_tableContent] = useState([]);

    useEffect(() => {
        if(!isAdmin) window.location.href = `${BASE_URL}`;
        window.addEventListener('resize', () => { set_WIDTH(getWebsiteWidth()); });
        onLoad();
    }, [])

    const onLoad = () => {
        getBackupList();
    }

    useEffect(() => {
        set_tableContent([]);
        if(data)
        {
            set_tableContent(data.map((item)=>{
                return(
                    <Hoverable key={item.id}>
                    {isHovered => (
                        <Pressable 
                            key={item.id} 
                            style={[style(WIDTH).table_row,
                                    isHovered && style(WIDTH).hover
                                ]}
                        >
                            <DataTable.Row key={item.id}>
                            <View style={[style(WIDTH).dataTable_content, {flex:1}]}><Text style={style(WIDTH).fontSize2}>{item.createdAt || '-'}</Text></View>
                            <View style={[style(WIDTH).dataTable_content, {flex:1}]}><Text style={style(WIDTH).fontSize2}>{item.backup_to?.substring(0, 10) || '-'}</Text></View>
                            <View style={[style(WIDTH).dataTable_content, {flex:1}]}><Text style={style(WIDTH).fontSize2}>{item.filename || '-'}</Text></View>
                            <View style={[style(WIDTH).dataTable_content, {flex:1}]}><Text style={style(WIDTH).fontSize2}>{item.shipment_no || '-'}</Text></View>
                            <View style={[style(WIDTH).dataTable_content, {flex:1}]}><Text style={style(WIDTH).fontSize2}>{item.do_no || '-'}</Text></View>
                            <View style={[style(WIDTH).dataTable_content, {flex:1}]}><Text style={style(WIDTH).fontSize2}>{item.extra_no || '-'}</Text></View>
                            <View style={[style(WIDTH).dataTable_content, {flex:1}]}><Text style={style(WIDTH).fontSize2}>{item.checksum || '-'}</Text></View>
                            <View style={[style(WIDTH).dataTable_content, {flex:1}]}><Text style={style(WIDTH).fontSize2}>
                                {item.status==null? "-" : ''}
                                {item.status==0? 'Pending' : ""}
                                {item.status==1? 'Done' : ""}
                                {item.status==2? 'Cancelled/Deleted' : ""}
                                {item.status==3? 'Failed' : ""}
                                </Text></View>
                            <View style={[style(WIDTH).dataTable_content, {flex:3}]}><Text style={style(WIDTH).fontSize2}>{item.error_log || '-'}</Text></View>
                            <View style={[style(WIDTH).dataTable_content, {flex:1, flexDirection:'row'}]}>
                                <Popable content='Retry backup' position="left" backgroundColor='#FF9F1D' action="hover"> 
                                <ButtonElements icon={<Icon name='refresh' type="MaterialIcons" color="red" size={option(WIDTH).iconSize2}/>} type="clear"
                                    onPress={() => retryBackup(item.id)}/></Popable>
                            </View>

                            </DataTable.Row>
                        </Pressable>
                    )}
                    </Hoverable>
                );
            }));
        }
    },[data, WIDTH]);

    const getBackupList = async() => {
        ShowLoading();
        await axios({
            method:'get',
            url: API_URL + 'backup', 
            headers: {Authorization: `Bearer ${window.localStorage.getItem('token')}`}
        }).then(res => {
        //    console.log(res.data.result);
           set_data(res.data.result.dataList)
        }).catch( err => {
            MessageBox("Error", "Fail to get backup list.");
        }).finally(()=>{
            HideLoading();
        })
    }
 
    const createBackup = async() => {
        ShowLoading();
        await axios({
            method:'post',
            url: API_URL + 'backup', 
            headers: {Authorization: `Bearer ${window.localStorage.getItem('token')}`}
        }).then(res => {
            MessageBox("", "Backup scheduled");
            onLoad();
        }).catch( err => {
            if(err?.response?.data?.result)
                MessageBox("Error", err.response.data.result);
        }).finally(()=>{
            HideLoading();
        })
    }

    const retryBackup = async(id) => {
        if(!id || !isAdmin) return;

        ShowLoading();
        await axios({
            method:'post',
            url: API_URL + 'backup/retry', 
            headers: {Authorization: `Bearer ${window.localStorage.getItem('token')}`},
            data: {id:id}
        }).then(res => {
           console.log(res.data.result);
        }).catch( err => {
            if(err?.response?.data?.result)
                MessageBox("Error", err.response.data.result);
        }).finally(()=>{
            HideLoading();
        })
    }

    return (
        <View style={[style(WIDTH).container]}>
        {isAdmin? 
        <View>
            <View><Text style={[style(WIDTH).create_title, style(WIDTH).fontSize4]}>Backup</Text></View>
                <View style={style(WIDTH).container_row}>
                    <Popable content='Create backup' position="left" backgroundColor='#FF9F1D' action="hover">
                        <ButtonElements icon={<Icon name='addfile' type="antdesign" size={option(WIDTH).iconSize2}/>} type="clear" style={{padding: '5%'}}
                            onPress={createBackup}/></Popable>
                </View>      
            <DataTable style={{padding: '2%'}}>               
                <DataTable.Header style={style(WIDTH).dataTable_header_border}>
                    <View style={[style(WIDTH).dataTable_header, {flex:1}]}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>Create</Text></View>
                    <View style={[style(WIDTH).dataTable_header, {flex:1}]}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>Backup until</Text></View>
                    <View style={[style(WIDTH).dataTable_header, {flex:1}]}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>Filename</Text></View>
                    <View style={[style(WIDTH).dataTable_header, {flex:1}]}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>SO(s)</Text></View>
                    <View style={[style(WIDTH).dataTable_header, {flex:1}]}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>DO(s)</Text></View>
                    <View style={[style(WIDTH).dataTable_header, {flex:1}]}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>Extra(s)</Text></View>
                    <View style={[style(WIDTH).dataTable_header, {flex:1}]}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>Chksum</Text></View>
                    <View style={[style(WIDTH).dataTable_header, {flex:1}]}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>Status</Text></View>
                    <View style={[style(WIDTH).dataTable_header, {flex:3}]}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>Error</Text></View>
                    <View style={[style(WIDTH).dataTable_header, {flex:1}]}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>Action</Text></View>
                </DataTable.Header>
                {tableContent}
            </DataTable>       
        </View>: null}
        </View>
    )
}

export default Backup;
