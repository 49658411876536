import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Image, View, Text, ScrollView,TextInput, Picker, Pressable} from 'react-native'

import axios from 'axios';
import { API_URL, BASE_URL } from '../config/config';
import { ITEM_PER_TABLE_PAGE, maxWidth } from '../constants';
import { DataTable, Button as ButtonPaper} from 'react-native-paper';
import { en, registerTranslation, DatePickerModal } from 'react-native-paper-dates';
registerTranslation('en', en);

import DropDownPicker from 'react-native-dropdown-picker';
import { style, option } from '../styles/style.js';
import { useHover, Hoverable } from 'react-native-web-hooks';

import prev from '../assets/prev-arrow.png';
import next from '../assets/next-arrow.png';
import { Button, Icon, Overlay } from 'react-native-elements';
import { getWebsiteWidth } from '../components/utilities';
import Client_list from './Client_list.js';
import { Popable } from 'react-native-popable';

function SalesOrder_List() {
    const [WIDTH, set_WIDTH] = useState(getWebsiteWidth());
    const isHovered = useHover(ref);
    const ref = useRef(null);

    const [uiState] = useState({
        totalPages:0, 
        currentPage: 0, 
        totalItems: 0,
        btn_createFrom: "-",
        btn_createTo: "-",        
        btn_etaFrom: "-",
        btn_etaTo: "-",        
        btn_etdFrom: "-",
        btn_etdTo: "-"
    });
    const [searchParams, set_searchParams] = useState([]);

    const [salesOrder, set_salesOrder] = useState(null);
    const [salesOrderTable, set_salesOrderTable] = useState([]);
    const [dropdownSalesperson, set_dropdownSalesperson] = useState([]);
    const [dropdownStaff, set_dropdownStaff] = useState([]);
    const [state_shipmentID, set_state_shipmentID] = useState('');
    const [state_invoiceID, set_state_invoiceID] = useState('');
    const [state_shipperID, set_state_shipperID] = useState('');
    const [state_staffID, set_state_staffID] = useState('');
    const [state_clientID, set_state_clientID] = useState('');
    const [state_reference, set_state_reference] = useState('');
    const init_dropdown_state = {salesperson:false, staff:false};
    const [openDd, set_openDd] = useState(init_dropdown_state);
    const setOpen = (key, value) => {
        let new_state = init_dropdown_state;
        if(typeof(key) != "undefined")
            new_state[key] = value;
        set_openDd(new_state);
    }
    const [state_goToPage, set_state_goToPage] = useState(0);
    // Pagination
    const [isFirst, setFirst] = useState(1);
    const [isLast, setLast] = useState(0);

    // Search
    const [showSearch, setShowSearch] = useState(0);

    // Calander
    const [showCalander, set_showCalander] = useState(false);
    const [calanderMessage, set_calanderMessage] = useState('');
    const [calanderFor_Attribute, set_calanderFor_Attribute] = useState('');
    const [calanderFor_Point, set_calanderFor_Point] = useState('');
    const onDismiss = useCallback(() => {
        set_showCalander(false);      
        uiState["btn_" + calanderFor_Attribute + calanderFor_Point] = "-";
        onChangeValue(calanderFor_Attribute + calanderFor_Point, "");
    });
    const onConfirm = useCallback((params) => {
            set_showCalander(false);
            var date = new Date(params.date);     
            if(calanderFor_Point == "From")
            {
                date.setHours(0);
                date.setMinutes(0);
                date.setSeconds(0);
                date.setMilliseconds(0);
            }
            else if(calanderFor_Point == "To")
            {
                date.setHours(23);
                date.setMinutes(59);
                date.setSeconds(59);
                date.setMilliseconds(999);
            } 
            let y = date.getFullYear();
            let m = date.getMonth()+1;
            let d = date.getDate();

            if (d < 10)
                d = '0' + d;
            if (m < 10)
                m = '0' + m;        
            uiState["btn_" + calanderFor_Attribute + calanderFor_Point] = d + "/" + m + "/" + y;
            onChangeValue(calanderFor_Attribute + calanderFor_Point, date.getTime());
    });

     // Overlay
    const [select_client, set_select_client] = useState(false);
    const [is_client, set_is_client] = useState(false);
    const [currentIndex, set_currentIndex] = useState(null);
    const [state_showOverlay, set_state_showOverlay] = useState(false);
    const showOverlay = (index) => {
        set_currentIndex(index);
        set_state_showOverlay(true);
        if(index == 'client') {
          set_select_client(1);
          set_is_client(1);
        }
        else {
          set_select_client(0);
          set_is_client(0);
        }
     };
    const hideOverlay = () => { set_state_showOverlay(false); };
    const sendDataToParent = async(params) => {
        onChangeValue(currentIndex, params.id);
        if(currentIndex == 'client')
            set_state_clientID(params.name);
        else
            set_state_shipperID(params.name);
        set_state_showOverlay(false);
    }

     // Prepare table data
    useEffect(() => {
        set_salesOrderTable([]);
        if(salesOrder)
        {
          console.log(salesOrder);
            uiState.totalPages = salesOrder.totalPages;
            salesOrder.totalPages > 0? uiState.currentPage = salesOrder.currentPage + 1: uiState.currentPage = 0;
            uiState.totalItems = salesOrder.totalItems;
            set_state_goToPage(uiState.currentPage);

            uiState.currentPage == uiState.totalPages? setLast(1):setLast(0);
            uiState.currentPage == 1 || uiState.currentPage == 0? setFirst(1):setFirst(0);

            let _salesOrder = [];
            for(let i = 0; i < salesOrder.dataList.length; i++){
                let obj = {
                    ID:             salesOrder.dataList[i].shipmentID,
                    Client:         salesOrder.dataList[i].client_name || "-",
                    Cus_Ref:         salesOrder.dataList[i].referenceNo || "-",
                    CreatedDate:    salesOrder.dataList[i].createdAt || "-",
                    ETA:            salesOrder.dataList[i].eta || "-",
                    ETD:            salesOrder.dataList[i].etd || "-",
                    salesID:        salesOrder.dataList[i].salesID || "-",
                    Shipper:        salesOrder.dataList[i].shipper_name || "-",
                    StaffName:      salesOrder.dataList[i].username || "-",
                    is_complete:    salesOrder.dataList[i].is_complete || false,
                    is_deleted:     salesOrder.dataList[i].is_deleted || false,
                    unlock_request: salesOrder.dataList[i].unlock_request || false,
                    invID:          salesOrder.dataList[i].invID || "-",
                };
                _salesOrder.push(obj);
            }

            let content = _salesOrder.map((item)=>{
                return(
                    <Hoverable key={item.ID}>
                    {isHovered => (
                        <Pressable 
                            key={item.ID} 
                            style={[style(WIDTH).table_row,
                                    item.is_complete && style(WIDTH).table_row_completed,
                                    item.is_deleted && style(WIDTH).table_row_deleted,
                                    item.is_complete && item.unlock_request && style(WIDTH).table_row_unlock_request,
                                    isHovered && style(WIDTH).hover
                                ]}
                                // {borderWidth:4}, 
                                    // isHovered? {borderColor:'#E8E8E8'}:{borderColor:'red'},
                                    // item.is_complete? {backgroundColor:'green'}:{},
                                    // item.is_deleted? {backgroundColor:'red'}:{}
                            // }
                          
                            //     borderColor
                            //     if(isHovered)               return {backgroundColor:'#E8E8E8'}
                            //     else if (!item.is_completed) return {backgroundColor:'black'}
                            //     else if (item.is_deleted) return {backgroundColor:'red'}
                            // }}
                            onPress={() => {
                                var url =  BASE_URL + 'sales_order/' + item.ID;
                                window.location.href = url;
                                }}
                        >
                            <DataTable.Row key={item.ID}>
                                <DataTable.Cell style={[style(WIDTH).dataTable_content,{justifyContent:'flex-start', alignContent:'center'}]}>                                    
                                    {item.is_deleted? <Icon name='remove-circle-outline' type="ionicons" size={WIDTH*0.01}/>: null}
                                    {item.unlock_request? <Icon name='unlock' type="antdesign" size={WIDTH*0.01}/>: null}
                                    {!item.is_deleted && !item.unlock_request && item.is_complete? <Icon name='check' type="entypo" size={WIDTH*0.01}/>: null}
                                    <Text style={style(WIDTH).fontSize2}>{item.is_deleted || item.unlock_request || item.is_complete? "  ": ""}{item.ID || '-'}</Text>
                                </DataTable.Cell>
                                <View style={style(WIDTH).dataTable_content}><Text style={style(WIDTH).dataTable_content_flex}>{item.invID || '-'}</Text></View>
                                <View style={style(WIDTH).dataTable_content}><Text style={style(WIDTH).dataTable_content_flex}>{item.Client || '-'}</Text></View>
                                <View style={style(WIDTH).dataTable_content}><Text style={style(WIDTH).dataTable_content_flex}>{item.Cus_Ref || '-'}</Text></View>
                                <View style={style(WIDTH).dataTable_content}><Text style={style(WIDTH).fontSize2}>{item.ETD || '-'}</Text></View>
                                <View style={style(WIDTH).dataTable_content}><Text style={style(WIDTH).fontSize2}>{item.ETA || '-'}</Text></View>
                                <View style={style(WIDTH).dataTable_content}><Text style={style(WIDTH).dataTable_content_flex}>{item.Shipper || '-'}</Text></View>
                                <View style={style(WIDTH).dataTable_content}><Text style={style(WIDTH).dataTable_content_flex}>{item.StaffName || '-'}</Text></View>
                                <View style={style(WIDTH).dataTable_content}><Text style={style(WIDTH).fontSize2}>{item.CreatedDate || '-'}</Text></View>
                            </DataTable.Row>
                        </Pressable>
                    )}
                    </Hoverable>
                );
            });
            set_salesOrderTable(content);
        }
    },[salesOrder, WIDTH]);

    // Get data from server
    useEffect(() => {     
        window.addEventListener('resize', () => { set_WIDTH(getWebsiteWidth()); });
        onLoad();
    },[]);
    
    const onLoad = async() => {
        await getSalesOrder();
        ShowLoading();
        await getSalespersonOption();
        await getStaffOption();
        HideLoading();
    }
    // const goToPage = async(req, params) => {
    //     let targetPage = Math.round(state_goToPage);
    //     if(targetPage > uiState.totalPages)   
    //         targetPage = uiState.totalPages;
    //     else if (targetPage < 1)
    //         targetPage = 1;
    //     uiState.currentPage = targetPage;
    //     getSalesOrder(null, {page:targetPage - 1});
    // }

    let pageTimer;
    const onChangePage = (value) => {
        if(value != '' && (value <= uiState.totalPages) && (value > 0)){
            let targetPage = Math.round(value);
            clearTimeout(pageTimer);
            pageTimer = setTimeout(() => {    
                getSalesOrder(null, { page:targetPage-1 });
            }, 800);
        }
        //let targetPage = Math.round(value);
        // if(targetPage > uiState.totalPages)   
        //     targetPage = uiState.totalPages;
        // else if (targetPage < 1)
        //     targetPage = 1;
        // uiState.currentPage = targetPage;
        // clearTimeout(pageTimer);
        // pageTimer = setTimeout(() => {          
        //     getSalesOrder(null, { page:targetPage-1 });
        // }, 800);
        // getSalesOrder(null, {page:targetPage - 1});
    }

    const clearFilter = async(req, params) => {
        set_state_shipmentID("");
        set_state_shipperID("");
        set_state_staffID("");
        set_state_clientID("");
        set_state_reference("");
        set_state_invoiceID("");
        uiState.btn_createFrom = "-";
        uiState.btn_createTo = "-";
        uiState.btn_etaFrom = "-";
        uiState.btn_etaTo = "-";
        uiState.btn_etdFrom = "-";
        uiState.btn_etdTo = "-";     
        set_searchParams([]);  
    }

    const getSalesOrder = async(req, params) => {
        ShowLoading();
        let queryParams = {};
        for (const sp in searchParams) {
            if(searchParams[sp] != '')
                queryParams['filter[' + sp + ']'] = searchParams[sp];
        }
        queryParams['size'] = ITEM_PER_TABLE_PAGE; // number of item for each request (in a page)
        queryParams['page'] = 0;
        if (params && params.page)
            queryParams['page'] = params.page; // the page index

        const token = window.localStorage.getItem('token');
        let url = API_URL + 'salesorder?';
        // url += '?=' + 'filter[pol]=&sort[pod]=asc&page=0&size20';
        const headers = { Authorization: `Bearer ${token}` };
        await axios({
            method:'get',
            url: url,
            headers: headers,
            params: queryParams
        }).then(data => {
            set_salesOrder(data.data.result);
        }).catch( err => {
            MessageBox("Error", "Fail to retrieve sales order data.");
            console.log(err);
        })
        HideLoading();
    };

   
    const getSalespersonOption = async(req, params) => {
      window.API_CALL_GET('utils/options/salesperson', {active_only:0}).then(
        function(data) {        
          data.sort((a, b) => a.title.localeCompare(b.title));
          // data.unshift({id:"", title:"Salesperson"});

          let content = [];
          for(let i = 0; i < data.length; i++)            
              if(i % 2 == 1)
                  content.push({label: data[i].title, value: data[i].id,  
                      containerStyle: {backgroundColor: "#f2f2f2"}});
              else
                  content.push({label: data[i].title, value: data[i].id});
          set_dropdownSalesperson(content);        
        },
        function(error) { MessageBox("Error", error); }
      );
    };

    const getStaffOption = async(req, params) => {
        const token = window.localStorage.getItem('token');
        let url = API_URL + 'utils/options/user';
        const headers = { Authorization: `Bearer ${token}` };
        const res = await axios({
            method:'get',
            url: url,
            headers: headers,
            params: {active_only:0}
        });

        set_dropdownStaff([]);
        if(res.status === 200)
        {   
            let data = res.data.result;
            data.sort((a, b) => a.title.localeCompare(b.title));
            // data.unshift({id:"", title:"Staff"});
            let content = [];
            for(let i = 0; i < data.length; i++)
                if(i % 2 == 1)
                    content.push({label: data[i].title, value: data[i].id,  
                        containerStyle: {backgroundColor: "#f2f2f2"}});
                else
                    content.push({label: data[i].title, value: data[i].id});
            set_dropdownStaff(content);
        }    
    };

    const onChangeValue = (key, value) => {
        searchParams[key] = value;   
    };

    const previousPage = () => {
        if(uiState.totalPages > 1){
            uiState.currentPage = uiState.currentPage - 1;
            setLast(0);
            uiState.currentPage == 1? setFirst(1):setFirst(0);
            getSalesOrder(null, {page:uiState.currentPage - 1});
        }         
    };

    const nextPage = () => {        
        if(uiState.currentPage < uiState.totalPages){
            uiState.currentPage = uiState.currentPage + 1;
            setFirst(0);
            uiState.currentPage == uiState.totalPages? setLast(1):setLast(0);
            getSalesOrder(null, {page:uiState.currentPage - 1});
        }
    };
    
    return (
        <View style={style(WIDTH).container} >
            <DataTable style={{zIndex:1}}>
                <View style={style(WIDTH).container_row}>
                    <Popable content='Refresh' position="left" backgroundColor='#FF9F1D' action="hover">
                    <Button icon={<Icon name='refresh' type="simplelineicons" size={option(WIDTH).iconSize2}/>} type="clear" style={{padding: '5%'}}
                        onPress={async()=>{
                            await getSalesOrder();
                            ShowLoading();
                            await getStaffOption();
                            await getSalespersonOption();
                            HideLoading();} }/></Popable>
                    <View style={style(WIDTH).container_icon}></View>
                    <Popable content='Search' position="left" backgroundColor='#FF9F1D' action="hover"> 
                    <Button icon={<Icon name='search1' type="antdesign" size={option(WIDTH).iconSize2}/>} type="clear" style={{padding: '5%'}}
                        onPress={()=>setShowSearch(!showSearch)}/></Popable> 
                    <View style={style(WIDTH).container_icon}></View>
                    <Popable content='Create' position="left" backgroundColor='#FF9F1D' action="hover">
                    <Button icon={<Icon name='addfile' type="antdesign" size={option(WIDTH).iconSize2}/>} type="clear" style={{padding: '5%'}}
                        onPress={()=>{ window.location.href = '/sales_order/create'}}/></Popable>
                </View>

                <View style={[style(WIDTH).container_column, {marginTop:'2%'}]}>                   
                    {showSearch? 
                        (<View style={style(WIDTH).container_search_input}>
                            <ButtonPaper
                                style={style(WIDTH).button_orange} 
                                labelStyle={style().button_label_white} 
                                onPress={clearFilter}
                            >
                                <Text style={style(WIDTH).fontSize2}>Clear Filter</Text>
                            </ButtonPaper>
                            <DataTable.Header style={style(WIDTH).dataTable_header2}>
                                <View style={style(WIDTH).scontainer} >
                                    <TextInput
                                        style={[style(WIDTH).searchInput,style(WIDTH).fontSize2,style(WIDTH).searchInput_so]}
                                        value={state_shipmentID}
                                        onChangeText={(value) => {
                                            onChangeValue("shipmentID", value);
                                            set_state_shipmentID(value);
                                        }}
                                        placeholder={'Shipment ID'}
                                    />
                                </View>

                                <View style={style(WIDTH).scontainer} >
                                    <TextInput
                                        style={[style(WIDTH).searchInput,style(WIDTH).fontSize2,style(WIDTH).searchInput_so]}
                                        value={state_invoiceID}
                                        onChangeText={(value) => {
                                            onChangeValue("invID", value);
                                            set_state_invoiceID(value);
                                        }}
                                        placeholder={'Invoice ID'}
                                    />
                                </View>

                                {/* <View style={[style(WIDTH).scontainer, {zIndex:10}]}> */}
                                    <Pressable style={[style(WIDTH).scontainer, {zIndex:10}]} onPress={()=>{showOverlay('client');}}><TextInput pointerEvents='none'
                                        multiline={state_clientID? true: false}
                                        style={[style(WIDTH).searchInput,style(WIDTH).fontSize2,style(WIDTH).searchInput_so]}
                                        value={state_clientID}
                                        placeholder={'Client'}/></Pressable>
                                {/* </View> */}

                                <View style={style(WIDTH).scontainer} >
                                    <TextInput
                                        style={[style(WIDTH).searchInput,style(WIDTH).fontSize2,style(WIDTH).searchInput_so]}
                                        value={state_reference}
                                        onChangeText={(value) => {
                                            onChangeValue("referenceNo", value);
                                            set_state_reference(value);
                                        }}
                                        placeholder={'Cus. Ref.'}
                                    />
                                </View>

                                <View style={[style(WIDTH).scontainer2]} >
                                    <Pressable
                                        style={style(WIDTH).from_and_to}    
                                        onPress={() => {
                                            set_calanderFor_Attribute('eta');
                                            set_calanderFor_Point('From');
                                            set_showCalander(true);
                                            set_calanderMessage('From');
                                        }}
                                    >
                                        <Text style={[{textAlign:'center'}, style(WIDTH).fontSize2]}>{'From: ' + uiState.btn_etaFrom}</Text>
                                    </Pressable>
                        
                                    <Pressable
                                        style={style(WIDTH).from_and_to}                
                                        onPress={() => {
                                            set_calanderFor_Attribute('eta');
                                            set_calanderFor_Point('To');
                                            set_showCalander(true);
                                            set_calanderMessage('To');
                                        }}
                                        title= {'Until: ' + uiState.btn_etaTo}
                                    >
                                        <Text style={[{textAlign:'center'}, style(WIDTH).fontSize2]}>{'Until: ' + uiState.btn_etaTo}</Text>
                                    </Pressable>
                                </View>

                                <View style={[style(WIDTH).scontainer2]} >
                                    <Pressable
                                        style={style(WIDTH).from_and_to}             
                                        onPress={() => {
                                            set_calanderFor_Attribute('etd');
                                            set_calanderFor_Point('From');
                                            set_showCalander(true);
                                            set_calanderMessage('From');
                                        }}
                                    >
                                        <Text style={[{textAlign:'center'}, style(WIDTH).fontSize2]}>{'From: ' +uiState.btn_etdFrom}</Text>
                                    </Pressable>
                        
                                    <Pressable
                                        style={style(WIDTH).from_and_to}              
                                        onPress={() => {
                                            set_calanderFor_Attribute('etd');
                                            set_calanderFor_Point('To');
                                            set_showCalander(true);
                                            set_calanderMessage('To');
                                        }}
                                    >
                                        <Text style={[{textAlign:'center'}, style(WIDTH).fontSize2]}>{'Until: ' + uiState.btn_etdTo}</Text>
                                    </Pressable>
                                </View>                
                
                                <Pressable style={[style(WIDTH).scontainer, {zIndex:10}]} onPress={()=>{showOverlay('shipper');}}><TextInput pointerEvents='none'
                                            style={[style(WIDTH).searchInput,style(WIDTH).fontSize2,style(WIDTH).searchInput_so]}
                                            value={state_shipperID}
                                            multiline={state_shipperID? true: false}
                                            placeholder={'Shipper'}/>
                                </Pressable>
                
                                <View style={[style(WIDTH).scontainer, {zIndex:10}]} >
                                    <DropDownPicker
                                        open={openDd.staff}
                                        value={state_staffID}
                                        items={dropdownStaff}
                                        setOpen={(val)=>{setOpen("staff", val)}}
                                        setValue={set_state_staffID}
                                        searchable={true}
                                        closeOnBackPressed={true}
                                        listMode="SCROLLVIEW"
                                        showTickIcon={false}
                                        placeholder="Staff"
                                        placeholderStyle={{color:'#808080'}}
                                        showArrowIcon={false}
                                        containerStyle={[style(WIDTH).searchInput, style(WIDTH).fontSize2, {justifyContent:'center',height:WIDTH*0.055}]}
                                        dropDownContainerStyle={[style(WIDTH).dropDownContainer, 
                                            {position:'absolute', left: -WIDTH*0.0001, top: WIDTH*0.055}]}
                                        searchPlaceholder="Search..."
                                        textStyle={[style(WIDTH).fontSize2]}
                                        onChangeValue={(value) => { onChangeValue("userID", value); }}
                                        dropDownDirection="BOTTOM"
                                    />  
                                </View>
                
                                <View style={[style(WIDTH).scontainer2]} >
                                    <Pressable
                                        style={style(WIDTH).from_and_to}              
                                        onPress={() => {
                                            set_calanderFor_Attribute('create');
                                            set_calanderFor_Point('From');
                                            set_showCalander(true);
                                            set_calanderMessage('From');
                                        }}
                                    >
                                        <Text style={[{textAlign:'center'}, style(WIDTH).fontSize2]}>{'From: ' + uiState.btn_createFrom}</Text>
                                    </Pressable>
                                    
                                    <Pressable
                                        style={style(WIDTH).from_and_to}             
                                        onPress={() => {
                                            set_calanderFor_Attribute('create');
                                            set_calanderFor_Point('To');
                                            set_showCalander(true);
                                            set_calanderMessage('To');
                                        }}
                                    >
                                        <Text style={[{textAlign:'center'}, style(WIDTH).fontSize2]}>{'Until: ' + uiState.btn_createTo}</Text>
                                    </Pressable>
                                </View>
                            </DataTable.Header>
                            <ButtonPaper 
                                style={[style(WIDTH).button_orange, {marginTop:WIDTH*0.01}]} 
                                labelStyle={style().button_label_white}  
                                onPress={() => {getSalesOrder();}}> <Text style={style(WIDTH).fontSize2}>Search</Text></ButtonPaper>
                        </View>):null}
                </View>

                <DataTable.Header style={[style(WIDTH).dataTable_header_border, {marginTop:'2%'}]}>
                    <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content, {fontWeight:'bold'}]}>ID</Text></View>
                    <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content, {fontWeight:'bold'}]}>Invoice ID</Text></View>
                    <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content, {fontWeight:'bold'}]}>Client</Text></View>
                    <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content, {fontWeight:'bold'}]}>Cus. Ref.</Text></View>
                    <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content, {fontWeight:'bold'}]}>ETD</Text></View>
                    <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content, {fontWeight:'bold'}]}>ETA</Text></View>
                    <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content, {fontWeight:'bold'}]}>Shipper</Text></View>
                    <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content, {fontWeight:'bold'}]}>Staff</Text></View>
                    <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content, {fontWeight:'bold'}]}>Created at</Text></View>
                </DataTable.Header>
                
                {salesOrderTable}

                <View style={style().container_first}>
                    <Text style={[style(WIDTH).text_grey, style(WIDTH).text_total_item, style(WIDTH).fontSize2]}>{`Total items: ${uiState.totalItems}`}</Text>
                    
                    <View style={style(WIDTH).container_second}>
                        <Pressable 
                            style={isFirst? style(WIDTH).paginationButtonDisabled:style(WIDTH).paginationButton} 
                            onPress={previousPage} 
                            disabled={isFirst}
                        >
                            <Image source={prev} resizeMode='contain' style={[style(WIDTH).pagination_arrow,isFirst && style().disableTint]} />
                        </Pressable>
                        
                        <Text style={[style(WIDTH).text_grey,style(WIDTH).text_page, style(WIDTH).fontSize2]}>
                            {`Page `}
                            <Text style={[style().text_current_page, style(WIDTH).fontSize3]}>{`${uiState.currentPage}`}</Text>
                            {` of ${uiState.totalPages}`}
                        </Text>
                        
                        <Pressable 
                            style={isLast? style(WIDTH).paginationButtonDisabled: style(WIDTH).paginationButton} 
                            onPress={nextPage}
                            disabled={isLast}
                        >
                            <Image source={next} resizeMode='contain' style={[style(WIDTH).pagination_arrow,isLast && style().disableTint]} />
                        </Pressable>
                    </View>
                
                    <View style={style(WIDTH).container_third}>
                    <Text style={[style(WIDTH).text_grey,style(WIDTH).text_page, style(WIDTH).fontSize2]}>Go to page: </Text>
                        <TextInput 
                            style={[style(WIDTH).fontSize2, style(WIDTH).go_to_input]} 
                            onChangeText={(value) => onChangePage(value)}
                            placeholder={`${uiState.currentPage}`}
                        />
                    </View>
                </View>       
            </DataTable>
            {/* <Text>Page {uiState.currentPage}/{uiState.totalPages}, Total items: {uiState.totalItems}</Text>
            <Text>Go to page: </Text>
            <TextInput style={styles.tb} placeholder=""  
                keyboardType = 'numeric'
                value={state_goToPage}
                onChangeText={(value) => {
                    if (+value || value == "") 
                        set_state_goToPage(value);                    
                }}
                onSubmitEditing={goToPage}
            />           */}
            <DatePickerModal
                mode="single"
                visible={showCalander}
                onDismiss={onDismiss}
                date={new Date()}      
                onConfirm={onConfirm}
                validRange={{
                    startDate: new Date(2020, 0, 1),
                    endDate: new Date()
                }}
                // onChange={} // same props as onConfirm but triggered without confirmed by user
                saveLabel="Select Date" // optional
                uppercase={false} // optional, default is true
                label={calanderMessage} // optional
                animationType="fade" // optional, default is 'slide' on ios/android and 'none' on web
            />    

            <Overlay isVisible={state_showOverlay} onBackdropPress={hideOverlay} animationType='fade' overlayStyle={{width:'95%', height:'95%'}}>   
                <View style={{alignItems: 'flex-end'}}><Icon name='close' type="antdesign" size={option(WIDTH).iconSize3} onPress={hideOverlay}/></View>                     
                <ScrollView style={{marginTop:'1%'}}
                    contentContainerStyle={{flex:1, paddingRight:100}} 
                    showsHorizontalScrollIndicator={false} 
                    showsVerticalScrollIndicator={false} 
                    focusable={false}
                >
                    <Client_list selectContact={select_client} selectAddress={!select_client} sendDataToParent={sendDataToParent} is_client={is_client}/>
                </ScrollView>                    
            </Overlay>     
        </View>
    )
}

export default SalesOrder_List;