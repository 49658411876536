import React, { useEffect, useState, useCallback, useRef } from 'react'
import { View, StyleSheet, Image, TextInput, ScrollView, Text, Picker, Pressable, Switch, Dimensions, TouchableOpacity } from 'react-native'
import { API_URL, BASE_URL } from '../config/config';
import axios from 'axios';
import { DataTable, Button as ButtonPaper } from 'react-native-paper';
import ReactExport from 'react-export-excel';
import DropDownPicker from 'react-native-dropdown-picker';
import { style, option } from '../styles/style';
import { en, registerTranslation, DatePickerModal } from 'react-native-paper-dates';
registerTranslation('en', en);
import { Button, Icon, Overlay } from 'react-native-elements';
import { useHover } from 'react-native-web-hooks';
import { Hoverable } from 'react-native-web-hooks';
import Client_List from './Client_list';
import { ITEM_PER_TABLE_PAGE } from '../constants';
import { Popable } from 'react-native-popable';

import { getWebsiteWidth } from '../components/utilities';

import prev from '../assets/prev-arrow.png';
import next from '../assets/next-arrow.png';
import { height, width } from '@mui/system';
import { set } from 'react-native-reanimated';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const token = window.localStorage.getItem('token');
const headers = {
    Authorization: `Bearer ${token}` 
}

const label = ["Jan","Feb","Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];

// let currentYear = new Date().getFullYear();
// let lastYear = (parseInt(currentYear) - 1).toString();
// let lastTwoYear = (parseInt(lastYear) - 1).toString();
// let yearList = [lastTwoYear, lastYear, currentYear];

// const YearList = yearList.map((item)=>( <Picker.Item key={item} label={item} value={item} /> ));

function Report_Client() {
    if(window.localStorage.getItem('role') == 1) window.location.href = `${BASE_URL}`;
    const [WIDTH, set_WIDTH] = useState(getWebsiteWidth());

    const [clientID, setClientID] = useState(null);
    const [searchParams, set_searchParams] = useState([]);
    const [totalSpend, setTotalSpend] = useState([]);
    const [totalSpendList, setTotalSpendList] = useState([]);
    const [clientName, setClientName] = useState('');
    const [val_goToPage, set_val_goToPage] = useState('');
    const [totalSales, set_totalSales] = useState(null);
    const [totalProfit, set_totalProfit] = useState(null);

    const [excelData, setExcelData] = useState([]);
    const [filename, setFilename] = useState('');

    // for pagination
    const [tablePage, setCurrentPage] = useState({totalPages:0, currentPage:0});
    const [totalItems, setTotalItems] = useState(0);
    const [isFirst, setFirst] = useState(1);
    const [isLast, setLast] = useState(0);

    const isHovered = useHover(ref);
    const ref = useRef(null);

    let pageTimer;

    // Overlay
    const [state_showOverlay, set_state_showOverlay] = useState(false);
    const showOverlay = () => {
        set_state_showOverlay(true);
    };
    const hideOverlay = () => {
        set_state_showOverlay(false);
    };
    const sendDataToParent = async(params) => {
        set_state_showOverlay(false);
        setClientID(params.id);
        setClientName(params.name + '  |  ' + params.code);    
    }

    // const getCustomerList = async() =>{
    //     let url = API_URL + 'utils/options/client';

    //     const res = await axios({
    //         method:"GET",
    //         url:url,
    //         headers:headers,
    //     });

    //     if(res.status === 200){
    //         let all_client = res.data.result;
    //         let client_list = all_client.map((client) => {return({label:client.title,value:client.id})})
    //         setNameList(client_list);
    //     }
    // }


    const getTotalSpentByCustomer = async(params) => {
        if(!clientID) 
        {
            MessageBox("Error", 'Please select a client.');
            return; 
        }

        let queryParams = {};
        for (const sp in searchParams){
            if(searchParams[sp] != '')
                queryParams['filter[' + sp + ']'] = searchParams[sp];
        }
        queryParams['clientID'] = clientID;
        
        window.API_CALL_GET('report/totalspend', queryParams).then(
          function(data) {        
            setTotalItems(data.length);
            let totalPage = Math.ceil(data.length / ITEM_PER_TABLE_PAGE);
            setCurrentPage({currentPage: 0, totalPages: totalPage});
            setTotalSpend(data);   
            setFirst(1);      
            set_val_goToPage('');     
            if(totalPage == 0 || totalPage == 1)
                setLast(1);
            else
                setLast(0);
            if(data.length == 0)
                MessageBox('', 'No data.');

            let _totalSales = {RM:0, USD:0, EUR:0, SGD:0}, _totalProfit = {RM:0, USD:0, EUR:0, SGD:0};
            let _clientExcelData = [];
            for(let i=0; i<data.length; i++)
            {
                let so_ID = data[i].shipmentID;
                let desc = data[i].desc;
                let consignee = data[i].consignee_name;
                let created_date = data[i].createdAt;
                let sales_RM, sales_SGD, sales_EUR, sales_USD;
                let profit_RM, profit_SGD, profit_EUR, profit_USD;
                
                
                if(data[i].gross_profit)
                {
                    data[i].gross_profit.RM?    _totalProfit.RM  += data[i].gross_profit.RM: null;
                    data[i].gross_profit.SGD?   _totalProfit.SGD += data[i].gross_profit.SGD: null;
                    data[i].gross_profit.USD?   _totalProfit.USD += data[i].gross_profit.USD: null;
                    data[i].gross_profit.EUR?   _totalProfit.EUR += data[i].gross_profit.EUR: null;

                    profit_RM = data[i].gross_profit.RM?    data[i].gross_profit.RM: "-";
                    profit_SGD = data[i].gross_profit.SGD?   data[i].gross_profit.SGD: "-";
                    profit_USD = data[i].gross_profit.USD?   data[i].gross_profit.USD: "-";
                    profit_EUR = data[i].gross_profit.EUR?   data[i].gross_profit.EUR: "-";
                }
                if(data[i].total_sales)
                {
                    data[i].total_sales.RM?      _totalSales.RM  += data[i].total_sales.RM: null;
                    data[i].total_sales.SGD?     _totalSales.SGD += data[i].total_sales.SGD: null;
                    data[i].total_sales.USD?     _totalSales.USD += data[i].total_sales.USD: null;
                    data[i].total_sales.EUR?     _totalSales.EUR += data[i].total_sales.EUR: null;

                    sales_RM = data[i].total_sales.RM?      data[i].total_sales.RM: "-";
                    sales_SGD = data[i].total_sales.SGD?     data[i].total_sales.SGD: "-";
                    sales_USD = data[i].total_sales.USD?     data[i].total_sales.USD: "-";
                    sales_EUR = data[i].total_sales.EUR?     data[i].total_sales.EUR: "-";
                }

                // // for excel data
                _clientExcelData.push([so_ID, desc, consignee, sales_RM, sales_SGD, sales_USD,sales_EUR,
                    profit_RM,profit_SGD,profit_USD,profit_EUR,created_date]);
            }
            set_totalProfit(_totalProfit);
            set_totalSales(_totalSales); 

            let obj = {
                total_data: [_totalSales.RM, _totalSales.SGD, _totalSales.USD, _totalSales.EUR,_totalProfit.RM, _totalProfit.SGD, _totalProfit.USD, _totalProfit.EUR],
                data: _clientExcelData
            }

            setExcelData([obj]);
            let name = clientName.split('|')[0]
            setFilename(`Client_${name}_Report`);
          },
          function(error) { MessageBox("Error", error); }
        );
    }
    
    let _run = false;
    useEffect(()=>{
        if (!totalSpend) return;
        if(!_run)
        {
            _run = true;
            setFirst(tablePage.currentPage == 0);
            setLast(tablePage.currentPage + 1 == tablePage.totalPages)
            const indexStart = tablePage.currentPage * ITEM_PER_TABLE_PAGE;
            const indexEnd = indexStart + ITEM_PER_TABLE_PAGE;
            const _totalSpend = totalSpend.slice(indexStart, indexEnd);

            setTotalSpendList(_totalSpend.map((item,index)=>{
                return(
                    <Hoverable key={index}>
                    {isHovered => (
                        <Pressable key={index} style={[style(WIDTH).table_row, isHovered && style(WIDTH).hover]}
                            onPress={() => {window.open(`${BASE_URL}sales_order/${item.shipmentID}`, '_blank')}}>
                            <DataTable.Row key={index}>
                                <View style={style(WIDTH).dataTable_content}><Text style={style(WIDTH).dataTable_content_flex}>{item.shipmentID || '-'}</Text></View>
                                <View style={[style(WIDTH).dataTable_content, {flex:2}]}><Text style={style(WIDTH).dataTable_content_flex}>{item.desc || '-'}</Text></View>
                                <View style={style(WIDTH).dataTable_content}><Text style={style(WIDTH).dataTable_content_flex}>{item.consignee_name||'-'}</Text></View>
                                <View style={style(WIDTH).dataTable_content}><Text style={style(WIDTH).dataTable_content_flex}>{item.total_sales?.RM||'-'}</Text></View>                           
                                <View style={style(WIDTH).dataTable_content}><Text style={style(WIDTH).dataTable_content_flex}>{item.gross_profit?.RM||'-'}</Text></View>                           
                                <View style={style(WIDTH).dataTable_content}><Text style={style(WIDTH).dataTable_content_flex}>{item.createdAt||'-'}</Text></View>
                            </DataTable.Row>
                        </Pressable>
                    )}
                    </Hoverable>
                )
            })); 
            _run = false;
        }
    },[totalSpend, tablePage, WIDTH])

    useEffect(()=>{
        window.addEventListener('resize', () => { set_WIDTH(getWebsiteWidth()); });
    },[])

    const onChangePage = (value) => {
            set_val_goToPage(value);
            clearTimeout(pageTimer);
            pageTimer = setTimeout(() => {   
                if(value != '' && value > 0)
                {
                    if (value > tablePage.totalPages) {value = tablePage.totalPages; set_val_goToPage(value)};
                    setCurrentPage({currentPage: value - 1, totalPages: tablePage.totalPages});
                }
            }, 800);
        
    }

    const previousPage = () => {
        if(tablePage.totalPages > 1) {
            (tablePage.currentPage - 1) == 0? setFirst(1):setFirst(0);
            setCurrentPage({currentPage: tablePage.currentPage - 1, totalPages: tablePage.totalPages});     
            setLast(0);
        }
    }

    const nextPage = () => {
        if(tablePage.currentPage + 1 < tablePage.totalPages) {
            (tablePage.currentPage+1) == tablePage.totalPages? setLast(1):setLast(0)
            setCurrentPage({currentPage: tablePage.currentPage + 1, totalPages: tablePage.totalPages});     
            setFirst(0);
        }
    }

    // Calander
    const [showCalander, set_showCalander] = useState(false);
    const [calanderMessage, set_calanderMessage] = useState('');
    const [calanderFor_Attribute, set_calanderFor_Attribute] = useState('');
    const [calanderFor_Point, set_calanderFor_Point] = useState('');
    const [last_dt, set_last_dt] = React.useState('');

    const [calendarState] = useState({
        fromDate:'-',
        toDate:'-'
    })

    const onChangeValue = (key, value) => {
        searchParams[key] = value;
    }

    const doubleClickToSelect = useCallback((params) => {
        if(last_dt == params.date.toString())
        {
            onConfirm(params);
            set_showCalander(false); 
        }        
        else
            set_last_dt(params.date.toString());  
    });
    
    const onDismiss = useCallback(() => {
        set_showCalander(false);
        calendarState[calanderFor_Attribute] = '-'      
        
        onChangeValue(calanderFor_Attribute, "");
    });
    const onConfirm = useCallback((params) => {
            set_showCalander(false);
            var date = new Date(params.date);     
            if(calanderFor_Point == "From")
            {
                date.setHours(0);
                date.setMinutes(0);
                date.setSeconds(0);
                date.setMilliseconds(0);
            }
            else if(calanderFor_Point == "To")
            {
                date.setHours(23);
                date.setMinutes(59);
                date.setSeconds(59);
                date.setMilliseconds(999);
            } 
            let y = date.getFullYear();
            let m = date.getMonth()+1;
            let d = date.getDate();

            if (d < 10)
                d = '0' + d;
            if (m < 10)
                m = '0' + m;
            
            calendarState[calanderFor_Attribute] = d + "/" + m + "/" + y;
            onChangeValue(calanderFor_Attribute, date.getTime());
    });

    return(
        <View style={style(WIDTH).container}>
            <View style={{flexDirection:'row'}}>
                <View style={{flexDirection:'column', flex:1}}>
                    <View style={{flexDirection:'row'}}>
                        <Text style={[style(WIDTH).fontSize2, style(WIDTH).report_attribute]}>Client</Text>
                        <TouchableOpacity onPress={() => showOverlay("shipper")}>
                            <TextInput style={[style(WIDTH).fontSize2, style(WIDTH).report_searchInput, {width:WIDTH*0.217}]} editable={false}
                                value={clientName} placeholder='Select client...'/>
                        </TouchableOpacity>
                    </View>
                    
                    <View style={{flexDirection:'row', marginTop:WIDTH*0.005}}>
                        <Text style={[style(WIDTH).fontSize2, style(WIDTH).report_attribute]}>Select Date</Text>
                            <Pressable
                                onPress={() => {
                                    set_calanderFor_Attribute('fromDate');
                                    set_calanderFor_Point('From');
                                    set_last_dt('');
                                    set_showCalander(true);
                                    set_calanderMessage('From');
                                }}>
                                    <TextInput style={[style(WIDTH).fontSize2, style(WIDTH).report_searchInput, {width:WIDTH*0.105}]} editable={false}
                                        value={'From:  ' + calendarState['fromDate']}/>
                            </Pressable>
                            <View style={{width: WIDTH * 0.007}} />
                            <Pressable
                            onPress={() => {
                                set_calanderFor_Attribute('toDate');
                                set_calanderFor_Point('To');
                                set_last_dt('');
                                set_showCalander(true);
                                set_calanderMessage('To');
                            }}>
                            <TextInput style={[style(WIDTH).fontSize2, style(WIDTH).report_searchInput, {width:WIDTH*0.105}]} editable={false}
                                        value={'Until :  ' + calendarState['toDate']}/>
                        </Pressable>     
                    </View>

                    
                </View>
                <View style={style(WIDTH).container_row}>
                    {
                        excelData.length>0? 
                        <Popable content='Export Excel' position="left" backgroundColor='#FF9F1D' action="hover">
                        <ExcelFile filename={filename} element={
                            <Button icon={<Icon name='microsoft-excel' type="material-community" size={option(WIDTH).iconSize2}/>} type="clear" style={[option(WIDTH).buttonIcon,{pointerEvents: 'none'}]}/>}>
                        {excelData.map((item, index)=>{
                            let name = clientName.split('|')[0];
            
                            let dataset = [{
                                xSteps:3,
                                columns:["Sales", "","","","Profit","","",""],
                                data:[]
                            },
                            {
                                columns: ["Sales Order ID", "Description", "Consignee", "RM", "SGD", "USD", "EUR", "RM", "SGD", "USD", "EUR", "Created Date"],
                                data:item.data
                            },
                            {
                                xSteps:2,
                                columns:[],
                                data: [[{value: "Total", style: {font: {bold: true}}}]]
                            },
                            {
                                xSteps:3,
                                ySteps:-2,
                                columns:[],
                                data:[item.total_data]
                            }]

                            return(
                                <ExcelSheet key={index} dataSet={dataset} name={`Client_Report`} fileExtension="xlsx"></ExcelSheet>
                            )
                        })}
                    </ExcelFile>    
                    </Popable>:null}
                </View>



                {/* {totalSpendList.length>0? <View style={{flexDirection:'column', flex:1}}>
                    <View style={{flexDirection:'row', height:WIDTH*0.031}}>
                        <Text style={[style(WIDTH).fontSize2, style(WIDTH).report_attribute]}>Total Sales</Text>
                        <Text style={[style(WIDTH).fontSize2, {alignSelf:'center', flexShrink:1}]}>
                            RM {totalSales?.RM? totalSales.RM.toLocaleString(): '-'}  ,  
                            SGD {totalSales?.SGD? totalSales.SGD.toLocaleString(): '-'}  ,  
                            USD {totalSales?.USD? totalSales.USD.toLocaleString(): '-'}  ,  
                            EUR {totalSales?.EUR? totalSales.EUR.toLocaleString(): '-'}
                        </Text>
                    </View>
                    <View style={{flexDirection:'row', height:WIDTH*0.031, marginTop:WIDTH*0.005}}>
                        <Text style={[style(WIDTH).fontSize2, style(WIDTH).report_attribute]}>Total Profit</Text>
                        <Text style={[style(WIDTH).fontSize2, {alignSelf:'center', flexShrink:1}]}>
                            RM {totalProfit?.RM? totalProfit.RM.toLocaleString(): '-'}  ,  
                            SGD {totalProfit?.SGD? totalProfit.SGD.toLocaleString(): '-'}  ,  
                            USD {totalProfit?.USD? totalProfit.USD.toLocaleString(): '-'}  ,  
                            EUR {totalProfit?.EUR? totalProfit.EUR.toLocaleString(): '-'}
                        </Text>
                    </View>
                    {
                        excelData.length>0? 
                        <Popable content='Export Excel' position="left" backgroundColor='#FF9F1D' action="hover">
                            <ExcelFile filename={filename} element={
                        <Button icon={<Icon name='microsoft-excel' type="material-community" size={option(WIDTH).iconSize2}/>} type="clear" style={[option(WIDTH).buttonIcon,{pointerEvents: 'none'}]}/>}>
                        {excelData.map((item, index)=>{
                            let name = clientName.split('|')[0];
            
                            let dataset = [{
                                xSteps:3,
                                columns:["Sales", "","","","Profit","","",""],
                                data:[]
                            },
                            {
                                columns: ["Sales Order ID", "Description", "Consignee", "RM", "SGD", "USD", "EUR", "RM", "SGD", "USD", "EUR", "Created Date"],
                                data:item.data
                            },
                            {
                                xSteps:2,
                                columns:[],
                                data: [[{value: "Total", style: {font: {bold: true}}}]]
                            },
                            {
                                xSteps:3,
                                ySteps:-2,
                                columns:[],
                                data:[item.total_data]
                            }]

                            return(
                                <ExcelSheet key={index} dataSet={dataset} name={`Client_${name}`}></ExcelSheet>
                            )
                        })}
                    </ExcelFile>    
                    </Popable>:null}
                </View>: null} */}
            </View>
            <View style={{alignItems: 'flex-end', flexDirection:'row'}}>
                <Text style={[style(WIDTH).fontSize2, style(WIDTH).report_attribute]}></Text>
                <ButtonPaper
                    style={[style(WIDTH).create_btn, {width: WIDTH * 0.217, marginTop:WIDTH*0.005}]} 
                    labelStyle={style(WIDTH).create_btn_label} 
                    onPress={()=>{
                        setExcelData([]);
                        getTotalSpentByCustomer();
                    }}>
                        Search
                </ButtonPaper>  
            </View>
        
        {totalSpendList.length > 0?(

        <DataTable style={{marginTop:'2%'}}>
            <Text style={[style(WIDTH).fontSize2, {alignSelf:'flex-end'}]}>*Only completed S.O. is  considered.</Text>
            <DataTable.Header style={[style(WIDTH).dataTable_header_border]}>
                <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>Sales Order ID</Text></View>
                <View style={[style(WIDTH).dataTable_header, {flex:2}]}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>Description</Text></View>
                <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>Consignee</Text></View>
                <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>Sales (RM)</Text></View>
                <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>Profit (RM)</Text></View>
                <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>Created Date</Text></View>
            </DataTable.Header>
           
            {totalSpendList}

            <View style={style().container_first}>
                    <Text style={[style(WIDTH).text_grey, style(WIDTH).text_total_item, style(WIDTH).fontSize2]}>{`Total items: ${totalItems}`}</Text>
                    <View style={style(WIDTH).container_second}>
                        <Pressable 
                            style={isFirst? style(WIDTH).paginationButtonDisabled:style(WIDTH).paginationButton} 
                            onPress={previousPage} 
                            disabled={isFirst}
                        >
                            <Image source={prev} resizeMode='contain' style={[style(WIDTH).pagination_arrow,isFirst && style().disableTint]} />
                        </Pressable>
                    
                        <Text style={[style(WIDTH).text_grey,style(WIDTH).text_page, style(WIDTH).fontSize2]}>
                            {`Page `}
                            <Text style={[style().text_current_page, style(WIDTH).fontSize3]}>{`${tablePage.totalPages == 0? tablePage.currentPage:tablePage.currentPage+1}`}</Text>
                            {` of ${tablePage.totalPages}`}
                        </Text>
                        
                        <Pressable 
                            style={isLast? style(WIDTH).paginationButtonDisabled: style(WIDTH).paginationButton} 
                            onPress={nextPage}
                            disabled={isLast}
                        >
                            <Image source={next} resizeMode='contain' style={[style(WIDTH).pagination_arrow,isLast && style().disableTint]} />
                        </Pressable>
                    </View>
                
                    <View style={style(WIDTH).container_third}>
                        <Text style={[style(WIDTH).text_grey,style(WIDTH).text_page, style(WIDTH).fontSize2]}>Go to page: </Text>
                        <TextInput 
                            style={[style(WIDTH).fontSize2, style(WIDTH).go_to_input]}
                            onChangeText={(value) => onChangePage(value)}
                            value={val_goToPage}
                            placeholder={`${tablePage.totalPages == 0? tablePage.currentPage:tablePage.currentPage+1}`}
                        />
                    </View>
                </View>
        </DataTable>):null
}

        <DatePickerModal
            mode="single"
            visible={showCalander}
            onDismiss={onDismiss}
            // date={new Date()}      
            onConfirm={onConfirm}
            validRange={{
                endDate: new Date()
            }}
            onChange={doubleClickToSelect} // same props as onConfirm but triggered without confirmed by user
            saveLabel="Select Date" // optional
            uppercase={false} // optional, default is true
            label={calanderMessage} // optional
            animationType="fade" // optional, default is 'slide' on ios/android and 'none' on web
        />    

        <Overlay isVisible={state_showOverlay} onBackdropPress={hideOverlay} animationType='fade' overlayStyle={{maxWidth:WIDTH*0.95, maxHeight:'95%'}}>   
        <View style={style(WIDTH).overlayCloseButton}><Icon name='close' type="antdesign" size={option(WIDTH).iconSize3} onPress={hideOverlay}/></View>                     
                <ScrollView
                    contentContainerStyle={{flex:1}} 
                    showsHorizontalScrollIndicator={false} 
                    showsVerticalScrollIndicator={false} 
                    focusable={false}
                >
                    <Client_List selectContact={1} is_client={1} selectAddress={0} sendDataToParent={sendDataToParent}/>
                </ScrollView>                    
            </Overlay>     
        
    </View>
    )
}

export default Report_Client