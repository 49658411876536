import React, { useEffect, useState, useCallback, useRef } from 'react'
import { View, StyleSheet, Image, TextInput, Linking, Text, Picker, Pressable } from 'react-native'
import { API_URL, BASE_URL } from '../config/config';
import axios from 'axios';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import ReactExport from 'react-export-excel';
import DropDownPicker from 'react-native-dropdown-picker';
import { style, option } from '../styles/style';
import { en, registerTranslation, DatePickerModal } from 'react-native-paper-dates';
registerTranslation('en', en);
import { Button, Icon } from 'react-native-elements';
import { getWebsiteWidth } from '../components/utilities';
import { Popable } from 'react-native-popable';
import {Bar, Chart as Chart2} from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import pdfConverter from 'jspdf';

import prev from '../assets/prev-arrow.png';
import next from '../assets/next-arrow.png';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const token = window.localStorage.getItem('token');
const headers = {
    Authorization: `Bearer ${token}` 
}

const label = ["Jan","Feb","Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];

let currentYear = new Date().getFullYear();
let lastYear = (parseInt(currentYear) - 1).toString();
let lastTwoYear = (parseInt(lastYear) - 1).toString();
let yearList = [lastTwoYear, lastYear, currentYear];

const YearList = yearList.map((item)=>( <Picker.Item key={item} label={item} value={item} /> ));

function Report_Sales() {
    if(window.localStorage.getItem('role') == 1) window.location.href = `${BASE_URL}`;
    const [WIDTH, set_WIDTH] = useState(getWebsiteWidth());

    // select year
    const [year, setYear] = useState(currentYear);

    // select sales person name
    const [nameList,setNameList] = useState([]);
    const [isOpen, setOpen] = useState(false);
    const [value, setValue] = useState('');

    // excel data
    const [excelData, setExcelData] = useState([]);
    const [filename,setFilename] = useState(`SalesPerson_${year}`);
    const [pdfFileName, setPdfFileName] = useState(`SalesPerson_${year}`);

    // // for toggle
    // const [profit,setProfit] = useState(true);
    // const [totalSales, setTotalSales] = useState(false);

    // data list (graph)
    const [allData, setAllData] = useState([]);
    const [dataList, setDataList] = useState([]);
    const [selectedData, setSelectedData] = useState(null);
    
    // const [profitList, setProfitList] = useState([]);
    // const [selectedProfitList, setSelectedProfit] = useState(null);
    // const [tolSalesList, setTolSalesList] = useState([]);
    // const [selectedSalesList, setSelectedSales] = useState(null);
    
    // for pagination
    const [showPage, setShowPage] = useState(false);
    const [partOfDataList, setPartOfDataList] =  useState([]);

    // const [partOfProfitList, setPartOfProfitList] = useState([]);
    // const [partOfTolSales, setPartOfTolSales] = useState([]);
    const [tablePage, setCurrentPage] = useState({totalPages:0, currentPage:1});
    const [totalItems, setTotalItems] = useState(0);
    const [isFirst, setFirst] = useState(1);
    const [isLast, setLast] = useState(0);
    const limit = 5;

    let pageTimer;

    useEffect(async()=>{
        ShowLoading();
        await getSalesKPI();
        setFilename(`SalesPerson_${year}`);
        setPdfFileName(`SalesPerson_${year}`)
        HideLoading();
        window.addEventListener('resize', () => { set_WIDTH(getWebsiteWidth()); });
    },[year])

    const getSalesKPI = async() => {
        window.API_CALL_GET('report/saleskpi', {year: year}).then(
          function(data) {    
            let all_sales = data;
            let sales_name = [];
            let sales_excel_data = [];

            // data=[[salesname,kpi,profit,totalsales],[...]]
            for(let i=0;i<label.length;i++){
                let each_sales = [];
                for(let j=0;j<all_sales.length;j++){
                    let each_kpi = all_sales[j].kpi[i+1];
                    let name = all_sales[j].name;
                    let profit_rm;
                    let profit_eur;
                    let profit_sgd;
                    let profit_usd;

                    let tolsales_rm;
                    let tolsales_eur;
                    let tolsales_sgd;
                    let tolsales_usd;
                    if(all_sales[j].gross_profit[i+1].length>0){

                    all_sales[j].gross_profit[i+1].map((item)=>{
                        switch(item.currency){
                            case 'RM':
                                profit_rm = item.value;
                                break;
                            case 'EUR':
                                profit_eur = item.value;
                                break;
                            case 'SGD':
                                profit_sgd = item.value;
                                break;
                            case 'USD':
                                profit_usd = item.value;
                                break;
                            default:
                                break;
                        }
                    })
                }else{
                    profit_rm='-';
                    profit_eur='-';
                    profit_sgd='-';
                    profit_usd='-';
                }

                if(all_sales[j].total_sales[i+1].length>0){
                    all_sales[j].total_sales[i+1].map((item)=>{
                        switch(item.currency){
                            case 'RM':
                                tolsales_rm = item.value;
                                break;
                            case 'EUR':
                                tolsales_eur = item.value;
                                break;
                            case 'SGD':
                                tolsales_sgd = item.value;
                                break;
                            case 'USD':
                                tolsales_usd = item.value;
                                break;
                            default:
                                break;
                        }
                    })
                }else{
                    tolsales_rm='-';
                    tolsales_eur='-';
                    tolsales_sgd='-';
                    tolsales_usd='-';
                }
                    profit_rm = profit_rm? profit_rm:'-';
                    profit_eur = profit_eur? profit_eur:'-';
                    profit_sgd = profit_sgd? profit_sgd:'-';
                    profit_usd = profit_usd? profit_usd:'-';

                    tolsales_rm = tolsales_rm? tolsales_rm:'-';
                    tolsales_eur = tolsales_eur? tolsales_eur:'-';
                    tolsales_sgd = tolsales_sgd? tolsales_sgd:'-';
                    tolsales_usd = tolsales_usd? tolsales_usd:'-';

                    each_sales.push([name,each_kpi,profit_rm,profit_eur,profit_sgd,profit_usd,tolsales_rm,tolsales_eur,tolsales_sgd,tolsales_usd]);
                }
                let obj = {
                    month:label[i],
                    data:each_sales
                }
                sales_excel_data.push(obj);
            }
            setExcelData(sales_excel_data);

            // save kpi month by month
            // save gross profit (RM) & total sales(RM) month by month
            let all_data = all_sales.map((item,index)=>{
                sales_name.push({label:item.name, value:index});
                let profit_keys = Object.keys(item.gross_profit);
                let profit_data = [];
                let kpi = [];
                
                for(let a=0; a< profit_keys.length;a++){
                    if(item.kpi[profit_keys[a]] != undefined){
                        kpi.push(item.kpi[profit_keys[a]]);
                    }
                    
                    if(item.gross_profit[profit_keys[a]].length > 0){
                        item.gross_profit[profit_keys[a]].map(data=>{
                            if(data.currency == 'RM'){
                                profit_data.push(data.value);
                            }
                        })
                    }else{
                        profit_data.push(0);
                    }
                }

                let sales_keys = Object.keys(item.total_sales);
                let sales_data = [];
                for(let b=0; b<sales_keys.length;b++){
                    if(item.total_sales[sales_keys[b]].length > 0){
                        item.total_sales[sales_keys[b]].map(data=>{
                            if(data.currency == 'RM'){
                                sales_data.push(data.value);
                            }
                        })
                    }else{
                        sales_data.push(0);
                    }
                }

                let all_dt = {
                    kpi: kpi,
                    name: item.name,
                    profit_data: profit_data,
                    sales_data: sales_data
                }

                return all_dt;
            });
            setAllData(all_data);
            setNameList(sales_name);
          },
          function(error) { MessageBox("Error", error); }
      );
    }

    // const onChangeToggle = () => {
    //     setProfit(!profit);
    //     setTotalSales(!totalSales);
    // }

    useEffect(()=>{
        let chart_list = allData.map((item, index)=>{
            let dt = {
                labels:label,
                datasets:[{
                    type:'line',
                    label:'KPI (RM)',
                    borderColor: '#0f59a4',
                    borderWidth: WIDTH*0.002,
                    fill: false,
                    data: item.kpi
                },{
                    type:'bar',
                    label:'Profit (RM)',
                    borderColor: '#000000',
                    backgroundColor:'#fbda41',
                    borderWidth: WIDTH*0.0015,
                    data: item.profit_data
                },{
                    type:'bar',
                    label:'Total Sales (RM)',
                    borderColor: '#000000',
                    borderWidth: WIDTH*0.0015,
                    backgroundColor:'#ff9900',
                    data: item.sales_data
                }]
            }
        
            return(
                <View key={index} style={{marginBottom:WIDTH*0.05}}>
                    {/* <Text style={[style(WIDTH).fontSize2,{fontWeight:'bold'}]}>{item.name}</Text> */}
                    <div className='chart'>
                        <Chart2
                            height={90}
                            data={dt}
                            options={{
                                responsive:true,
                                plugins:{
                                    legend:{
                                        display:true,
                                        position:'top',
                                        labels:{ font:{ size:WIDTH*0.01 } }
                                    },
                                    tooltip:{
                                        bodyFont:{ size:WIDTH*0.01 },
                                        titleFont:{ size:WIDTH*0.01 }
                                    },
                                    title:{
                                        display:true,
                                        text:`Salesperson Report ${year} - ${item.name}`,
                                        font:{ size:WIDTH*0.01 }
                                    }
                                },
                                scales:{
                                    yAxes:{
                                        grid:{ display:false},
                                        ticks:{ font:{ size:WIDTH*0.01 } }
                                    },
                                    xAxes:{
                                        grid:{ display:false},
                                        ticks:{ font:{ size:WIDTH*0.01 } }
                                    }
                                }
                            }}
                        />
                    </div>
                </View>
            )
        });
        setDataList(chart_list);
    },[allData, WIDTH])

    useEffect(()=>{
        if(dataList.length > limit && selectedData == null){
            let totalpage = Math.ceil(dataList.length/limit);
            tablePage['totalPages'] = totalpage;
            setShowPage(true);
            setPartOfDataList(dataList.slice(0,limit));
        }else{
            setPartOfDataList(dataList);
        }
    },[dataList])

    // when select a sales peson -> don't show the pagination
    useEffect(()=>{
        if(selectedData != null){
            setShowPage(false);
            let name = allData[selectedData.key].name;
            console.log(name);
            setPdfFileName(`SalesPerson_${name}_${year}`);
        }
        // } setShowPage(false);
    },[selectedData, partOfDataList])

    const div2PDF = async(e)  => {
        ShowLoading();
        const but = e.target;
        but.style.display = "none";
        let input = window.document.getElementsByClassName("all_chart")[0];
        const pdf = new jsPDF("p", "pt");
        let ori_y = 20;
        let ratio = 748 / input.childNodes[0].clientWidth

        for(let a=0; a<input.childElementCount; a++){
            html2canvas(input.childNodes[a], {scale:ratio}).then(async(canvas) => {
                const img = canvas.toDataURL("image/png");
                if(canvas.height + ori_y > 840){
                    ori_y = 20;
                    pdf.addPage()
                        pdf.addImage(
                            img,
                            "png",
                            20,ori_y
                        );
                        ori_y = canvas.height + ori_y - 40;
                    
                }else{
                    pdf.addImage(
                        img,
                        "png",
                        20,ori_y
                    );
                    ori_y = canvas.height + ori_y - 40;
                }
            }).then(()=>{
                if(a == input.childElementCount-1){
                    pdf.save(pdfFileName+'.pdf');
                    but.style.display = "block";
                }
            }).catch(()=>{
                MessageBox("Error", "Failed to export PDF.");
                return;
            }).finally(()=>{
                HideLoading();
            })
        }
    };

    const onChangePage = (value) => {
        if(value != '' && (value <= tablePage.totalPages) && value > 0){
            clearTimeout(pageTimer);
            pageTimer = setTimeout(() => {   
                let goTo = (value-1)*limit;
                tablePage.currentPage = parseInt(value);
                tablePage.currentPage == 1? setFirst(1):setFirst(0);
                tablePage.currentPage == tablePage.totalPages? setLast(1):setLast(0);
                setPartOfDataList(dataList.slice(goTo, (goTo+limit)));       
            }, 800);
        }
    }

    const previousPage = () => {
        if(tablePage.totalPages > 1) {
            tablePage.currentPage = tablePage.currentPage - 1;
            setLast(0);
            tablePage.currentPage == 1? setFirst(1):setFirst(0);
            // if(tablePage.currentPage == 0) setFirst(1);
            let from = (tablePage.currentPage-1) * limit;
            let to = from+limit;
            setPartOfDataList(dataList.slice(from, to));
        }
    }

    const nextPage = () => {
        if(tablePage.totalPages > 1) {
            tablePage.currentPage = tablePage.currentPage + 1;
            setFirst(0);
            (tablePage.currentPage) == tablePage.totalPages? setLast(1):setLast(0)
            let from = (tablePage.currentPage-1) * limit;
            let to = from+limit;
            setPartOfDataList(dataList.slice(from, to));
        }
    }

    const onChange = (index) =>{
        if(index!=undefined){
            setSelectedData(dataList[index]);
        }
    }

    return(
        <View style={style(WIDTH).container}>
            <View style={{flexDirection:'row'}}>
                <View style={{flexDirection:'row', flex:1}}>
                <Text style={[style(WIDTH).fontSize2,style(WIDTH).report_attribute]}>Year</Text>
                    <Picker 
                        style={[style(WIDTH).fontSize2, style(WIDTH).report_searchInput]} 
                        selectedValue={year}
                        onValueChange={itemValue => setYear(itemValue)}
                    >
                        {YearList}
                    </Picker>
                </View>
                <View style={style(WIDTH).container_row}>
                <Popable content='Export PDF' position="left" backgroundColor='#FF9F1D' action='hover'>
                        <Button icon={<Icon name='file-pdf-o' type="font-awesome" size={option(WIDTH).iconSize2}/>} type="clear"style={[option(WIDTH).buttonIcon]} 
                            onPress={async(e) => {
                                ShowLoading();
                                await div2PDF(e)
                                HideLoading();}}/>
                    </Popable>
                    <View style={style(WIDTH).container_icon} />
                    <Popable content='Export Excel' position="left" backgroundColor='#FF9F1D' action="hover">
                    <ExcelFile filename={filename} element={
                        <Button icon={<Icon name='microsoft-excel' type="material-community" size={option(WIDTH).iconSize2}/>} type="clear" style={[option(WIDTH).buttonIcon,{pointerEvents: 'none'}]}/>}>
                        {excelData.map((item,index)=>{
                            let dataset = [{
                                xSteps:1,
                                columns: ["KPI","Profit","","","","Total Sales","","",""],
                                data:[]
                            },
                            {
                                columns:["Name","RM","RM","EUR","SGD","USD","RM","EUR","SGD","USD"],
                                data: item.data
                            }];
                            return( <ExcelSheet dataSet={dataset} name={item.month} key={index}/> )
                        })}
                    </ExcelFile>    
                    </Popable> 
                </View>
            </View>
            <View style={style(WIDTH).container_icon} />
            <View style={{flexDirection:'row',width: 0.65*WIDTH,zIndex:10000}}>
                <View style={{flexDirection:'row', flex:1, zIndex:10000}}>
                <Text style={[style(WIDTH).fontSize2,style(WIDTH).report_attribute]}>Salesperson</Text>
                    <View style={{flex:0.38}}>
                        <DropDownPicker
                            open={isOpen}
                            value={value}
                            items={nameList}
                            setOpen={setOpen}
                            setValue={setValue}
                            searchable={true}
                            closeOnBackPressed={true}
                            style={[style(WIDTH).report_searchInput, style(WIDTH).fontSize2]}
                            dropDownContainerStyle={style(WIDTH).dropDownContainer}
                            listMode="SCROLLVIEW"
                            mode="SIMPLE"
                            showTickIcon={false}
                            placeholder="Select salesperson..."
                            placeholderStyle={{color:'#808080'}}
                            textStyle={[style(WIDTH).fontSize2]}
                            showArrowIcon={false}
                            searchPlaceholder="Search..."
                            scroll
                            onChangeValue={(value) => { onChange(value) }}
                        /> 
                    </View>
                    {/* <TouchableOpacity style={[style(WIDTH).searchInput,style(WIDTH).fontSize2, {flex:0.4}]} onPress={()=> showStaffOverlay()} >
                    <Text style={[style(WIDTH).fontSize2]}>{staff_name || '---'}</Text>
                    </TouchableOpacity> */}
                </View>
            </View>
            <View style={style(WIDTH).container_icon2} />

            {/* <Text>Profit</Text>
            <Switch value={profit} onValueChange={onChangeToggle}/>
            <Text>Total Sales</Text>
            <Switch value={totalSales} onValueChange={onChangeToggle}/> */}
            <div className='all_chart'>
            {selectedData?selectedData:partOfDataList}
            </div>
            {/* {profit? 
                (selectedProfitList? 
                    selectedProfitList:partOfProfitList)
            
                :(selectedSalesList? 
                    selectedSalesList:partOfTolSales)} */}

            {showPage? 
                (<View style={style().container_first}>
                    <Text style={[style(WIDTH).text_grey, style(WIDTH).text_total_item, style(WIDTH).fontSize2]}>{`Total items: ${dataList.length}`}</Text>
                    <View style={style(WIDTH).container_second}>
                        <Pressable 
                            style={isFirst? style(WIDTH).paginationButtonDisabled:style(WIDTH).paginationButton} 
                            onPress={previousPage} 
                            disabled={isFirst}
                        >
                            <Image source={prev} resizeMode='contain' style={[style(WIDTH).pagination_arrow,isFirst && style().disableTint]} />
                        </Pressable>
                    
                        <Text style={[style(WIDTH).text_grey,style(WIDTH).text_page, style(WIDTH).fontSize2]}>
                            {`Page `}
                            <Text style={[style().text_current_page, style(WIDTH).fontSize3]}>{`${tablePage.currentPage}`}</Text>
                            {` of ${tablePage.totalPages}`}
                        </Text>
                        
                        <Pressable 
                            style={isLast? style(WIDTH).paginationButtonDisabled: style(WIDTH).paginationButton} 
                            onPress={nextPage}
                            disabled={isLast}
                        >
                            <Image source={next} resizeMode='contain' style={[style(WIDTH).pagination_arrow,isLast && style().disableTint]} />
                        </Pressable>
                    </View>
                
                    <View style={style(WIDTH).container_third}>
                        <Text style={[style(WIDTH).text_grey,style(WIDTH).text_page, style(WIDTH).fontSize2]}>Go to page: </Text>
                        <TextInput 
                            style={[style(WIDTH).fontSize2, style(WIDTH).go_to_input]}
                            onChangeText={(value) => onChangePage(value)}
                            placeholder={`${tablePage.currentPage}`}
                        />
                    </View>
                </View>):null}
        </View>
    )
}

export default Report_Sales