import React, {useState, useEffect} from 'react'
import { Picker, View, Text, StyleSheet, Pressable, TextInput} from 'react-native'
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { API_URL, BASE_URL } from '../config/config';
import { getWebsiteWidth, isDigit } from '../components/utilities';
import { style, grey2 } from '../styles/style';
import {Button as ButtonPaper} from 'react-native-paper';
import { set } from 'react-native-reanimated';

function SalesPerson_Create() {
    const [WIDTH, set_WIDTH] = useState(getWebsiteWidth());
    const [active, set_active] = useState(true);

    useEffect(() => {
        window.addEventListener('resize', () => { set_WIDTH(getWebsiteWidth()); });
    }, [])

    const { register, setValue, handleSubmit, control, formState: { errors } } = useForm({
        defaultValues: {
            name: '',
            gender:'',
            email:'',
            contact:'',
            kpi:'',
            status:'1'
        }
    });

    const onSubmit = async(data) => {
        ShowLoading();
        let url = API_URL + 'salesperson';
        let token = window.localStorage.getItem('token');
        let headers = {
            Authorization: `Bearer ${token}` 
        }

        let payload = {
            "mode":'Add',
            "email":data.email,
            "name":data.name,
            "contact":data.contact,
            "gender":data.gender,
            "kpi":data.kpi,
            "status":data.status
        }
        await axios({
            method:'post',
            url: url, 
            headers: headers,
            data:payload
        }).then(()=> {
            set_active(false);
            MessageBox("", 'Salesperson created.');
            window.location.href = `${BASE_URL}salesperson`;
        })
        .catch(() => MessageBox("Error", "Failed to create salesperson."))
        .finally(() => HideLoading());       
    }

    const onChange = arg => {
        return {
            value: arg.nativeEvent.text,
        };
    };
    return (
        <View style={[style(WIDTH).container,{width:WIDTH/2}]} pointerEvents={active? "auto":"none"}>
            <View style={style(WIDTH).container_create_col}>
                <View style={{marginBottom:WIDTH*0.025}}>
                    <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize4]}>Create Salesperson</Text></View>
                <View style={{flexDirection:'row'}}>
                    <View style={{flexDirection:'column', flex:0.6}}>
                        <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Name {errors.name?
                        <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                        <Controller
                            control={control}
                            render={({field:{onChange, onBlur, value}}) => (
                                <TextInput
                                    style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                    onBlur={onBlur}
                                    onChangeText={value => onChange(value)}
                                    value={value}
                                    placeholder='Enter name...'
                                    placeholderTextColor = {grey2}
                                />
                            )}
                            name="name"
                            rules={{required: true}}
                        />
                    </View>
                    <View style={style(WIDTH).container_icon} />
                    <View style={{flexDirection:'column', flex:0.4}}>
                        <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Gender {errors.gender?
                        <Text style={style(WIDTH).fontError}>is REQUIRED, please select!</Text>: null}</Text>
                        <Controller 
                            control={control}
                            render={({field:{onChange, onBlur, value}})=>(
                                <Picker
                                    selectedValue={value}
                                    style = {[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                    onValueChange={(itemValue, itemIndex)=>onChange(itemValue)}
                                    onBlur={onBlur}
                                >
                                    <Picker.Item label="Select..." value="" />
                                    <Picker.Item label="Female" value="F" />
                                    <Picker.Item label="Male" value="M" />                          
                                </Picker>
                            )}
                            name="gender"
                            rules={{ required: true }}
                        />
                    </View>
                </View>
                    
                <View>
                    <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Contact No. {errors.contact?
                        <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                    <Controller
                        control={control}
                        render={({field:{onChange, onBlur, value}}) => (
                            <TextInput
                                style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                onBlur={onBlur}
                                onChangeText={value => onChange(value)}
                                value={value}
                                placeholder='Enter contact no...'
                                placeholderTextColor = {grey2}
                                keyboardType="numeric"
                            />
                        )}
                        name="contact"
                        rules={{ required: true}}
                    />
                </View>

                <View>
                    <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Email {errors.email?
                        <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                    <Controller
                        control={control}
                        render={({field:{onChange, onBlur, value}}) => (
                            <TextInput
                                style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}    
                                onBlur={onBlur}
                                onChangeText={value => onChange(value)}
                                value={value}
                                placeholder='Enter email...'
                                placeholderTextColor = {grey2}
                            />
                        )}
                        name="email"
                        rules={{ required: true}}
                    />
                </View>
                
                <View style={{flexDirection:'row'}}>
                    <View style={style(WIDTH).create_col_space}>
                        <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>KPI (RM) {errors.kpi?
                        <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                        <Controller
                            control={control}
                            render={({field:{onChange, onBlur, value}}) => (
                                <TextInput
                                    style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                    onBlur={onBlur}
                                    onChangeText={(val) => {if(isDigit(val)) onChange(val)}}
                                    value={value}
                                    placeholder='Enter your KPI...'
                                    keyboardType="numeric"
                                    placeholderTextColor = {grey2}
                                />
                            )}
                            name="kpi"
                            rules={{required: true}}
                        />
                    </View>
                    <View style={style(WIDTH).container_icon} />
                    <View style={style(WIDTH).create_col_space}>
                        <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Status {errors.status?
                        <Text style={style(WIDTH).fontError}>is REQUIRED, please select!</Text>: null}</Text>
                        <Controller
                            control={control}
                            render={({field:{onChange, onBlur, value}}) => (
                                <Picker
                                    selectedValue={value}
                                    style = {[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                    onValueChange={(itemValue, itemIndex)=>onChange(itemValue)}
                                    onBlur={onBlur}
                                >
                                    <Picker.Item label="Active" value="1" />
                                    <Picker.Item label="Inactive" value="0" />                          
                                </Picker>
                            )}
                            name="status"
                            rules={{ required: true}}
                        />
                    </View>
                </View>
                {active && <ButtonPaper
                    style={[style(WIDTH).create_btn, {right: -WIDTH*0.015}]} 
                    labelStyle={style(WIDTH).create_btn_label} 
                    onPress={handleSubmit(onSubmit)}>
                    Create
                </ButtonPaper> }        
            </View>
        </View>
    )
}

export default SalesPerson_Create
