// export const API_URL = 'https://acaglobalportal.com/api/';
// export const BASE_URL = 'https://acaglobalportal.com/';

// export const BASE_URL = 'http://localhost:19006/';
// export const API_URL = 'http://localhost:8080/api/';


// export const API_URL = 'http://159.223.39.119/api/';

export const API_URL = 'https://acaglobalportal.com/api/aca/api/';
export const BASE_URL = 'https://acaglobalportal.com/';