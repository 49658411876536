import React, { useState, useEffect, useRef } from 'react';
import { Image, View, Text, ScrollView, Dimensions, TextInput, Pressable } from 'react-native';
import axios from 'axios';
import { API_URL, BASE_URL } from '../config/config';
import { ITEM_PER_TABLE_PAGE } from '../constants';
import { DataTable, Button as ButtonPaper} from 'react-native-paper';
import { style, option } from '../styles/style';
import { useHover, Hoverable } from 'react-native-web-hooks';
import { Button as ButtonElements, Icon, Overlay} from 'react-native-elements';
import { getWebsiteWidth } from '../components/utilities';
import Client_list from './Client_list.js';
import { Popable } from 'react-native-popable';
import DropDownPicker from 'react-native-dropdown-picker';

import prev from '../assets/prev-arrow.png';
import next from '../assets/next-arrow.png';
import search from '../assets/search-icon.png';

function DeliveryOrder() {
    const [WIDTH, set_WIDTH] = useState(getWebsiteWidth());

    const initialDO = [{
        SO_id:'',
        DO_id:'',
        truck_no:'',
        pickup_add:'',
        delivery_add:'',
        detail:'',
    }]

    // for DO table
    const [deliveryOrder, setDO] = useState(initialDO);
    const [DOList, setDOList] = useState('');
    
    // for searching
    const [showSearch, setShowSearch] = useState(0);
    const [searchParams, setSearchParams] = useState([]);
    const [state_shipperID, set_state_shipperID] = useState('');
    const [state_consigneeID, set_state_consigneeID] = useState('');
    const [state_staffID, set_state_staffID] = useState('');

    // for pagination
    const [tablePage, setCurrentPage] = useState({totalPages:0, currentPage:0});
    const [totalItems, setTotalItems] = useState(0);
    const [isFirst, setFirst] = useState(1);
    const [isLast, setLast] = useState(0);

    const isHovered = useHover(ref);
    const ref = useRef(null);

    let pageTimer;

    // Dropdown
    const init_dropdown_state = {staff:false};
    const [openDd, set_openDd] = useState(init_dropdown_state);
    const [dropdownStaff, set_dropdownStaff] = useState([]);
    const setOpen = (key, value) => {
      let new_state = init_dropdown_state;
      if(typeof(key) != "undefined")
          new_state[key] = value;
      set_openDd(new_state);
  }

    // Overlay
    const [state_showOverlay, set_state_showOverlay] = useState(false);
    const [currentIndex, set_currentIndex] = useState(null);
    const showOverlay = (index) => {
        set_currentIndex(index);
        set_state_showOverlay(true);
    };
    const hideOverlay = () => {
        set_state_showOverlay(false);
    };

    const sendDataToParent = async(params) => {
        onChangeValue(currentIndex, params.id);
        if(currentIndex == 'pickupAddress')
            set_state_shipperID(params.name);
        else if (currentIndex == 'deliverAddress')
            set_state_consigneeID(params.name);
        set_state_showOverlay(false);
    }

    // redirect to edit page when click the row
    const handleClickRow = (id) => {
        window.location.href = `${BASE_URL}delivery_order/${id}`
    };

    const getDO = async(params) => {
        ShowLoading();
        let token = window.localStorage.getItem('token');
        let url = API_URL + 'deliveryorder?';

        const headers = {
            Authorization: `Bearer ${token}` 
        }

        let queryParams = {};
        for(const sp in searchParams){
            if(searchParams[sp] != '')
                queryParams['filter[' + sp + ']'] = searchParams[sp];
        }
        
        queryParams['size'] = ITEM_PER_TABLE_PAGE;
        queryParams['page'] = 0;
        if(params && params.page){
            queryParams['page'] = params.page;
        }

        window.API_CALL_GET('deliveryorder', queryParams).then(
          function(data) {        
            let DOData = data.dataList;
            let numOfDO = DOData.length;
            let totalPage = data.totalPages;
            let currentPage = data.currentPage;
            setTotalItems(data.totalItems);
            setCurrentPage({totalPages: totalPage, currentPage: currentPage});

            (currentPage+1) == totalPage? setLast(1):setLast(0);
            currentPage == 0? setFirst(1):setFirst(0);
            let all_do = []
            for(let a = 0; a < numOfDO; a++){
                let obj = {
                    SO_id:DOData[a].shipmentID,
                    DO_id: DOData[a].doID,
                    truck_no: DOData[a].truck_no,
                    pickup_add: DOData[a].pickup_address,
                    delivery_add: DOData[a].deliver_address,
                    detail: DOData[a].cargoDetail,
                    is_completed: DOData[a].is_completed,
                    is_deleted: DOData[a].is_deleted,
                    shipper: DOData[a].shipper,
                    consignee: DOData[a].consignee,
                    pickupname: DOData[a].pickupname,
                    delivername: DOData[a].delivername,
                    staffName: DOData[a].username,
                    Cus_Ref: DOData[a].so_reference                    
                };
                all_do.push(obj);
            }
            setDO(all_do);
          },
          function(error) { MessageBox("Error", error); }
        );
    }

    const getStaffOption = async(req, params) => {
      const token = window.localStorage.getItem('token');
      let url = API_URL + 'utils/options/user';
      const headers = { Authorization: `Bearer ${token}` };
      const res = await axios({
          method:'get',
          url: url,
          headers: headers,
          params: {active_only:0}
      });

      set_dropdownStaff([]);
      if(res.status === 200)
      {   
          let data = res.data.result;
          data.sort((a, b) => a.title.localeCompare(b.title));
          // data.unshift({id:"", title:"Staff"});
          let content = [];
          for(let i = 0; i < data.length; i++)
              if(i % 2 == 1)
                  content.push({label: data[i].title, value: data[i].id,  
                      containerStyle: {backgroundColor: "#f2f2f2"}});
              else
                  content.push({label: data[i].title, value: data[i].id});
          set_dropdownStaff(content);
      }    
    };

    useEffect(() => {
        let dolist = deliveryOrder.map((item)=>{
            return(
                <Hoverable key={item.DO_id}>
                {isHovered => (
                    <Pressable key={item.DO_id} onPress={() => handleClickRow(item.DO_id)} 
                        style={[style(WIDTH).table_row,
                            item.is_completed && style(WIDTH).table_row_completed,
                            item.is_deleted && style(WIDTH).table_row_deleted,
                            item.is_complete && item.unlock_request && style(WIDTH).table_row_unlock_request,
                            isHovered && style(WIDTH).hover
                        ]}>
                        <DataTable.Row key={item.DO_id}>
                            <View style={[style(WIDTH).dataTable_content,{flex:1}]}><Text style={style(WIDTH).fontSize2}>{item.DO_id || '-'}</Text></View>
                            <View style={[style(WIDTH).dataTable_content,{flex:1}]}><Text style={style(WIDTH).fontSize2}>{item.SO_id || '-'}</Text></View>
                            <View style={[style(WIDTH).dataTable_content,{flex:1}]}><Text style={style(WIDTH).dataTable_content_flex}>{item.Cus_Ref || '-'}</Text></View>
                            <View style={[style(WIDTH).dataTable_content,{flex:1}]}><Text style={style(WIDTH).dataTable_content_flex}>{item.truck_no || '-'}</Text></View>
                            {/* <View style={[style(WIDTH).dataTable_content,{flex:1}]}><Text style={style(WIDTH).dataTable_content_flex}>{item.pickupname || '-'}</Text></View>
                            <View style={[style(WIDTH).dataTable_content,{flex:1}]}><Text style={style(WIDTH).dataTable_content_flex}>{item.delivername || '-'}</Text></View> */}
                            <View style={[style(WIDTH).dataTable_content,{flex:1}]}><Text style={style(WIDTH).dataTable_content_flex}>{item.staffName || '-'}</Text></View>
                        </DataTable.Row>
                    </Pressable>
                )}
                </Hoverable>
            )
        })
        setDOList(dolist);
    },[deliveryOrder, WIDTH]);

    useEffect(() => {
        // isMounted = true;
        window.addEventListener('resize', () => { set_WIDTH(getWebsiteWidth()); });
        onLoad();
        // return () => {
        //     // executed when unmount
        //     isMounted = false;
        // }
    }, []);

    
    const onLoad = () => {
        getDO();
        getStaffOption();
    }

   

    const [soID, setSOid] = useState('');
    const [doID, setDOid] = useState('');
    const [pickup_address, setPickup] = useState('');
    const [deliver_address, setDeliver] = useState('');
    const [truck_no, setTruckNo] = useState('');
    const [cusRef, set_cusRef] = useState('');

    const clearFilter = () => {
        setSOid('');
        setDOid('');
        setTruckNo('');
        setPickup('');
        setDeliver('');
        set_cusRef('');
        setSearchParams([]);
        set_state_shipperID('');
        set_state_consigneeID('');
        set_state_staffID('');
    }

    const onChangeValue = (key, value) => {
        searchParams[key] = value;
    }

    const onChangePage = (value) => {
        if(value != '' && (value <= tablePage.totalPages) && value > 0){
            clearTimeout(pageTimer);
            pageTimer = setTimeout(() => {          
                getDO({ page:value-1 });
            }, 800);
        }
    }

    const previousPage = () => {
        if(tablePage.totalPages > 1) {
            tablePage.currentPage = tablePage.currentPage - 1;
            setLast(0);
            tablePage.currentPage == 0? setFirst(1):setFirst(0)
            getDO({ page:tablePage.currentPage });
        }
    }

    const nextPage = () => {
        if(tablePage.totalPages > 1) {
            tablePage.currentPage = tablePage.currentPage + 1;
            setFirst(0);
            (tablePage.currentPage+1) == tablePage.totalPages? setLast(1):setLast(0)
            getDO({ page:tablePage.currentPage });
        }
    }

    return (
        <View style={style(WIDTH).container} >
            <DataTable style={{zIndex:1}}>
                <View style={style(WIDTH).container_row}>
                    <Popable content='Refresh' position="left" backgroundColor='#FF9F1D' action="hover">
                    <ButtonElements icon={<Icon name='refresh' type="simplelineicons" size={option(WIDTH).iconSize2}/>} type="clear" style={{padding: '5%'}}
                        onPress={getDO}/></Popable>                 
                    <View style={style(WIDTH).container_icon}></View>
                    <Popable content='Search' position="left" backgroundColor='#FF9F1D' action="hover">
                    <ButtonElements icon={<Icon name='search1' type="antdesign" size={option(WIDTH).iconSize2}/>} type="clear" style={{padding: '5%'}}
                        onPress={()=>setShowSearch(!showSearch)}/></Popable>
                </View> 
                <View style={[style(WIDTH).container_column, {marginTop:'2%'}]}>                   
                    { showSearch? 
                    (<View style={style(WIDTH).container_search_input}>
                        <ButtonPaper
                            style={style(WIDTH).button_orange} 
                            labelStyle={style().button_label_white} 
                            onPress={clearFilter}
                        >
                            <Text style={style(WIDTH).fontSize2}>Clear Filter</Text>
                        </ButtonPaper>
                        <DataTable.Header style={style(WIDTH).dataTable_header2}>
                        <View style={style(WIDTH).scontainer}>
                                <TextInput
                                    style={[style(WIDTH).searchInput,style(WIDTH).fontSize2]}
                                    value={doID}
                                    onChangeText={(value) => {
                                        onChangeValue("doID",value);
                                        setDOid(value);
                                    }}
                                    placeholder="Delivery O. ID"
                                />
                            </View>

                            <View style={style(WIDTH).scontainer}>
                                <TextInput
                                    style={[style(WIDTH).searchInput,style(WIDTH).fontSize2]}
                                    value={soID}
                                    onChangeText={(value) => {
                                        onChangeValue("shipmentID",value);
                                        setSOid(value);
                                    }}
                                    placeholder="Sales O. ID"
                                />
                            </View>     

                            <View style={style(WIDTH).scontainer}>
                                <TextInput
                                    style={[style(WIDTH).searchInput,style(WIDTH).fontSize2]}
                                    value={cusRef}
                                    onChangeText={(value) => {
                                        onChangeValue("so_reference",value);
                                        set_cusRef(value);
                                    }}
                                    placeholder="Cus. Ref"
                                />
                            </View>

                            <View style={style(WIDTH).scontainer}>
                                <TextInput
                                    style={[style(WIDTH).searchInput,style(WIDTH).fontSize2]}
                                    value={truck_no}
                                    onChangeText={(value) => {
                                        onChangeValue("truck_no",value);
                                        setTruckNo(value);
                                    }}
                                    placeholder="Truck No."
                                />
                            </View>

                            <View style={[style(WIDTH).scontainer, {zIndex:10}]} >
                                    <DropDownPicker
                                        open={openDd.staff}
                                        value={state_staffID}
                                        items={dropdownStaff}
                                        setOpen={(val)=>{setOpen("staff", val)}}
                                        setValue={set_state_staffID}
                                        searchable={true}
                                        closeOnBackPressed={true}
                                        listMode="SCROLLVIEW"
                                        showTickIcon={false}
                                        placeholder="Staff"
                                        placeholderStyle={{color:'#808080'}}
                                        showArrowIcon={false}
                                        containerStyle={[style(WIDTH).searchInput, style(WIDTH).fontSize2]}
                                        dropDownContainerStyle={[style(WIDTH).dropDownContainer, 
                                            {position:'absolute', left: -WIDTH*0.0001, top: WIDTH*0.055}]}
                                        searchPlaceholder="Search..."
                                        textStyle={[style(WIDTH).fontSize2]}
                                        onChangeValue={(value) => { onChangeValue("staff", value); }}
                                        dropDownDirection="BOTTOM"
                                    />  
                            </View>
                
                        </DataTable.Header>
                        <ButtonPaper 
                            style={style(WIDTH).button_orange} 
                            labelStyle={style().button_label_white}  
                            onPress={() => {getDO();}}> 
                                <Text style={style(WIDTH).fontSize2}>Search</Text>
                        </ButtonPaper>
                    </View>):null
                    }
                </View>

                <DataTable.Header style={[style(WIDTH).dataTable_header_border, {marginTop:'2%'}]}>
                    <View style={[style(WIDTH).dataTable_header,{flex:1}]}><Text style={[style(WIDTH).dataTable_content, {fontWeight:'bold'}]}>Delivery O. ID</Text></View>
                    <View style={[style(WIDTH).dataTable_header,{flex:1}]}><Text style={[style(WIDTH).dataTable_content, {fontWeight:'bold'}]}>Sales O. ID</Text></View>
                    <View style={[style(WIDTH).dataTable_header,{flex:1}]}><Text style={[style(WIDTH).dataTable_content, {fontWeight:'bold'}]}>Cus. Ref.</Text></View>
                    <View style={[style(WIDTH).dataTable_header,{flex:1}]}><Text style={[style(WIDTH).dataTable_content, {fontWeight:'bold'}]}>Truck No.</Text></View>
                    {/* <View style={[style(WIDTH).dataTable_header,{flex:1}]}><Text style={[style(WIDTH).dataTable_content, {fontWeight:'bold'}]}>Pickup from</Text></View>
                    <View style={[style(WIDTH).dataTable_header,{flex:1}]}><Text style={[style(WIDTH).dataTable_content, {fontWeight:'bold'}]}>Deliver to</Text></View> */}
                    <View style={[style(WIDTH).dataTable_header,{flex:1}]}><Text style={[style(WIDTH).dataTable_content, {fontWeight:'bold'}]}>Staff</Text></View>
                </DataTable.Header>
                
                {DOList?DOList:null}

                <View style={style().container_first}>
                    <Text style={[style(WIDTH).text_grey, style(WIDTH).text_total_item, style(WIDTH).fontSize2]}>{`Total items: ${totalItems}`}</Text>
                    <View style={style(WIDTH).container_second}>
                        <Pressable 
                            style={isFirst? style(WIDTH).paginationButtonDisabled:style(WIDTH).paginationButton} 
                            onPress={previousPage} 
                            disabled={isFirst}
                        >
                            <Image source={prev} resizeMode='contain' style={[style(WIDTH).pagination_arrow,isFirst && style().disableTint]} />
                        </Pressable>
                        
                        <Text style={[style(WIDTH).text_grey,style(WIDTH).text_page, style(WIDTH).fontSize2]}>
                            {`Page `}
                            <Text style={[style().text_current_page, style(WIDTH).fontSize3]}>{`${tablePage.totalPages == 0? tablePage.currentPage:tablePage.currentPage+1}`}</Text>
                            {` of ${tablePage.totalPages}`}
                        </Text>
                        
                        <Pressable 
                            style={isLast? style(WIDTH).paginationButtonDisabled: style(WIDTH).paginationButton} 
                            onPress={nextPage}
                            disabled={isLast}
                        >
                            <Image source={next} resizeMode='contain' style={[style(WIDTH).pagination_arrow,isLast && style().disableTint]} />
                        </Pressable>
                    </View>
                
                    <View style={style(WIDTH).container_third}>
                        <Text style={[style(WIDTH).text_grey,style(WIDTH).text_page, style(WIDTH).fontSize2]}>Go to page: </Text>
                        <TextInput 
                            style={[style(WIDTH).fontSize2, style(WIDTH).go_to_input]}
                            onChangeText={(value) => onChangePage(value)}
                            placeholder={`${tablePage.totalPages == 0? tablePage.currentPage:tablePage.currentPage+1}`}
                        />
                    </View>
                </View>
            </DataTable>
            <Overlay isVisible={state_showOverlay} onBackdropPress={hideOverlay} animationType='fade' overlayStyle={{width:WIDTH*0.95, maxHeight:'95%'}}>   
                <View style={style(WIDTH).overlayCloseButton}><Icon name='close' type="antdesign" size={option(WIDTH).iconSize3} onPress={hideOverlay}/></View>         
                <ScrollView contentContainerStyle={{flex:1}} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} focusable={false}>
                    <Client_list selectClient={true} sendDataToParent={sendDataToParent}/>
                </ScrollView>                    
            </Overlay>   
        </View>
    )
}

export default DeliveryOrder
