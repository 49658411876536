import React, { useEffect,useState,useRef } from 'react'
import { View, Text, StyleSheet,Pressable, TextInput,Image } from 'react-native'
import { API_URL, BASE_URL } from '../config/config';
import { ITEM_PER_TABLE_PAGE } from '../constants';
import axios from 'axios';
import { DataTable, Button as ButtonPaper, Modal, Portal, Provider} from 'react-native-paper';
import { style, option, grey2 } from '../styles/style.js';
import { useHover, Hoverable } from 'react-native-web-hooks';
import { Popable } from 'react-native-popable';
import prev from '../assets/prev-arrow.png';
import next from '../assets/next-arrow.png';
import { Button, Icon, Overlay} from 'react-native-elements';
import { getWebsiteWidth } from '../components/utilities';

function Category_List() {
    if(window.localStorage.getItem('role') != 0 && window.localStorage.getItem('role') != 3) window.location.href = `${BASE_URL}`;
    const [WIDTH, set_WIDTH] = useState(getWebsiteWidth());

    let pageTimer;

    const initalCategory = [{
        id:'',
        code:'',
        description:'',
    }];
    const [category, setCategory] = useState(initalCategory);
    const [categoryList, setCategoryList] = useState('');
    const [code, setCode] = useState('');
    const [description, setDesc] = useState('');
    const [isDelete, setIsDelete] = useState(false);
    const isHovered = useHover(ref);
    const ref = useRef(null);

    // for searching
    const [showSearch, setShowSearch] = useState(0);
    const [searchParams, setSearchParams] = useState([]);
    
    // for pagination
    const [tablePage, setCurrentPage] = useState({totalPages:0, currentPage:0});
    const [totalItems, setTotalItems] = useState(0);
    const [isFirst, setFirst] = useState(1);
    const [isLast, setLast] = useState(0);

    const [modal, setModal] = useState('');
    const [visible, setVisible] = useState(false);
    const showModal = () => {setVisible(true);};
    const hideModal = () => {setVisible(false); setShowCreate(false); setEditCounter(0); setCounter(0); setConfirmCounter(0);}

    const [editCode, setEditCode] = useState('');
    const [editDesc, setEditDesc] = useState('');
    const [editID, setEditID] = useState('');
    const [lastEdit, setLastEDit] = useState('');
    const [editCounter, setEditCounter] = useState(0);
    const [counter, setCounter] = useState(0);
    const [confirmCounter, setConfirmCounter] = useState(0);

    const [createCode, setCreateCode] = useState('');
    const [createDesc, setCreateDesc] = useState('');
    const [showCreate, setShowCreate] = useState(false);

    const token = window.localStorage.getItem('token');
    const headers = {
        Authorization: `Bearer ${token}` 
    }

    // message box
     // for message box
    const [state_MsgBox, set_state_MsgBox] = useState({
        title: "",
        message: "",
        show: false
    });
    const MessageBox = function(title, message, message2 = ""){
        if(typeof(message) == "undefined")
        set_state_MsgBox({title:title, message:message2, show:true});
        else
        set_state_MsgBox({title:title, message:message, show:true});
    }
    const closeMessageBox = () => {
        set_state_MsgBox({show:false});
    };


    useEffect(() => {
        window.addEventListener('resize', () => { set_WIDTH(getWebsiteWidth()); });
        getCategory();
    }, [])

    const getCategory = async (params) => {
        ShowLoading();

        let queryParams = {};
        for(const sp in searchParams){
            if(searchParams[sp] != '')
                queryParams['filter[' + sp + ']'] = searchParams[sp];
        }
        queryParams['size'] = ITEM_PER_TABLE_PAGE;
        queryParams['page'] = 0;
        queryParams['sort[id]'] = 'asc';
        if(params && params.page){
            queryParams['page'] = params.page;
        }

        window.API_CALL_GET('account_no', queryParams).then(
          function(data) {        
            let category_list = data.dataList;
            let totalPage = data.totalPages;
            let currentPage = data.currentPage;

            setTotalItems(data.totalItems);
            setCurrentPage({totalPages: totalPage, currentPage: currentPage});

            (currentPage+1) == totalPage? setLast(1):setLast(0);
            currentPage == 0? setFirst(1):setFirst(0);

            // console.log(category_list);
            let allCategory = [];
            for(let a=0; a< category_list.length; a++){
                let obj={
                    id: category_list[a].id,
                    code: category_list[a].code,
                    description: category_list[a].description,
                    is_deleted: category_list[a].is_deleted
                }

                allCategory.push(obj);
            }
            setCategory(allCategory);
          },
          function(error) { MessageBox("Error", error); }
        );
    }

    useEffect(()=>{
        let final_list = category.map((item)=>(
            <Hoverable key={item.id}>
                {isHovered => (
                    <Pressable onPress={()=>{setVisible(true); setEditID(item.id); modalContent(item.id); }}
                        style={[style(WIDTH).table_row,isHovered && style(WIDTH).hover, item.is_deleted && style(WIDTH).table_row_deleted]}>
                        <DataTable.Row key={item.id} >
                            <View style={style(WIDTH).dataTable_content}><Text style={style(WIDTH).dataTable_content_flex}>{item.code || '-'}</Text></View>
                            <View style={style(WIDTH).dataTable_content}><Text style={style(WIDTH).dataTable_content_flex}>{item.description || '-'}</Text></View>
                        </DataTable.Row>
                    </Pressable>
                )}
            </Hoverable>
        ))

        final_list.length === category.length? setCategoryList(final_list):null;

    },[category, WIDTH])
    
    const onChangeValue = (key, value) => {
        searchParams[key] = value;
    }

    const onChangePage = (value) => {
        if(value != '' && (value <= tablePage.totalPages) && value > 0){
            clearTimeout(pageTimer);
            pageTimer = setTimeout(() => {          
                getCategory({ page:value-1 });
            }, 800);
        }
    }

    const previousPage = () => {
        if(tablePage.totalPages > 1) {
            tablePage.currentPage = tablePage.currentPage - 1;
            setLast(0);
            tablePage.currentPage == 0? setFirst(1):setFirst(0)
            // if(tablePage.currentPage == 0) setFirst(1);
            getCategory({ page:tablePage.currentPage });
        }
    }

    const nextPage = () => {
        if(tablePage.totalPages > 1) {
            tablePage.currentPage = tablePage.currentPage + 1;
            setFirst(0);
            (tablePage.currentPage+1) == tablePage.totalPages? setLast(1):setLast(0)
            // if(tablePage.currentPage +1 == tablePage.totalPages) setLast(1);
            getCategory({ page:tablePage.currentPage });
        }
    }

    const clearFilter = () => {
        setCode('');
        setDesc('');
        setSearchParams([]);
    }

    const modalContent = async (id) => {
        let url = `${API_URL}account_no/${id}`;

        const res = await axios({
            method:"GET",
            url:url,
            headers:headers,
        });

        if(res.status === 200){
            // console.log(res);
            let data = res.data.result;
            data.is_deleted? setIsDelete(true):setIsDelete(false);
            setLastEDit(data.last_edit_name);
            setEditCode(data.code);
            setEditDesc(data.description);
            
        }
    }

    const editCategory = async () => {
        if(editCounter == 0){
            MessageBox("Warning", "Do you want to edit this category? It is possible to affect other data. If yes, click the button again!");
            setEditCounter(editCounter + 1);
        }else{
            if(editCode == '' || editDesc == ''){
                MessageBox("Error", "Content cannot be empty!!");
                return;
            }
            let url = `${API_URL}account_no`;

            let payload = {
                "mode":"Edit",
                "code":editCode,
                "description":editDesc,
                "id":editID
            };
        
            try {
                const res = await axios({
                    method:"POST",
                    url:url,
                    headers:headers,
                    data:payload
                });
    
                if(res.status === 200){
                    MessageBox("Success", "Edit successfully!");
                    hideModal();
                    getCategory();
                    setEditCounter(0);
                }
            } catch (error) {
                MessageBox("Error", error.toString());
            }
        }
    }

    // when edit category
    useEffect(()=>{
        setModal(
            <View style={{padding:WIDTH*0.028}}>
                <View style={{alignItems: 'flex-end'}}><Icon name='close' type="antdesign" size={option(WIDTH).iconSize2} onPress={hideModal}/></View>  
                {isDelete? (
                    <View style={[style(WIDTH).container_row2,style().table_row_deleted]}>
                        <Text style={[style(WIDTH).fontSize2, {alignSelf:'center', fontWeight:'bold'}]}>Deleted by: {lastEdit}</Text>
                        <View style={style(WIDTH).container_icon} />
                        <Popable content='Approve' position="bottom" backgroundColor='#FF9F1D' action='hover'>
                        <Button icon={<Icon name='check' type="antdesign" size={option(WIDTH).iconSize1}/>} type="clear" 
                        style={{backgroundColor:'#11CD01'}}
                        onPress={()=>{
                            if(confirmCounter == 0) 
                            {
                                setConfirmCounter(confirmCounter + 1);
                                MessageBox("Warning", "You are deleting this category permanently. If this is your intention, kindly click the delete button again.");
                            }    
                            else deleteCategory();}}/>
                            </Popable>
                    <View style={style(WIDTH).container_icon}></View>
                    <Popable content='Reject' position="bottom" backgroundColor='#FF9F1D' action='hover'>
                    <Button icon={<Icon name='close' type="antdesign" size={option(WIDTH).iconSize1}/>} type="clear"
                        style={{backgroundColor:'#ff2100'}}
                        onPress={()=>{undoDeleteCategory();}}/>
                        </Popable>
                    </View> 
                ):null}
                <View pointerEvents={isDelete? 'none':'auto'}>
                    <View style={style(WIDTH).scontainer}>
                        <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Code: </Text>
                        <TextInput
                            style={[style(WIDTH).create_input,style(WIDTH).fontSize2]} 
                            value={editCode}
                            onChangeText={(value) => { setEditCode(value); }}
                            placeholder="Insert code..."
                        />
                    </View>
                    <View style={style(WIDTH).scontainer}>
                        <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Description: </Text>
                        <TextInput
                            style={[style(WIDTH).create_input,style(WIDTH).fontSize2]} 
                            value={editDesc}
                            onChangeText={(value) => { setEditDesc(value); }}
                            placeholder="Insert description..."
                        />
                    </View>

                    <View style={[style(WIDTH).scontainer, {flexDirection: 'row'}]}>
                        <ButtonPaper
                            style={[style(WIDTH).create_btn_red, {marginRight:0}]} 
                            labelStyle={style(WIDTH).create_btn_label} 
                            onPress={()=>{ deleteCategory()}}
                        >
                            Delete
                        </ButtonPaper>
                    
                        <View style={style(WIDTH).scontainer} />
                        <ButtonPaper
                            style={[style(WIDTH).create_btn, {marginRight:WIDTH*0.01}]} 
                            labelStyle={style(WIDTH).create_btn_label} 
                            onPress={()=>{ editCategory()}}
                        >
                            Submit
                        </ButtonPaper>
                    </View>
                </View>
            </View>
        )
    },[editCode, editDesc, WIDTH, counter, confirmCounter, editCounter])

    // when create category
    useEffect(()=>{
        if(showCreate){
            setModal(
                <View style={{padding:WIDTH*0.028}}>
                    <View style={{alignItems: 'flex-end'}}><Icon name='close' type="antdesign" size={option(WIDTH).iconSize2} onPress={hideModal}/></View> 
                    <View style={style(WIDTH).scontainer}>
                        <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Code: </Text>
                        <TextInput
                            style={[style(WIDTH).create_input,style(WIDTH).fontSize2]} 
                            value={createCode}
                            onChangeText={(value) => { setCreateCode(value); }}
                            placeholder="Insert code..."
                        />
                    </View>
                    
                    <View style={style(WIDTH).scontainer}>
                        <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Description: </Text>
                        <TextInput
                            style={[style(WIDTH).create_input,style(WIDTH).fontSize2]} 
                            value={createDesc}
                            onChangeText={(value) => { setCreateDesc(value); }}
                            placeholder="Insert description..."
                        />
                    </View>
    
                    <View style={style(WIDTH).scontainer}>
                        <ButtonPaper
                            style={[style(WIDTH).create_btn, {marginRight:WIDTH*0.01}]} 
                            labelStyle={style(WIDTH).create_btn_label} 
                            onPress={()=>{ createCategory()}}
                        >
                            Submit
                        </ButtonPaper>
                    </View>
                </View>
            )
            setVisible(true);
        }else{
            setCreateCode('');
            setCreateDesc('');
        }
    },[showCreate, createCode, createDesc, WIDTH])

    const createCategory = async () => {
        console.log('create');
        if(createCode == '' || createDesc == ''){
            MessageBox("Error", "Content cannot be empty!!");
            return;
        }
        let url = `${API_URL}account_no`;

        let payload = {
            "mode":"Add",
            "code":createCode,
            "description":createDesc,
        };
        
        try {
            const res = await axios({
                method:"POST",
                url:url,
                headers:headers,
                data:payload
            });
    
            if(res.status === 200){
                MessageBox("Success", "Create successfully!");
                hideModal();
                getCategory();
            }
        } catch (error) {
            MessageBox("Error", error.toString());
        }
    }

    const deleteCategory = async () => {
        if(counter < 1 && !isDelete){
            setCounter(counter + 1);
            MessageBox("Warning", "Do you want to delete this category's data? It may affect other data. If yes, click the delete button again.");
        }else{
            let url = `${API_URL}utils/delete/account_no`;

            let payload = {
                id:editID
            };
        
            try {
                const res = await axios({
                    method:"POST",
                    url:url,
                    headers:headers,
                    data:payload
                });
    
                if(res.status === 200){
                    MessageBox("Success", "Delete successfully!");
                    window.location.reload();
                    // hideModal();
                    // getCategory();
                }
            } catch (error) {
                MessageBox("Error", error.toString());
            }
        }
    }

    const undoDeleteCategory = async() => {
        let url = API_URL + 'account_no/restoreRecord';
            let token = window.localStorage.getItem('token');
            let headers = {
                Authorization: `Bearer ${token}` 
            }

            let payload = {
                id:editID
            };

            try {
                const res = await axios({
                    method:'post',
                    url: url, 
                    headers: headers,
                    data: payload
                })
                if(res.status === 200){
                    MessageBox("Success","Restore Category Data");
                    window.location.reload();
                }
            } catch (error) {
                console.log(error.message);
            }
    }

    return (
        // <Provider>
            <View style={[style(WIDTH).container, {width: WIDTH *0.45}]}>
                {/* <Portal>
                    <Modal visible={visible} onDismiss={hideModal} contentContainerStyle={style(WIDTH).modal_container}>
                        {modal}
                    </Modal>
                </Portal> */}

                <DataTable>
                <View style={style(WIDTH).container_row}>
                <Popable content='Refresh' position="left" backgroundColor='#FF9F1D' action='hover'>
                    <Button icon={<Icon name='refresh' type="simplelineicons" size={option(WIDTH).iconSize2}/>} type="clear" style={{padding: '5%'}} 
                        onPress={async()=>{ 
                            ShowLoading();
                            await getCategory();
                            HideLoading();}}/>
                            </Popable>
                    <View style={style(WIDTH).container_icon}></View>
                    <Popable content='Search' position="left" backgroundColor='#FF9F1D' action='hover'>
                    <Button icon={<Icon name='search1' type="antdesign" size={option(WIDTH).iconSize2}/>} type="clear" style={{padding: '5%'}}
                        onPress={()=>setShowSearch(!showSearch)}/>
                        </Popable>      
                    <View style={style(WIDTH).container_icon}></View>
                    <Popable content='Create' position="left" backgroundColor='#FF9F1D' action='hover'>
                    <Button icon={<Icon name='addfile' type="antdesign" size={option(WIDTH).iconSize2}/>} type="clear" style={{padding: '5%'}}
                        onPress={()=>{ setShowCreate(true)}}/>
                        </Popable>
                </View>

                <View style={style().container_column}>
                    { showSearch? 
                        (<View style={style(WIDTH).container_search_input}>
                            <ButtonPaper
                                style={style(WIDTH).button_orange} 
                                labelStyle={style().button_label_white} 
                                onPress={clearFilter}
                            >
                                <Text style={style(WIDTH).fontSize2}>Clear Filter</Text>
                            </ButtonPaper>
                            <DataTable.Header style={style(WIDTH).dataTable_header2}>
                                <View style={style(WIDTH).scontainer}>
                                    <TextInput
                                        style={[style(WIDTH).searchInput,style(WIDTH).fontSize2]}
                                        value={code}
                                        onChangeText={(value) => {
                                            onChangeValue("code",value);
                                            setCode(value);
                                        }}
                                        placeholder="Code"
                                    />
                                </View>

                                <View style={style(WIDTH).scontainer}>
                                    <TextInput
                                        style={[style(WIDTH).searchInput,style(WIDTH).fontSize2]}
                                        value={description}
                                        onChangeText={(value) => {
                                            onChangeValue("description",value);
                                            setDesc(value);
                                        }}
                                        placeholder="Description"
                                    />
                                </View>
                            </DataTable.Header>
                            <ButtonPaper 
                                style={style(WIDTH).button_orange} 
                                labelStyle={style().button_label_white}  
                                onPress={() => {getCategory();}}> 
                                    <Text style={style(WIDTH).fontSize2}>Search</Text>
                            </ButtonPaper>
                        </View>):null
                    }
                </View>
                
                <DataTable.Header style={style(WIDTH).dataTable_header_border}>
                    <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>Code</Text></View>
                    <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>Description</Text></View>
                </DataTable.Header>
                
                {categoryList.length>0? categoryList:null}

                <View style={style().container_first}>
                    <Text style={[style(WIDTH).text_grey, style(WIDTH).text_total_item, style(WIDTH).fontSize2]}>{`Total items: ${totalItems}`}</Text>
                    <View style={style(WIDTH).container_second}>
                        <Pressable 
                            style={isFirst? style(WIDTH).paginationButtonDisabled:style(WIDTH).paginationButton} 
                            onPress={previousPage} 
                            disabled={isFirst}
                        >
                            <Image source={prev} resizeMode='contain' style={[style(WIDTH).pagination_arrow,isFirst && style().disableTint]} />
                        </Pressable>
                    
                        <Text style={[style(WIDTH).text_grey,style(WIDTH).text_page, style(WIDTH).fontSize2]}>
                            {`Page `}
                            <Text style={[style().text_current_page, style(WIDTH).fontSize3]}>{`${tablePage.totalPages == 0? tablePage.currentPage:tablePage.currentPage+1}`}</Text>
                            {` of ${tablePage.totalPages}`}
                        </Text>
                        
                        <Pressable 
                            style={isLast? style(WIDTH).paginationButtonDisabled: style(WIDTH).paginationButton} 
                            onPress={nextPage}
                            disabled={isLast}
                        >
                            <Image source={next} resizeMode='contain' style={[style(WIDTH).pagination_arrow,isLast && style().disableTint]} />
                        </Pressable>
                    </View>
                
                    <View style={style(WIDTH).container_third}>
                        <Text style={[style(WIDTH).text_grey,style(WIDTH).text_page, style(WIDTH).fontSize2]}>Go to page: </Text>
                        <TextInput 
                            style={[style(WIDTH).fontSize2, style(WIDTH).go_to_input]}
                            onChangeText={(value) => onChangePage(value)}
                            placeholder={`${tablePage.totalPages == 0? tablePage.currentPage:tablePage.currentPage+1}`}
                        />
                    </View>
                </View>
            </DataTable>
            <Overlay isVisible={visible} onBackdropPress={hideModal} animationType='fade' overlayStyle={{width:WIDTH*0.35, maxHeight:'95%'}}>  
                {modal}
            </Overlay>
            <Overlay isVisible={state_MsgBox.show} onBackdropPress={closeMessageBox} animationType='fade' style={{position:'absolute', zIndex:1000}}
                overlayStyle={[style(WIDTH).overlay_container]}>
                <Text style={style(WIDTH).msg_box_title}>{state_MsgBox.title}</Text>
                <Text style={style(WIDTH).msg_box_message}>{state_MsgBox.message}</Text>
                <ButtonPaper
                style={[style(WIDTH).msg_box_btn]} 
                labelStyle={style(WIDTH).create_btn_label} 
                onPress={closeMessageBox}>
                    Close
                </ButtonPaper>  
            </Overlay> 
        </View>
    // </Provider>
    )
}

export default Category_List;
