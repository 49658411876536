import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL, BASE_URL } from '../config/config';
import { Route, Link, Routes } from 'react-router-dom'
import { View, Text, TouchableOpacity, ScrollView, TextInput, Picker } from 'react-native';
import { useForm } from "react-hook-form";
import { option, style } from '../styles/style.js';
import Client_list from './Client_list.js';
import { Overlay, Icon, Tooltip } from 'react-native-elements';
import { DataTable, Button as ButtonPaper} from 'react-native-paper';
import { getWebsiteWidth } from '../components/utilities';
import DropDownPicker from 'react-native-dropdown-picker';

export default function SalesOrder_Duplicate(params) {
    const [WIDTH, set_WIDTH] = useState(getWebsiteWidth());

    const [yearList, set_yearList] = useState([]);
    const [valueClient, set_valueClient] = useState(params.data.client_name + " | " + (params.data.client_code || ''));
    const [valueShipper, set_valueShipper] = useState(params.data.shipper_name);
    const [valueConsignee, set_valueConsignee] = useState(params.data.consignee_name);
    const [year, set_year] = React.useState(params.data.year);
    const [month, set_month] = React.useState(params.data.month);
    const [documentType, set_documentType] = React.useState(params.data.documentType);

    // dropdown
    const [dropdownSalesperson, set_dropdownSalesperson] = useState([]);
    const [selectDdSalesperson, set_selectDdSalesperson] = useState(params.data.sales);
    const [openDd, set_openDd] = useState(false);
    const setOpen = (val) => {
        set_openDd(val);
    }

    // Overlay
    const [state_showOverlay, set_state_showOverlay] = useState(false);
    const [currentIndex, set_currentIndex] = useState(null);
    const [is_client, set_is_client] = useState(false);
    const [select_client, set_select_client] = useState(false);
    const showOverlay = (index) => {
      set_currentIndex(index);
      if (index == 'client') {
          set_select_client(1);
          set_is_client(1);
      }
      else {
          set_select_client(0);
          set_is_client(0);
      }
      set_state_showOverlay(true);
  };
    const hideOverlay = () => {
        set_state_showOverlay(false);
    };
    const sendDataToParent = async(params) => {
        set_state_showOverlay(false);
        setValue(currentIndex, params.id);
        if(currentIndex == "client") set_valueClient(params.name + "  |  " + params.code);
        if(currentIndex == "shipper") set_valueShipper(params.name);
        else if(currentIndex == "consignee") set_valueConsignee(params.name);
    }

    // form
    const { control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        defaultValues: { 
            shipper: params.data.shipper || "", 
            consignee: params.data.consignee || "", 
            client: params.data.client || "",
            year: params.data.year || "",
            month: params.data.month || "",
            salesID: params.data.salesperson || ""} 
    });  

    const onSubmit = async(data) => {
        data.shipmentID = params.data.shipmentID;
        console.log(data);
        ShowLoading();
        await axios({
            method:'post',
            url: API_URL + 'salesorder/copy', 
            headers: {Authorization: `Bearer ${window.localStorage.getItem('token')}`},
            data: data
        }).then(res => {
            if(params.sendDataToParent)
                params.sendDataToParent({shipmentID: res.data.result.shipmentID});
        }).catch( err => {
            MessageBox("Error", "Fail to duplicate S.O.");
            console.log(err);
        }).finally(()=>{
            HideLoading();
        })
    }

    const getSalespersonOption = async(req, params) => {
        const token = window.localStorage.getItem('token');
        let url = API_URL + 'utils/options/salesperson';
        const headers = { Authorization: `Bearer ${token}` };
        set_dropdownSalesperson([]);
        await axios({
            method:'get',
            url: url,
            headers: headers,
            params: {active_only:1}
        }).then(res => {
            let data = res.data.result;
            data.sort((a, b) => a.title.localeCompare(b.title));
            data.unshift({id:"", title:""});
            let content = [];
            for(let i = 0; i < data.length; i++)
                if(i % 2 == 1)
                    content.push({label: data[i].title, value: data[i].id,  
                        containerStyle: {backgroundColor: "#f2f2f2"}});
                else
                    content.push({label: data[i].title, value: data[i].id});

            set_dropdownSalesperson(content);
        }).catch(err => {
            MessageBox("Error", "Fail to retrieve salesperson data");
            console.log(err);
        })     
    };

    useEffect(async() => {
        if(typeof(params.data.shipmentID) == "undefined")
        {
            if(params.sendDataToParent)
                params.sendDataToParent(false);
            else
                window.location.href = BASE_URL + "sales_order";    
        } 
        window.addEventListener('resize', () => { set_WIDTH(getWebsiteWidth()) });
        await getSalespersonOption();

        let dt = new Date();
        let year = [];
        for(let i=2; i>=0; i--)
            year.push(dt.getFullYear() - i);

        let content = year.map((item)=>{
            return(
                <Picker.Item label={item} value={item} key={item}/>                      
            )});
        set_yearList(content);
        // let months = [ "January", "February", "March", "April", "May", "June", 
        //    "July", "August", "September", "October", "November", "December" ];
        // set_year(params.year);
        // set_month(months[params.month-1]);
        // set_salesperson(params.salesperson);
        // set_documentType(params.documentType);
    }, []);

    return (        
        <View style={[style(WIDTH).container]} >
            {typeof(params.data.shipmentID) != "undefined"? (
            <View>
            <View style={style(WIDTH).container_create_col}>
                <View style={{marginBottom:WIDTH*0.02, marginLeft:WIDTH*0.015}}>
                    <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize4]}>Duplicate Sales Order</Text></View>


                <View style={style(WIDTH).container_create_row}>
                    <Text style={[style(WIDTH).fontSize2,style().text_create,style().create_attribute]}>Document Type</Text>
                    <Text style={[style(WIDTH).fontSize2, style().container_2_flex,{alignSelf:'center'}]}>{documentType}</Text>
                </View>

                <View style={style(WIDTH).container_create_row}>
                    <Text style={[style(WIDTH).fontSize2,style().text_create,style().create_attribute]}>Year</Text>
                    <View style={style().container_2_flex}>
                        <Picker style={[style(WIDTH).searchInput,  style(WIDTH).fontSize2, {width:WIDTH*0.08}]} 
                            onValueChange={val => {setValue('year', val); set_year(val)}} value={year}>
                            {yearList}
                        </Picker>                   
                    </View>
                </View>

                <View style={style(WIDTH).container_create_row}>
                    <Text style={[style(WIDTH).fontSize2,style().text_create,style().create_attribute]}>Month</Text>
                    <View style={style().container_2_flex}>
                        <Picker style={[style(WIDTH).searchInput,  style(WIDTH).fontSize2, {width:WIDTH*0.08}]} 
                            onValueChange={val => {setValue('month', val); set_month(val)}} value={month}>
                            <Picker.Item label="January" value="1" />
                            <Picker.Item label="February" value="2" />
                            <Picker.Item label="March" value="3" />
                            <Picker.Item label="April" value="4" />
                            <Picker.Item label="May" value="5" />
                            <Picker.Item label="June" value="6" />
                            <Picker.Item label="July" value="7" />
                            <Picker.Item label="August" value="8" />
                            <Picker.Item label="September" value="9" />
                            <Picker.Item label="October" value="10" />
                            <Picker.Item label="November" value="11" />
                            <Picker.Item label="December" value="12" />
                        </Picker>
                    </View>
                </View>

                <View style={[style(WIDTH).container_create_row, {zIndex:100}]}>
                    <Text style={[style(WIDTH).fontSize2,style().text_create,style().create_attribute]}>Salesperson</Text>
                    <View style={[style().container_2_flex]}>
                        <DropDownPicker
                            zIndex={100}
                            open={openDd}
                            setOpen={val => setOpen(val)}
                            value={selectDdSalesperson}
                            setValue={set_selectDdSalesperson}
                            items={dropdownSalesperson}
                            setItems={set_dropdownSalesperson}
                            searchable={true}
                            closeOnBackPressed={true}
                            style={[style(WIDTH).searchInput, style(WIDTH).fontSize2]}
                            dropDownContainerStyle={style(WIDTH).dropDownContainer}
                            listMode="SCROLLVIEW"
                            showTickIcon={false}
                            dropDownDirection="BOTTOM"
                            placeholder="Select salesperson"
                            placeholderStyle={{color:'#808080'}}
                            showArrowIcon={false}
                            searchPlaceholder="Search..."
                            textStyle={[style(WIDTH).fontSize2]}
                            onChangeValue={(val)=>{ setValue("salesID", val);}  }  
                        />
                    </View>
                </View>

                <View style={style(WIDTH).container_create_row}>
                    <Text style={[style(WIDTH).fontSize2,style().text_create,style().create_attribute]}>Client</Text>
                    <View style={style().container_2_flex}>
                        <TouchableOpacity onPress={() => showOverlay("client")}>
                            <TextInput pointerEvents="none" style={[style(WIDTH).searchInput,  style(WIDTH).fontSize2]} value={valueClient} placeholder="Select client"/>              
                        </TouchableOpacity>
                    </View>
                </View>

                <View style={style(WIDTH).container_create_row}>
                    <Text style={[style(WIDTH).fontSize2,style().text_create,style().create_attribute]}>Shipper</Text>
                    <View style={style().container_2_flex}>
                        <TouchableOpacity onPress={() => showOverlay("shipper")}>
                            <TextInput pointerEvents="none" style={[style(WIDTH).searchInput,  style(WIDTH).fontSize2]} value={valueShipper} placeholder="Select shipper"/>              
                        </TouchableOpacity>
                    </View>
                </View>

                <View style={style(WIDTH).container_create_row}>
                    <Text style={[style(WIDTH).fontSize2,style().text_create,style().create_attribute]}>Consignee</Text>
                    <View style={style().container_2_flex}>
                        <TouchableOpacity onPress={() => showOverlay("consignee")}>
                            <TextInput pointerEvents="none" style={[style(WIDTH).searchInput, style(WIDTH).fontSize2]} value={valueConsignee} placeholder="Select consignee"/>               
                        </TouchableOpacity>
                    </View>
                </View>
            
                <View style={style(WIDTH).container_create_row}>
                    <View style={{flex:5}}/>
                    <ButtonPaper
                        style={[style(WIDTH).button_orange, {right:-WIDTH*0.008}]} 
                        labelStyle={[style().button_label_white, style(WIDTH).fontSize2]} 
                        onPress={handleSubmit(onSubmit)}
                    >
                        Submit
                    </ButtonPaper>
                </View>
                <Overlay isVisible={state_showOverlay} onBackdropPress={hideOverlay} animationType='fade' overlayStyle={{width:'95%', height:'95%'}}>   
                    <View style={{alignItems: 'flex-end'}}><Icon name='close' type="antdesign" size={option(WIDTH).iconSize2} onPress={hideOverlay}/></View>         
                    <ScrollView style={{marginTop:'1%'}}
                        contentContainerStyle={{flex:1}} 
                        showsHorizontalScrollIndicator={true} 
                    >
                        <Client_list selectContact={select_client} selectAddress={!select_client} sendDataToParent={sendDataToParent} is_client={is_client}/>
                    </ScrollView>                    
                </Overlay>     
            </View>
            </View>): <View><Text style={style(WIDTH).fontSize4}>Invalid Shipment ID</Text></View>}
        </View>
        // </TouchableWithoutFeedback>
    )
}