import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL, BASE_URL } from '../config/config';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom'
import { View, Text, TouchableOpacity,  Dimensions, ScrollView, TextInput , Picker} from 'react-native';
import { useForm, Controller } from "react-hook-form";
import DropDownPicker from 'react-native-dropdown-picker';
import { option, style } from '../styles/style.js';
import Client_list from './Client_list.js';
import { Overlay, Icon, Tooltip } from 'react-native-elements';
import { DataTable, Button as ButtonPaper} from 'react-native-paper';
import { getWebsiteWidth } from '../components/utilities';
import { height } from '@mui/system';

function SalesOrder_Create() {
    const [WIDTH, set_WIDTH] = useState(getWebsiteWidth());
    const handleResize = () => { set_WIDTH(getWebsiteWidth()); } 

    const [yearList, set_yearList] = useState([]);
    const [valueShipper, set_valueShipper] = useState('');
    const [valueConsignee, set_valueConsignee] = useState('');
    const [valueClient, set_valueClient] = useState('');
    const [is_client, set_is_client] = useState(false);
    const [select_client, set_select_client] = useState(false);

    // Overlay
    const [state_showOverlay, set_state_showOverlay] = useState(false);
    const [currentIndex, set_currentIndex] = useState(null);
    const showOverlay = (index) => {
        set_currentIndex(index);
        if (index == 'client') {
            set_select_client(1);
            set_is_client(1);
        }
        else {
            set_select_client(0);
            set_is_client(0);
        }
        set_state_showOverlay(true);
    };
    const hideOverlay = () => {
        set_state_showOverlay(false);
    };
    const sendDataToParent = async(params) => {
        set_state_showOverlay(false);
        setValue(currentIndex, params.id);
        if(currentIndex == "shipper") {set_valueShipper(params.name); clearErrors('shipper');}
        else if(currentIndex == "consignee") {set_valueConsignee(params.name);  clearErrors('consignee');}
        else if(currentIndex == "client") {set_valueClient(params.name);  clearErrors('client');}
    }

    // for dropdown list
    const init_dropdown_state = {salesperson:false};
    const [openDd, set_openDd] = useState(init_dropdown_state);
    const setOpen = (key, value) => {
        let new_state = init_dropdown_state;
        if(typeof(key) != "undefined")
            new_state[key] = value;
        set_openDd(new_state);
    }
    const [dropdownSalesperson, set_dropdownSalesperson] = useState([]);
    const [selectDdSalesperson, set_selectDdSalesperson] = useState(null);
    
    // form
    const { control, handleSubmit, reset, setValue, formState: { errors }, clearErrors } = useForm({
        defaultValues: { documentType:"Sales Order", year:"", month:"", salesID:"", shipper:"", consignee:"", client:''}        
    });  

    const onSubmit = async(data) => {
        console.log(data);
        if(data.shipper == '' || data.consignee == '')
        {
            MessageBox("Error", 'Incomplete data.');
            return;
        }
        let url = API_URL + 'salesorder';
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}` 
        }    
        data.mode = "Add";

        ShowLoading();
        await axios({
            method:'post',
            url: url, 
            headers: headers,
            data: data
        }).then((res) => {
            window.location.href = BASE_URL + 'sales_order/' + res.data.result.shipmentData.shipmentID;
        }).catch( err => {
            MessageBox("Error", "Fail to retrieve sales order data");
            console.log(err);
        }).finally(()=>{
            HideLoading();
        })
    }

    const getSalespersonOption = async(req, params) => {
        const token = window.localStorage.getItem('token');
        let url = API_URL + 'utils/options/salesperson';
        const headers = { Authorization: `Bearer ${token}` };
        set_dropdownSalesperson([]);
        await axios({
            method:'get',
            url: url,
            headers: headers,
            params: {active_only:1}
        }).then(res => {
            let data = res.data.result;
            data.sort((a, b) => a.title.localeCompare(b.title));
            data.unshift({id:"", title:""});
            let content = [];
            for(let i = 0; i < data.length; i++)
                if(i % 2 == 1)
                    content.push({label: data[i].title, value: data[i].id,  
                        containerStyle: {backgroundColor: "#f2f2f2"}});
                else
                    content.push({label: data[i].title, value: data[i].id});

            set_dropdownSalesperson(content);
        }).catch(err => {
            MessageBox("Error", "Fail to retrieve salesperson data");
            console.log(err);
        })     
    };

    useEffect(() => {
        window.addEventListener('resize',handleResize);
        onLoad();
    }, [])

    // useEffect(() => {
    //     onLoad();
    // },[]);

    const onLoad = async() => {
        ShowLoading();
        await getSalespersonOption();
        let dt = new Date();
        let year = [];
        for(let i=2; i>=0; i--)
            year.push(dt.getFullYear() - i);

        let content = year.map((item)=>{
            return(
                <Picker.Item label={item} value={item} key={item}/>                      
            )});
        set_yearList(content);
        reset({ documentType:"Sales Order", salesID:'', year:dt.getFullYear(), month:dt.getMonth()+1, shipper:'', consignee:'', client:''});
        HideLoading();
    }
    
    return (
        <View style={[style(WIDTH).container,{width:'60%'}]} >
            <View style={style(WIDTH).container_create_col}>
                <View style={{marginBottom:WIDTH*0.02, marginLeft:WIDTH*0.015}}>
                    <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize4]}>Create Sales Order / Credit Note</Text></View>

                <View style={style(WIDTH).container_create_row}>
                    <Text style={[style(WIDTH).fontSize2,style().text_create,style().create_attribute]}>Document Type {errors.documentType?
                        <Text style={style(WIDTH).fontError}>is REQUIRED, please select!</Text>: null}</Text>
                    <Controller name="documentType" rules={{required: 'Document type is required.'}} control={control} render={({ field: { onChange, onBlur, value } }) => (
                        <Picker style={[style(WIDTH).searchInput,  style(WIDTH).fontSize2, {width:WIDTH*0.292}]} onValueChange={onChange} onBlur={null} value={value}>
                            <Picker.Item label="Sales Order" value="Sales Order" />
                            <Picker.Item label="Credit Note" value="Credit Note" />
                            <Picker.Item label="Debit Note" value="Debit Note" />
                        </Picker>)}
                    />
                 
                </View>
               
                <View style={style(WIDTH).container_create_row}>
                    <Text style={[style(WIDTH).fontSize2,style().text_create,style().create_attribute]}>Year {errors.year?
                        <Text style={style(WIDTH).fontError}>is REQUIRED, please select!</Text>: null}</Text>                    
                        <Controller name="year" rules={{required: true}} control={control} render={({ field: { onChange, onBlur, value } }) => (
                        <Picker style={[style(WIDTH).searchInput,  style(WIDTH).fontSize2, {width:WIDTH*0.292}]} onValueChange={onChange} onBlur={null} value={value}>
                            {yearList}
                        </Picker>)}/>
                    </View>

                <View style={style(WIDTH).container_create_row}>
                <Text style={[style(WIDTH).fontSize2,style().text_create,style().create_attribute]}>Month {errors.month?
                    <Text style={style(WIDTH).fontError}>is REQUIRED, please select!</Text>: null}</Text>                    
                    <Controller name="month" rules={{required: true}} control={control} render={({ field: { onChange, onBlur, value } }) => (
                        <Picker style={[style(WIDTH).searchInput,  style(WIDTH).fontSize2, {width:WIDTH*0.292}]} onValueChange={onChange} onBlur={null} value={value}>
                            <Picker.Item label="January" value="1" />
                            <Picker.Item label="February" value="2" />
                            <Picker.Item label="March" value="3" />
                            <Picker.Item label="April" value="4" />
                            <Picker.Item label="May" value="5" />
                            <Picker.Item label="June" value="6" />
                            <Picker.Item label="July" value="7" />
                            <Picker.Item label="August" value="8" />
                            <Picker.Item label="September" value="9" />
                            <Picker.Item label="October" value="10" />
                            <Picker.Item label="November" value="11" />
                            <Picker.Item label="December" value="12" />
                        </Picker>)}
                    />
                </View>

                <View style={[style(WIDTH).container_create_row,{zIndex:50}]}>
                    <Text style={[style(WIDTH).fontSize2,style().text_create,style().create_attribute]}>Salesperson {errors.salesID?
                        <Text style={style(WIDTH).fontError}>is REQUIRED, please select!</Text>: null}</Text>
                    <View style={style().container_2_flex}>
                        <Controller name="salesID" rules={{required: true}} control={control} render={() => (
                            <DropDownPicker
                                zIndex={3000}
                                zIndexInverse={1000}
                                open={openDd.salesperson}
                                setOpen={(val)=>{setOpen("salesperson", val)}}
                                value={selectDdSalesperson}
                                setValue={set_selectDdSalesperson}
                                items={dropdownSalesperson}
                                setItems={set_dropdownSalesperson}
                                searchable={true}
                                closeOnBackPressed={true}
                                style={[style(WIDTH).searchInput, style(WIDTH).fontSize2]}
                                dropDownContainerStyle={style(WIDTH).dropDownContainer}
                                listMode="SCROLLVIEW"
                                showTickIcon={false}
                                dropDownDirection="BOTTOM"
                                placeholder="Select salesperson"
                                placeholderStyle={{color:'#808080'}}
                                showArrowIcon={false}
                                searchPlaceholder="Search..."
                                textStyle={[style(WIDTH).fontSize2]}
                                onChangeValue={(val)=>{ setValue("salesID", val); clearErrors('salesID');}  }  
                            />)}
                        />
                    </View>
                </View>

                <View style={style(WIDTH).container_create_row}>
                    <Text style={[style(WIDTH).fontSize2,style().text_create,style().create_attribute]}>Client {errors.client?
                        <Text style={style(WIDTH).fontError}>is REQUIRED, please select!</Text>: null}</Text>
                    <View style={style().container_2_flex}>
                        <Controller name="client" rules={{required: true}} control={control} render={({ field: {} }) => null}/>  
                        <TouchableOpacity onPress={() => showOverlay("client")}>
                            <TextInput pointerEvents="none" style={[style(WIDTH).searchInput,style(WIDTH).fontSize2]} value={valueClient} placeholder="Select client"/>               
                        </TouchableOpacity>
                    </View>
                </View>

                <View style={style(WIDTH).container_create_row}>
                    <Text style={[style(WIDTH).fontSize2,style().text_create,style().create_attribute]}>Shipper {errors.shipper?
                        <Text style={style(WIDTH).fontError}>is REQUIRED, please select!</Text>: null}</Text>
                    <View style={style().container_2_flex}>
                        <Controller name="shipper" rules={{required: true}} control={control} render={({ field: {} }) => null}/>  
                        <TouchableOpacity onPress={() => showOverlay("shipper")}>
                            <TextInput pointerEvents="none" style={[style(WIDTH).searchInput,style(WIDTH).fontSize2]} value={valueShipper} placeholder="Select shipper"/>              
                        </TouchableOpacity>
                    </View>
                </View>
                
                <View style={style(WIDTH).container_create_row}>
                    <Text style={[style(WIDTH).fontSize2,style().text_create,style().create_attribute]}>Consignee {errors.consignee?
                        <Text style={style(WIDTH).fontError}>is REQUIRED, please select!</Text>: null}</Text>
                    <View style={style().container_2_flex}>
                        <Controller name="consignee" rules={{required: true}} control={control} render={({ field: {} }) => null}/>  
                        <TouchableOpacity onPress={() => showOverlay("consignee")}>
                            <TextInput pointerEvents="none" style={[style(WIDTH).searchInput,style(WIDTH).fontSize2]} value={valueConsignee} placeholder="Select consignee"/>               
                        </TouchableOpacity>
                    </View>
                </View>
            
                <View style={style(WIDTH).container_create_row}>
                    <View style={{flex:3}}/>
                    <ButtonPaper
                        style={[style(WIDTH).button_orange,  {right:-WIDTH*0.008}]} 
                        labelStyle={[style(WIDTH).button_label_white, style(WIDTH).fontSize2]} 
                        onPress={handleSubmit(onSubmit)}>
                        Create
                    </ButtonPaper>
                </View>
                {/* <Button title="Submit" onPress={() => setOpen(), handleSubmit(onSubmit)} />   */}
                <Overlay isVisible={state_showOverlay} onBackdropPress={hideOverlay} animationType='fade' overlayStyle={{width:'95%', height:'95%'}}>   
                    <View style={{alignItems: 'flex-end'}}><Icon name='close' type="antdesign" size={option(WIDTH).iconSize2} onPress={hideOverlay}/></View>         
                    <ScrollView style={{marginTop:'1%'}}
                        contentContainerStyle={{flex:1}} 
                        showsHorizontalScrollIndicator={false} 
                        showsVerticalScrollIndicator={false} 
                        focusable={false}
                    >
                        <Client_list selectContact={select_client} selectAddress={!select_client} sendDataToParent={sendDataToParent} is_client={is_client}/>
                    </ScrollView>                    
                </Overlay>     
            </View>
        </View>
        // </TouchableWithoutFeedback>
    )
}


export default SalesOrder_Create;