import React, { useEffect,useState,useRef } from 'react'
import { View, Text, Picker,Pressable, TextInput,Image } from 'react-native'
import { API_URL, BASE_URL } from '../config/config';
import { ITEM_PER_TABLE_PAGE } from '../constants';
import axios from 'axios';
import { DataTable, Button as ButtonPaper} from 'react-native-paper';
import { style, option } from '../styles/style.js';
import { useHover, Hoverable } from 'react-native-web-hooks';

import prev from '../assets/prev-arrow.png';
import next from '../assets/next-arrow.png';
import { Button, Icon } from 'react-native-elements';
import { getWebsiteWidth } from '../components/utilities';
import { Popable } from 'react-native-popable';

function Accounting() {
    const [WIDTH, set_WIDTH] = useState(getWebsiteWidth());
    let pageTimer;

    useEffect(async() => {
        window.localStorage.getItem('role') == 1? window.location.href = `${BASE_URL}`: null;
        window.addEventListener('resize', () => { set_WIDTH(getWebsiteWidth()); });
        onLoad();
    }, []);

    const onLoad = async() => {
        getExcel();
    }

    const token = window.localStorage.getItem('token');
    const headers = {
        Authorization: `Bearer ${token}` 
    }

    const [data, setData] = useState([]);
    const [dataList, setDataList] = useState('');
    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');
    const isHovered = useHover(ref);
    const ref = useRef(null);

    // for searching
    const [showSearch, setShowSearch] = useState(0);
    const [searchParams, setSearchParams] = useState([]);
    
    // for pagination
    const [tablePage, setCurrentPage] = useState({totalPages:0, currentPage:0});
    const [totalItems, setTotalItems] = useState(0);
    const [isFirst, setFirst] = useState(1);
    const [isLast, setLast] = useState(0);

    const getExcel = async (params) => {
        ShowLoading();
        let url = API_URL + 'accounting?';

        let queryParams = {};
        // for(const sp in searchParams){
        //     if(searchParams[sp] != '')
        //         queryParams['filter[' + sp + ']'] = searchParams[sp];
        // }
        queryParams['size'] = ITEM_PER_TABLE_PAGE;
        queryParams['page'] = 0;
        if(params && params.page){
            queryParams['page'] = params.page;
        }

        window.API_CALL_GET('accounting', queryParams).then(
          function(data) {        
            let dt = data.dataList;
            let totalPage = data.totalPages;
            let currentPage = data.currentPage;

            setTotalItems(data.totalItems);
            setCurrentPage({totalPages: totalPage, currentPage: currentPage});

            if((currentPage+1) == totalPage){
                setLast(1)
            }else{
                setLast(0)
            }

            currentPage == 0? setFirst(1):setFirst(0);

            let allData = [];
            for(let a=0; a< dt.length; a++){
                let obj={
                    id:dt[a].id,
                    createdAt:dt[a].createdAt,
                    path:dt[a].path,
                }
                allData.push(obj);
            }
            setData(allData);
          },
          function(error) { MessageBox("Error", error); }
        );
    }

    const requestExcel = async () => {
        ShowLoading();
        await axios({
            method:'post',
            url: API_URL + 'accounting', 
            headers: headers,
        }).then(() => {
            getExcel();
        }).catch(err => {
            console.log(err); 
            MessageBox("Error", err.response.data.result);
        }).finally(()=>{
            HideLoading();
        })
    }

    const onChangeValue = (key, value) => {
        searchParams[key] = value;
    }

    const onChangePage = (value) => {
        if(value != '' && (value <= tablePage.totalPages) && value > 0){
            clearTimeout(pageTimer);
            pageTimer = setTimeout(() => {          
                getExcel({ page:value-1 });
            }, 800);
        }
    }

    const previousPage = () => {
        if(tablePage.totalPages > 1) {
            tablePage.currentPage = tablePage.currentPage - 1;
            setLast(0);
            tablePage.currentPage == 0? setFirst(1):setFirst(0)
            getExcel({ page:tablePage.currentPage });
        }
    }

    const nextPage = () => {
        if(tablePage.totalPages > 1) {
            tablePage.currentPage = tablePage.currentPage + 1;
            setFirst(0);
            (tablePage.currentPage+1) == tablePage.totalPages? setLast(1):setLast(0)
            getExcel({ page:tablePage.currentPage });
        }
    }

    const DownloadFile = async(url) => {
        if(!url) {
            MessageBox("Error", "Accounting data not available");
            return;
        }
        ShowLoading();
        const token = window.localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        await axios({
            method:'get',
            url: url, 
            headers: headers,
            responseType: 'blob',
            // data: {shipmentID:shipmentID}
        }).then(response => {
            let filename = url.split('/').pop();
            const _url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = _url;
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();
        }).catch(err => {
            MessageBox("Error", "Failed to download file");
            console.log(err);
        }).finally(()=>{
            HideLoading();
        })         
    }

    useEffect(()=>{
        // let months = [ "January", "February", "March", "April", "May", "June", 
        //             "July", "August", "September", "October", "November", "December" ];
        let final_list = [];
        if(data.length == 0) setDataList([]);

        data.map((item)=>{
            final_list.push((
                <Hoverable key={item.id}>
                    {isHovered => (
                        <View style={[style(WIDTH).table_row, isHovered && style(WIDTH).hover]}>
                            <DataTable.Row key={item.id} >
                                <View style={style(WIDTH).dataTable_content}><Text style={[style(WIDTH).dataTable_content_flex, style(WIDTH).fontSize2]}>{item.createdAt || '-'}</Text></View>
                                <View style={[style(WIDTH).dataTable_content]}>
                                    {item.path?
                                        <>
                                        <Popable content='Download' position="left" backgroundColor='#FF9F1D' action="hover"> 
                                        <Button style={{alignSelf:'flex-start'}} icon={<Icon name='cloud-download-outline' type="ionicon" size={option(WIDTH).iconSize2}/>} type="clear"
                                            onPress={() => DownloadFile(item.path)}/></Popable>
                                        </>: 
                                        <>
                                        <Text style={style(WIDTH).fontSize2}>Preparing... please check again 15 mins later.</Text>
                                        <Popable content='Refresh' position="left" backgroundColor='#FF9F1D' action="hover"> 
                                        <Button style={{alignSelf:'flex-start'}} icon={<Icon name='refresh' type="simplelineicons" size={option(WIDTH).iconSize2}/>} type="clear"
                                            onPress={getExcel}/></Popable>                                        
                                        </>}                                    
                                </View>
                            </DataTable.Row>
                        </View>
                    )}
                </Hoverable>
            ))
        });
        setDataList(final_list);
    },[data, WIDTH])

    const clearFilter = () => {
        setYear('');
        setMonth('');
        setSearchParams([]);
    }

    return (
        <View style={[style(WIDTH).container, {width:WIDTH/2}]} >
            <DataTable style={{zIndex:1}}>
                <View style={style(WIDTH).container_row}>
                    <Popable content='Refresh' position="left" backgroundColor='#FF9F1D' action="hover">
                    <Button icon={<Icon name='refresh' type="simplelineicons" size={option(WIDTH).iconSize2}/>} type="clear" style={{padding: '5%'}} 
                        onPress={getExcel}/></Popable>
                    {/* <View style={style(WIDTH).container_icon}></View>
                    <Popable content='Search' position="left" backgroundColor='#FF9F1D' action="hover">
                    <Button icon={<Icon name='search1' type="antdesign" size={option(WIDTH).iconSize2}/>} type="clear" style={{padding: '5%'}}
                        onPress={()=>setShowSearch(!showSearch)}/></Popable> */}
                    <View style={style(WIDTH).container_icon}></View>
                    <Popable content='Export' position="left" backgroundColor='#FF9F1D' action="hover">
                    <Button icon={<Icon name='file-move-outline' type="material-community" size={option(WIDTH).iconSize2}/>} type="clear" style={{padding: '5%'}}
                        onPress={requestExcel}/></Popable>
                </View>

                <View style={[style().container_column, {marginTop:'2%'}]}>
                { showSearch? 
                        (<View style={style(WIDTH).container_search_input}>
                            <ButtonPaper
                                style={style(WIDTH).button_orange} 
                                labelStyle={style().button_label_white} 
                                onPress={clearFilter}
                            >
                                <Text style={style(WIDTH).fontSize2}>Clear Filter</Text>
                            </ButtonPaper>
                            <DataTable.Header style={style(WIDTH).dataTable_header2}>
                                <View style={style(WIDTH).scontainer}>
                                    <TextInput
                                        style={[style(WIDTH).searchInput,style(WIDTH).fontSize2]}
                                        value={year}
                                        onChangeText={(value) => {
                                            onChangeValue("year",value);
                                            setYear(value);
                                        }}
                                        placeholder="Year"
                                        
                                    />
                                </View>

                                <View style={style(WIDTH).scontainer}>
                                    <Picker style={[style(WIDTH).searchInput,style(WIDTH).fontSize2]} 
                                        value={month}
                                        onValueChange={(value) => {
                                            onChangeValue("month",value);
                                            setMonth(value);
                                        }}>
                                        <Picker.Item label="Month" value="" />
                                        <Picker.Item label="January" value="1" />
                                        <Picker.Item label="February" value="2" />
                                        <Picker.Item label="March" value="3" />
                                        <Picker.Item label="April" value="4" />
                                        <Picker.Item label="May" value="5" />
                                        <Picker.Item label="June" value="6" />
                                        <Picker.Item label="July" value="7" />
                                        <Picker.Item label="August" value="8" />
                                        <Picker.Item label="September" value="9" />
                                        <Picker.Item label="October" value="10" />
                                        <Picker.Item label="November" value="11" />
                                        <Picker.Item label="December" value="12" />
                                    </Picker>                                   
                                </View>
                                
                            </DataTable.Header>
                            <ButtonPaper 
                                style={style(WIDTH).button_orange} 
                                labelStyle={style().button_label_white}  
                                onPress={() => {getExcel();}}> 
                                    <Text style={style(WIDTH).fontSize2}>Search</Text>
                            </ButtonPaper>
                        </View>):null
                    }
                </View>

                <DataTable.Header style={[style(WIDTH).dataTable_header_border, {marginTop:'2%'}]}>
                    <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>Date & time </Text></View>
                    <View style={[style(WIDTH).dataTable_header]}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>Download</Text></View>
                </DataTable.Header>
                {dataList.length>0? dataList:null}

                <View style={style().container_first}>
                    <Text style={[style(WIDTH).text_grey, style(WIDTH).text_total_item, style(WIDTH).fontSize2]}>{`Total items: ${totalItems}`}</Text>
                    <View style={style(WIDTH).container_second}>
                        <Pressable 
                            style={isFirst? style(WIDTH).paginationButtonDisabled:style(WIDTH).paginationButton} 
                            onPress={previousPage} 
                            disabled={isFirst}
                        >
                            <Image source={prev} resizeMode='contain' style={[style(WIDTH).pagination_arrow,isFirst && style().disableTint]} />
                        </Pressable>
                        
                        <Text style={[style(WIDTH).text_grey,style(WIDTH).text_page, style(WIDTH).fontSize2]}>
                            {`Page `}
                            <Text style={[style().text_current_page, style(WIDTH).fontSize3]}>{`${tablePage.totalPages == 0? tablePage.currentPage:tablePage.currentPage+1}`}</Text>
                            {` of ${tablePage.totalPages}`}
                        </Text>
                        
                        <Pressable 
                            style={isLast? style(WIDTH).paginationButtonDisabled: style(WIDTH).paginationButton} 
                            onPress={nextPage}
                            disabled={isLast}
                        >
                            <Image source={next} resizeMode='contain' style={[style(WIDTH).pagination_arrow,isLast && style().disableTint]} />
                        </Pressable>
                    </View>
                
                    <View style={style(WIDTH).container_third}>
                        <Text style={[style(WIDTH).text_grey,style(WIDTH).text_page, style(WIDTH).fontSize2]}>Go to page: </Text>
                        <TextInput 
                            style={[style(WIDTH).fontSize2, style(WIDTH).go_to_input]}
                            onChangeText={(value) => onChangePage(value)}
                            placeholder={`${tablePage.totalPages == 0? tablePage.currentPage:tablePage.currentPage+1}`}
                        />
                    </View>
                </View>
            </DataTable>
        </View>
    )
}

export default Accounting;