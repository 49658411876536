import React, { useState, useEffect } from 'react'
import { Image, View, Text, StyleSheet, Pressable, Dimensions } from 'react-native'
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom'
import logo from '../assets/logo1.png';
import { BASE_URL } from '../config/config';
import { style, option } from '../styles/style';
import { getWebsiteWidth } from '../components/utilities';
import {Button, Icon} from 'react-native-elements'
import { Popable } from 'react-native-popable';

export default class Navbar extends React.Component{
    _isMounter = false;

    constructor(props){
        super(props);
        this.state = {
            isAdmin: false,
            isStaff: false,
            isManager: false,
            isDirector: false,
            isUser: window.localStorage.getItem('user'),
            isClicked: {
                sales_order: false,
                delivery_order: false,
                client: false,
                nonclient: false,
                sales: false,
                report: false,
                report_company: false,
                report_staff: false,
                report_sales:false,
                report_client:false,
                accounting: false,
                category:false,
                staff: false,
                profile: false,
                backup: false
            },
            width: getWebsiteWidth(),
            isShowReport: false,
            isShowAddressBook: false
        }

        let role = window.localStorage.getItem('role');
        let path = window.location.pathname.split('/')[1];

        switch(role){
            case '0':
                this.state.isAdmin = true;
                break;
            case '1':
                this.state.isStaff = true;
                break;
            case '2':
                this.state.isManager = true;
                break;
            case '3':
                this.state.isDirector = true;
                break;
            default:
                this.state.isStaff = true;
                break;
        }

        switch(path){
            case 'sales_order':
            case 'sales_order_create':
            case 'sales_order_edit':
                this.state.isClicked.sales_order = true;
                break;
            case 'delivery_order':
            case 'delivery_order_create':
            case 'delivery_order_edit':
                this.state.isClicked.delivery_order = true;
                break;
            case 'client':
                this.state.isClicked.client = true;
                this.state.isShowAddressBook = true;
                break;
            case 'nonclient':
                this.state.isClicked.nonclient = true;
                this.state.isShowAddressBook = true;
                break;
            case 'salesperson':
            case 'salesperson_create':
            case 'salesperson_edit':
                this.state.isClicked.sales = true;
                break;
            // case 'report':
            //     this.state.isClicked.report = true;
            //     break;
            case 'report_company':
                this.state.isShowReport = true;
                this.state.isClicked.report_company = true;
                break;
            case 'report_staff':
                this.state.isShowReport = true;
                this.state.isClicked.report_staff = true;
                break;
            case 'report_salesperson':
                this.state.isShowReport = true;
                this.state.isClicked.report_sales = true;
                break;
            case 'report_client':
                this.state.isShowReport = true;
                this.state.isClicked.report_client = true;
                break;
            case 'accounting':
                this.state.isClicked.accounting = true;
                break;
            case 'category':
                this.state.isClicked.category = true;
                break;
            case 'staff':
            case 'staff_create':
            case 'staff_edit':
                this.state.isClicked.staff = true;
                break;
            case 'profile':
                this.state.isClicked.profile = true;
                break;
            case 'backup':
                this.state.isClicked.backup = true;
                break;
            default:
                this.state.isClicked.sales_order = true;
                break;
        }

        this.handleLogout = this.handleLogout.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleClickReport = this.handleClickReport.bind(this);
    }

    handleLogout(){
        window.localStorage.clear();
        window.location.href = BASE_URL;
    }

    handleClick(index){
        let newState = this.state.isClicked;
        let name = Object.keys(newState);

        for(let a = 0; a< name.length; a++) {
            newState[name[a]] = (index == name[a])? true:false
        }

        this.setState({isClicked: newState});
    }

    handleResize(){
        this.setState({width:getWebsiteWidth()});
    }

    componentDidMount() {
        this._isMounted = true;
        this.handleResize();
        window.addEventListener('resize', this.handleResize.bind(this));
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleClickReport(){
        // this.setState({isShowAddressBook: false});
        this.setState({isShowReport: !this.state.isShowReport});
    }

    handleClickAddressBook(){
        // this.setState({isShowReport: false});
        this.setState({isShowAddressBook: !this.state.isShowAddressBook});
    }

    render(){
        return (
            <View style={style(this.state.width).navContainer}>
                <Image 
                    style={style(this.state.width).navLogo} 
                    source={logo} 
                    resizeMode='contain'
                />
                <Text style={[style(this.state.width).fontSize2, {paddingLeft: this.state.width * 0.02}]}>Welcome,</Text>
                <Text style={[style(this.state.width).fontSize2, {paddingLeft: this.state.width * 0.02, paddingBottom: this.state.width * 0.015}]}>{window.localStorage.getItem("name")}</Text>
                {/* <Text style={[style(this.state.width).fontSize1, {position:'absolute', 
                    bottom:this.state.width*0.02, left:this.state.width*0.005}]}>Welcome,</Text>
                <Text style={[style(this.state.width).fontSize2, {position:'absolute', 
                    bottom:this.state.width*0.005, left:this.state.width*0.005}]}>{window.localStorage.getItem("name")}</Text> */}
                { this.state.isStaff || this.state.isAdmin || this.state.isDirector || this.state.isManager? 
                    <>
                        <Link 
                            style={this.state.isClicked.sales_order? option(this.state.width).optionstyleClick:option(this.state.width).optionStyle} 
                            to='/sales_order' 
                            onClick={() => this.handleClick('sales_order')}
                        >
                            Sales Order
                        </Link>

                        <Link 
                            style={this.state.isClicked.delivery_order? option(this.state.width).optionstyleClick:option(this.state.width).optionStyle} 
                            to='/delivery_order' 
                            onClick={() => this.handleClick('delivery_order')}
                        >
                            Delivery Order
                        </Link>

                        <Link 
                            style={option(this.state.width).report} 
                            to='#' 
                            onClick={() => {this.handleClickAddressBook()}}
                        >
                            <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                                <Text style={option(this.state.width).optionStyle}>Address Book</Text>
                                <Icon name='caretdown' type="antdesign" size={option(this.state.width).iconSize0} style={style(this.state.width).report_dropdown_icon}/>
                            </View>
                        </Link>
                        
                        {this.state.isShowAddressBook && 
                        <>
                            <Link 
                                style={this.state.isClicked.client? option(this.state.width).reportOptionClick:option(this.state.width).reportOption} 
                                to='/client' 
                                onClick={() => {this.handleClick('client')}}
                            >
                                Client
                            </Link>
                            <Link 
                                style={this.state.isClicked.nonclient? option(this.state.width).reportOptionClick:option(this.state.width).reportOption} 
                                to='/nonclient' 
                                onClick={() => {this.handleClick('nonclient')}}
                            >
                                Non-Client
                            </Link>
                        </>}

                        <Link 
                            style={this.state.isClicked.sales? option(this.state.width).optionstyleClick:option(this.state.width).optionStyle}
                            to='/salesperson' 
                            onClick={() => this.handleClick('sales')}
                        >
                            Salesperson
                        </Link>
                    </>:null }
                
                { this.state.isUser == 1? 
                    <Link 
                        style={this.state.isClicked.staff? option(this.state.width).optionstyleClick:option(this.state.width).optionStyle} 
                        to='/staff' 
                        onClick={() => this.handleClick('staff')}
                    >
                        Management
                    </Link>:null 
                }
                
                { this.state.isAdmin || this.state.isDirector? 
                (<>
                    <Link  
                        style={this.state.isClicked.accounting? option(this.state.width).optionstyleClick:option(this.state.width).optionStyle} 
                        to='/accounting' 
                        onClick={() => this.handleClick('accounting')}
                    >
                        Accounting
                    </Link>
                    <Link  
                        style={this.state.isClicked.category? option(this.state.width).optionstyleClick:option(this.state.width).optionStyle} 
                        to='/category' 
                        onClick={() => this.handleClick('category')}
                    >
                        Category
                    </Link>
                    </>):null 
                }

                { this.state.isManager || this.state.isAdmin || this.state.isDirector? 
                    <>
                        <Link 
                        style={option(this.state.width).report} 
                        to='#' 
                        onClick={() => {this.handleClickReport()}}
                    >
                        <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                            <Text style={option(this.state.width).optionStyle}>Report</Text>
                            <Icon name='caretdown' type="antdesign" size={option(this.state.width).iconSize0} style={style(this.state.width).report_dropdown_icon}/>
                        </View>
                    </Link>
                    
                    {this.state.isShowReport && 
                    <>
                        <Link 
                            style={this.state.isClicked.report_company? option(this.state.width).reportOptionClick:option(this.state.width).reportOption} 
                            to='/report_company' 
                            onClick={() => {this.handleClick('report_company')}}
                        >
                            Company Profit Loss
                        </Link>
                        <Link 
                            style={this.state.isClicked.report_staff? option(this.state.width).reportOptionClick:option(this.state.width).reportOption} 
                            to='/report_staff' 
                            onClick={() => {this.handleClick('report_staff')}}
                        >
                            Staff Report
                        </Link>
                        <Link 
                            style={this.state.isClicked.report_sales? option(this.state.width).reportOptionClick:option(this.state.width).reportOption} 
                            to='/report_salesperson' 
                            onClick={() => {this.handleClick('report_sales')}}
                        >
                            Salesperson Report
                        </Link>
                        <Link 
                            style={this.state.isClicked.report_client? option(this.state.width).reportOptionClick:option(this.state.width).reportOption} 
                            to='/report_client' 
                            onClick={() => {this.handleClick('report_client')}}
                        >
                            Client Report
                        </Link>
                    </>}</>:null 
                }

                <View style={{flexDirection:'row', marginLeft:this.state.width*0.015}}>
                    <Popable content='Profile' position="top" backgroundColor='#FF9F1D' action='hover'>
                    <Link style={{textDecorationLine:'none'}}
                        to='/profile' 
                        onClick={() => this.handleClick('profile')}>
                        <Button pointerEvents="none" icon={ <Icon name='person-sharp' type="ionicon" size={option(this.state.width).iconSize2}/>} 
                            type="clear" style={[option(this.state.width).buttonIcon, {backgroundColor: this.state.isClicked.profile? '#FF9F1D': null}]}/>
                    </Link></Popable>
                    <View style={style(this.state.width).container_icon} />
                    {this.state.isAdmin? <View style={{flexDirection:'row'}}>
                    <Popable content='System' position="top" backgroundColor='#FF9F1D' action='hover'>
                    <Link style={{textDecorationLine:'none'}}
                        to='/backup' 
                        onClick={() => this.handleClick('backup')}>
                        <Button pointerEvents="none" pointer icon={ <Icon name='gear' type="font-awesome" size={option(this.state.width).iconSize2}/>} 
                            type="clear" style={[option(this.state.width).buttonIcon, {backgroundColor: this.state.isClicked.backup? '#FF9F1D': null}]}/>
                    </Link></Popable>
                    </View> : null}
                    
                    <View style={style(this.state.width).container_icon} />
                    <Popable content='Logout' position="top" backgroundColor='#FF9F1D' action='hover'>
                        <Button icon={ <Icon name='logout' type="material-icon" size={option(this.state.width).iconSize2}/>} 
                            type="clear" style={[option(this.state.width).buttonIcon]} onPress={this.handleLogout}/></Popable>
                </View>
            </View>
        )
    }
}

// // for link style
// const optionstyle = (width) => {return{
//         padding:normalize(15,width),
//     paddingLeft:40, 
//     textDecorationLine:'none', 
//     fontSize:18, 
//     color:'black',
//     fontFamily: 'sans-serif'
// }
// }

// const optionstyleClick = {
//     padding:15,
//     paddingLeft:40, 
//     textDecorationLine:'none', 
//     fontSize:20, 
//     color:'white',
//     backgroundColor:'#FF9F1D',
//     fontWeight:'bold',
//     fontFamily: 'sans-serif'
// }

// const styles = StyleSheet.create({
//     container: {
//         backgroundColor:'#F9F9F9',
//         left:0,
//         width:200,
//         height:'100%',
//         position: 'fixed',
//         zIndex: 5,
//         paddingTop:25
//     },
//     option:{
//         padding:15,
//         paddingLeft:40, 
//         textDecorationLine:'none', 
//         fontSize:18, 
//         color:'black',
//         fontFamily: 'sans-serif'
//     }
// });
// 
// export default Navbar
