import React, {useEffect, useState} from 'react'
import { Picker,View, Text, StyleSheet, Pressable, TextInput} from 'react-native'
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { API_URL, BASE_URL } from '../config/config';
import { getWebsiteWidth } from '../components/utilities';
import { style, grey2 } from '../styles/style';
import {Button as ButtonPaper} from 'react-native-paper';

function Profile(props) {
    const token = window.localStorage.getItem('token');
    const [editable, set_editable] = useState(0);

    const [WIDTH, set_WIDTH] = useState(getWebsiteWidth());
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [userData, set_userData] = useState(null);
    const [name, set_name] = useState('');
    const [email, set_email] = useState('');
    const [contact, set_contact] = useState('');
    const [roleOption, set_roleOption] = useState(null);
    const [role, set_role] = useState('');

    useEffect(() => {
        window.addEventListener('resize', () => { set_WIDTH(getWebsiteWidth()); });
        onLoad();
    }, [])

    const onLoad = async() => {
        ShowLoading();
        await getRoleOption();
        await getUserProfile();
        HideLoading();
    }

    useEffect(() => {
        if(userData && roleOption)
            for(let i=0; i<roleOption.length; i++)
                if(roleOption[i].id == userData.roleID)
                {
                    set_role(roleOption[i].title);
                    break;
                } 
    }, [userData, roleOption])

    const { register, setValue, handleSubmit, control, reset, formState: { errors } } = useForm({
        defaultValues: {
            currentPassword:'',
            newPassword:'',
            confirmPassword:''
        }
    });

    const onSubmit = async(data) => {
        if(!editable) return;

        ShowLoading();
        let url = API_URL + 'user/changepassword';
        let token = window.localStorage.getItem('token');
        let headers = {
            Authorization: `Bearer ${token}` 
        }

        let payload;
        if (data.newPassword.length < 8 || data.newPassword.length > 32) {
            MessageBox("Error", "Password must be minimum 8 and maximum 32 characters!");
            return;
        }
        if(data.confirmPassword === data.newPassword){
            payload = {
                "current":data.currentPassword,
                "password":data.newPassword
            }
        }else{
            MessageBox("Error",'Confirm password is not match with password!');
            return;
        }

        await axios({
            method:'post',
            url: url, 
            headers: headers,
            data:payload
        }).then(res => {
            reset({
                currentPassword:'',
                newPassword:'',
                confirmPassword:''
            });
            setShowChangePassword(false);
            MessageBox("Success",'Change Password Successfully!');
        }).catch(err => {
            let msg = '';
            for(let i=0; i<err.response.data.result.errors.length; i++)
            {   
                if(msg != '')   msg += '\n';
                msg += err.response.data.result.errors[i].param + ': ' + err.response.data.result.errors[i].msg;
            }
            MessageBox('Error', msg);
        })
        .finally(() => HideLoading());  
    }

    const onChange = arg => {
        return {
            value: arg.nativeEvent.text,
        };
    };

    const getUserProfile = async() => {
        let url = API_URL + `user/profile`;
        const headers = {
            Authorization: `Bearer ${token}` 
        }

        window.API_CALL_GET('user/profile').then(
          function(data) {        
            set_name(data.name);
            set_contact(data.contact);
            set_email(data.email);
            set_userData(data);
            set_editable(true);
          },
          function(error) { MessageBox("Error", error); }
      );
    }

    const getRoleOption = async() => {
        let url = API_URL + 'utils/options/role';
        let token = window.localStorage.getItem('token');
        let headers = { Authorization: `Bearer ${token}` }
        await axios({
            method:'get',
            url: url, 
            headers: headers,
        }).then(res => {
            set_roleOption(res.data.result);
        }).catch (err => {
            MessageBox("Error","Failed to get role option.")
        });
    }

    return (
        <View>
            {editable?     
            <View style={[style(WIDTH).container,{width:WIDTH/2}]}>
            <View style={style(WIDTH).container_create_col}>
                <View style={{marginBottom:WIDTH*0.025}}>
                    <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize4]}>Profile</Text></View>
                <View style={{flexDirection:'row'}}>
                    <View style={style(WIDTH).create_col_space}>
                        <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Name</Text>
                        <TextInput
                            style={[style(WIDTH).create_input,style(WIDTH).fontSize2, style().grey_background]}
                            value={name}
                            placeholder='Enter email...'
                            placeholderTextColor = {grey2}
                            editable={false}
                        />           
                    </View>
                    
                    <View style={style(WIDTH).container_icon} />
                    <View style={style(WIDTH).create_col_space}>
                        <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Role</Text>
                        <TextInput
                            style={[style(WIDTH).create_input,style(WIDTH).fontSize2, style().grey_background]}
                            value={role}
                            placeholder='Enter role...'
                            placeholderTextColor = {grey2}
                            editable={false}/>   
                    </View>
                </View>     

                <View style={style(WIDTH).create_col_space}>
                    <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Email</Text>
                    <TextInput
                        style={[style(WIDTH).create_input,style(WIDTH).fontSize2, style().grey_background]}
                        value={email}
                        placeholder='Enter email...'
                        placeholderTextColor = {grey2}
                        editable={false}
                    />                    
                </View>
            
                <View style={style(WIDTH).create_col_space}>
                    <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Contact</Text>
                    <TextInput
                        style={[style(WIDTH).create_input,style(WIDTH).fontSize2, style().grey_background]}
                        value={contact}
                        placeholder='Enter email...'
                        placeholderTextColor = {grey2}
                        editable={false}
                    />           
                </View>

                
                <ButtonPaper
                    style={style(WIDTH).change_pw_btn} 
                    labelStyle={style(WIDTH).change_pw_btn_label} 
                    onPress={() => {setShowChangePassword(!showChangePassword);}}
                >
                    Change Password
                </ButtonPaper> 

                {showChangePassword &&<>    
                <View style={[style(WIDTH).create_col_space, {marginTop:WIDTH*0.02}]}>
                    <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Current Password {errors.currentPassword?
                        <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                    <Controller
                        control={control}
                        render={({field:{onChange, onBlur, value}}) => (
                            <TextInput
                                style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                onBlur={onBlur}
                                onChangeText={value => onChange(value)}
                                value={value}
                                placeholder='Enter password..'
                                placeholderTextColor = {grey2}
                                secureTextEntry={true}
                                textContentType='password'
                            />
                        )}
                        name="currentPassword"
                        rules={{required: true}}
                    />
                
                </View>

                <View style={style(WIDTH).create_col_space}>
                    <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>New Password {errors.newPassword?
                        <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                    <Controller
                        control={control}
                        render={({field:{onChange, onBlur, value}}) => (
                            <TextInput
                                style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                onBlur={onBlur}
                                onChangeText={value => onChange(value)}
                                value={value}
                                placeholder='Enter password..'
                                placeholderTextColor = {grey2}
                                secureTextEntry={true}
                                textContentType='password'
                            />
                        )}
                        name="newPassword"
                        rules={{required: true}}
                    />
                </View>
                    
                <View style={style(WIDTH).create_col_space}>
                    <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Comfirm Password {errors.confirmPassword?
                        <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                    <Controller
                        control={control}
                        render={({field:{onChange, onBlur, value}}) => (
                            <TextInput
                                style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                onBlur={onBlur}
                                onChangeText={value => onChange(value)}
                                value={value}
                                placeholder='Enter password again...'
                                placeholderTextColor = {grey2}
                                secureTextEntry={true}
                                textContentType='password'
                            />
                        )}
                        name="confirmPassword"
                        rules={{ required: true}}
                    />
                </View></>}
                    
            </View>

            {showChangePassword && 
            <ButtonPaper
                style={[style(WIDTH).create_btn, {marginTop:WIDTH*0.005}]} 
                labelStyle={style(WIDTH).create_btn_label} 
                onPress={handleSubmit(onSubmit)}
            >
                Submit
            </ButtonPaper>
}                    
        </View>: null}
        </View>
    
    )
}

const styles = StyleSheet.create({
    input:{
        borderWidth:2, 
        borderColor:'#AAAAAA', 
        height:50, 
        padding:10,
        margin:10,
        marginLeft:0
    },
    title:{
        fontSize:18
    }
})


export default Profile