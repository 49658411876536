import React, { useState, useEffect } from 'react'
import { Image, View, Text, TextInput, StyleSheet, Pressable, Dimensions } from 'react-native';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import axios from 'axios';
import { API_URL, BASE_URL } from '../config/config';
import logo from '../assets/logo1.png';
import { getWebsiteWidth } from '../components/utilities';
import { style, grey2 } from '../styles/style';

function ChangePassword(props) {
    const [currentPassword, setCurrentPassword] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isChange, setChange] = useState(window.localStorage.getItem("change_password"));
    const token = window.localStorage.getItem("token");
    const change_password = window.localStorage.getItem("change_password");

    const [WIDTH, set_WIDTH] = useState(getWebsiteWidth());
    useEffect(() => {
        window.addEventListener('resize', () => { set_WIDTH(getWebsiteWidth()); });
    }, [])


    const onSubmit = async (req, params) => {
        if (password.length < 8 || password.length > 32) {
            MessageBox("Error", "Password must be minimum 8 and maximum 32 characters!");
            return;
        } else if (confirmPassword != password) {
            MessageBox("Error", "Confirm password and password are not match!");
            return;
        }

        ShowLoading();
        let headers = { Authorization: `Bearer ${token}` };

        const payload = {
            "current": currentPassword,
            "password": password,
            "cpassword": confirmPassword
        };

        await axios({
            method: 'post',
            url: API_URL + 'user/changepassword',
            headers: headers,
            data: payload
        }).then(() => {
            // alert("Password changed successfully!");
            MessageBox("Success", "Password changed successfully!");
            window.localStorage.setItem("change_password", 0);
            window.location.href = `${BASE_URL}sales_order`;
            
        }).catch(error => {
            if (typeof (error.response.data.result) == 'string') {
                MessageBox("Error", error.response.data.result + "!");
                return;
            } else {
                MessageBox("Error", error.response.data.result.errors[0].msg + "!");
                return;
            }
        }).finally(() => { HideLoading();});
    };


    return (
        <View style={[style(WIDTH).login_container2, style(WIDTH).change_pw_container]}>
        {isChange == 1 ? <Image source={logo} resizeMode='contain' style={style(WIDTH).login_logo} /> : null} 
            <Text style={[style(WIDTH).fontSize3,{ margin: 'auto', marginVertical: 0, marginBottom: WIDTH*0.02 }]}>Change Password</Text> 
            <Text style={[style(WIDTH).login_text, style(WIDTH).fontSize2]}>Current Password </Text> 
            <TextInput
                style={[style(WIDTH).fontSize2, style(WIDTH).login_textinput]}
                placeholder='Enter current password...'
                secureTextEntry={true}
                textContentType='password'
                onChangeText={text => setCurrentPassword(text)}
                onSubmitEditing={onSubmit}
            />

            <Text style={[style(WIDTH).login_text, style(WIDTH).fontSize2]}> Password </Text> 
            <TextInput
                style={[style(WIDTH).fontSize2, style(WIDTH).login_textinput]}
                placeholder='Enter new password...'
                secureTextEntry={true}
                textContentType='password'
                onChangeText={text => setPassword(text)}
                onSubmitEditing={onSubmit}
            />

            <Text style={[style(WIDTH).login_text, style(WIDTH).fontSize2]}> Confirm Password </Text> 
            <TextInput
                style={[style(WIDTH).fontSize2, style(WIDTH).login_textinput]}
                placeholder='Confirm password...'
                secureTextEntry={true}
                textContentType='password'
                onChangeText={text => setConfirmPassword(text)}
                onSubmitEditing={onSubmit}
            />
                        
            <Pressable
                style={style(WIDTH).login_btn}
                onPress={() => onSubmit()}>
                    <Text style={[style(WIDTH).fontSize2,{ color: 'white' }]}>Submit</Text>
            </Pressable>
        </View>    
    )
}

export default ChangePassword;