import React, { useState, useEffect } from 'react'
import { Picker, View, Text, StyleSheet, Pressable, TextInput} from 'react-native'
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { API_URL, BASE_URL } from '../config/config';
import { getWebsiteWidth } from '../components/utilities';
import { style, grey2 } from '../styles/style';
import {Button as ButtonPaper} from 'react-native-paper';

function Staff_Create() {
    const [WIDTH, set_WIDTH] = useState(getWebsiteWidth());
    const [roleOption, setRoleOption] = useState([]);
    const [roleList, setRoleList] = useState('');
    useEffect(() => {
        if(window.localStorage.getItem('role') == 1) window.location.href = `${BASE_URL}`;
        window.addEventListener('resize', () => { set_WIDTH(getWebsiteWidth()); });
        onLoad();
    }, [])
    
    const onLoad = async() => {
        ShowLoading();
        await getOption();
        HideLoading();
    }
    const { register, setValue, handleSubmit, control, formState: { errors } } = useForm({
        defaultValues: {
            roleID:'',
            name:'',
            email:'',
            contact:'',
            // password:'',
            // confirmPassword:''
        }
    });

    const getOption = async() => {
        let url = API_URL + 'utils/options/role';
        let token = window.localStorage.getItem('token');
        let headers = {
            Authorization: `Bearer ${token}` 
        }

        try {
            const res = await axios({
                method:'get',
                url: url, 
                headers: headers,
            })
            if(res.status === 200){
                setRoleOption(res.data.result);
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    useEffect(()=>{
        let rlist = roleOption.map(item => (
            <Picker.Item key={item.id} label={item.title} value={item.id} /> 
        ));
        setRoleList(rlist);
    },[roleOption,WIDTH])

    const onSubmit = async(data) => {
        ShowLoading();
        let url = API_URL + 'user';
        let token = window.localStorage.getItem('token');
        let headers = {
            Authorization: `Bearer ${token}` 
        }
        let payload = {
            "mode":'Add',
            "email":data.email,
            "name":data.name,
            "contact":data.contact,
            "status":1,
            "is_deleted":0,
            "roleID":data.roleID,
            // "password":data.password
        }

        await axios({
            method:'post',
            url: url, 
            headers: headers,
            data:payload
        }).then(()=>{
            if(!alert("Create Successfully!")) {window.location.href = `${BASE_URL}staff`;}
        }).catch (err => {
            if(typeof(err.response.data.result.errors) != 'undefined')
            {
                let msg = '';
                for(let i=0; i<err.response.data.result.errors.length; i++)
                {   
                    if(msg != '')   msg += '\n';
                    msg += err.response.data.result.errors[i].param + ': ' + err.response.data.result.errors[i].msg;
                }
                MessageBox('Error', msg);
            }
            else
                MessageBox("Error", "Failed to save salesperson info.");
        }).finally(()=>HideLoading());
    }

    const onChange = arg => {
        return {
            value: arg.nativeEvent.text,
        };
    };
    return (
        <View style={[style(WIDTH).container,{width:WIDTH/2}]}>
            <View style={style(WIDTH).container_create_col}>
                <View style={{marginBottom:WIDTH*0.025}}>
                    <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize4]}>Create Staff</Text></View>

                <View style={{flexDirection:'row'}}>
                    <View style={style(WIDTH).create_col_space}>
                        <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Name {errors.name?
                            <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                        <Controller
                            control={control}
                            render={({field:{onChange, onBlur, value}}) => (
                                <TextInput
                                    style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                    onBlur={onBlur}
                                    onChangeText={value => onChange(value)}
                                    value={value}
                                    placeholder='Enter your name...'
                                    placeholderTextColor = {grey2}
                                />
                            )}
                            name="name"
                            rules={{required: true}}
                        />
                    </View>
                    <View style={style(WIDTH).container_icon} />
                    <View style={style(WIDTH).create_col_space}>
                        <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Role {errors.roleID?
                            <Text style={style(WIDTH).fontError}>is REQUIRED, please select!</Text>: null}</Text>
                        <Controller
                            control={control}
                            render={({field:{onChange, onBlur, value}}) => (
                                <Picker
                                    selectedValue={value}
                                    style = {[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                    onValueChange={(itemValue, itemIndex)=>onChange(itemValue)}
                                    onBlur={onBlur}
                                >
                                    <Picker.Item value="" label="Select..." />
                                    {roleList}                         
                                </Picker>
                            )}
                            name="roleID"
                            rules={{ required: true}}
                        />
                    </View>
                </View>               
                
                <View>
                    <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Email {errors.email?
                        <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                    <Controller
                        control={control}
                        render={({field:{onChange, onBlur, value}}) => (
                            <TextInput
                                style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                onBlur={onBlur}
                                onChangeText={value => onChange(value)}
                                value={value}
                                placeholder='Enter email...'
                                placeholderTextColor = {grey2}
                            />
                        )}
                        name="email"
                        rules={{required: true}}
                    />
                </View>

                <View>
                    <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize2]}>Contact {errors.contact?
                        <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                    <Controller
                        control={control}
                        render={({field:{onChange, onBlur, value}}) => (
                            <TextInput
                                style={[style(WIDTH).create_input,style(WIDTH).fontSize2]}
                                onBlur={onBlur}
                                onChangeText={value => onChange(value)}
                                value={value}
                                placeholder='Enter contact...'
                                placeholderTextColor = {grey2}
                            />
                        )}
                        name="contact"
                        rules={{required: true}}
                    />
                </View>  
                <ButtonPaper
                    style={[style(WIDTH).create_btn, {right: -WIDTH*0.015}]} 
                    labelStyle={style(WIDTH).create_btn_label} 
                    onPress={handleSubmit(onSubmit)}>
                    Submit
                </ButtonPaper>        
            </View>
        </View>
    )
}

export default Staff_Create
