import { useEffect } from "react";
import { StyleSheet } from "react-native";
import { normalize} from '../components/utilities';
import React, { useState} from 'react';
import { maxWidth } from "../constants";

const grey0 = '#F9F9F9';
const grey1 = '#E8E8E8';
export const grey2 = '#AAAAAA';
const grey3 = '#505050';
const grey4 = '#808080';
const grey5 = '#C5C5C5';

const white = '#FFFFFF';
const black = '#0F0F0F';
export const orange = '#FF9F1D';

export const option = (width) => {
    return{
        report:{
            // paddingLeft:normalize(2, width), 
            // paddingRight:normalize(2, width),
            // paddingTop:normalize(1, width),
            // paddingBottom:normalize(1, width),
            textDecorationLine:'none', 
            fontSize:normalize(1.3, width), 
            color:'black',
            fontFamily: 'sans-serif'
        },
        optionStyle:{
            paddingLeft:normalize(2, width), 
            paddingRight:normalize(2, width),
            paddingTop:normalize(0.8, width),
            paddingBottom:normalize(0.8, width),
            textDecorationLine:'none', 
            fontSize:normalize(1.3, width), 
            color:'black',
            fontFamily: 'sans-serif'
        },
        optionstyleClick: {
            paddingLeft:normalize(2, width), 
            paddingRight:normalize(2, width),
            paddingTop:normalize(0.8, width),
            paddingBottom:normalize(0.8, width),
            textDecorationLine:'none', 
            fontSize:normalize(1.3, width), 
            color:'white',
            backgroundColor:orange,
            fontWeight:'bold',
            fontFamily: 'sans-serif'
        },
        reportOption:{
            paddingLeft:normalize(3, width), 
            paddingRight:normalize(2, width),
            paddingTop:normalize(0.6, width),
            paddingBottom:normalize(0.6, width),
            textDecorationLine:'none', 
            fontSize:normalize(1, width), 
            color:'black',
            fontFamily: 'sans-serif',
        },
        reportOptionClick:{
            paddingLeft:normalize(3, width), 
            paddingRight:normalize(2, width),
            paddingTop:normalize(0.8, width),
            paddingBottom:normalize(0.8, width),
            textDecorationLine:'none', 
            fontSize:normalize(1, width), 
            color:white,
            fontFamily: 'sans-serif',
            backgroundColor:orange

        },
        iconSize0:normalize(0.7,width),
        iconSize1:normalize(1.5,width),
        iconSize2:normalize(2,width),
        iconSize3:normalize(3, width),
        buttonIcon: {
            width:normalize(3,width),
            height:normalize(3,width),
            justifyContent:'center',
            borderRadius: normalize(0.20, width),
        }
        
    }
}

export const style = (width) => StyleSheet.create({
    background: {
        backgroundColor: white,
        height: '100%',
        width: width*0.98,
        flexDirection:'column'
    },
    //login & changepassword background
    background2:{
        backgroundColor:'#E8E8E8', 
        width:width,
        justifyContent:'center',
        alignItems:'center'
    },
    //container beside the navbar
    bigContainer:{
        top: 0,
        left: normalize(15, width),
        width:normalize(83, width),
    },

    //report dropdown button
    report_dropdown_icon:{
        margin:'auto',
        paddingRight:normalize(1.5,width)
    },

    //only for login
    login_container1:{
        paddingHorizontal: normalize(15, width),
        maxWidth: width
    },
    login_container2:{
        width: normalize(40, width),
        height: normalize(37, width),
        margin: normalize(10, width),
        marginTop: normalize(5, width),
        marginBottom: 0,
        padding: normalize(2, width),
        paddingTop: normalize(1, width),
        backgroundColor: 'white',
        justifyContent: 'center',
        alignContent: 'center',
        alignSelf: 'center',
        shadowColor: grey2,
        shadowOffset: { width: 0, height: 10 },
        shadowOpacity: 0.4,
        shadowRadius: 10,
        overflow: 'visible',
        elevation: 14,
    },
    change_pw_container:{
        height: normalize(40, width),
        margin:'auto'
    },

    //login logo
    login_logo:{
        width: normalize(19, width), 
        height: normalize(12,width), 
        margin: 'auto', 
        marginTop: 0, 
        marginBottom: 0
    },
    login_text:{
        textAlign: 'left', 
        width: normalize(20,width), 
        height: normalize(1,width), 
        marginHorizontal: 'auto', 
        marginBottom: 0
    },
    login_textinput:{
        padding: normalize(1.5,width), 
        width: normalize(20,width), 
        height: normalize(2, width),
        borderColor: black,
        borderWidth: 1, 
        marginHorizontal: 'auto',
        marginTop:normalize(1,width),
        marginBottom:normalize(1,width),
    },
    login_btn:{
        width: normalize(20,width), 
        height: normalize(3, width),
        backgroundColor: orange, 
        justifyContent: 'center', 
        alignItems: 'center', 
        margin: 'auto', 
        marginVertical: 10,
        borderRadius: normalize(0.20, width),
    },

    //overlay
    overlay_container:{
        maxWidth:normalize(50, width),
        maxHeight:normalize(50,width)
    },

    //container inside the big container
    container:{
        flexDirection:'column',
        width:'95%',
        justifyContent: 'flex-start', 
        margin:normalize(2, width)
    },

    // container
    container_row:{
        flexDirection:'row', 
        justifyContent:'flex-end'
        // margin:normalize(20, width)
    },
    container_row2:{
        flexDirection:'row', 
        justifyContent:'flex-start'
    },

    //container
    container_column:{
        flexDirection:'column',
        zIndex:50
    },

    //small container for search input
    scontainer:{
        margin:normalize(0.3, width), 
        flex:1,
        justifyContent:'flex-start'
    },
    scontainer2:{
        margin:normalize(0.3, width), 
        flex:1,
        justifyContent:'flex-start',
        flexDirection:'column',
        height:normalize(7,width)
    },

    // for create page container
    container_create_col:{
        padding:normalize(1.5, width), 
        flexDirection:'column', 
        flex:1
    },
    container_create_row:{
        flex:1, 
        flexDirection:'row',
        marginHorizontal:normalize(1.5, width),
        marginTop:normalize(1.5, width),
        justifyContent:'flex-start',
    },

    container_create_row_do:{
        // flex:1, 
        flexDirection:'row',
        marginHorizontal:normalize(1.5, width),
        marginTop:normalize(1.5, width),
        justifyContent:'flex-start',
        // height:normalize(5,width)
    },
    container_2_flex:{
        flex:2
    },
    container_1_flex:{
        flex:1
    },

    //for SO view
    container_view_col:{
        flexDirection:'column',
        width:normalize(20, width)
    },
    //SO view textbox
    container_view_tb:{
        borderColor:grey4, 
        borderWidth:1, 
        height:normalize(3.1, width), 
        padding:normalize(0.8, width),
        backgroundColor:white,
        width:normalize(40,width)
    },
    container_view_tb_short:{
        borderColor:grey4, 
        borderWidth:1, 
        height:normalize(3.1, width), 
        padding:normalize(0.8, width),
        backgroundColor:white,
        width:normalize(10,width)
    },
    text_view:{
        width:normalize(12,width)
    },
    button_view:{
        alignItems:'flex-end'
    },
    // container_view_row:{
    //     flex:1, 
    //     flexDirection:'row', 
    //     margin:normalize(1.5, width),
    //     // justifyContent:'space_between',
    // },

    text_create:{
        flex:1,
        paddingRight: normalize(1, width),
    },
    // overlay_container:{
    //     width: normalize(95, width),
    //     height:normalize(95,width)
    // },

    //container for the search&refresh&create icon (space between)
    container_icon:{
        width:normalize(0.5, width),
        height:normalize(0.5,width)
    },
    container_icon2:{
        width:normalize(2.5, width),
        height:normalize(2.5,width)
    },

    // container for search input
    container_search_input:{
        backgroundColor:'#fcfcfc', 
        borderColor:'black', 
        borderWidth:1, 
        marginVertical:normalize(0.1,width)
    },

    //data table
    dataTable:{
        margin:normalize(50, width),
    },
    dataTable_header:{
        // top:0,
        // // height:100,
        // // flex:2,
        // height:normalize(5, width), 
        // borderBottomWidth:0,
        // alignItems:'center'

        //if change to view
        flex:1,
        // justifyContent:'center',
        alignContent:'center',
        paddingLeft:normalize(0.1,width)
        // justifyContent:'flex-end'
        
        
        
    },
    dataTable_header2:{
        // height:normalize(6.8,width), 
        flex:2,
        borderBottomWidth:0,
        zIndex:10,
        marginTop:normalize(0.3,width)
    },
    dataTable_title_text:{
        fontSize:normalize(15, width),
        flexShrink:1        
    },
    dataTable_title:{
        justifyContent:'center'
    },
    dataTable_content:{
        padding:normalize(0.5,width),
        fontSize:normalize(1,width),
        flex:1,
        justifyContent:'center'
    },
    dataTable_content_billing:{
        padding:normalize(0.5,width),
        paddingRight: 0,
        fontSize:normalize(1,width),
        flex:1,
        justifyContent:'center'
    },
    dataTable_content_flex:{
        flexShrink:1,
        fontSize:normalize(1,width),
        alignContent:'center'  
    },
    dataTable_row:{
        justifyContent:'center',
        flex:1
    },
    dataTable_header_border:{
        borderBottomColor:black,
        height:normalize(2.5,width),
        alignItems:'center'
    },
    xscontainer:{
        width:normalize(0.25,width),
        height:normalize(0.25,width)
    },

    // button style (react native paper, width)
    button_orange:{
        flex:1, 
        backgroundColor:orange, 
        marginTop:normalize(0.5, width),
        marginHorizontal:normalize(0.8, width),
        marginBottom:normalize(0.5, width),
        justifyContent:'center',
        borderRadius: normalize(0.20, width),
        height:normalize(4, width)
    },

    button_label_white:{
        color:white,
        padding:normalize(0.8,width)
    },
    tab_style:{
        backgroundColor:orange,
        height: normalize(4, width),
        justifyContent:'center'
    },
    picker:{
        paddingLeft:normalize(0.5, width)
    },
    //overlay
    overlayBackdrop:{
        opacity: 0.85, 
        backgroundColor:"black"
    },
    overlay:{
        backgroundColor: "transparent", 
        elevation: 0, 
        shadowOpacity: 0
    },

    //message box
    msg_box_title:{
        marginVertical: normalize(1, width),
        marginHorizontal: normalize(3, width),
        textAlign: 'center',
        fontSize: normalize(2.2, width),
        fontWeight:'bold'
    },
    msg_box_message:{
        marginBottom: normalize(1, width),
        marginHorizontal: normalize(3, width),
        textAlign: 'center' || 'justify',
        fontSize: normalize(1.5, width),
    },

    // search icon
    search_icon_pressable:{
        flexDirection:'row',
        justifyContent:'flex-end'
    },
    search_icon:{
        width:normalize(40, width),
        height:normalize(40, width)
    },


    // for pagination part
    // all container
    container_first:{
        flexDirection:'row', 
        justifyContent:'space-between'
    },
    container_second:{
        flexDirection:'row', 
        alignSelf:'flex-start', 
        marginTop:normalize(1.5, width)
    },
    container_third:{
        flexDirection:'row', 
        marginTop:normalize(1.5, width), 
        alignItems:'center'
    },
    // all text
    text:{
        fontSize:normalize(15,width), 
        fontFamily: 'sans-serif'
    },
    text_grey:{ 
        color:grey3,
        fontFamily: 'sans-serif'
    },
    text_total_item:{
        marginTop:normalize(2.5, width), 
    },
    text_page:{
        marginHorizontal:normalize(0.8, width),
        alignSelf:'center',
    },
    text_current_page:{
        color:'black',
        fontFamily: 'sans-serif'
    },

    //pagination button
    paginationButton:{
        padding:normalize(1, width),
        backgroundColor:orange,
        width:normalize(2, width),
        height:normalize(2, width),
        borderWidth:1,
        borderColor:white,
        justifyContent:'center',
        alignItems:'center',
        color:black,
        fontWeight:'bolder',
    },
    paginationButtonDisabled:{
        padding:normalize(1, width),
        backgroundColor:grey5,
        width:normalize(2, width),
        height:normalize(2, width),
        borderWidth:1,
        borderColor:white,
        justifyContent:'center',
        alignItems:'center',
        color:black,
        fontWeight:'bolder',
    },

    //prev & next arrow image style
    pagination_arrow:{
        width:normalize(1.5, width), 
        height:normalize(1.5, width), 
        tintColor:'white'
    },
    disableTint:{
        tintColor:grey4
    },
    //go to page text input
    go_to_input:{
        width:normalize(3.1, width), 
        height:normalize(2.5, width), 
        borderWidth:1, 
        borderRadius:2, 
        padding:normalize(0.4, width),
        
    },


    // modal container style
    modal_container:{
        backgroundColor: 'white', 
        padding: normalize(4.5, width), 
        width:normalize(39, width), 
        margin:'auto'
    },

    //tab billing
    text_billing:{
        fontSize: normalize(1.5, width),
        fontWeight:'bold',
        paddingTop:normalize(1,width)
    },
    picker_billing:{
        borderColor:grey4, 
        borderWidth:1, 
        height:normalize(3.1, width), 
        padding:normalize(0.8, width),
        backgroundColor:white,
        width:normalize(5,width)
    },
    picker_billing_add_row:{
      borderColor:grey4, 
      borderWidth:1, 
      height:normalize(2, width), 
      paddingVertical:normalize(0.05, width),
      paddingHorizontal:normalize(0.1, width),
      backgroundColor:white,
      width:normalize(3,width)
    },
    tb_billing:{
        borderColor:grey4, 
        borderWidth:1, 
        height:normalize(3.1, width), 
        padding:normalize(0.8, width),
        backgroundColor:white,
        flex:2
    },

    //client create
    create_col_space:{
        flexDirection:'column', 
        flex:1, 
        // justifyContent:'space-between'
    },
    create_input:{
        borderWidth:normalize(0.15,width), 
        borderColor:grey2, 
        height:normalize(4,width), 
        padding:normalize(0.8,width),
        margin:normalize(0.8,width),
        marginLeft:0
    },
    // for DO address
    do_height:{
        height: normalize(11, width)
    },
    do_width:{
        width: normalize(65, width)
    },
    create_title:{
        fontWeight:'bold'
    },
    create_attribute:{
        fontWeight:'bold',
        alignSelf:'center',
        flexShrink:1
    },
    msg_box_btn:{
        marginTop:normalize(1,width),
        backgroundColor:orange,
        margin:normalize(1, width),
        width:normalize(9.8,width),
        alignSelf:'center', 
        
        height:normalize(3.5,width),
        justifyContent:'center', 
        alignContent:'center', 
        
        
        borderRadius: normalize(0.20, width),
    },
    create_btn:{
        marginTop:normalize(1.5,width),
        backgroundColor:orange, 
        width:normalize(9.8,width), 
        height:normalize(3.5,width),
        justifyContent:'center', 
        alignContent:'center', 
        alignSelf:'flex-end',
        marginRight:normalize(2.4,width),
        borderRadius: normalize(0.20, width),
    },
    create_btn_label:{
        color:white,
        padding:normalize(0.7,width),
        fontSize:normalize(1,width)
    },
    create_btn_red:{
        marginTop:normalize(2,width),
        borderRadius: normalize(0.20, width),
        backgroundColor:'#F70D1A', 
        width:normalize(9.8,width), 
        height:normalize(3.5,width),
        justifyContent:'center', 
        alignContent:'center', 
        alignSelf:'flex-end',
        marginRight:normalize(2,width)
    },

    change_pw_btn:{
        marginTop:normalize(2,width),
        backgroundColor:orange, 
        width:normalize(15,width), 
        height:normalize(3.5,width),
        justifyContent:'center', 
        alignContent:'center', 
        alignSelf:'flex-end',
        marginRight:normalize(1,width),
        borderRadius: normalize(0.20, width),
    },
    change_pw_btn_label:{
        color:white,
        padding:normalize(0.3,width),
        fontSize:normalize(1,width)
    },
    grey_background:{
        backgroundColor:grey1
    },



    navContainer:{
        backgroundColor:grey0,
        left:0,
        width:normalize(15, width),
        height:'100%',
        position: 'fixed',
        zIndex: 5,
    },
    navLogo:{
        width:normalize(10, width), 
        height:normalize(5, width), 
        marginTop:normalize(1, width),
        marginVertical:normalize(1, width),
        alignSelf:'center'
    },
    header: {
        fontSize: normalize(3, width),
        margin: normalize(3, width),
    },
    searchInput_so:{
        height:normalize(6, width),
    },
    searchInput:{
        borderColor:grey4, 
        borderWidth:1, 
        height:normalize(3.1, width), 
        padding:normalize(0.8, width),
        backgroundColor:white,
        // flex:1
    },
    textBox:{
      height:normalize(3.1, width), 
      padding:normalize(0.8, width),
      backgroundColor:white,
  },
    report_searchInput:{
        borderColor:grey4, 
        borderWidth:1, 
        height:normalize(3.1, width), 
        padding:normalize(0.8, width),
        backgroundColor:white,
    },
    report_attribute:{
        fontWeight:'bold',justifyContent:'center', alignSelf:'center', width:normalize(8, width)
    },
    button:{
        padding:10,
        backgroundColor:orange,
        width:100,
        height:40,
    },
    overlayCloseButton: {
        right: 0, 
        top: 0, 
        position:'absolute', 
        zIndex:100
    },
    overlayButton1: {
        right: normalize(10, width), 
        top: 0, 
        position:'absolute', 
        zIndex:100
    },


    hover:{
        borderColor: orange,
        borderWidth: normalize(0.2, width),
    },
    table_row:{
        backgroundColor:'white',
        borderColor: 'white',
        borderWidth: normalize(0.2, width),
    },
    table_row_deleted:{
        backgroundColor:"#FFE4E4",    
    },
    table_row_completed:{
        backgroundColor:"#E5E5E5",
    },
    table_row_unlock_request:{
        backgroundColor:"#F6FAAF"
    },
    // searchInput:{
    //     borderColor:grey4, 
    //     borderWidth:1, 
    //     height:normalize(3.1, width), 
    //     padding:normalize(0.8, width),
    //     backgroundColor:white,
    //     flex:1
    // },
    from_and_to:{
        borderColor:grey4, 
        borderWidth:1,  
        height:normalize(2.7,width), 
        padding:normalize(0.8, width),
        backgroundColor:white,
        // flex:1,
        justifyContent:'center',
        marginTop:0,
        marginVertical:normalize(0.2, width)
        
    },
    dropDownContainer:{
        backgroundColor:'white',
        borderWidth:normalize(0.005,width),
        padding:normalize(0.5,width),
        zIndex:10,
        borderRadius:0,
        maxHeight: normalize(20,width),
        shadowRadius: normalize(0.4,width),
        shadowColor: 'rgba(0,0,0,0.3)',
        borderColor: 'black',
        overflow: 'visible',
    },
    fontSize1: { fontSize: normalize(0.7, width) },
    fontSize2: { fontSize: normalize(1  , width) },
    fontSize3: { fontSize: normalize(1.5, width) },
    fontSize4: { fontSize: normalize(2  , width) },

    fontError: { color: 'red' }

})