import React, { useState, useEffect, useRef } from 'react';
import { Image, Picker, View, Text, StyleSheet, TextInput, Pressable } from 'react-native';
import axios from 'axios';
import { API_URL, BASE_URL } from '../config/config';
import { ITEM_PER_TABLE_PAGE } from '../constants';
import { DataTable, Button as ButtonPaper} from 'react-native-paper';
import { style, option } from '../styles/style.js';
import { useHover, Hoverable } from 'react-native-web-hooks';

import prev from '../assets/prev-arrow.png';
import next from '../assets/next-arrow.png';
import { Button, Icon } from 'react-native-elements';
import { getWebsiteWidth } from '../components/utilities';
import { Popable } from 'react-native-popable';

function Staff_List() {
    const [WIDTH, set_WIDTH] = useState(getWebsiteWidth());

    const initialStaff = [{
        userID:'',
        roleID:'',
        name:'',
        email:'',
        status:'',
        is_deleted:'',
        contact:''
    }]

    const [staff, setStaff] = useState(initialStaff);
    const [staffList, setStaffList] = useState([]);

    // for searching
    const [showSearch, setShowSearch] = useState(0);
    const [searchParams, setSearchParams] = useState([]);
    
    // for pagination
    const [tablePage, setCurrentPage] = useState({totalPages:0, currentPage:0});
    const [totalItems, setTotalItems] = useState(0);
    const [isFirst, setFirst] = useState(1);
    const [isLast, setLast] = useState(0);

    const isHovered = useHover(ref);
    const ref = useRef(null);
    const edit_director_admin = window.localStorage.getItem('edit_director_admin');
    const [isAdmin, setAdmin] =  useState(false);

    let pageTimer;

    // redirect to edit page when click the row
    const handleClickRow = (id, roleID) => {
        console.log('editable: ' + edit_director_admin);
        if(roleID == 0 || roleID == 3){
            if(edit_director_admin == 1){
                window.location.href = `${BASE_URL}staff/${id}`
            }else{
                MessageBox("Error", "You are not able to edit.");
            }
        }else{
            window.location.href = `${BASE_URL}staff/${id}`
        }
    };
    
    const getStaff = async(params) => {
        let queryParams = {};
        for(const sp in searchParams){
            if(searchParams[sp] != '')
                queryParams['filter[' + sp + ']'] = searchParams[sp];
        }
        
        queryParams['size'] = ITEM_PER_TABLE_PAGE;
        queryParams['page'] = 0;
        if(params && params.page){
            queryParams['page'] = params.page;
        }

        window.API_CALL_GET('user', queryParams).then(
          function(data) {        
            let staffData = data.dataList;
            let numOfStaff = staffData.length;
            let totalPage = data.totalPages;
            let currentPage = data.currentPage;

            setTotalItems(data.totalItems);
            setCurrentPage({totalPages: totalPage, currentPage: currentPage});

            (currentPage+1) == totalPage? setLast(1):setLast(0);
            currentPage == 0? setFirst(1):setFirst(0);
            if(numOfStaff == 0) setStaff(initialStaff);
            let all_staff = [];

            for(let a = 0; a < numOfStaff; a++){
                let obj = {
                    userID:staffData[a].userID,
                    roleID:staffData[a].roleID,
                    name:staffData[a].name,
                    contact:staffData[a].contact,
                    email:staffData[a].email,
                    status:staffData[a].status,
                    is_deleted:staffData[a].is_deleted   
                };
                all_staff.push(obj);
                // a>0? setStaff(oldStaff => [...oldStaff, obj]):setStaff([obj]);
            }
            setStaff(all_staff);
            
          },
          function(error) { MessageBox("Error", error); }
        );
    }

    useEffect(() => {
        let stafflist = staff.map((localState)=>{
            let role;
            switch(localState.roleID){
                case 0:
                    role = 'Admin';
                    break;
                case 1:
                    role = 'Staff';
                    break;
                case 2:
                    role = 'Manager';
                    break;
                case 3:
                    role = 'Director';
                    break;
                default:
                    role = '';
                    break;
            }
            return(
                localState.is_deleted && !isAdmin? null:(
                    <Hoverable key={localState.userID}>
                    {isHovered => (
                        <Pressable key={localState.userID} onPress={() => handleClickRow(localState.userID, localState.roleID)} 
                        style={[style(WIDTH).table_row, isHovered && style(WIDTH).hover, localState.is_deleted && style(WIDTH).table_row_deleted]}>
                        <DataTable.Row key={localState.userID}>
                                <View style={style(WIDTH).dataTable_content}><Text style={style(WIDTH).dataTable_content_flex}>{localState.name || '-'}</Text></View>
                                <View style={style(WIDTH).dataTable_content}><Text style={style(WIDTH).dataTable_content_flex}>{localState.email || '-'}</Text></View>
                                <View style={style(WIDTH).dataTable_content}><Text style={style(WIDTH).dataTable_content_flex}>{localState.contact || '-'}</Text></View>
                                <View style={style(WIDTH).dataTable_content}><Text style={style(WIDTH).dataTable_content_flex}>{role|| '-'}</Text></View>
                                <View style={style(WIDTH).dataTable_content}><Text style={style(WIDTH).dataTable_content_flex}>{localState.status? 'Active':'Inactive' || '-'}</Text></View>
                            </DataTable.Row>
                        </Pressable>
                    )}
                    </Hoverable>
                )
            )     
        })

        setStaffList(stafflist);
    },[staff, WIDTH]);

    useEffect(() => {
        window.localStorage.getItem('role') != 1? setAdmin(true): window.location.href = `${BASE_URL}`;
        window.addEventListener('resize', () => { set_WIDTH(getWebsiteWidth()); });
        onLoad();
    }, [])

    const onLoad = () => {      
        getStaff();
    }
    
    const [Name, setName] = useState('');
    const [Contact, setContact] = useState('');
    const [Email, setEmail] = useState('');
    const [RoleID, setRoleID] = useState('');
    const [Status, setStatus] = useState('');

    const clearFilter = () => {
        setName('');
        setContact('');
        setEmail('');
        setRoleID('');
        setStatus('');
        setSearchParams([]);
    }

    const onChangeValue = (key,value) => {
        searchParams[key] = value;
    }

    const onChangePage = (value) => {
        if(value != '' && (value <= tablePage.totalPages) && value > 0){
            clearTimeout(pageTimer);
            pageTimer = setTimeout(() => {          
                getStaff({ page:value-1 });
            }, 800);
        }
    }

    const previousPage = () => {
        if(tablePage.totalPages > 1) {
            tablePage.currentPage = tablePage.currentPage - 1;
            setLast(0);
            tablePage.currentPage == 0? setFirst(1):setFirst(0)
            getStaff({ page:tablePage.currentPage });
        }
    }

    const nextPage = () => {
        if(tablePage.totalPages > 1) {
            tablePage.currentPage = tablePage.currentPage + 1;
            setFirst(0);
            (tablePage.currentPage+1) == tablePage.totalPages? setLast(1):setLast(0)
            getStaff({ page:tablePage.currentPage });
        }
    }

    return (
        <View style={style(WIDTH).container} >
            <DataTable style={{zIndex:1}}>
                <View style={style(WIDTH).container_row}>
                    <Popable content='Refresh' position="left" backgroundColor='#FF9F1D' action="hover"> 
                    <Button icon={<Icon name='refresh' type="simplelineicons" size={option(WIDTH).iconSize2}/>} type="clear" style={{padding: '5%'}} 
                        onPress={getStaff}/></Popable>
                    <View style={style(WIDTH).container_icon}></View>
                    <Popable content='Search' position="left" backgroundColor='#FF9F1D' action="hover"> 
                    <Button icon={<Icon name='search1' type="antdesign" size={option(WIDTH).iconSize2}/>} type="clear" style={{padding: '5%'}}
                        onPress={()=>setShowSearch(!showSearch)}/></Popable>
                    <View style={style(WIDTH).container_icon}></View>
                    <Popable content='Create' position="left" backgroundColor='#FF9F1D' action="hover"> 
                    <Button icon={<Icon name='addfile' type="antdesign" size={option(WIDTH).iconSize2}/>} type="clear" style={{padding: '5%'}}
                        onPress={()=>{ window.location.href = `${BASE_URL}staff_create`}}/></Popable>
                </View>

                <View style={[style().container_column, {marginTop:'2%'}]}>
                    { showSearch? 
                        (<View style={style(WIDTH).container_search_input}>
                            <ButtonPaper
                                style={style(WIDTH).button_orange} 
                                labelStyle={style().button_label_white} 
                                onPress={clearFilter}
                            >
                                <Text style={style(WIDTH).fontSize2}>Clear Filter</Text>
                            </ButtonPaper>
                            <DataTable.Header style={style(WIDTH).dataTable_header2}>
                                <View style={style(WIDTH).scontainer}>
                                    <TextInput
                                        style={[style(WIDTH).searchInput,style(WIDTH).fontSize2]}
                                        value={Name}
                                        onChangeText={(value) => {
                                            onChangeValue("name",value);
                                            setName(value);
                                        }}
                                        placeholder="Name"
                                    />
                                </View>
                                
                                <View style={style(WIDTH).scontainer}>
                                    <TextInput
                                        style={[style(WIDTH).searchInput,style(WIDTH).fontSize2]}
                                        value={Email}
                                        onChangeText={(value) => {
                                            onChangeValue("email",value);
                                            setEmail(value);
                                        }}
                                        placeholder="Email"
                                    />
                                </View>

                                <View style={style(WIDTH).scontainer}>
                                    <TextInput
                                        style={[style(WIDTH).searchInput,style(WIDTH).fontSize2]}
                                        value={Contact}
                                        onChangeText={(value) => {
                                            onChangeValue("contact",value);
                                            setContact(value);
                                        }}
                                        placeholder="Contact"
                                    />
                                </View>

                                <View style={style(WIDTH).scontainer}>
                                    <Picker
                                        style={[style(WIDTH).searchInput,style(WIDTH).fontSize2,{padding:1}]}
                                        selectedValue={RoleID}
                                        onValueChange={(itemValue, itemIndex)=>{
                                            let id = itemIndex == 0?'':itemIndex-1;
                                            onChangeValue("roleID", id.toString());
                                            setRoleID(itemValue);
                                        }}
                                    >
                                        <Picker.Item label='Role' value='' />
                                        <Picker.Item label='Staff' value='Staff' />
                                        <Picker.Item label='Manager' value='Manager' />
                                        <Picker.Item label='Director' value='Director' />
                                    </Picker>
                                </View>

                                <View style={style(WIDTH).scontainer}>
                                    <Picker
                                        style={[style(WIDTH).searchInput,style(WIDTH).fontSize2,{padding:1}]}
                                        selectedValue={Status}
                                        onValueChange={(itemValue)=>{
                                            onChangeValue("status",itemValue);
                                            setStatus(itemValue);
                                        }}
                                    >
                                        <Picker.Item label='Status' value='' />
                                        <Picker.Item label='Inactive' value='0' />
                                        <Picker.Item label='Active' value='1' />
                                    </Picker>
                                </View>
                            </DataTable.Header>
                            <ButtonPaper 
                                style={style(WIDTH).button_orange} 
                                labelStyle={style().button_label_white}  
                                onPress={() => {getStaff();}}> 
                                    <Text style={style(WIDTH).fontSize2}>Search</Text>
                            </ButtonPaper>
                        </View>):null
                    }
                </View>
                
                <DataTable.Header style={[style(WIDTH).dataTable_header_border, {marginTop:'2%'}]}>
                {/* <View style={{flexDirection:'column'}}><Text style={{textAlign:'center'}}>Name</Text><Text>Hi</Text></View> */}
                    <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content, {fontWeight:'bold'}]}>Name</Text></View>
                    <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content, {fontWeight:'bold'}]}>Email</Text></View>
                    <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content, {fontWeight:'bold'}]}>Contact</Text></View>
                    <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content, {fontWeight:'bold'}]}>Role</Text></View>
                    <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content, {fontWeight:'bold'}]}>Status</Text></View>
                </DataTable.Header>
                
                {staffList? staffList:null}

                <View style={style().container_first}>
                    <Text style={[style(WIDTH).text_grey, style(WIDTH).text_total_item, style(WIDTH).fontSize2]}>{`Total items: ${totalItems}`}</Text>
                    <View style={style(WIDTH).container_second}>
                        <Pressable 
                            style={isFirst? style(WIDTH).paginationButtonDisabled:style(WIDTH).paginationButton} 
                            onPress={previousPage} 
                            disabled={isFirst}
                        >
                            <Image source={prev} resizeMode='contain' style={[style(WIDTH).pagination_arrow,isFirst && style().disableTint]} />
                        </Pressable>
                        
                        <Text style={[style(WIDTH).text_grey,style(WIDTH).text_page, style(WIDTH).fontSize2]}>
                            {`Page `}
                            <Text style={style().text_current_page, style(WIDTH).fontSize3}>{`${tablePage.currentPage+1}`}</Text>
                            {` of ${tablePage.totalPages}`}
                        </Text>
                        
                        <Pressable 
                            style={isLast? style(WIDTH).paginationButtonDisabled: style(WIDTH).paginationButton} 
                            onPress={nextPage}
                            disabled={isLast}
                        >
                            <Image source={next} resizeMode='contain' style={[style(WIDTH).pagination_arrow,isLast && style().disableTint]} />
                        </Pressable>
                    </View>
                
                    <View style={style(WIDTH).container_third}>
                        <Text style={[style(WIDTH).text_grey,style(WIDTH).text_page, style(WIDTH).fontSize2]}>Go to page: </Text>
                        <TextInput 
                            style={[style(WIDTH).fontSize2, style(WIDTH).go_to_input]}
                            onChangeText={(value) => onChangePage(value)}
                            placeholder={`${tablePage.currentPage+1}`}
                        />
                    </View>
                </View>
            </DataTable>
        </View>
    )
}

export default Staff_List
