import React, { useEffect, useState, useCallback, useRef } from 'react'
import { View, StyleSheet, Image, TextInput, Linking, useWindowDimensions, Text, Picker, Pressable, Switch, Dimensions } from 'react-native'
// import { BarChart, Grid, YAxis} from 'react-native-svg-charts';
// import {Text} from 'react-native-svg';
import { API_URL, BASE_URL } from '../config/config';
import axios from 'axios';
import { Button, DataTable } from 'react-native-paper';
import { TabView, SceneMap, TabBar } from 'react-native-tab-view';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import { LineChart, BarChart } from 'react-native-chart-kit';
import ReactExport from 'react-export-excel';
import DropDownPicker from 'react-native-dropdown-picker';
import { style } from '../styles/style';
import { en, registerTranslation, DatePickerModal } from 'react-native-paper-dates';
registerTranslation('en', en);
import { useHover } from 'react-native-web-hooks';
import { Hoverable } from 'react-native-web-hooks';

import prev from '../assets/prev-arrow.png';
import next from '../assets/next-arrow.png';
import search from '../assets/search-icon.png';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const token = window.localStorage.getItem('token');
const headers = {
    Authorization: `Bearer ${token}` 
}

const label = ["Jan","Feb","Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];

let currentYear = new Date().getFullYear();
let lastYear = (parseInt(currentYear) - 1).toString();
let lastTwoYear = (parseInt(lastYear) - 1).toString();
let yearList = [lastTwoYear, lastYear, currentYear];

const YearList = yearList.map((item)=>( <Picker.Item key={item} label={item} value={item} /> ));

const Download = (props) => {
    // console.log('props data');
    // console.log(props.data);

    return (
        <ExcelFile filename={props.filename} element={<button>Excel</button>}>
            
            <ExcelSheet data={props.data} name={props.name}>
                <ExcelColumn label="Month" value="month"/>
                <ExcelColumn label="Value (RM)" value={(col)=>(col.RM? col.RM:'-')}/>
                <ExcelColumn label="Value (EUR)" value={(col)=>(col.EUR? col.EUR:'-')}/>
                <ExcelColumn label="Value (USD)" value={(col)=>(col.USD? col.USD:'-')}/>
                <ExcelColumn label="Value (SGD)" value={(col)=>(col.SGD? col.SGD:'-')}/>
            </ExcelSheet>
        </ExcelFile>
    );

}

function TabCompany({title, fillShadowGradient, data}){
    /*
        1. get current year, last year & last 2 year
        2. request get profit loss using the current year (reponse = profitLossData)
        3. when picker year change -> request again
        4. when profitLossData change -> format the graph data -> change the graph data (plGraphData)
    */
    //console.log('Tab Company');

    const [year, setYear] = useState(currentYear);
    const [profitLossData, setPLData] = useState([]);
    const [plGraphData, setPLGraphData] = useState([]);
    const [excelData, setExcelData] = useState([]);

    useEffect(async()=>{
        // console.log('Year Change');
        ShowLoading();
        await getProfitLoss(year);
        HideLoading();
    },[year]);

    useEffect(() => {
        // console.log('Profit Loss Data Change');
        // console.log(profitLossData);
        let dollarUSLocale = Intl.NumberFormat('en-US');
        let data = [];
        let exceldt = [];``
        for(let a=0; a<profitLossData.length;a++){
            let obj = {month: label[a]}
            if(profitLossData[a].gross_profit.length>0){
                for(let b=0; b<profitLossData[a].gross_profit.length; b++){
                    let key = profitLossData[a].gross_profit[b].currency;
                    let value =  profitLossData[a].gross_profit[b].value;
                    
                    let finalValue = dollarUSLocale.format(value);
                    obj[key] = finalValue;
                }
            }
            // else{
            //     currency.map(item=>{
            //         obj[item] = '-';
            //     })
            // }
            // console.log(obj);
            exceldt.push(obj);
        }
        setExcelData(exceldt);

        profitLossData.map(item=>{
            if(item.gross_profit.length > 0){
                item.gross_profit.map(pl => {
                    if(pl.currency == 'RM'){
                        data.push(pl.value);
                    }
                })
            }else{
                data.push(0);
            }
            
        })

        // console.log(data);
        setPLGraphData(data);
    }, [profitLossData]);

    const getProfitLoss = async(year) => {
        console.log(`year: ${year}`)
        let url = API_URL + 'report/pl';
        let params = {
            "year":year,
        }

        const res = await axios({
            method:"GET",
            url:url,
            headers:headers,
            params:params
        });

        if(res.status === 200){
            // console.log('Profit Loss');
            // console.log(res);
            setPLData(res.data.result);
        }

    }

    if(plGraphData.length > 0){
        const bar_data = {
            labels:label,
            datasets:[{data:plGraphData}]
        };

        return(
            <View style={{flexDirection:'column'}}>
                <Picker 
                    selectedValue={year}
                    onValueChange={itemValue => setYear(itemValue)}
                >
                    {YearList}
                </Picker>

                <BarChart
                    data={bar_data}
                    backgroundColor={ 'transparent' }
                    hasLegend={true} 
                    width={800}
                    height={220}
                    fromZero={true}
                    withInnerLines={false}
                    showValuesOnTopOfBars={true}
                    // yAxisLabel={'RM'}
                    chartConfig={{
                        backgroundGradientFrom: "#fff",
  backgroundGradientTo: "#fff",
  barPercentage: 0.7,
  height:500,
  fillShadowGradient: `rgba(1, 122, 205, 1)`,
  fillShadowGradientOpacity: 1,
  decimalPlaces: 2, // optional, defaults to 2dp
  color: (opacity = 1) => `rgba(1, 122, 205, 1)`,
  labelColor: (opacity = 1) => `rgba(0, 0, 0, 1)`,

  style: {
    borderRadius: 16,
    fontFamily: "Bogle-Regular",
  },
  propsForBackgroundLines: {
    strokeWidth: 1,
    stroke: "#e3e3e3",
    strokeDasharray: "0",
  },
  propsForLabels: {
    fontFamily: "Bogle-Regular",
  },
                        
                    }}
                    style={{
                        marginVertical: 50,
                        marginHorizontal:50
                        // borderRadius: 16,
                    }}
                    
                />
                <Download data={excelData} name={'Company_Profit_Loss'} filename={`CompanyProfitLoss_${year}`} />
            
            </View>
        )
    }else{
        return null;
    }
}

const TabStaff = ({title, fillShadowGradient, data}) => {
   // console.log('Tab Staff');

    const [year, setYear] = useState(currentYear);
    const [list, setList]= useState([]);
    const [nameList,setNameList] = useState([]);
    const [excelData, setExcelData] = useState([]);
    const [excelKey, setExcelKey] = useState([]);
    const [isOpen, setOpen] = useState(false);
    const [value, setValue] = useState('');
    const [selectedList, setSelected] = useState(null);
    const [filename,setFilename] = useState(`StaffReport_${year}`);
    
    let isMounted = false;
    let windowWidth = Dimensions.get('window').width;
    let WIDTH = windowWidth >= maxWidth? maxWidth:windowWidth;
    let pageTimer;

    const handleResize = () => {
        // if(isMounted){
            windowWidth = Dimensions.get('window').width;
            WIDTH = windowWidth >= maxWidth? maxWidth:windowWidth;
            // console.log(WIDTH);
        // }
    }

    const [showPage, setShowPage] = useState(false);
    const [partOfList, setPartOfList] = useState([]);
    // for pagination
    const [tablePage, setCurrentPage] = useState({totalPages:0, currentPage:1});
    const [totalItems, setTotalItems] = useState(0);
    const [isFirst, setFirst] = useState(1);
    const [isLast, setLast] = useState(0);
    const limit = 10;

    const onChangePage = (value) => {
        if(value != '' && (value <= tablePage.totalPages) && value > 0){
            clearTimeout(pageTimer);
            pageTimer = setTimeout(() => {   
                let goTo = (value-1)*limit;
                tablePage.currentPage = parseInt(value);
                tablePage.currentPage == 1? setFirst(1):setFirst(0);
                tablePage.currentPage == tablePage.totalPages? setLast(1):setLast(0);       
                setPartOfList(list.slice(goTo, (goTo+limit)));
            }, 800);
        }
    }

    const previousPage = () => {
        if(tablePage.totalPages > 1) {
            tablePage.currentPage = tablePage.currentPage - 1;
            setLast(0);
            tablePage.currentPage == 1? setFirst(1):setFirst(0);
            // if(tablePage.currentPage == 0) setFirst(1);
            let from = (tablePage.currentPage-1) * limit;
            let to = from+limit;
            setPartOfList(list.slice(from, to));
        }
    }

    const nextPage = () => {
        if(tablePage.totalPages > 1) {
            tablePage.currentPage = tablePage.currentPage + 1;
            setFirst(0);
            // console.log(tablePage.currentPage);
            // console.log(tablePage.totalPages);
            (tablePage.currentPage) == tablePage.totalPages? setLast(1):setLast(0)
            let from = (tablePage.currentPage-1) * limit;
            let to = from+limit;
            console.log(`from: ${from} to:${to}`)
            setPartOfList(list.slice(from, to));
        }
    }

    const getStaffReport = async() => {
        let url = API_URL + 'report/staffReport';
        let params = {
            "year":year
        }

        const res = await axios({
            method:"GET",
            url:url,
            headers:headers,
            params:params
        });

        if(res.status === 200){
            console.log('Staff Report');
            // console.log(res.data.result);

            let all_staff = res.data.result;
            let staff_name = [];
            let exceldt = [];

            for(let a=0;a<label.length;a++){
                let obj = {Month:label[a]};
                for(let b=0;b<all_staff.length;b++){
                    // console.log(all_staff.t)
                    obj[all_staff[b].name] = all_staff[b].tol_shipment[(a+1).toString()];
                }
                // console.log(obj);
                exceldt.push(obj);
            }
            // console.log(exceldt);
            setExcelKey(Object.keys(exceldt[0]));
            setExcelData(exceldt);
            
            let line_list = all_staff.map((item,index)=>{
                let so_keys = Object.keys(item.tol_shipment);
                let so_data = [];
                staff_name.push({label:item.name,value:index});

                for(let i =0; i<so_keys.length;i++){
                    so_data.push(item.tol_shipment[so_keys[i]]);
                }

                // console.log(so_data);
                let dtset = {
                    labels:label,
                    datasets:[{data:so_data}]
                }
                return(
                    <View key={item.userID}>
                    <Text>{item.name}</Text>
                    <BarChart
                        data={dtset}
                        backgroundColor={ 'transparent' }
                        hasLegend={true} 
                        width={800}
                        height={220}
                        fromZero={true}
                        withInnerLines={false}
                        showValuesOnTopOfBars={true}
                        // yAxisLabel={'RM'}
                        chartConfig={{
                            backgroundGradientFrom: "#fff",
                            backgroundGradientTo: "#fff",
                            barPercentage: 0.7,
                            height:500,
                            fillShadowGradient: `rgba(1, 122, 205, 1)`,
                            fillShadowGradientOpacity: 1,
                            decimalPlaces: 0, // optional, defaults to 2dp
                            color: () => `rgba(1, 122, 205, 1)`,
                            labelColor: () => `rgba(0, 0, 0, 1)`,
                            style: {
                                borderRadius: 16,
                                fontFamily: "Bogle-Regular",
                            },
                            propsForBackgroundLines: {
                                strokeWidth: 1,
                                stroke: "#e3e3e3",
                                strokeDasharray: "0",
                            },
                            propsForLabels: {
                                fontFamily: "Bogle-Regular",
                            },
                        }}
                    />
                    </View>
                )                
            })
            // console.log(staff_name)
            setList(line_list);
            setNameList(staff_name);
        }
    }

    useEffect(()=>{
        getStaffReport();
        setFilename(`StaffReport_${year}`);
    },[year])

    const onChange = (index) =>{
        if(index!=undefined){
            setSelected(list[index]);
        }
    }

    useEffect(()=>{
        if(list.length > limit && selectedList == null){
            console.log('sales bigger than limit');
            let totalpage = Math.ceil(list.length/limit);
            tablePage['totalPages'] = totalpage;
            setFirst(1);
            setLast(0);
            setShowPage(true);
            setPartOfList(list.slice(0,limit));
        }else{
            setPartOfList(list)
        }
    },[list]);

    useEffect(()=>{
        if(selectedList!=null) setShowPage(false);
    },[selectedList])

    useEffect(()=>{
        console.log('change');
        // console.log(selectedList);
    },[partOfList])


    return(
        <View style={{flexDirection:'column'}}>
            <Pressable onPress={()=>{
                setValue();
                setYear(currentYear);
                setCurrentPage({totalPages:0, currentPage:1});
                setSelected(null);
                getStaffReport();

            }}><Text>Refresh</Text></Pressable>
        <Picker 
            selectedValue={year}
            onValueChange={itemValue => setYear(itemValue)}
        >
            {YearList}
        </Picker>

        <ExcelFile filename={filename} element={<button>Excel</button>}>    
            <ExcelSheet data={excelData} name={`staff_report_${year}`}>
                {
                    excelKey.map((key, index)=>(
                        <ExcelColumn key={index} label={key} value={key} />
                    ))
                }
            </ExcelSheet>
        </ExcelFile>  

        <DropDownPicker
            open={isOpen}
            value={value}
            items={nameList}
            setOpen={setOpen}
            setValue={setValue}
            searchable={true}
            closeOnBackPressed={true}
            style={[{paddingLeft:10,width:100,height:50}]}
            // dropDownContainerStyle={style(windowWidth).dropDownContainer}
            listMode="SCROLLVIEW"
            showTickIcon={false}
            placeholder=""
            showArrowIcon={false}
            searchPlaceholder="Search staff..."
            onChangeValue={(value) => { onChange(value) }}
        /> 

        {selectedList? selectedList:partOfList}

        {showPage? (<View style={{flexDirection:'row', justifyContent:'space-between'}}>
                    <Text style={{marginTop:30, fontSize:15, color:'#505050',fontFamily: 'sans-serif'}}>{`Total items: ${list.length}`}</Text>
                    <View style={{flexDirection:'row', alignSelf:'flex-end', marginTop:20}}>
                        <Pressable 
                            style={isFirst? style(windowWidth).paginationButtonDisabled:style(windowWidth).paginationButton} 
                            onPress={previousPage} 
                            disabled={isFirst}
                        >
                            <Image source={prev} resizeMode='contain' style={[{width:20, height:20, tintColor:'white'},isFirst && {tintColor:'#808080'}]} />
                        </Pressable>
                        
                        <Text style={{marginHorizontal:10,alignSelf:'center',color:'#505050',fontFamily: 'sans-serif'}}>
                            {`Page `}
                            <Text style={{fontSize:20, color:'black',fontFamily: 'sans-serif'}}>{`${tablePage.currentPage}`}</Text>
                            {` of ${tablePage.totalPages}`}
                        </Text>
                        
                        <Pressable 
                            style={isLast? style(windowWidth).paginationButtonDisabled: style(windowWidth).paginationButton} 
                            onPress={nextPage}
                            disabled={isLast}
                        >
                            <Image source={next} resizeMode='contain' style={[{width:20, height:20, tintColor:'white'},isLast && {tintColor:'#808080'}]} />
                        </Pressable>
                    </View>
                
                    <View style={{flexDirection:'row', marginTop:20, alignItems:'center'}}>
                        <Text style={{alignSelf:'center',color:'#505050',fontFamily: 'sans-serif', marginRight:5}}>Go to page: </Text>
                        <TextInput 
                            style={{width:40, height:30, borderWidth:1, borderRadius:2, padding:5}} 
                            onChangeText={(value) => onChangePage(value)}
                            placeholder={`${tablePage.currentPage}`}
                        />
                    </View>
                </View>):null}

        </View>
    )
};

const TabSales = ({title, fillShadowGradient, data}) => {
    // console.log('Tab Sales');

    const [year, setYear] = useState(currentYear);
    const [nameList,setNameList] = useState([]);
    const [excelData, setExcelData] = useState([]);
    const [isOpen, setOpen] = useState(false);
    const [value, setValue] = useState('');
    const [filename,setFilename] = useState(`SalesPerson_${year}`);
    const [profit,setProfit] = useState(true);
    const [totalSales, setTotalSales] = useState(false);
    const [profitList, setProfitList] = useState([]);
    const [selectedProfitList, setSelectedProfit] = useState(null);
    const [tolSalesList, setTolSalesList] = useState([]);
    const [selectedSalesList, setSelectedSales] = useState(null);

    let isMounted = false;
    let windowWidth = Dimensions.get('window').width;
    let WIDTH = windowWidth >= maxWidth? maxWidth:windowWidth;
    let pageTimer;

    const handleResize = () => {
        // if(isMounted){
            windowWidth = Dimensions.get('window').width;
            WIDTH = windowWidth >= maxWidth? maxWidth:windowWidth;
            // console.log(WIDTH);
        // }
    }

    useEffect(async()=>{
        // ShowLoading();
        await getSalesKPI();
        setFilename(`SalesPerson_${year}`);
        // HideLoading();
    },[year])

    const getSalesKPI = async() => {
        let url = API_URL + 'report/saleskpi';
        let params = {
            "year":year,
        }

        const res = await axios({
            method:"GET",
            url:url,
            headers:headers,
            params:params
        });

        if(res.status === 200){
            console.log('Sales KPI');
            let start = new Date();

            let all_sales = res.data.result;
            let sales_name = [];
            let sales_excel_data = [];

            // data=[[salesname,kpi,profit,totalsales],[...]]
            for(let i=0;i<label.length;i++){
                let each_sales = [];
                for(let j=0;j<all_sales.length;j++){
                    let each_kpi = all_sales[j].kpi[i+1];
                    let name = all_sales[j].name;
                    let profit_rm;
                    let profit_eur;
                    let profit_sgd;
                    let profit_usd;

                    let tolsales_rm;
                    let tolsales_eur;
                    let tolsales_sgd;
                    let tolsales_usd;
                    if(all_sales[j].gross_profit[i+1].length>0){

                    all_sales[j].gross_profit[i+1].map((item)=>{
                        switch(item.currency){
                            case 'RM':
                                profit_rm = item.value;
                                break;
                            case 'EUR':
                                profit_eur = item.value;
                                break;
                            case 'SGD':
                                profit_sgd = item.value;
                                break;
                            case 'USD':
                                profit_usd = item.value;
                                break;
                            default:
                                break;
                        }
                    })
                }else{
                    profit_rm='-';
                    profit_eur='-';
                    profit_sgd='-';
                    profit_usd='-';
                }

                if(all_sales[j].total_sales[i+1].length>0){
                    all_sales[j].total_sales[i+1].map((item)=>{
                        switch(item.currency){
                            case 'RM':
                                tolsales_rm = item.value;
                                break;
                            case 'EUR':
                                tolsales_eur = item.value;
                                break;
                            case 'SGD':
                                tolsales_sgd = item.value;
                                break;
                            case 'USD':
                                tolsales_usd = item.value;
                                break;
                            default:
                                break;
                        }
                    })
                }else{
                    tolsales_rm='-';
                    tolsales_eur='-';
                    tolsales_sgd='-';
                    tolsales_usd='-';
                }
                    profit_rm = profit_rm? profit_rm:'-';
                    profit_eur = profit_eur? profit_eur:'-';
                    profit_sgd = profit_sgd? profit_sgd:'-';
                    profit_usd = profit_usd? profit_usd:'-';

                    tolsales_rm = tolsales_rm? tolsales_rm:'-';
                    tolsales_eur = tolsales_eur? tolsales_eur:'-';
                    tolsales_sgd = tolsales_sgd? tolsales_sgd:'-';
                    tolsales_usd = tolsales_usd? tolsales_usd:'-';

                    each_sales.push([name,each_kpi,profit_rm,profit_eur,profit_sgd,profit_usd,tolsales_rm,tolsales_eur,tolsales_sgd,tolsales_usd]);
                }
                let obj = {
                    month:label[i],
                    data:each_sales
                }
                sales_excel_data.push(obj);
            }
            setExcelData(sales_excel_data);

            // save kpi month by month
            // save gross profit (RM) & total sales(RM) month by month
            let profit_list = all_sales.map((item,index)=>{
                sales_name.push({label:item.name, value:index});
                let profit_keys = Object.keys(item.gross_profit);
                let profit_data = [];
                let kpi = [];
                for(let a=0; a< profit_keys.length;a++){
                    if(item.kpi[profit_keys[a]] != undefined){
                        kpi.push(item.kpi[profit_keys[a]]);
                    }
                    if(item.gross_profit[profit_keys[a]].length > 0){
                    item.gross_profit[profit_keys[a]].map(data=>{
                        if(data.currency == 'RM'){
                            profit_data.push(data.value);
                        }
                    })}else{
                        profit_data.push(0);
                    }
                }

                //profit_data.pop();
                //console.log(profit_data);
                let dtset = {
                    labels:label,
                    datasets:[{data:profit_data}]
                };

                return(
                    <View key={item.salesID}>
                    <Text>{item.name}</Text>
                    <BarChart
                        data={dtset}
                        backgroundColor={ 'transparent' }
                        hasLegend={true} 
                        width={800}
                        height={220}
                        fromZero={true}
                        withInnerLines={false}
                        showValuesOnTopOfBars={true}
                        // yAxisLabel={'RM'}
                        chartConfig={{
                            backgroundGradientFrom: "#fff",
                            backgroundGradientTo: "#fff",
                            barPercentage: 0.7,
                            height:500,
                            fillShadowGradient: `rgba(1, 122, 205, 1)`,
                            fillShadowGradientOpacity: 1,
                            decimalPlaces: 0, // optional, defaults to 2dp
                            color: (opacity = 1) => `rgba(1, 122, 205, 1)`,
                            labelColor: (opacity = 1) => `rgba(0, 0, 0, 1)`,
                            style: {
                                borderRadius: 16,
                                fontFamily: "Bogle-Regular",
                            },
                            propsForBackgroundLines: {
                                strokeWidth: 1,
                                stroke: "#e3e3e3",
                                strokeDasharray: "0",
                            },
                            propsForLabels: {
                                fontFamily: "Bogle-Regular",
                            },
                        }}
                    />

                    <Text>KPI</Text>
                    <DataTable key={item.salesID}>
                    <DataTable.Header>
                        {label.map((month)=>{
                            return(<DataTable.Title key={month}>{month}</DataTable.Title>)    
                        })}
                        <DataTable.Title></DataTable.Title>
                    </DataTable.Header>
                    <DataTable.Row>
                        {kpi.map((kpivalue,index)=>(
                            <DataTable.Cell key={index}>{kpivalue}</DataTable.Cell>
                        ))}
                    </DataTable.Row>
                    </DataTable>
                    
                    </View>
                )
            });
            setProfitList(profit_list);

            let total_sales_list = all_sales.map((item)=>{
                let sales_keys = Object.keys(item.total_sales);
                let sales_data = [];
                for(let b=0; b<sales_keys.length;b++){
                    if(item.total_sales[sales_keys[b]].length > 0){
                        item.total_sales[sales_keys[b]].map(data=>{
                            if(data.currency == 'RM'){
                                sales_data.push(data.value);
                            }
                        })}else{
                            sales_data.push(0);
                        }
                }

                let salesdtset = {
                    labels:label,
                    datasets:[{data:sales_data}]
                };

                return(
                    <View key={item.salesID}>
                    <Text>{item.name}</Text>
                    <BarChart
                        data={salesdtset}
                        backgroundColor={ 'transparent' }
                        hasLegend={true} 
                        width={800}
                        height={220}
                        fromZero={true}
                        withInnerLines={false}
                        showValuesOnTopOfBars={true}
                        // yAxisLabel={'RM'}
                        chartConfig={{
                            backgroundGradientFrom: "#fff",
                            backgroundGradientTo: "#fff",
                            barPercentage: 0.7,
                            height:500,
                            fillShadowGradient: `rgba(1, 122, 205, 1)`,
                            fillShadowGradientOpacity: 1,
                            decimalPlaces: 0, // optional, defaults to 2dp
                            color: () => `rgba(1, 122, 205, 1)`,
                            labelColor: () => `rgba(0, 0, 0, 1)`,
                            style: {
                                borderRadius: 16,
                                fontFamily: "Bogle-Regular",
                            },
                            propsForBackgroundLines: {
                                strokeWidth: 1,
                                stroke: "#e3e3e3",
                                strokeDasharray: "0",
                            },
                            propsForLabels: {
                                fontFamily: "Bogle-Regular",
                            },
                        }}
                    />
                    </View>
                )
            });
            setTolSalesList(total_sales_list);
            setNameList(sales_name);
            let end = new Date();
                console.log('time: ' + (end-start))
        }

    }

    const onChangeToggle = () => {
        setProfit(!profit);
        setTotalSales(!totalSales);
    }

    const [showPage, setShowPage] = useState(false);
    const [partOfProfitList, setPartOfProfitList] = useState([]);
    const [partOfTolSales, setPartOfTolSales] = useState([]);
    // for pagination
    const [tablePage, setCurrentPage] = useState({totalPages:0, currentPage:1});
    const [totalItems, setTotalItems] = useState(0);
    const [isFirst, setFirst] = useState(1);
    const [isLast, setLast] = useState(0);
    const limit = 10;

    useEffect(()=>{
        // console.log('change');

        if(profitList.length > limit && selectedProfitList==null && selectedSalesList==null){
            console.log('bigger than limit');
            let totalpage = Math.ceil(profitList.length/limit);
            tablePage['totalPages'] = totalpage;
            setFirst(1);
            setLast(0);
            setShowPage(true);
            setPartOfProfitList(profitList.slice(0,limit));
            setPartOfTolSales(tolSalesList.slice(0,limit));
        }else{
            setPartOfProfitList(profitList);
            setPartOfTolSales(tolSalesList);
        }
    },[profitList,tolSalesList])

    useEffect(()=>{
        if(selectedProfitList!=null && selectedSalesList!=null)
            setShowPage(false);

    },[selectedProfitList,selectedSalesList])

    useEffect(()=>{
        // console.log(partOfProfitList);
    },[partOfProfitList, partOfTolSales]);

    const onChangePage = (value) => {
        if(value != '' && (value <= tablePage.totalPages) && value > 0){
            clearTimeout(pageTimer);
            pageTimer = setTimeout(() => {   
                let goTo = (value-1)*limit;
            tablePage.currentPage = parseInt(value);
            tablePage.currentPage == 1? setFirst(1):setFirst(0);
            tablePage.currentPage == tablePage.totalPages? setLast(1):setLast(0);       
                setPartOfProfitList(profitList.slice(goTo, (goTo+limit)));
                setPartOfTolSales(tolSalesList.slice(goTo, (goTo+limit)));
            }, 800);
        }
    }

    const previousPage = () => {
        if(tablePage.totalPages > 1) {
            tablePage.currentPage = tablePage.currentPage - 1;
            setLast(0);
            tablePage.currentPage == 1? setFirst(1):setFirst(0);
            // if(tablePage.currentPage == 0) setFirst(1);
            let from = (tablePage.currentPage-1) * limit;
            let to = from+limit;
            setPartOfProfitList(profitList.slice(from, to));
            setPartOfTolSales(tolSalesList.slice(from,to));
        }
    }

    const nextPage = () => {
        if(tablePage.totalPages > 1) {
            tablePage.currentPage = tablePage.currentPage + 1;
            setFirst(0);
            // console.log(tablePage.currentPage);
            // console.log(tablePage.totalPages);
            (tablePage.currentPage) == tablePage.totalPages? setLast(1):setLast(0)
            let from = (tablePage.currentPage-1) * limit;
            let to = from+limit;
            setPartOfProfitList(profitList.slice(from, to));
            setPartOfTolSales(tolSalesList.slice(from,to));
        }
    }

    const onChange = (index) =>{
        if(index!=undefined){
            setSelectedProfit(profitList[index]);
            setSelectedSales(tolSalesList[index]);
        }
    }

    return(
        <View>
            <Pressable onPress={()=>{
                setValue();
                setYear(currentYear);
                setCurrentPage({totalPages:0, currentPage:1});
                setProfit(true);
                setTotalSales(false);
                setSelectedProfit(null);
                setSelectedSales(null);
                getSalesKPI();
            }}><Text>Refresh</Text></Pressable>
        <Picker 
            selectedValue={year}
            onValueChange={itemValue => setYear(itemValue)}
        >
            {YearList}
        </Picker>
        <DropDownPicker
            open={isOpen}
            value={value}
            items={nameList}
            setOpen={setOpen}
            setValue={setValue}
            searchable={true}
            closeOnBackPressed={true}
            style={[{paddingLeft:10,width:100,height:50}]}
            // dropDownContainerStyle={style(windowWidth).dropDownContainer}
            listMode="SCROLLVIEW"
            showTickIcon={false}
            placeholder=""
            showArrowIcon={false}
            searchPlaceholder="Search staff..."
            onChangeValue={(value) => { onChange(value) }}
        /> 
        <Text>Profit</Text>
        <Switch value={profit} onValueChange={onChangeToggle}/>
        <Text>Total Sales</Text>
        <Switch value={totalSales} onValueChange={onChangeToggle}/>
        
        <ExcelFile filename={filename} element={<button>Excel</button>}>
            {excelData.map((item,index)=>{
                let dataset = [
                    {
                        xSteps:2,
                        columns: ["Profit","","","","Total Sales","","",""],
                        data:[]
                    },
                    {
                        columns:["Name","KPI","RM","EUR","SGD","USD","RM","EUR","SGD","USD"],
                        data: item.data
                    },
                ];
                return(
                    <ExcelSheet dataSet={dataset} name={item.month} key={index}/>
                )
            })}
        </ExcelFile>
        {profit? 
            (selectedProfitList? 
                selectedProfitList:partOfProfitList)
            
            :(selectedSalesList? 
                selectedSalesList:partOfTolSales)}

                {showPage? (<View style={{flexDirection:'row', justifyContent:'space-between'}}>
                    <Text style={{marginTop:30, fontSize:15, color:'#505050',fontFamily: 'sans-serif'}}>{`Total items: ${profitList.length}`}</Text>
                    <View style={{flexDirection:'row', alignSelf:'flex-end', marginTop:20}}>
                        <Pressable 
                            style={isFirst? style(windowWidth).paginationButtonDisabled:style(windowWidth).paginationButton} 
                            onPress={previousPage} 
                            disabled={isFirst}
                        >
                            <Image source={prev} resizeMode='contain' style={[{width:20, height:20, tintColor:'white'},isFirst && {tintColor:'#808080'}]} />
                        </Pressable>
                        
                        <Text style={{marginHorizontal:10,alignSelf:'center',color:'#505050',fontFamily: 'sans-serif'}}>
                            {`Page `}
                            <Text style={{fontSize:20, color:'black',fontFamily: 'sans-serif'}}>{`${tablePage.currentPage}`}</Text>
                            {` of ${tablePage.totalPages}`}
                        </Text>
                        
                        <Pressable 
                            style={isLast? style(windowWidth).paginationButtonDisabled: style(windowWidth).paginationButton} 
                            onPress={nextPage}
                            disabled={isLast}
                        >
                            <Image source={next} resizeMode='contain' style={[{width:20, height:20, tintColor:'white'},isLast && {tintColor:'#808080'}]} />
                        </Pressable>
                    </View>
                
                    <View style={{flexDirection:'row', marginTop:20, alignItems:'center'}}>
                        <Text style={{alignSelf:'center',color:'#505050',fontFamily: 'sans-serif', marginRight:5}}>Go to page: </Text>
                        <TextInput 
                            style={{width:40, height:30, borderWidth:1, borderRadius:2, padding:5}} 
                            onChangeText={(value) => onChangePage(value)}
                            placeholder={`${tablePage.currentPage}`}
                        />
                    </View>
                </View>):null}
        </View>
    )
};

const TabClient = () => {
    const windowHeight = Dimensions.get('window').height;
    const windowWidth = Dimensions.get('window').width;

    const [nameList,setNameList] = useState([]);
    const [isOpen, setOpen] = useState(false);
    const [value, setValue] = useState('');
    const [clientID, setClientID] = useState('');
    const [searchParams, set_searchParams] = useState([]);
    const [totalSpend, setTotalSpend] = useState(initialSpend);
    const [totalSpendList, setTotalSpendList] = useState('');

    // for pagination
    const [tablePage, setCurrentPage] = useState({totalPages:0, currentPage:0});
    const [totalItems, setTotalItems] = useState(0);
    const [isFirst, setFirst] = useState(1);
    const [isLast, setLast] = useState(0);

    const isHovered = useHover(ref);
    const ref = useRef(null);

    const initialSpend = [{
        SOid:'',
        sales_name: '',
        consignee_name: '',
        shipper_name: '',
        gross_profit: '',
        createdAt: ''
    }]

    let pageTimer;

    const getCustomerList = async() =>{
        let url = API_URL + 'utils/options/client';

        const res = await axios({
            method:"GET",
            url:url,
            headers:headers,
        });

        if(res.status === 200){
            let all_client = res.data.result;
            let client_list = all_client.map((client) => {return({label:client.title,value:client.id})})
            setNameList(client_list);
        }
    }


    const getTotalSpentByCustomer = async(params) => {
        let url = API_URL + 'report/totalspend';
        
        let queryParams = {};
        for (const sp in searchParams){
            if(searchParams[sp] != '')
                queryParams['filter[' + sp + ']'] = searchParams[sp];
        }
        queryParams['clientID'] = clientID;
        queryParams['size'] = 5;
        queryParams['page'] = 0;
        if(params && params.page){
            queryParams['page'] = params.page;
        }

        const res = await axios({
            method:"GET",
            url:url,
            headers:headers,
            params:queryParams
        });

        if(res.status === 200){
            console.log('Total spent by customer');
            console.log(res);
            let all_spend = res.data.result.dataList;
            let totalPage = res.data.result.totalPages;
            let currentPage = res.data.result.currentPage;

            setTotalItems(res.data.result.totalItems);
            setCurrentPage({totalPages: totalPage, currentPage: currentPage});
            if(all_spend.length == 0) setTotalSpend(initialSpend);

            (currentPage+1) == totalPage? setLast(1):setLast(0);
            currentPage == 0? setFirst(1):setFirst(0);

            let all_spend_obj = [];
            for(let a=0; a<all_spend.length; a++){

                let obj = {
                    SOid:all_spend[a].shipmentID,
                    sales_name: all_spend[a].sales_name,
                    consignee_name: all_spend[a].consignee_name,
                    shipper_name: all_spend[a].shipper_name,
                    createdAt: all_spend[a].createdAt
                }

                if(all_spend[a].gross_profit!=null){
                    if(all_spend[a].gross_profit.RM){
                        obj['gross_profit'] = all_spend[a].gross_profit.RM;
                    }else{
                        obj['gross_profit'] = '-'
                    }
                }else{
                    obj['gross_profit'] = '-'
                }

                all_spend_obj.push(obj);

                all_spend_obj.length == all_spend.length? setTotalSpend(all_spend_obj):null;
            }

        }
    }

    useEffect(() => {
        if(totalSpend!=undefined){
        let tslist = totalSpend.map((item,index)=>{

            return(
                <Hoverable key={index}>
                {isHovered => (
                    <Pressable key={index} style={isHovered? {backgroundColor:'#E8E8E8'}:{backgroundColor:'white'}}>
                        <DataTable.Row key={index}>
                            <DataTable.Cell>{item.SOid || '-'}</DataTable.Cell>
                            <DataTable.Cell>{item.sales_name || '-'}</DataTable.Cell>
                            <DataTable.Cell>{item.consignee_name || '-'}</DataTable.Cell>
                            <DataTable.Cell>{item.shipper_name||'-'}</DataTable.Cell>
                            <DataTable.Cell>{item.gross_profit||'-'}</DataTable.Cell>                            
                            <DataTable.Cell>{item.createdAt||'-'}</DataTable.Cell>
                        </DataTable.Row>
                    </Pressable>
                )}
                </Hoverable>
            )
        })
        

        setTotalSpendList(tslist);
    }
    },[totalSpend]);

    useEffect(async ()=>{
        ShowLoading();
        await getCustomerList();
        HideLoading();
        //getTotalSpentByCustomer();
    },[])

    const onChangePage = (value) => {
        if(value != '' && (value <= tablePage.totalPages) && value > 0){
            clearTimeout(pageTimer);
            pageTimer = setTimeout(() => {          
                getTotalSpentByCustomer({ page:value-1 });
            }, 800);
        }
    }

    const previousPage = () => {
        if(tablePage.totalPages > 1) {
            tablePage.currentPage = tablePage.currentPage - 1;
            setLast(0);
            tablePage.currentPage == 0? setFirst(1):setFirst(0)
            getTotalSpentByCustomer({ page:tablePage.currentPage });
        }
    }

    const nextPage = () => {
        if(tablePage.totalPages > 1) {
            tablePage.currentPage = tablePage.currentPage + 1;
            setFirst(0);
            (tablePage.currentPage+1) == tablePage.totalPages? setLast(1):setLast(0)
            getTotalSpentByCustomer({ page:tablePage.currentPage });
        }
    }

    // Calander
    const [showCalander, set_showCalander] = useState(false);
    const [calanderMessage, set_calanderMessage] = useState('');
    const [calanderFor_Attribute, set_calanderFor_Attribute] = useState('');
    const [calanderFor_Point, set_calanderFor_Point] = useState('');

    const [calendarState] = useState({
        fromDate:'-',
        toDate:'-'
    })

    const onChangeValue = (key, value) => {
        searchParams[key] = value;
    }

    const onDismiss = useCallback(() => {
        set_showCalander(false);
        calendarState[calanderFor_Attribute] = '-'      
        
        onChangeValue(calanderFor_Attribute, "");
    });
    const onConfirm = useCallback((params) => {
            set_showCalander(false);
            var date = new Date(params.date);     
            if(calanderFor_Point == "From")
            {
                date.setHours(0);
                date.setMinutes(0);
                date.setSeconds(0);
                date.setMilliseconds(0);
            }
            else if(calanderFor_Point == "To")
            {
                date.setHours(23);
                date.setMinutes(59);
                date.setSeconds(59);
                date.setMilliseconds(999);
            } 
            let y = date.getFullYear();
            let m = date.getMonth()+1;
            let d = date.getDate();

            if (d < 10)
                d = '0' + d;
            if (m < 10)
                m = '0' + m;
            
            calendarState[calanderFor_Attribute] = d + "/" + m + "/" + y;
            onChangeValue(calanderFor_Attribute, date.getTime());
    });

    return(
    <View>
        <Text>Client</Text>
        <DropDownPicker
            open={isOpen}
            value={value}
            items={nameList}
            setOpen={setOpen}
            setValue={setValue}
            searchable={true}
            closeOnBackPressed={true}
            style={[{paddingLeft:10,width:100,height:50}]}
            // dropDownContainerStyle={style(windowWidth).dropDownContainer}
            listMode="SCROLLVIEW"
            showTickIcon={false}
            placeholder=""
            showArrowIcon={false}
            searchPlaceholder="Search staff..."
            onChangeValue={(value) => { setClientID(value); }}
        /> 

        <View style={{margin:5, flex:1, flexDirection:'column'}}>
            <Pressable
                style={style().from_and_to}    
                onPress={() => {
                    set_calanderFor_Attribute('fromDate');
                    set_calanderFor_Point('From');
                    set_showCalander(true);
                    set_calanderMessage('From');
                }}
            >
                <Text style={{textAlign:'center'}}>{'From: ' + calendarState['fromDate']}</Text>
            </Pressable>
                        
            <Pressable
                style={style().from_and_to}                
                onPress={() => {
                    set_calanderFor_Attribute('toDate');
                    set_calanderFor_Point('To');
                    set_showCalander(true);
                    set_calanderMessage('To');
                }}
                title= {'Until: ' }
            >
                <Text style={{textAlign:'center'}}>{'Until: ' + calendarState['toDate']}</Text>
            </Pressable>
        </View>
        <View style={{flexDirection:'row', margin:20}}>
            <Button
                style={{flex:1, borderColor:'#FF9F1D', borderWidth:2,marginHorizontal:20}} 
                labelStyle={{color:'black'}} 
                color='#FF9F1D'
                mode="outlined"  
                onPress={getTotalSpentByCustomer}
            >
                Search
            </Button>
        </View>
        {totalSpendList?(

        <DataTable>
        <DataTable.Header style={{flex:5,height:50,fontSize:20, borderBottomColor:'#000000', justifyContent:'space-between'}}>
                    <DataTable.Title>Sales Order ID</DataTable.Title>
                    <DataTable.Title>Sales Person Name</DataTable.Title>
                    <DataTable.Title>Consignee Name</DataTable.Title>
                    <DataTable.Title>Shipper Name</DataTable.Title>
                    <DataTable.Title>{'Gross Profit (RM)'}</DataTable.Title>
                    <DataTable.Title>Created Date</DataTable.Title>
                    {/* <DataTable.Title>Edit</DataTable.Title> */}
                </DataTable.Header>
                {totalSpendList?totalSpendList:null}
                <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                    <Text style={{marginTop:30, fontSize:15, color:'#505050',fontFamily: 'sans-serif'}}>{`Total items: ${totalItems}`}</Text>
                    <View style={{flexDirection:'row', alignSelf:'flex-end', marginTop:20}}>
                        <Pressable 
                            style={isFirst? style(windowWidth).paginationButtonDisabled:style(windowWidth).paginationButton} 
                            onPress={previousPage} 
                            disabled={isFirst}
                        >
                            <Image source={prev} resizeMode='contain' style={[{width:20, height:20, tintColor:'white'},isFirst && {tintColor:'#808080'}]} />
                        </Pressable>
                        
                        <Text style={{marginHorizontal:10,alignSelf:'center',color:'#505050',fontFamily: 'sans-serif'}}>
                            {`Page `}
                            <Text style={{fontSize:20, color:'black',fontFamily: 'sans-serif'}}>{`${tablePage.totalPages == 0? tablePage.currentPage:tablePage.currentPage+1}`}</Text>
                            {` of ${tablePage.totalPages}`}
                        </Text>
                        
                        <Pressable 
                            style={isLast? style(windowWidth).paginationButtonDisabled: style(windowWidth).paginationButton} 
                            onPress={nextPage}
                            disabled={isLast}
                        >
                            <Image source={next} resizeMode='contain' style={[{width:20, height:20, tintColor:'white'},isLast && {tintColor:'#808080'}]} />
                        </Pressable>
                    </View>
                
                    <View style={{flexDirection:'row', marginTop:20, alignItems:'center'}}>
                        <Text style={{alignSelf:'center',color:'#505050',fontFamily: 'sans-serif', marginRight:5}}>Go to page: </Text>
                        <TextInput 
                            style={{width:40, height:30, borderWidth:1, borderRadius:2, padding:5}} 
                            onChangeText={(value) => onChangePage(value)}
                            placeholder={`${tablePage.totalPages == 0? tablePage.currentPage:tablePage.currentPage+1}`}
                        />
                    </View>
                </View>
        </DataTable>):null
}

        <DatePickerModal
            mode="single"
            visible={showCalander}
            onDismiss={onDismiss}
            date={new Date()}      
            onConfirm={onConfirm}
            validRange={{
                startDate: new Date(2020, 0, 1),
                endDate: new Date()
            }}
            // onChange={} // same props as onConfirm but triggered without confirmed by user
            saveLabel="Select Date" // optional
            uppercase={false} // optional, default is true
            label={calanderMessage} // optional
            animationType="fade" // optional, default is 'slide' on ios/android and 'none' on web
        />    
        
    </View>
    )
};


function Report() {
    // tab view 
    const [routes] = useState([
        { key: 'tabCompany', title: 'Company Profit Loss' },
        { key: 'tabStaff', title: 'Staff Report' },
        { key: 'tabSales', title: 'Sales Person Report' },
        { key: 'tabClient', title: 'Client Report' },
    ])

    const [isLoading, setLoading] = useState(1);

    const [index, setIndex] = React.useState(0);

    const renderScene = SceneMap({
        tabCompany: TabCompany,
        tabStaff: TabStaff,
        tabSales: TabSales,
        tabClient: TabClient 
    })


    useEffect(()=>{
        setLoading(0)
    },[])

    
    const renderTabBar = props => (
        <TabBar
            {...props}
            indicatorStyle={{backgroundColor:'white'}}
            labelStyle={{color:'black'}}
            activeColor='black'
            inactiveColor='white'
            style={{backgroundColor:'#FF9F1D'}}
        />
    );
    
    return (
        isLoading? null:(
        <View>     
            <TabView
                renderTabBar={renderTabBar}
                navigationState={{ index, routes }}
                renderScene={renderScene}
                onIndexChange={setIndex}
                initialLayout={{ width: 1300 }}
                uppercase={false}
                swipeEnabled={false}
            />
        </View>)
    )
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f5fcff"
    }
});
export default Report
