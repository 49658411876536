import React from "react";
import { View, ScrollView, StyleSheet, TouchableOpacity, Dimensions} from "react-native";
import { minWidth, maxWidth } from "../constants";

export function date_to_ddmmyyyy (date) {
    let y = date.getFullYear();
    let m = date.getMonth()+1;
    let d = date.getDate();
    if (d < 10) d = '0' + d;
    if (m < 10) m = '0' + m;   
    return (d + "/" + m + "/" + y);  
}

export function date_remove_time (date) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date; 
}

export function isDigit (text) {
    // const matches = text.match(
    //     /^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,2})?)(?:e[+-]?\d+)?$/
    // );
    // if (matches?.length > 0)
    //     return text;
    // return "";
    return !isNaN(text);
}

export function isDigit_withNegative (text) {
    if(text == "-")
        return true;
    return !isNaN(text);
}


// Normalize the size of style -> responsive
export function normalize(size, width) {
    // Check the screen size of the device
    // If width larger than 800 -> maxWidth: 1280 (web view)
    // If smaller than 800 -> maxWidth: 800 (mobile view)
    // const scale = width / maxWidth;
    const newSize = size / 100 * width;

    // return (Math.round(PixelRatio.roundToNearestPixel(newSize))) ;       
    return (Math.round(newSize)) ;       
}

export function getWebsiteWidth() {
    let windowWidth = Dimensions.get('window').width;        
    return windowWidth < minWidth? minWidth:windowWidth;        
}

export function printRequestError(err) {
    console.log(
        err.response.request.status + ': ' + 
        err.response.request.statusText
    );  
}

const styles = StyleSheet.create({
  container: { flex: 1, width: 0, height: 0, flexShrink: 1, flexGrow: 0 },
});
export const FocusTrap = (props) => {
  return (
    <View style={styles.container}>
      <ScrollView>
        <TouchableOpacity />
      </ScrollView>
    </View>
  );
};