import React, { useState, useEffect } from 'react'
import { Image, View, Text, TextInput, StyleSheet, Pressable, Dimensions } from 'react-native';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import axios from 'axios';
import { style } from '../styles/style.js';
import { API_URL, BASE_URL } from '../config/config';
import logo from '../assets/logo1.png';
import { getWebsiteWidth } from '../components/utilities';

const baseURL = API_URL + 'auth/login';
const resetURL = API_URL + 'auth/resetpassword';

function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [WIDTH, set_WIDTH] = useState(getWebsiteWidth());
  const handleResize = () => { set_WIDTH(getWebsiteWidth()); }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  },[]);

  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const onSubmit = async (req, params) => {
    if (email === "" || password === "") {
      MessageBox("Error", "Please fill up your email and password!");
      return;
    }

    if (!re.test(email.trim())) {
      MessageBox("Error", "Invalid email address format!");
      return;
    }
    if (password.length < 8 || password.length > 32) {
      MessageBox("Error", "Password must be minimum 8 and maximum 32 characters!");
      return;
    }

    const payload = {
      email: email.trim(),
      password: password
    };

    try {
      const response = await axios.post(baseURL, payload);
      if (response.status == 200) {
        let role = response.data.result.userRole.roleID;
        let user = response.data.result.userRole.user;
        let edit = response.data.result.userRole.edit_director_admin;
        let edit_profile = response.data.result.userRole.editprofile;
        let change_password = response.data.result.reset_password;

        let name = response.data.result.name;
        if(name.length > 18)
          name = name.substring(0, 18) + '...';

        window.localStorage.setItem("name", name);
        window.localStorage.setItem("role", role);
        window.localStorage.setItem("token", response.data.result.token)
        let newExpiredDate = new Date();
        newExpiredDate.setDate(newExpiredDate.getDate() + 1);
        newExpiredDate.setHours(4);
        newExpiredDate.setMinutes(0);
        newExpiredDate.setSeconds(0);
        newExpiredDate.setMilliseconds(0);
        window.localStorage.setItem("expired", newExpiredDate.getTime());
        // window.localStorage.setItem("expired", response.data.result.expired);        
        window.localStorage.setItem("user", user);
        window.localStorage.setItem("edit_director_admin", edit);
        window.localStorage.setItem("change_password", change_password);
        window.localStorage.setItem("edit_profile", edit_profile);
        if (change_password) {
          window.location.href = '/change_password';
        } else {
          window.location.href = '/sales_order';
        }
        // console.log(response)
      }
    } catch (err) {
      MessageBox("Error", err.response.data.result + "!");
    }
  };

  const handleReset = async () => {
    if (email === "") {
      MessageBox("Error", "Email cannot be empty!");
      return;
    } else if (!re.test(email.trim())) {
      MessageBox("Error", "Invalid email address format!");
      return;
    }
    ShowLoading();
    const payload = { email: email.trim() };

    await axios.post(resetURL, payload)
    .then(() => { MessageBox("Success", "A new password have been sent to your email.") })
    .catch(err => {MessageBox("Error", err.response.data.result + "!");})
    .finally(() => {HideLoading();})    
  }

  return (
    <View style={style(WIDTH).login_container2}>
      <Image source={logo} resizeMode='contain' style={style(WIDTH).login_logo} />
      <Text style={[style(WIDTH).fontSize3,{ margin: 'auto', marginVertical: 0, marginBottom: 25 }]}>Login</Text>
      <Text style={[style(WIDTH).login_text, style(WIDTH).fontSize2]}>Email</Text>
      <TextInput
        style={[style(WIDTH).fontSize2, style(WIDTH).login_textinput]}
        placeholder='Enter email...'
        textContentType='emailAddress'
        onChangeText={text => setEmail(text)}
        onSubmitEditing={onSubmit}
      />
      <Text style={[style(WIDTH).login_text, style(WIDTH).fontSize2]}>Password</Text>
      <TextInput
        style={[style(WIDTH).fontSize2, style(WIDTH).login_textinput]}
        placeholder='Enter password...'
        secureTextEntry={true}
        textContentType='password'
        onChangeText={text => setPassword(text)}
        onSubmitEditing={onSubmit}
      />

      <Pressable
        style={style(WIDTH).login_btn}
        onPress={() => onSubmit()}>
        <Text style={[style(WIDTH).fontSize2,{ color: 'white' }]}>Login</Text>
      </Pressable>

      <View style={{ flexDirection: 'row', justifyContent: 'center', margin: 10 }}>
        <Text style={[style(WIDTH).fontSize2,{ color: '#505050' }]}>{'Forgot Password? '}</Text>
        <Pressable onPress={handleReset}><Text style={[style(WIDTH).fontSize2,{ textDecorationLine: 'underline' }]}>Reset Password</Text></Pressable>
      </View>
    </View>
  )
}

export default Login
