import React, { useEffect, useState, useCallback, useRef } from 'react'
import { View, Text, TextInput, Dimensions, StyleSheet, Pressable, ScrollView, TouchableOpacity} from 'react-native'
import { useParams } from 'react-router';
import { API_URL, BASE_URL } from '../config/config';
import { MAX_FILE_SIZE_BYTE } from '../constants';
import { useForm, Controller } from "react-hook-form";
import axios from 'axios';
import { DataTable, Button as ButtonPaper } from 'react-native-paper';
import { useHover, Hoverable } from 'react-native-web-hooks';
import { Icon } from 'react-native-elements';
import { Overlay, Button } from 'react-native-elements';
import Client_list from './Client_list.js';
import { style, option } from '../styles/style.js';
import { set } from 'react-native-reanimated';
import { date_to_ddmmyyyy, date_remove_time, isDigit} from '../components/utilities.js';
import { DatePickerModal } from 'react-native-paper-dates';
import * as DocumentPicker from 'expo-document-picker';
import { getWebsiteWidth } from '../components/utilities';
import { Popable } from 'react-native-popable';

function DeliveryOrder_Edit(params) {
    const isCreate = (typeof(params.mode) != "undefined") && params.mode == "add"? true:false;
    const [shipmentID, set_shipmentID] = useState((typeof(params.shipmentID) != "undefined")? params.shipmentID:null);
    const [doID, set_doID] = useState((typeof(params.doID) != "undefined")? params.doID:null);
    const {doID: params_doID} = useParams();
    const is_admin = window.localStorage.getItem("role") != 1;
    const [is_completed, set_is_completed] = React.useState(false);
    const [is_deleted, set_is_deleted] = React.useState(false);
    const [last_edit_name, set_last_edit_name] = useState(getWebsiteWidth());
    const [pathDO, set_pathDO] = React.useState('');
    const [pathSignedDO, set_pathSignedDO] = React.useState('');
    const [WIDTH, set_WIDTH] = useState(getWebsiteWidth());
    const [cus_reference, set_cus_reference] = useState(params.reference || 'N/A');
    const [table_content, set_table_content] = React.useState(null);

    const init_formData = {
        cargoDetail: "",
        deliverAddress: "",
        pickupAddress:"",
        // pickupDate:"",
        truck_no:"",
        package: {
            total_m3: "0",
            total_quantity: "",
            total_weight: "0",
            item: []
        }
    }    
    const { control, handleSubmit, reset, getValues, setValue, formState: { errors }} = useForm({
        defaultValues: init_formData });    
    const [shipper, set_shipper] = useState('');
    const [consignee, set_consignee] = useState('');
    const [pickupDate, set_pickupDate] = useState('N/A');
    const [pickupAddress, set_pickupAddress] = useState('');
    const [deliveryAddress, set_deliveryAddress] = useState('');
    const [total_m3, set_total_m3] = useState(0);
    const [total_weight, set_total_weight] = useState(0);
    const [counter_delete, set_counter_delete] = React.useState(0);    

     // Overlay
    const [state_showOverlay, set_state_showOverlay] = useState(false);
    const [currentIndex, set_currentIndex] = useState('');
    const showOverlay = (index) => {
        set_currentIndex(index);
        set_state_showOverlay(true);
    };
    const hideOverlay = () => {
        set_state_showOverlay(false);
    };
    const sendDataToParent = async(params) => {
        set_state_showOverlay(false);
        if(currentIndex == "shipper")
        {
            setValue("pickupAddress", params.id);
            set_shipper(params.name);
            set_pickupAddress(params.address);
        }
        else if(currentIndex == "consignee")
        {
            setValue("deliverAddress", params.id);
            set_consignee(params.name);
            set_deliveryAddress(params.address);
        }
    }

    // Calander
    const [showCalander, set_showCalander] = useState(false);
    const [calander_defaultDate, set_calander_defaultDate] = useState('');
    const onDismiss = useCallback(() => { /*set_showCalander(false); */});
    const onConfirm = useCallback((params) => {
        // set_showCalander(false);
        // let date = date_remove_time(new Date(params.date));
        // let strDate = date_to_ddmmyyyy(date);
        // setValue("pickupDate", date.getTime());   
        // set_pickupDate(strDate); 
    });

    useEffect(async() => {  
        window.addEventListener('resize', () => { set_WIDTH(getWebsiteWidth()); });
        if(isCreate){
            ShowLoading();
            if(params.pickupDate) {
                let date = date_remove_time(new Date(params.pickupDate));
                set_pickupDate(date_to_ddmmyyyy(date));
            }
            await getClient("shipper", params.pickupFrom.id);
            setValue("pickupAddress", params.pickupFrom.id);
            await getClient("consignee", params.deliverTo.id);
            setValue("deliverAddress", params.deliverTo.id);

            HideLoading();
        }
        else if(doID || params_doID){
            getDeliveryOrder(doID? doID:params_doID);

            if(params.pickupDate) {
                let date = date_remove_time(new Date(params.pickupDate));
                set_pickupDate(date_to_ddmmyyyy(date));
            }
           
        }
        // else
        //     window.location.href = BASE_URL + "sales_order"; 
    },[]);       
    
    useEffect(() => {  
       processTable();
    },[WIDTH]);      

    const processTable = () => {
        set_table_content(null);
        if(typeof(getValues("package").item) != "undefined")
        set_table_content(getValues('package').item.map((item, index)=>{ return(
        <Hoverable key={index}> 
            {isHovered => (
            <Pressable key={index} style={[style(WIDTH).table_row,isHovered && style(WIDTH).hover]}>  
            <DataTable.Row key={index}>
                <View style={style(WIDTH).dataTable_content}>
                    <Controller name={"package.item[" + index + "].no_package"} rules={{required: true}} control={control} render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput style={[style(WIDTH).searchInput, style(WIDTH).fontSize2]} onChangeText={onChange} onBlur={onBlur} value={value}/>)}/></View>
                
                <View style={[style(WIDTH).dataTable_content, {flex:4}]}>
                    <Controller name={"package.item[" + index + "].desc"} rules={{required: true}} control={control} render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput style={[style(WIDTH).searchInput, style(WIDTH).fontSize2]} onChangeText={onChange} onBlur={onBlur} value={value}/>)}/></View>
                
                <View style={style(WIDTH).dataTable_content}>
                    <Controller name={"package.item[" + index + "].m3"} control={control} render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput style={[style(WIDTH).searchInput, style(WIDTH).fontSize2]} onChangeText={(val) => {if(isDigit(val)) onChange(val)}} onBlur={onBlur} value={value}/>)}/></View>
                
                <View style={style(WIDTH).dataTable_content}>
                    <Controller name={"package.item[" + index + "].weight"} control={control} render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput style={[style(WIDTH).searchInput, style(WIDTH).fontSize2]} onChangeText={(val) => {if(isDigit(val)) onChange(val)}} onBlur={onBlur} value={value}/>)}/></View>
                
                <View style={style(WIDTH).dataTable_content}>
                    <Controller name={"package.item[" + index + "].markno"} control={control} render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput style={[style(WIDTH).searchInput, style(WIDTH).fontSize2]} onChangeText={onChange} onBlur={onBlur} value={value}/>)}/></View>
                
                <View style={[style(WIDTH).dataTable_content,{flex:0.5}]}>
                    <Popable content='Delete' position="left" backgroundColor='#FF9F1D' action="hover"> 
                    <Button icon={<Icon name='delete-outline' type="MaterialIcons" color="red" size={WIDTH*0.02}/>} type="clear"
                        onPress={() => deleteRow(index)}/></Popable></View>
            </DataTable.Row>
            </Pressable>)}        
            </Hoverable>
        )})); 
    }   

    const addRow = () => {
        let newIndex = typeof(getValues("package.item")) == "undefined"?
            0: getValues("package.item").length;
        setValue("package.item[" + newIndex + "].desc", "");
        setValue("package.item[" + newIndex + "].no_package", "");
        setValue("package.item[" + newIndex + "].length", "");
        setValue("package.item[" + newIndex + "].width", "");
        setValue("package.item[" + newIndex + "].height", "");
        setValue("package.item[" + newIndex + "].m3", "");
        setValue("package.item[" + newIndex + "].weight", "");
        setValue("package.item[" + newIndex + "].markno", "");
        processTable();
    }
 
    const deleteRow = (index) => {
        let data = getValues("package.item");
        let rows = [];
        for (let i=0; i<data.length; i++)
        {
            if(i == index) continue;
            rows.push({
                desc: data[i].desc,
                no_package: data[i].no_package,
                length: data[i].length,
                width: data[i].width,
                height: data[i].height,
                m3: data[i].m3,
                weight: data[i].weight,
                markno: data[i].markno
            });
        }
        let new_data = getValues();
        new_data.package.item = rows;
        reset(new_data);        
        processTable();
    }

    const renderUI = async(data) => { 
        if(data.is_deleted && !is_admin)
            window.location.href = BASE_URL + "delivery_order";     
              
        set_pathSignedDO(data.edoc);
        set_is_completed(data.is_completed);
        set_is_deleted(data.is_deleted);
             
        set_last_edit_name(data.last_edit_name);
        set_pathDO(data.path);
        set_pathSignedDO(data.signpath);
        reset(data);
        set_total_m3(data.package.total_m3? data.package.total_m3: 0);
        set_total_weight(data.package.total_weight? data.package.total_weight: 0);
        set_shipmentID(data.shipmentID);
        set_doID(data.doID);
        // let date = date_remove_time(new Date(data.pickupDate));
        // let strDate = date_to_ddmmyyyy(date);
        // setValue("pickupDate", date.getTime());
        // set_pickupDate(strDate); 
        if (data.so_reference)
            set_cus_reference(data.so_reference);
        if(data.so_etd) {
            let date = date_remove_time(new Date(data.so_etd));
            set_pickupDate(date_to_ddmmyyyy(date));
        }
        data.pickupAddress? await getClient("shipper", data.pickupAddress): null;
        data.deliverAddress? await getClient("consignee", data.deliverAddress): null;
        processTable();
    };

    const getDeliveryOrder = async(doID) => {     
        window.API_CALL_GET('deliveryorder/' + doID).then(
          function(data) {        
            renderUI(data);

          },
          function(error) { MessageBox("Error", error); }
        );
    }

    const deleteDO = async() => { 
        if(is_deleted && !is_admin) return;

        ShowLoading();
        await axios({
            method:'post',
            url: API_URL + 'utils/delete/deliveryorder/', 
            headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` },
            data: {id: doID}
        }).then(() => {   
            if(is_admin && !is_deleted) {
                getDeliveryOrder(doID? doID:params_doID);
                if(params.sendDataToParent)
                    params.sendDataToParent({refresh: true});
            }
            else if(params.sendDataToParent)
                params.sendDataToParent(true);
        }).catch(err => {
            MessageBox("Error", "Fail to delete D.O.");
            console.log(err);
        }).finally(() => {
            HideLoading();
        })  
    };

    const undoDeleteDO = async() => {
        if(!is_deleted && !is_admin && !is_completed) return;

        ShowLoading();
        await axios({
            method:'post',
            url: API_URL + 'deliveryorder/restoreRecord', 
            headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` },
            data: {doID: doID}
        }).then(() => {   
                getDeliveryOrder(doID? doID:params_doID);
                if(params.sendDataToParent)
                    params.sendDataToParent({refresh: true});                   
            }).catch(err => {
            MessageBox("Error", "Fail to restore D.O.");
            console.log(err);
        }).finally(() => {
            HideLoading();
        })  
    }
    const UploadFile = async() => {
        if(typeof(shipmentID) === "undefined")  return; 
        if(is_deleted) return;

        let file = await DocumentPicker.getDocumentAsync({copyToCacheDirectory:true, multiple:false, type:'*/*'});
        if(file.size > MAX_FILE_SIZE_BYTE)
        {
            MessageBox("Error", "File size greater than 10 MB is not allowed to upload.");
            return;
        }

        ShowLoading();
        let formData = new FormData();
        formData.append('doID', doID);
        formData.append('shipmentID', shipmentID);
        formData.append('signedDO', file.file);
    
        const token = window.localStorage.getItem('token');
        let url = API_URL + 'deliveryorder/upload';
        const headers = { Authorization: `Bearer ${token}` };
        await axios({
            method:'post',
            url: url, 
            headers: headers,
            data: formData
        }).then(data => {
            renderUI(data.data.result);
        }).catch(err => {
            console.log(err);

            if(typeof(err.response.data.result.message)!="undefined")
                MessageBox("Error", err.response.data.result.message);
            else
                MessageBox("Error", "Fail to upload file");
            console.log(err);
        }).finally(()=>{
            HideLoading();
        });
    };

    const DownloadFile = async(url) => {
        if(!url) return;
        ShowLoading();
        const token = window.localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        await axios({
            method:'get',
            url: url, 
            headers: headers,
            responseType: 'blob'
        }).then(response => {
            let filename = url.split('/').pop();
            const _url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = _url;
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();
        }).catch(err => {
            MessageBox("Error", "Fail to download file");
            console.log(err);
        }).finally(()=>{
            HideLoading();
        })         
    }

    const getClient = async(type, id) => {
        await axios({
            method:'get',
            url: API_URL + 'client/addressbook/' + id, 
            headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` },
        }).then(res => {  
            let data = res.data.result; 
            let address = data.address1 + ', ';
            if(typeof(data.address2) && data.address2 != "") 
                address += data.address2 + ', ';
            address += data.zipcode + ' ' + data.city + ', ' + data.state + ', ' + data.country;
            let name = data.name;
            if(type == "shipper")
            {
                set_shipper(name);
                set_pickupAddress(address);
            }
            else if(type == "consignee")
            { 
                set_consignee(name);
                set_deliveryAddress(address);
            }
        }).catch(err => {
            MessageBox("Error", "Fail to get client info.");
            console.log(err);
        }).finally(() => {
            HideLoading();
        })  
    }
    
    const onSubmit = async(data) => {
        ShowLoading();
        data.mode = isCreate? "Add":  "Edit";
        if(isCreate) data.shipmentID = shipmentID;
        if(doID || params_doID) data.doID = doID? doID:params_doID;
    
        let item = getValues("package.item");
        let m3 = 0, weight = 0;
        for(let i=0; item && i<item.length; i++)
        {
            m3 += Number(item[i].m3);
            weight += Number(item[i].weight);
        }
        data.package.total_m3 = m3;
        data.package.total_weight = weight;

        await axios({
            method:'post',
            url: API_URL + 'deliveryorder/',
            headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}`},
            data: data
        }).then(data => {
            if(params.sendDataToParent)
                params.sendDataToParent(true);
            else
            {   
                MessageBox("","Saved.")
                renderUI(data.data.result);
            }
        }).catch(err => {
            if(isCreate)
                MessageBox("Error", "Fail to create D.O.");
            else
                MessageBox("Error", "Fail to save D.O.");
            console.log(err);
        }).finally(()=>{
            HideLoading();
        });    
    }

    return (
        <View style={{margin:'2.5%'}}>
            <View style={[
                is_completed && style().table_row_completed,
                is_deleted && style().table_row_deleted]}>

                <View style={{flex:1, justifyContent: 'flex-start', flexDirection:'row'}} >
                    <View style={{flex:1}} >
                        {!isCreate? <>
                            <Text style={[{fontWeight: 'bold'}, style(WIDTH).fontSize3 ]}>D.O. ID: {doID || '---'} {is_completed? "(Completed)":""}</Text></>:null}
                            <Text style={isCreate? [{fontWeight: 'bold'}, style(WIDTH).fontSize3 ]: style(WIDTH).fontSize2}>Shipment ID: {shipmentID || '---'}</Text>
                    </View>

                    <View style={{flex:1, justifyContent: 'flex-end', flexDirection:'row'}} >
                        {!isCreate?
                            <View style={{flexDirection:'row'}} >
                                {/* {pathSignedDO || !is_deleted?
                                <Text>Signed D.O.</Text>: null} */}

                                {!is_deleted && !is_completed? <>
                                    <View style={style(WIDTH).container_icon} />
                                    <Popable content='Upload Signed D.O.' position="left" backgroundColor='#FF9F1D' action="hover"> 
                                        <Button style={{paddingTop:-WIDTH*0.04}} 
                                                icon={<Icon name='cloud-upload-outline' 
                                                type="ionicon" size={option(WIDTH).iconSize2}/>} 
                                                type="clear" onPress={() => UploadFile()}/>
                                    </Popable>
                                </>:null}

                                {pathSignedDO?<>
                                    <View style={style(WIDTH).container_icon} />
                                    <Popable content='Download Signed D.O.' position="left" backgroundColor='#FF9F1D' action="hover">              
                                    <Button style={{paddingTop:-WIDTH*0.04}} 
                                            icon={<Icon name='cloud-download-outline' 
                                            type="ionicon" size={option(WIDTH).iconSize2}/>} 
                                            type="clear" onPress={() => DownloadFile(pathSignedDO)}/>
                                    </Popable>
                                </>:null}
                            </View>: null}

                        {!is_deleted && !isCreate?
                            <View style={{flexDirection:'row'}} >
                            {pathDO? 
                                <Popable content='Print D.O.' position="left" backgroundColor='#FF9F1D' action="hover">
                                    <Button icon={<Icon name='print' type="fontawesome" size={option(WIDTH).iconSize2}/>} type="clear"
                                    onPress={()=>DownloadFile(pathDO)}/></Popable>:null}  

                            {!is_completed?
                                (<>
                                    <View style={style(WIDTH).container_icon} />
                                    <Popable content='Delete' position="left" backgroundColor='#FF9F1D' action="hover">
                                        <Button icon={<Icon name='page-delete' type="foundation" color='red' size={option(WIDTH).iconSize2}/>} type="clear"
                                            onPress={()=>{
                                                if(counter_delete == 0){
                                                    set_counter_delete(counter_delete + 1);
                                                    MessageBox("Warning", "You are deleting the delivery order. If this is your intention, kindly click the delete button again.");
                                                }    
                                                else deleteDO();}}/>
                                    </Popable>
                                </>): null}                                            
                            </View> : null}
                    </View>
                </View>
                
                <View style={style(WIDTH).container_icon} />

                {is_deleted? <>
                    <View style={style(WIDTH).container_icon} />
                    <View style={{justifyContent: 'flex-start', flexDirection:'row'}} >
                        <Text style={style(WIDTH).fontSize3}>Deleted by: {last_edit_name}</Text> 
                            {is_admin? <>
                                <View style={style(WIDTH).container_icon} />
                                <Popable content='Approve' position="top" backgroundColor='#FF9F1D' action="hover"> 
                                    <Button icon={<Icon name='check' type="antdesign" size={option(WIDTH).iconSize1}/>} type="clear" 
                                        style={{backgroundColor:'#11CD01'}}
                                        onPress={()=>{
                                            if(counter_delete == 0){
                                                set_counter_delete(counter_delete + 1);
                                                MessageBox("Warning", "You are deleting the sales order. If this is your intention, kindly click the delete button again.");
                                            }    
                                            else deleteDO();}}/>
                                </Popable>
                                    {!is_completed? <>
                                        <View style={style(WIDTH).container_icon} />
                                        <Popable content='Reject' position="top" backgroundColor='#FF9F1D' action="hover"> 
                                            <Button icon={<Icon name='close' type="antdesign" size={option(WIDTH).iconSize1}/>} type="clear"
                                                style={{backgroundColor:'#ff2100'}}
                                                onPress={()=>{undoDeleteDO();}}/>
                                        </Popable>
                                    </> : null}
                            </>: null}                    
                    </View>
                </>: null}      
            </View>

            <View style={style(WIDTH).container_column} pointerEvents={is_completed || is_deleted? "none":"auto"}>
                <View style={[style(WIDTH).container_create_row_do, style(WIDTH).do_width]}>
                    <View style={[style(WIDTH).create_col_space,{flex:2}]}>
                        <Text style={[style(WIDTH).fontSize2, style(WIDTH).text_view, style(WIDTH).create_title]}>Customer's Reference</Text>
                        <TextInput multiline={true} editable={false} style={[style(WIDTH).create_input,style(WIDTH).fontSize2, {height:'auto'}]} 
                            value={cus_reference ||'N/A'}/>
                    </View>
                    
                    <View style={style(WIDTH).container_icon} />
                
                    <View style={[style(WIDTH).create_col_space]}> 
                        <Text style={[style(WIDTH).fontSize2, style(WIDTH).text_view, style(WIDTH).create_title]}>Pickup Date</Text>
                        <Text style={[style(WIDTH).create_input,{height:'auto'},style(WIDTH).fontSize2]}>{pickupDate}</Text>
                    </View>
                
                    <View style={style(WIDTH).container_icon} />
                
                    <View style={[style(WIDTH).create_col_space]}>  
                        <Text style={[style(WIDTH).fontSize2, style(WIDTH).text_view, style(WIDTH).create_title]}>Truck No. {errors.truck_no?
                            <Text style={style(WIDTH).fontError}>is REQUIRED, please select!</Text>: null}</Text>
                        <Controller name="truck_no" control={control} render={({ field: { onChange, onBlur, value } }) => (
                            <TextInput style={[style(WIDTH).create_input,style(WIDTH).fontSize2, {height:'auto'}]} onChangeText={onChange} onBlur={onBlur} value={value}/>)}/>                
                    </View>
                </View>

                <View style={[style(WIDTH).container_create_row, style(WIDTH).do_width]}>
                    <Controller name="pickupAddress" control={control} render={({ field: {} }) => null}/>  
                    <View style={[style(WIDTH).create_col_space]}>   
                        <Text style={[style(WIDTH).fontSize2, style(WIDTH).text_view, style(WIDTH).create_title]}>Pickup from {errors.pickupAddress?
                            <Text style={style(WIDTH).fontError}>is REQUIRED, please select!</Text>: null}</Text>
                        <TouchableOpacity style={[style(WIDTH).create_input, style(WIDTH).do_height]} onPress={() => showOverlay("shipper")}>
                            <Text style={[style(WIDTH).fontSize2, {fontWeight:'bold'}]}>Name</Text>
                            <Text style={[style(WIDTH).fontSize2]}>{shipper || '---'}</Text>
                            <View style={style(WIDTH).container_icon} />
                            <Text style={[style(WIDTH).fontSize2, {fontWeight:'bold'}]}>Address</Text>
                            <Text style={[style(WIDTH).fontSize2]}>{pickupAddress || '---'}</Text>
                        </TouchableOpacity>
                    </View>
                
                    <View style={style(WIDTH).container_icon} />
                
                    <View style={[style(WIDTH).create_col_space]}>   
                        <Controller name="deliverAddress" control={control} render={({ field: {} }) => null}/>  
                        <Text style={[style(WIDTH).fontSize2, style(WIDTH).text_view, style(WIDTH).create_title]}>Deliver to {errors.deliverAddress?
                            <Text style={style(WIDTH).fontError}>is REQUIRED, please select!</Text>: null}</Text>
                        <TouchableOpacity style={[style(WIDTH).create_input, style(WIDTH).do_height]} onPress={() => showOverlay("consignee")}>
                            <Text style={[style(WIDTH).fontSize2, {fontWeight:'bold'}]}>Name</Text>
                            <Text style={[style(WIDTH).fontSize2]}>{consignee || '---'}</Text>
                            <View style={style(WIDTH).container_icon} />
                            <Text style={[style(WIDTH).fontSize2, {fontWeight:'bold'}]}>Address</Text>
                            <Text style={[style(WIDTH).fontSize2]}>{deliveryAddress || '---'}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                
                <DataTable style={{padding: '1%', paddingTop: 0}}>
                    <DataTable.Header style={[style(WIDTH).dataTable_header_border, {marginTop:WIDTH*0.015}]}>
                        <View style={[style(WIDTH).dataTable_header,{flex:1}]}><Text style={[style(WIDTH).dataTable_content, {fontWeight:'bold'}]}>No. of packages</Text></View>
                        <View style={[style(WIDTH).dataTable_header,{flex:4}]}><Text style={[style(WIDTH).dataTable_content, {fontWeight:'bold'}]}>Description</Text></View>
                        <View style={[style(WIDTH).dataTable_header,{flex:1}]}><Text style={[style(WIDTH).dataTable_content, {fontWeight:'bold'}]}>M3</Text></View>
                        <View style={[style(WIDTH).dataTable_header,{flex:1}]}><Text style={[style(WIDTH).dataTable_content, {fontWeight:'bold'}]}>Weight (kg)</Text></View>
                        <View style={[style(WIDTH).dataTable_header,{flex:1}]}><Text style={[style(WIDTH).dataTable_content, {fontWeight:'bold'}]}>Marks & No</Text></View>
                        <View style={[style(WIDTH).dataTable_header,{flex:0.5}]}><Text style={[style(WIDTH).dataTable_content, {fontWeight:'bold'}]}></Text></View>
                    </DataTable.Header>
                    {table_content}
                    <View style={{alignItems:"center"}}>
                        <Popable content='Add item' position="right" strictPosition={true}backgroundColor='#FF9F1D' action="hover"> 
                        <Button icon={<Icon name='add-circle-outline' type="materialicons" size={option(WIDTH).iconSize2}/>} type="clear"
                        onPress={addRow}/></Popable>
                        {errors.package?.item?
                            <Text style={[style(WIDTH).fontError, style(WIDTH).fontSize2, style(WIDTH).create_title]}>No. of packages and Description are REQUIRED, please fill in!</Text>: null}   
                    </View>
                </DataTable>
            
                <View style={{width:WIDTH*0.55, flexDirection:'row'}}>
                    <View style={[style(WIDTH).create_col_space, {flexDirection:'row', alignItems:'center', flex:2}]}> 
                        <Text style={[style(WIDTH).fontSize2, style(WIDTH).text_view, style(WIDTH).create_title, {width:WIDTH*0.09}]}>Total package {errors.package?.total_quantity?
                            <Text style={style(WIDTH).fontError}>is REQUIRED, please fill in!</Text>: null}</Text>
                        <Controller name="package.total_quantity" control={control} render={({ field: { onChange, onBlur, value } }) => (
                            <TextInput style={[style(WIDTH).create_input, style(WIDTH).fontSize2, {width:WIDTH*0.25}]} multiline={true} onChangeText={onChange} onBlur={onBlur} value={value}/>)}/>   
                    </View>          

                    <View style={{flexDirection:'row', alignItems:'center', flexDirection:'row'}}> 
                        <Text style={[style(WIDTH).fontSize2, style(WIDTH).create_title]}>Total:    </Text>
                        <Text style={[style(WIDTH).fontSize2]} >{(total_m3 || '---') + ' m³, ' + (total_weight || '---') + ' kg'}</Text>
                    </View>                
                </View>

                <View style={{alignItems: 'flex-end'}}>
                    <ButtonPaper
                        style={[style(WIDTH).create_btn, {right: -WIDTH*0.015, marginTop: 0}]} 
                        labelStyle={style(WIDTH).create_btn_label} 
                        onPress={handleSubmit(onSubmit)}>
                            Save
                    </ButtonPaper>  
                </View>
            </View>
            
            <Overlay isVisible={state_showOverlay} onBackdropPress={hideOverlay} animationType='fade' overlayStyle={{width:WIDTH*0.95, maxHeight:'95%'}}>   
            <View style={style(WIDTH).overlayCloseButton}><Icon name='close' type="antdesign" size={option(WIDTH).iconSize3} onPress={hideOverlay}/></View>                     
                <ScrollView
                    contentContainerStyle={{flex:1}} 
                    showsHorizontalScrollIndicator={false} 
                    showsVerticalScrollIndicator={false}
                    focusable={false}
                >
                    <Client_list selectAddress={1} is_client={0} sendDataToParent={sendDataToParent}/>
                </ScrollView>                    
            </Overlay>  

            <DatePickerModal
                mode="single"
                visible={showCalander}
                onDismiss={onDismiss}
                date={calander_defaultDate}      
                onConfirm={onConfirm}
                validRange={{
                    // startDate: new Date()
                }}
                // onChange={} // same props as onConfirm but triggered without confirmed by user
                saveLabel="Select Date" // optional
                uppercase={false} // optional, default is true
                label='Select Pickup Date' // optional
                animationType="fade" // optional, default is 'slide' on ios/android and 'none' on web
            />      
        </View>
    )
}

export default DeliveryOrder_Edit;