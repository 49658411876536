import React, { useState, useEffect, useCallback , useRef}from 'react'
import { Dimensions, View, Text, TextInput, useWindowDimensions, StyleSheet, Pressable, Picker, ScrollView} from 'react-native'
import { useParams } from 'react-router';
import axios from 'axios';
import { API_URL, BASE_URL } from '../config/config';
import { MAX_FILE_SIZE_BYTE, maxWidth } from '../constants';
import { useForm, Controller } from "react-hook-form";
import { TabView, SceneMap, TabBar } from 'react-native-tab-view';
import { DataTable, overlay, Button as ButtonPaper } from 'react-native-paper';
import { useHover, Hoverable } from 'react-native-web-hooks';
import * as DocumentPicker from 'expo-document-picker';
import { style, option, grey2 } from '../styles/style.js';
import { date_to_ddmmyyyy, date_remove_time, isDigit, isDigit_withNegative} from '../components/utilities.js';
import { en, registerTranslation, DatePickerModal } from 'react-native-paper-dates';
import { set } from 'react-native-reanimated';
import Client_list from './Client_list.js';
import DeliveryOrder_Edit from './DeliveryOrder_edit.js';
import { Overlay, Icon, Tooltip, Button } from 'react-native-elements';
import SalesOrder_Duplicate from './SalesOrder_duplicate.js';
import { getWebsiteWidth } from '../components/utilities';
import { Popable } from 'react-native-popable';

function SalesOrder_View() {
    const [WIDTH, set_WIDTH] = useState(getWebsiteWidth());
    let {shipmentID} = useParams();
    const [SO, set_SO] = useState(null);
    const [invoice_date, set_invoice_date] = React.useState(null);
    const [invoice_date_str, set_invoice_date_str] = React.useState('');
    const [is_completed, set_is_completed] = React.useState(false);
    const [is_deleted, set_is_deleted] = React.useState(false);
    const [last_edit_name, set_last_edit_name] = React.useState('');
    const [is_salesOrder, set_is_salesOrder] = React.useState(false);
    const [is_unlock_request, set_is_unlock_request] = React.useState(false);
    const [unlock_requestor, set_unlock_requestor] = React.useState(false);
    const [invoice_path, set_invoice_path] = React.useState('');
    const [counter_delete, set_counter_delete] = React.useState(0);    
    const [num_cost_row, set_num_cost_row] = React.useState(1);    
    const [num_selling_row, set_num_selling_row] = React.useState(1);    
    const is_admin = window.localStorage.getItem("role") != 1;
    const isHovered = useHover(ref);
    const ref = useRef(null);

    // tab
    const [index, setIndex] = React.useState(0);
    const [routes] = React.useState([
        { key: 'tab_details', title: 'Details' },
        { key: 'tab_do', title: 'Delivery Order' },
        { key: 'tab_billing', title: 'Billing' },
        { key: 'tab_edoc', title: 'Edocs' },
    ]);

    //#region ------------ Details Tab's Declaration------------
    const [shipper, set_shipper] = React.useState('');
    const [consignee, set_consignee] = React.useState('');
    const [shipperName, set_shipperName] = React.useState('');
    const [consigneeName, set_consigneeName] = React.useState('');
    const [salespersonName, set_salespersonName] = React.useState('');
    const [clientName, set_clientName] = React.useState('');
    const [staffName, set_staffName] = React.useState('');
    const [createdAt, set_created_at] = React.useState('');

    const index_details = ["shipper","consignee","userID",
        "pod","pol","eta","etd","desc","m3","weight","referenceNo",
        "transportMode","salesID", "documentType"];
    const init_formDetails = {
        shipper: "",
        consignee: "",
        pod:"",
        pol:"",
        eta:"",
        etd:"",
        desc:"",
        m3:"",
        weight:"",
        referenceNo:"",
        transportMode:"road",
        salesID:"",
        documentType:""
    }    
    const { control: control_formDetails, handleSubmit: handleSubmit_formDetails, 
        reset: reset_formDetails, getValues: getValues_formDetails, setValue: setValue_formDetails } = useForm({
        defaultValues: init_formDetails        
    });    

    const onSubmit_Details = async(data) => {
        if(typeof(shipmentID) === "undefined")
            return;
        if(is_completed)
        {
            MessageBox("Error", "A completed sales order can't be saved.");
            return;
        }   
        ShowLoading();
        let _data = {};
        index_details.forEach(item=>{
            _data[item] = getValues_formDetails(item) == ""? null: getValues_formDetails(item);});
        let url = API_URL + 'salesorder';
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}` 
        }    
        _data.mode="Edit";
        _data.shipmentID=shipmentID;

        await axios({
            method:'post',
            url: url, 
            headers: headers,
            data: _data
        }).then(res => {   
            onLoad();
            MessageBox("", "Saved"); 
        }).catch(err => {
          MessageBox("Error", err.toString());
         }).finally(() => {
            HideLoading();
        })  
    }

    const MakeContentDetails = () => {
        let eta = getValues_formDetails("eta");
        let etd = getValues_formDetails("etd");
        if(typeof(eta) != "undefined" && eta != "")
        {
            let date = date_remove_time(new Date(eta));
            setValue_formDetails("eta", date.getTime());
            set_eta(date_to_ddmmyyyy(date));
        } 
        if(typeof(etd) != "undefined" && etd != "")
        {
            let date = date_remove_time(new Date(etd));
            setValue_formDetails("etd", date.getTime());
            set_etd(date_to_ddmmyyyy(date));
        } 
    }

    // Calander
    const [showCalander, set_showCalander] = useState(false);
    const [calander_defaultDate, set_calander_defaultDate] = useState('');
    const [calanderMessage, set_calanderMessage] = useState('');
    const [calander_attribute, set_calander_attribute] = useState('');
    const [eta, set_eta] = React.useState('');
    const [etd, set_etd] = React.useState('');
    const [last_dt, set_last_dt] = React.useState('');

    const onDismiss = useCallback(() => {
        set_showCalander(false);      
    });

    const doubleClickToSelect = useCallback((params) => {
        if(last_dt == params.date.toString())
        {
            onConfirm(params);
            set_showCalander(false); 
        }        
        else
            set_last_dt(params.date.toString());  
    });

    const onConfirm = useCallback((params) => {
        set_showCalander(false);
        let date = date_remove_time(new Date(params.date));
        let strDate = date_to_ddmmyyyy(date);
        setValue_formDetails(calander_attribute, date.getTime());
        switch (calander_attribute)
        {
            case "invoice_date":
                set_invoice_date(params.date);
                set_invoice_date_str(strDate);
                // DownloadInvoice(date.getTime());
                break;
            case "eta": 
                setValue_formDetails(calander_attribute, date.getTime());
                set_eta(strDate);
                break;
            case "etd":
                setValue_formDetails(calander_attribute, date.getTime());
                set_etd(strDate);
                break;
            default: break;
        }
      
    });

    // Overlay
    const [show_duplicateSoForm, set_show_duplicateSoForm] = useState(false);
    const [param_duplicateSO, set_param_duplicateSO] = useState(null);
    const showDuplicateSoForm = () => {
        if(is_completed) {
          set_show_duplicateSoForm(true);
        }
    };
    const hideDuplicateSoForm = () => {
        set_show_duplicateSoForm(false);
    };
    const receiveData_DuplicateSO = async(params) => {
        set_show_duplicateSoForm(false);
        if(params.shipmentID)
            window.open(`${BASE_URL}sales_order/${params.shipmentID}`, '_blank');
    }

    //#endregion
  
    //#region ------------ DO Tab's Declaration------------
    const [content_DO, set_content_DO] = useState([]);

    // Overlay
    const [show_FormDO, set_show_FormDO] = useState(false);
    const [edit_id_do, set_edit_id_do] = React.useState('');
    const [DoMode, set_DoMode] = React.useState('');
    const showCreateDoForm = (params) => {       
        set_DoMode(params.mode);
        if(params.mode == 'add' && !is_completed)
        {
            set_edit_id_do(null);
            set_show_FormDO(true);
        }
        else if (params.mode == 'edit')
        {
            set_edit_id_do(params.doID);
            set_show_FormDO(true);
        }        
    };
    const hideCreateDoForm = () => {
        set_show_FormDO(false);
    };
    const receiveData_CreateDO = (params) => {
        if(params.refresh) {    
            onLoad();
        }
        else if (params) {
            set_show_FormDO(false);
            onLoad();
        }
    }

    function roundToTwoDecimalPlaces(number) {
        const factor = 100;
        const roundedValue = Math.round(number * factor);
        return roundedValue / factor;
    }

    const calTotalAmount = (index) => {
        let qty = getValues_formBilling("billing.selling[" + index + "].qty");
        let unitPrice = getValues_formBilling("billing.selling[" + index + "].unit_price");
        let tax = getValues_formBilling("billing.selling[" + index + "].tax");
        if (qty && unitPrice) {
            setValue_formBilling("billing.selling[" + index + "].selling_amount", roundToTwoDecimalPlaces(roundToTwoDecimalPlaces(qty) * roundToTwoDecimalPlaces(unitPrice) * ((100 + parseInt(tax)) / 100)));
            // console.log(roundToTwoDecimalPlaces(qty), roundToTwoDecimalPlaces(unitPrice))
        }
        categorySelected(getValues_formBilling("billing.selling[" + index + "].selling_code"), index);
    }

    const MakeContentDO = (data, p_is_completed) => 
    {
        let content = null;
        if(data.length > 0)
        {
            content = data.map((item)=>{
                return(
                    <Hoverable key={item.doID}>
                        {isHovered => (
                        <Pressable key={item.doID} style={[
                            style(WIDTH).table_row,
                            p_is_completed && style(WIDTH).table_row_completed,
                            item.is_deleted && style(WIDTH).table_row_deleted,
                            isHovered && style(WIDTH).hover]}
                            onPress={() => {showCreateDoForm({mode:'edit', doID:item.doID})}}>
                            <DataTable.Row key={item.doID}>
                                <View style={[style(WIDTH).dataTable_content,{flex:1}]}><Text style={style(WIDTH).fontSize2}>{item.doID || '-'}</Text></View>
                                <View style={[style(WIDTH).dataTable_content,{flex:1}]}><Text style={style(WIDTH).fontSize2}>{item.truck_no || '-'}</Text></View>
                                <View style={[style(WIDTH).dataTable_content,{flex:2}]}><Text style={[style(WIDTH).dataTable_content_flex, style(WIDTH).fontSize2]}>{item.pickup_address || '-'}</Text></View>
                                <View style={[style(WIDTH).dataTable_content,{flex:2}]}><Text style={[style(WIDTH).dataTable_content_flex, style(WIDTH).fontSize2]}>{item.deliver_address || '-'}</Text></View>
                            </DataTable.Row>
                        </Pressable>
                        )}
                    </Hoverable>
                );
            }); 
        }
        set_content_DO(content);
    }
    //#endregion

    //#region ------------ Billing Tab's Declaration------------
    const [salesCategoryOption, set_salesCategoryOption] = useState([]);
    const [contentTableBilling_Cost, set_contentTableBilling_Cost] = useState([]);
    const [contentTableBilling_Selling, set_contentTableBilling_Selling] = useState([]);
    const [billingCreditorName, set_billingCreditorName] = React.useState([]);    
    const [grossProfit, set_grossProfit] = useState(null);    
    const init_formBilling = { billing: {cost:[], selling:[], selling_currency:"RM"} } ;  
    const { control: control_formBilling, handleSubmit: handleSubmit_formBilling, 
        reset: reset_formBilling, getValues: getValues_formBilling, setValue: setValue_formBilling } = useForm({
        defaultValues: init_formBilling        
    });   
    const [currentRow, set_currentRow] = useState(null);
    const [swapCost, set_swapCost] = useState(-1);
    const [swapSelling, set_swapSelling] = useState(-1);

    // Overlay
    const [state_showOverlay, set_state_showOverlay] = useState(false);
    const showOverlay = (index) => {
        set_currentRow(index);
        set_state_showOverlay(true);
    };
    const hideOverlay = () => {
        set_state_showOverlay(false);
    };
    const sendDataToParent = async(params) => {
        set_state_showOverlay(false);
        setValue_formBilling("billing.cost[" + currentRow + "].cost_creditor", params.id);
        let data = billingCreditorName;
        data[currentRow] = params.name /*+ '  |  ' + params.code*/;
        set_billingCreditorName(set_billingCreditorName);
    }

    let _run = false;
    useEffect(()=>{
        if(_run == false)
        {
            _run = true;
            renderUI();
            _run = false;
        }
    },[salesCategoryOption, billingCreditorName, WIDTH, swapSelling])

    const categorySelected = (value, index) => {
        if(value<0) {
            setValue_formBilling("billing.selling[" + index + "].unit_price", 0);
            setValue_formBilling("billing.selling[" + index + "].qty", 0);
            setValue_formBilling("billing.selling[" + index + "].selling_amount", 0);
            setValue_formBilling("billing.selling[" + index + "].uom", '');
            setValue_formBilling("billing.selling[" + index + "].tax", 0);
        }
    }
    const addRow_Cost = () => {
        for(let i=0; i<num_cost_row; i++) {
          let newIndex = typeof(getValues_formBilling("billing").cost) == "undefined"?
            0: getValues_formBilling("billing").cost.length;
          setValue_formBilling("billing.cost[" + newIndex + "].cost_description", "");
          setValue_formBilling("billing.cost[" + newIndex + "].cost_code", "");
          setValue_formBilling("billing.cost[" + newIndex + "].currency", "RM");
          setValue_formBilling("billing.cost[" + newIndex + "].cost_amount", "");
          setValue_formBilling("billing.cost[" + newIndex + "].cost_creditor", "");
          MakeContentBilling();
        }
    }
    const addRow_Selling = () => {
        for(let i=0; i<num_selling_row; i++) {
          let newIndex = typeof(getValues_formBilling("billing").selling) == "undefined"?
              0: getValues_formBilling("billing").selling.length;
          setValue_formBilling("billing.selling[" + newIndex + "].selling_description", "");
          setValue_formBilling("billing.selling[" + newIndex + "].selling_code", "");
          setValue_formBilling("billing.selling[" + newIndex + "].selling_amount", "");
          setValue_formBilling("billing.selling[" + newIndex + "].unit_price", "");
          setValue_formBilling("billing.selling[" + newIndex + "].qty", "");
          setValue_formBilling("billing.selling[" + newIndex + "].uom", "");
          setValue_formBilling("billing.selling[" + newIndex + "].tax", 0);
          MakeContentBilling();
        }
    }
    const deleteRow_Cost = (index) => {
        let count = getValues_formBilling("billing").cost.length;
        let cost = [];
        for (let i=0; i<count; i++)
        {
            if(i == index) continue;
            let _cost = {
                cost_description: getValues_formBilling("billing").cost[i].cost_description,
                cost_code: getValues_formBilling("billing").cost[i].cost_code,
                currency: getValues_formBilling("billing").cost[i].currency,
                cost_amount: getValues_formBilling("billing").cost[i].cost_amount,
                cost_creditor: getValues_formBilling("billing").cost[i].cost_creditor,
            };
            cost.push(_cost);
        }
        setValue_formBilling("billing.cost", cost);
        MakeContentBilling();
    }
    const deleteRow_Selling = (index) => {
        let count = getValues_formBilling("billing").selling.length;
        let selling = [];
        for (let i=0; i<count; i++)
        {
            if(i == index) continue;
            let _selling = {
                selling_description: getValues_formBilling("billing").selling[i].selling_description,
                selling_code: getValues_formBilling("billing").selling[i].selling_code,
                selling_amount: getValues_formBilling("billing").selling[i].selling_amount,
                qty: getValues_formBilling("billing").selling[i].qty,
                unit_price: getValues_formBilling("billing").selling[i].unit_price,
                uom: getValues_formBilling("billing").selling[i].uom,
                tax: getValues_formBilling("billing").selling[i].tax
            };
            selling.push(_selling);
        }
        setValue_formBilling("billing.selling", selling);
        MakeContentBilling();
    }

    useEffect(()=>{
        MakeContentBilling();
    },[swapSelling, swapCost])

    const swapRow = (type, index) => {
        if(type=="cost")
        {
            if (index == swapCost)
                set_swapCost(-1);
            else if (swapCost == -1)
                set_swapCost(index);
            else {
                let one = getValues_formBilling("billing.cost[" + index + "]");
                let two = getValues_formBilling("billing.cost[" + swapCost + "]");
                let temp = one;
                one = two;
                two = temp;
                setValue_formBilling("billing.cost[" + index + "]", one);
                setValue_formBilling("billing.cost[" + swapCost + "]", two);
                set_swapCost(-1);
                let _billingCreditorName = billingCreditorName;
                temp = _billingCreditorName[index];
                _billingCreditorName[index] = _billingCreditorName[swapCost];
                _billingCreditorName[swapCost] = temp;
                set_billingCreditorName(_billingCreditorName);
            }
        }
        else if(type=="selling")
        {
            if(index == swapSelling)
                set_swapSelling(-1);
            else if (swapSelling == -1)
                set_swapSelling(index);
            else{
                let one = getValues_formBilling("billing.selling[" + index + "]");
                let two = getValues_formBilling("billing.selling[" + swapSelling + "]");
                let temp = one;
                one = two;
                two = temp;
                setValue_formBilling("billing.selling[" + index + "]", one);
                setValue_formBilling("billing.selling[" + swapSelling + "]", two);
                set_swapSelling(-1);
            }
        }
        // let count = getValues_formBilling("billing").selling.length;
        // let selling = [];
        // for (let i=0; i<count; i++)
        // {
        //     if(i == index) continue;
        //     let _selling = {
        //         selling_description: getValues_formBilling("billing").selling[i].selling_description,
        //         selling_code: getValues_formBilling("billing").selling[i].selling_code,
        //         selling_amount: getValues_formBilling("billing").selling[i].selling_amount
        //     };
        //     selling.push(_selling);
        // }
        // setValue_formBilling("billing.selling", selling);
        // MakeContentBilling();
    }

    const MakeContentBilling = () => {
        set_contentTableBilling_Cost(null);
        set_contentTableBilling_Selling(null);
        
        if(typeof(getValues_formBilling("billing").cost) != "undefined")
        set_contentTableBilling_Cost(getValues_formBilling("billing").cost.map((item, index)=>{ return(
            <Hoverable key={index}>
            {isHovered => (     
            <Pressable 
                key={index} 
                style={[style(WIDTH).table_row, isHovered && style(WIDTH).hover]}>      
                <DataTable.Row key={index}>
                    <View style={[style(WIDTH).dataTable_content_billing,{flex:2.5}]}>
                        <Controller name={"billing.cost[" + index + "].cost_description"} rules={{required: true}} control={control_formBilling} render={({ field: { onChange, onBlur, value } }) => (
                            <TextInput multiline={true} style={[style(WIDTH).searchInput, style(WIDTH).fontSize2]} onChangeText={onChange} onBlur={onBlur} value={value}/>)}/>
                    </View>
                    <View style={style(WIDTH).dataTable_content_billing}>
                        <Controller name={"billing.cost[" + index + "].cost_code"} control={control_formBilling} render={({ field: { onChange, onBlur, value } }) => (
                            <TextInput style={[style(WIDTH).searchInput, style(WIDTH).fontSize2]} onChangeText={onChange} onBlur={onBlur} value={value}/>)}/>
                    </View>
                    <View style={[style(WIDTH).dataTable_content_billing,{flex:0.75}]}>
                        <Controller name={"billing.cost[" + index + "].currency"} rules={{required: true}} control={control_formBilling} render={({ field: { onChange, onBlur, value } }) => (
                            <Picker onValueChange={onChange} onBlur={null} value={value} style={[style(WIDTH).searchInput, style(WIDTH).fontSize2, style(WIDTH).picker]}>
                                <Picker.Item label="RM" value="RM" />
                                <Picker.Item label="EUR" value="EUR" />
                                <Picker.Item label="SGD" value="SGD" />
                                <Picker.Item label="USD" value="USD" />
                            </Picker>)}/>
                            </View>
                    <View style={style(WIDTH).dataTable_content_billing}>
                        <Controller name={"billing.cost[" + index + "].cost_amount"} rules={{required: true}} control={control_formBilling} render={({ field: { onChange, onBlur, value } }) => (
                            <TextInput style={[style(WIDTH).searchInput, style(WIDTH).fontSize2]} onChangeText={(val) => {if(isDigit(val)) onChange(val)}} onBlur={onBlur} value={value}/>)}/>
                    </View>
                    <View style={{display:'none', flex:0}}>
                        <Controller name={"billing.cost[" + index + "].cost_creditor"} rules={{required: true}} control={control_formBilling} render={({ field: { onChange, onBlur, value } }) => (
                            <TextInput onChangeText={onChange} onBlur={onBlur} value={value}/>)}/>
                    </View>
                    <View style={style(WIDTH).dataTable_content_billing}>  
                        <TextInput style={[style(WIDTH).searchInput, style(WIDTH).fontSize2]} onFocus={()=>showOverlay(index)} onChangeText={null} 
                        value={billingCreditorName[index]} multiline={true} scrollEnabled={false} 
                        showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}/>
                    </View>
                    <View style={[style(WIDTH).dataTable_content_billing, {alignItems:'flex-end', flexDirection:'row'}]}>
                        <Popable content='Swap' position="left" backgroundColor='#FF9F1D' action="hover"> 
                        <Button icon={<Icon name='swap' type="antdesign" color={swapCost==index? '#FF9F1D':"black"} size={option(WIDTH).iconSize2}/>} type="clear"
                            onPress={() => swapRow('cost', index)}/></Popable>
                        <View style={style(WIDTH).container_icon}></View>
                        <Popable content='Delete' position="left" backgroundColor='#FF9F1D' action="hover"> 
                        <Button icon={<Icon name='delete-outline' type="MaterialIcons" color="red" size={option(WIDTH).iconSize2}/>} type="clear"
                            onPress={() => deleteRow_Cost(index)}/></Popable>
                    </View>
                </DataTable.Row>
            </Pressable>)}
            </Hoverable>
        )}));

        if(typeof(getValues_formBilling("billing").selling) != "undefined")
        set_contentTableBilling_Selling(getValues_formBilling("billing").selling.map((item, index)=>{ return(
            <Hoverable key={index}>
            {isHovered => (     
                <Pressable 
                    key={index} 
                    style={[style(WIDTH).table_row,isHovered && style(WIDTH).hover]}>       
                    <DataTable.Row key={index}>
                        <View style={[style(WIDTH).dataTable_content_billing,{flex:4}]}>
                            <Controller rules={{required: true}}name={"billing.selling[" + index + "].selling_description"} control={control_formBilling} render={({ field: { onChange, onBlur, value } }) => (
                                <TextInput multiline={true} style={[style(WIDTH).searchInput, style(WIDTH).fontSize2]} onChangeText={onChange} onBlur={onBlur} value={value}/>)}/>
                        </View>
                        <View style={[style(WIDTH).dataTable_content_billing,{flex:2}]}>
                            <Controller name={"billing.selling[" + index + "].selling_code"} rules={{required: true}} control={control_formBilling} render={({ field: { onChange, onBlur, value } }) => (
                            <Picker style={[style(WIDTH).searchInput, style(WIDTH).fontSize2, style(WIDTH).picker]} onBlur={null} value={value}
                                onValueChange={(itemValue) => {
                                    onChange(itemValue); 
                                    categorySelected(itemValue, index);}}>
                                {salesCategoryOption}
                            </Picker>)}/>
                        </View>
                        <View style={[style(WIDTH).dataTable_content_billing, {flex:  0.5}]}>
                            <Controller rules={{required: true}} name={"billing.selling[" + index + "].qty"} control={control_formBilling} render={({ field: { onChange, onBlur, value } }) => (
                                <TextInput style={[style(WIDTH).searchInput, style(WIDTH).fontSize2]} onChangeText={(val) => {
                                    if(isDigit(val)) 
                                        onChange(val);
                                    calTotalAmount(index);
                                    }} onBlur={onBlur} value={value}/>)}/>
                        </View>
                        <View style={[style(WIDTH).dataTable_content_billing,{flex:1}]}>
                            <Controller name={"billing.selling[" + index + "].uom"} control={control_formBilling} render={({ field: { onChange, onBlur, value } }) => (
                            <Picker style={[style(WIDTH).searchInput, style(WIDTH).fontSize2, style(WIDTH).picker]} onBlur={null} value={value}
                                onValueChange={(itemValue) => {
                                    onChange(itemValue); 
                                    categorySelected(itemValue, index);}}>
                              <Picker.Item label="" value="" />
                              <Picker.Item label="CTN" value="CTN" />
                              <Picker.Item label="KG" value="KG" />
                              <Picker.Item label="MT" value="MT" />
                              <Picker.Item label="PLT" value="PLT" />
                              <Picker.Item label="TRIP" value="TRIP" />
                              <Picker.Item label="SET" value="SET" />
                              <Picker.Item label="JOB" value="JOB" />
                              <Picker.Item label="CBM" value="CBM" />
                            </Picker>)}/>
                        </View>
                        <View style={style(WIDTH).dataTable_content_billing}>
                            <Controller rules={{required: true}} name={"billing.selling[" + index + "].unit_price"} control={control_formBilling} render={({ field: { onChange, onBlur, value } }) => (
                                <TextInput style={[style(WIDTH).searchInput, style(WIDTH).fontSize2]} onChangeText={(val) => {
                                    if(isDigit_withNegative(val)) 
                                        onChange(val);
                                    calTotalAmount(index);
                                    }} onBlur={onBlur} value={value}/>)}/>
                        </View>
                        <View style={[style(WIDTH).dataTable_content_billing, { flex: 0.6 }]}>
                                        <Controller name={"billing.selling[" + index + "].tax"} control={control_formBilling} render={({ field: { onChange, onBlur, value } }) => (
                                            <Picker style={[style(WIDTH).searchInput, style(WIDTH).fontSize2, style(WIDTH).picker]} onBlur={null} value={value}
                                                onValueChange={(itemValue) => {
                                                    onChange(itemValue);
                                                    calTotalAmount(index);
                                                }}>
                                                <Picker.Item label="-" value="0" />
                                                <Picker.Item label="6%" value="6" />
                                                {/* <Picker.Item label="8%" value="8" /> */}
                                            </Picker>)} />
                                    </View>
                        <View style={style(WIDTH).dataTable_content_billing}>
                            <Controller name={"billing.selling[" + index + "].selling_amount"} control={control_formBilling} render={({ field: { onChange, onBlur, value } }) => (
                                <Text style={[style(WIDTH).textBox, style(WIDTH).fontSize2]}>{value}</Text>)}/>
                        </View>
                        <View style={[style(WIDTH).dataTable_content_billing, {alignItems:'flex-end', flexDirection:'row'}]}>
                            <Popable content='Swap' position="left" backgroundColor='#FF9F1D' action="hover"> 
                            <Button icon={<Icon name='swap' type="antdesign" color={swapSelling==index? '#FF9F1D':"black"} size={option(WIDTH).iconSize2}/>} type="clear"
                                onPress={() => swapRow('selling', index)}/></Popable>
                            <View style={style(WIDTH).container_icon}></View>
                            <Popable content='Delete' position="left" backgroundColor='#FF9F1D' action="hover"> 
                            <Button icon={<Icon name='delete-outline' type="MaterialIcons" color="red" size={option(WIDTH).iconSize2}/>} type="clear"
                                onPress={() => deleteRow_Selling(index)}/></Popable>
                        </View>
                    </DataTable.Row>
                </Pressable>)}
            </Hoverable>
        )}));

        let gp_array = [];
        if(getValues_formBilling("gross_profit.RM") != null)
            gp_array.push("RM " + roundToTwoDecimalPlaces(getValues_formBilling("gross_profit").RM).toFixed(2));
        if(getValues_formBilling("gross_profit.SGD") != null)
            gp_array.push("SGD " + roundToTwoDecimalPlaces(getValues_formBilling("gross_profit").SGD).toFixed(2));
        if(getValues_formBilling("gross_profit.USD") != null)
            gp_array.push("USD " + roundToTwoDecimalPlaces(getValues_formBilling("gross_profit").USD).toFixed(2));
        if(getValues_formBilling("gross_profit.EUR") != null)
            gp_array.push("EUR " + roundToTwoDecimalPlaces(getValues_formBilling("gross_profit").EUR).toFixed(2));

        set_grossProfit(gp_array.map((item, index)=>{ return(
            <Text key={item} style={style(WIDTH).fontSize2}>{item}</Text>
        )}));
    };
    const onSubmit_Billing = async(data) => {
        if(typeof(shipmentID) === "undefined")
            return;
        if(is_completed)
        {
            MessageBox("Error", "Trying to save a completed sales order.");
            return;
        }   

        ShowLoading();
        let _data = {};
        let url = API_URL + 'salesorder';
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}` 
        }    
        _data.billing = getValues_formBilling("billing");
        _data.mode="Edit";
        if(invoice_date)
            _data.invoice_date =  date_remove_time(new Date(invoice_date)).getTime();
        _data.shipmentID=shipmentID;
        for(let i=0; i<_data.billing.selling.length; i++) {
          if(_data.billing.selling[i].selling_code < 0)
            _data.billing.selling[i].selling_amount = 0;
        }
        await axios({
            method:'post',
            url: url, 
            headers: headers,
            data: _data
        }).then(res => {   
            // let data = res.data.result;
            // let new_formBilling = init_formBilling;
            // if(data["billing"] != null && data["billing"] != [])
            //     new_formBilling["billing"] = data["billing"];
            // new_formBilling["gross_profit"] = data["gross_profit"];
            // reset_formBilling(new_formBilling);
            // MakeContentBilling();
            MessageBox("", "Saved"); 
            onLoad();
        }).catch(err => {
            MessageBox("Error",err.toString());
            console.log(err.response.data.result);
         }).finally(() => {
            HideLoading();
        })        
    }
    //#endregion

    //#region ------------ EDOCS Tab's Declaration------------
    const [content_edoc, set_content_edoc] = useState(null);
    const init_formEdocs = { edocs:[]} ;  
    const { control: control_formEdocs, handleSubmit: handleSubmit_formEdocs,
        reset: reset_formEdocs, getValues: getValues_formEdocs, setValue: setValue_formEdocs } = useForm({
        defaultValues: init_formEdocs        
    });  
  
    const deleteRow_edocs = (index) => {
        let count = getValues_formEdocs("edocs").length;

        let files = [];
        for (let i=0; i<count; i++)
        {
            if(i == index) continue;
            let _file = {
                desc: getValues_formEdocs("edocs[" + i + "].desc"),
                path: getValues_formEdocs("edocs[" + i + "].path")
            };
            files.push(_file);
        }
        setValue_formEdocs("edocs", files);
        MakeContentEdocs();
    }
 

    const onSubmit_Edocs = async(data) => {
        if(typeof(shipmentID) === "undefined")
            return;
        if(is_completed)
        {
            MessageBox("Error", "A completed sales order can't be saved.");
            return;
        }   

        ShowLoading();
        let _data = data;     
        let url = API_URL + 'salesorder';
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}` 
        }   
        _data.mode="Edit";
        _data.shipmentID=shipmentID;
       
        await axios({
            method:'post',
            url: url, 
            headers: headers,
            data: _data
        }).then(data => {   
            MessageBox("", "Saved"); 
        }).catch(err => {
            MessageBox("Error",err.toString());
            console.log(err.response.data.result);
         }).finally(() => {
            HideLoading();
        })        
    }
    
    const MakeContentEdocs = () => {
        set_content_edoc(getValues_formEdocs("edocs").map((item, key)=>{
            let itemPath = item.path.split('/');
            const path = API_URL + 'uploads/extra/' + itemPath[itemPath.length-2] + '/' + itemPath[itemPath.length-1]; // due to BE problem, have to handle like this
            return(
                <Hoverable key={key}>
                    {isHovered => (     
                        <Pressable 
                            key={item.ID} 
                            style={[style(WIDTH).table_row, isHovered && style(WIDTH).hover]}>         
                        <DataTable.Row key={item.doID}>
                            
                        <View style={[style(WIDTH).dataTable_content,{flex:4}]}>
                            <Controller name={"edocs[" + key + "].desc"} rules={{required: true}} control={control_formEdocs} render={({ field: { onChange, onBlur, value } }) => (
                            <TextInput multiline={true} style={[style(WIDTH).searchInput, style(WIDTH).fontSize2]} onChangeText={onChange} onBlur={onBlur} value={value}/>)}/>
                        </View>
                        <View style={{flex: 1, justifyContent: 'flex-end', flexDirection:'row'}}>
                            <Popable content='Download' position="left" backgroundColor='#FF9F1D' action="hover"> 
                            <Button icon={<Icon name='cloud-download-outline' type="ionicon" size={option(WIDTH).iconSize2}/>} type="clear"
                                onPress={() => DownloadFile(path)}/></Popable>
                            <View style={style(WIDTH).container_icon}></View>
                            <Popable content='Delete' position="left" backgroundColor='#FF9F1D' action="hover"> 
                            <Button icon={<Icon name='delete-outline' type="MaterialIcons" color="red" size={option(WIDTH).iconSize2}/>} type="clear"
                                onPress={() => deleteRow_edocs(key)}/></Popable>
                        </View>
                        </DataTable.Row>
                        </Pressable>   
                    )}
                </Hoverable>
            );
        }));
    }  
    //#endregion

    useEffect(() => {      
        if(typeof(shipmentID) === "undefined")
            return; 
        window.addEventListener('resize', () => { set_WIDTH(getWebsiteWidth()); });
        onLoad();
    },[]);    

    const onLoad = async () => {
        ShowLoading();
        await getSalesOrder();
        await getSalesCategoryOption();  
        HideLoading();
    }

    const getSalesOrder = async(req, params) => {  
        try{
            const token = window.localStorage.getItem('token');
            let url = API_URL + 'salesorder/' + shipmentID;
            const headers = { Authorization: `Bearer ${token}` };
            const res = await axios({
                method:'get',
                url: url,
                headers: headers
            });
            if(res.status === 200)
            {    
                const data = res.data.result;
                if(data.shipmentID != shipmentID || (data.is_deleted && !is_admin))
                    window.location.href = BASE_URL + "sales_order";  

                set_is_salesOrder(data.documentType=="Sales Order"? true: false);
                set_is_completed(data.is_complete);
                set_is_deleted(data.is_deleted);
                set_is_unlock_request(data.is_complete? data.unlock_request: null);
                set_unlock_requestor(data.unlock_request? data.unlock_request: null);
                set_salespersonName(data.sales_name);
                set_clientName(data.client_name);                
                set_last_edit_name(data.last_edit_name);
                set_staffName(data.username);
                set_shipper({id:data.shipper,name:data.shipper_name});
                set_consignee({id:data.consignee,name:data.consignee_name});
                set_shipperName(data.shipper_name);
                set_consigneeName(data.consignee_name);
                set_invoice_date(data.invoice_date? new Date(data.invoice_date): null);
                if(data.invoice_date)
                    set_invoice_date_str(date_to_ddmmyyyy(date_remove_time(new Date(data.invoice_date))));
                set_invoice_path(data.path);
                set_created_at(data.createdAt);
                set_param_duplicateSO({
                    documentType: data.documentType,
                    shipmentID: data.shipmentID,
                    year: data.year,
                    month: data.month,
                    sales: data.salesID,
                    sales_name: data.sales_name,
                    client: data.client,
                    client_code: data.client_code,
                    client_name: data.client_name,
                    shipper: data.shipper,
                    shipper_name: data.shipper_name,
                    consignee: data.consignee,
                    consignee_name: data.consignee_name,
                });
             
                let _clientDetails = [];
                for(let i=0; data.billing && data.billing.cost && i<data.billing.cost.length; i++)
                    for(let j=0; j<data.creditor_name.length; j++)
                        if(data.billing.cost[i].cost_creditor == data.creditor_name[j].clientID)
                        {   
                            _clientDetails[i] = data.creditor_name[j].name;
                            break;
                        }
                set_billingCreditorName(_clientDetails);
                
                let new_formDetails = init_formDetails;
                index_details.forEach(item=>{
                    if(item == 'pol') new_formDetails[item] = (data[item] == null? data.shipper_name: data[item]);
                    else if(item == 'pod') new_formDetails[item] = (data[item] == null? data.consignee_name: data[item]);
                    else new_formDetails[item] = (data[item] == null? "": data[item]);});
                reset_formDetails(new_formDetails);
        
                let new_formBilling = init_formBilling;
                if(data["billing"] != null && data["billing"] != [])
                    new_formBilling["billing"] = data["billing"];
                new_formBilling["gross_profit"] = data["gross_profit"];
                reset_formBilling(new_formBilling);
        
                let new_formEdocs = init_formEdocs;
                if(data["edocs"] != null) new_formEdocs["edocs"] = data["edocs"];
                reset_formEdocs(new_formEdocs);

                set_SO(data);
                renderUI();
            }    
        }       
        catch(err){
            MessageBox("Error", err.toString());
            console.log(err.response.data.result);
            // window.location.href = BASE_URL + "sales_order";
        }
    };

    const renderUI = () => {      
        if(SO == null) return; 
        ShowLoading();
        MakeContentDO(SO.doList, SO.is_complete);
        MakeContentBilling();
        MakeContentEdocs();
        MakeContentDetails();     
        HideLoading();
    }
    
    const completeSalesOrder = async(status) => {
        ShowLoading();
        const token = window.localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        await axios({
            method:'post',
            url: API_URL + 'salesorder',
            headers: headers,
            data: {mode:"Edit", is_complete:status, shipmentID:shipmentID}
        }).then(() => {
            MessageBox("Sales order completed.");
            window.location.reload(false);
        }).catch(err => {
            if(typeof(err.response.data.result) != "undefined")
                MessageBox("Error", err.response.data.result);
            else if (status)
                MessageBox("Error", "Fail to complete sales order");
            else
                MessageBox("Error", "Fail to unlock sales order");
            console.log(err);
        }).finally(()=>{
            HideLoading();
        })         
    }

    const deleteSalesOrder = async() => {
        if(is_deleted && !is_admin) return;

        ShowLoading();
        const token = window.localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        await axios({
            method:'post',
            url: API_URL + "utils/delete/salesorder", 
            headers: headers,
            data: {id:shipmentID}
        }).then(() => {
            MessageBox("Sales order deleted.");
            if(is_admin && !is_deleted) window.location.reload(false);
            else window.location.href = `${BASE_URL}sales_order`;
        }).catch(err => {
            MessageBox("Error", "Fail to delete sales order");
            console.log(err);
        }).finally(()=>{
            HideLoading();
        })         
    }

    const undoDeleteSalesOrder = async() => {
        if(!is_admin) return;

        ShowLoading();
        const token = window.localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        await axios({
            method:'post',
            url: API_URL + "salesorder/restoreRecord", 
            headers: headers,
            data: {shipmentID:shipmentID}
        }).then((data) => {
            console.log(data);
            MessageBox("Restored sales order.");
            window.location.reload(false);
        }).catch(err => {
            MessageBox("Error", "Fail to restore sales order");
            console.log(err);
        }).finally(()=>{
            HideLoading();
        })         
    }
    
    const rejectUnlockRequest = async() => {
        if(!is_admin) return;

        ShowLoading();
        await axios({
            method:'post',
            url: API_URL + 'salesorder/rejectRequest',
            headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` },
            data: {shipmentID:shipmentID}
        }).then(() => {
            MessageBox("Rejected unlock reqeust.");
            window.location.reload(false);
        }).catch(err => {
            MessageBox("Error", "Fail to reject unlock request.");
            console.log(err);
        }).finally(()=>{
            HideLoading();
        })         
    }

    const requestUnlock = async() => {
        if(!is_completed) 
        {
            MessageBox("Error", "Unable to request unlock as the sales order is not completed.");
            return;
        }
        if(is_admin) {
            ShowLoading();    
            await axios({
                method:'post',
                url: API_URL + 'salesorder/approveRequest',
                headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` },
                data: {shipmentID:shipmentID}
            }).then(() => {
                MessageBox("Sales order unlocked.");
                window.location.reload(false);
            }).catch(err => {
                if(typeof(err.response.data.result) != "undefined")
                    MessageBox("Error", err.response.data.result);
                else
                    MessageBox("Error", "Fail to unlock sales order");
                console.log(err);
            }).finally(()=>{
                HideLoading();
            })         
        }
        else {  
            ShowLoading();    
            const token = window.localStorage.getItem('token');
            const headers = { Authorization: `Bearer ${token}` };
            await axios({
                method:'post',
                url: API_URL + "/salesorder/requestModified", 
                headers: headers,
                data: {shipmentID:shipmentID}
            }).then(() => {
                MessageBox("", "The request of unlock sales order is sent.");
                window.location.reload(false);
            }).catch(err => {
                MessageBox("Error", "Fail to request for unlock sales order.");
                console.log(err);
            }).finally(()=>{
                HideLoading();
            })    
        }     
    }

    // const DownloadInvoice = async(date) => {
    //     ShowLoading();
    //     const token = window.localStorage.getItem('token');
    //     const headers = { Authorization: `Bearer ${token}` };
    //     await axios({
    //         method:'post',
    //         url: API_URL + 'salesorder/confirmInvoice',
    //         headers: headers,
    //         data: {shipmentID:shipmentID, invoice_date: date}
    //     }).then(res => {
    //         if(res.data.result.path) DownloadFile(res.data.result.path);
    //         else MessageBox("Error", res.data.result.invoice_message);            
    //     }).catch(err => {
    //         MessageBox("Error", "Fail to download invoice.\nPlease make sure all the necessary data is provided.");
    //         console.log(err);
    //     }).finally(()=>{
    //         HideLoading();
    //     })         
    // }

    const DownloadFile = async(url) => {
        if(!url) {
            MessageBox("Error", "Invoice not available");
            return;
        }
        ShowLoading();
        const token = window.localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        await axios({
            method:'get',
            url: url, 
            headers: headers,
            responseType: 'blob',
            // data: {shipmentID:shipmentID}
        }).then(response => {
            let filename = url.split('/').pop();
            const _url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = _url;
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();
        }).catch(err => {
            MessageBox("Error", "Fail to download file");
            console.log(err);
        }).finally(()=>{
            HideLoading();
        })         
    }

    const getSalesCategoryOption= async(req, params) => {
        window.API_CALL_GET('utils/options/account_no').then(
          function(data) {        
            data.unshift({title:"REMARK", id:"-3"});
            data.unshift({title:"UNLOADING", id:"-2"});
            data.unshift({title:"LOADING", id:"-1"});
            data.unshift({title:"Select...", id:""});
            let content = data.map((item)=>{
                return(
                    <Picker.Item label={item.title} key={item.id} value={item.id}/>                      
            )});
            set_salesCategoryOption(content);
            
          },
          function(error) { MessageBox("Error", error); }
        );
    };

    const Tab_Details = () => (
        <View style={style(WIDTH).container_column} pointerEvents={is_completed || is_deleted? "none":"auto"}>
            <View style={style(WIDTH).container_create_row}>
                <View style={[style(WIDTH).container_view_col]}>            
                    <Text style={[style(WIDTH).fontSize2, style().text_create, {fontWeight:'bold'}]}>Document Type</Text>
                        <Controller name="documentType" control={control_formDetails} render={({ field: { value }}) => (
                            <Text style={[style(WIDTH).fontSize2, style().text_create]}>{value || "---"}</Text>)}/>
                </View>
                <View style={[style(WIDTH).container_view_col]}> 
                    <Text style={[style(WIDTH).fontSize2, style().text_create, {fontWeight:'bold'}]}>Created by Staff</Text>
                    <Text style={[style(WIDTH).fontSize2, style(WIDTH).text_create]} multiline={true}>{staffName || "---"}</Text>
                </View>
                <View style={[style(WIDTH).container_view_col]}>            
                    <Text style={[style(WIDTH).fontSize2, style().text_create, {fontWeight:'bold'}]}>Salesperson</Text>
                    <Text style={[style(WIDTH).fontSize2, style(WIDTH).text_create]} multiline={true}>{salespersonName || "---"}</Text>
                </View>
                <View style={[style(WIDTH).container_view_col]}>            
                    <Text style={[style(WIDTH).fontSize2, style().text_create, {fontWeight:'bold'}]}>Created At</Text>
                    <Text style={[style(WIDTH).fontSize2, style().text_create]}>{createdAt || "---"}</Text>
                </View>
            </View>

            <View style={style(WIDTH).container_create_row}>
                <View style={[style(WIDTH).container_view_col]}>            
                    <Text style={[style(WIDTH).fontSize2, style().text_create, {fontWeight:'bold'}]}>Client</Text>
                    <Text style={[style(WIDTH).fontSize2, style(WIDTH).text_create]} multiline={true}>{clientName || "---"}</Text>
                </View>
                <View style={[style(WIDTH).container_view_col]}> 
                    <Text style={[style(WIDTH).fontSize2, style().text_create, {fontWeight:'bold'}]}>Shipper</Text>
                    <Text style={[style(WIDTH).fontSize2, style(WIDTH).text_create]} multiline={true}>{shipperName || "---"}</Text>
                </View>
                <View style={[style(WIDTH).container_view_col]}> 
                    <Text style={[style(WIDTH).fontSize2, style().text_create, {fontWeight:'bold'}]}>Consignee</Text>
                    <Text style={[style(WIDTH).fontSize2, style(WIDTH).text_create]} multiline={true}>{consigneeName || "---"}</Text>
                </View>
            </View>

            {is_salesOrder && <View>
                <View style={style(WIDTH).container_create_row}>                   
                    <Text style={[style(WIDTH).fontSize2, style(WIDTH).text_view,style(WIDTH).create_attribute]}>Mode of Shipment</Text>
                    <Controller name="transportMode" control={control_formDetails} render={({ field: { onChange, onBlur, value } }) => (
                        <Picker style={[style(WIDTH).container_view_tb_short,style(WIDTH).fontSize2,style(WIDTH).picker]} onValueChange={onChange} onBlur={null} value={value}>
                            <Picker.Item label="Road" value="road" />
                            <Picker.Item label="Sea" value="Sea" />
                            <Picker.Item label="Air" value="Air" />
                        </Picker>)}/>
                </View>

                <View style={style(WIDTH).container_create_row}>                   
                    <Text style={[style(WIDTH).fontSize2, style(WIDTH).text_view,style(WIDTH).create_attribute]}>POL / Loading</Text>
                    <Controller name="pol" control={control_formDetails} render={({ field: { onChange, onBlur, value } }) => (
                        <TextInput multiline={true} style={[style(WIDTH).container_view_tb,style(WIDTH).fontSize2]} onChangeText={onChange} onBlur={onBlur} value={value}/>)}/>
                </View>

                <View style={style(WIDTH).container_create_row}>                   
                    <Text style={[style(WIDTH).fontSize2, style(WIDTH).text_view,style(WIDTH).create_attribute]}>POD / Unloading</Text>
                    <Controller name="pod" control={control_formDetails} render={({ field: { onChange, onBlur, value } }) => (
                        <TextInput multiline={true} style={[style(WIDTH).container_view_tb,style(WIDTH).fontSize2]} onChangeText={onChange} onBlur={onBlur} value={value}/>)}/>
                </View>
              
                <View style={style(WIDTH).container_create_row}>                   
                    <Text style={[style(WIDTH).fontSize2, style(WIDTH).text_view,style(WIDTH).create_attribute]}>ETD</Text>
                    <TextInput style={[style(WIDTH).container_view_tb_short,style(WIDTH).fontSize2]} value={etd} onFocus ={()=>{
                        set_calander_defaultDate(new Date());
                        set_calander_attribute('etd');
                        set_last_dt('');
                        set_showCalander(true);
                        set_calanderMessage('Select ETD');}}/>
                </View>

                <View style={style(WIDTH).container_create_row}>                   
                    <Text style={[style(WIDTH).fontSize2, style(WIDTH).text_view,style(WIDTH).create_attribute]}>ETA</Text>
                    <TextInput style={[style(WIDTH).container_view_tb_short,style(WIDTH).fontSize2]} value={eta} onFocus ={()=>{
                        set_calander_defaultDate(new Date());
                        set_calander_attribute('eta');
                        set_last_dt('');
                        set_showCalander(true);
                        set_calanderMessage('Select ETA');}}/>
                </View>

                <View style={style(WIDTH).container_create_row}>                   
                    <Text style={[style(WIDTH).fontSize2, style(WIDTH).text_view,style(WIDTH).create_attribute]}>Customer's Reference</Text>
                    <Controller name="referenceNo" control={control_formDetails} render={({ field: { onChange, onBlur, value } }) => (
                        <TextInput multiline={true} style={[style(WIDTH).container_view_tb,style(WIDTH).fontSize2]} onChangeText={onChange} onBlur={onBlur} value={value}/>)}/>
                </View>

               
                <View style={{alignItems:'flex-end'}}>
                    <ButtonPaper
                        style={[style(WIDTH).button_orange, {marginTop:WIDTH*0.02, marginRight: WIDTH*0.252}]}
                        labelStyle={[style(WIDTH).button_label_white, style(WIDTH).fontSize2]} 
                        onPress={handleSubmit_formDetails(onSubmit_Details)}
                    >
                        Save
                    </ButtonPaper>
                </View>
            </View>}
        </View>
    );
    
    const Tab_DO = () => (
        <View style={{ flex: 1}}>
            {is_salesOrder && <View>
            <DataTable style={{padding: '2%'}}>
                <DataTable.Header style={style(WIDTH).dataTable_header_border}>
                    <View style={[style(WIDTH).dataTable_header, {flex:1}]}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>ID</Text></View>
                    <View style={[style(WIDTH).dataTable_header, {flex:1}]}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>Truck No</Text></View>
                    <View style={[style(WIDTH).dataTable_header, {flex:2}]}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>Pickup Address</Text></View>
                    <View style={[style(WIDTH).dataTable_header, {flex:2}]}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>Delivery Address</Text></View>
                </DataTable.Header>
                {content_DO}
                {!is_completed?
                <View style={{alignItems:"center"}} pointerEvents={is_completed || is_deleted? "none":"auto"}>
                    <Popable content='Add D.O.' position="right" strictPosition={true} backgroundColor='#FF9F1D' action="hover"> 
                    <Button icon={<Icon name='add-circle-outline' type="materialicons" size={option(WIDTH).iconSize2}/>} type="clear"
                        onPress={() => showCreateDoForm({mode:'add'})}/></Popable>
                </View>:null}
            </DataTable>          
            </View>}
        </View>
    );

    const Tab_Billing = () => (
        <View style={{ flex: 1}} pointerEvents={is_completed || is_deleted? "none":"auto"}>
            {is_salesOrder && 
            <View style={{alignItems:"center"}}>
                <Text style={style(WIDTH).text_billing}>Cost</Text>
                <DataTable>
                    <View style={style(WIDTH).container_icon} />
                    <DataTable.Header style={style(WIDTH).dataTable_header_border}>
                        <View style={[style(WIDTH).dataTable_header,{flex:2.5}]}><Text style={[style(WIDTH).dataTable_content_billing, {fontWeight:'bold'}]}>Description</Text></View>
                        <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content_billing, {fontWeight:'bold'}]}>Code (optional)</Text></View>
                        <View style={[style(WIDTH).dataTable_header,{flex:0.75}]}><Text style={[style(WIDTH).dataTable_content_billing, {fontWeight:'bold'}]}>Currency</Text></View>
                        <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content_billing, {fontWeight:'bold'}]}>Amount</Text></View>
                        <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content_billing, {fontWeight:'bold'}]}>Creditor</Text></View>
                        <View style={[style(WIDTH).dataTable_header,{flex:1}]}></View>
                    </DataTable.Header>
                        {contentTableBilling_Cost}  
                    <View style={{alignSelf:"center", flexDirection:'row'}}>
                    <Picker style={[style(WIDTH).picker_billing_add_row, style(WIDTH).fontSize2, style(WIDTH).picker, {alignSelf:'center'}]} 
                          onValueChange={set_num_cost_row} value={num_cost_row}>
                            <Picker.Item label="1" value="1" />
                            <Picker.Item label="3" value="3" />
                            <Picker.Item label="5" value="5" />
                            <Picker.Item label="10" value="10" />
                            <Picker.Item label="15" value="15" />
                            <Picker.Item label="20" value="20" />
                        </Picker>                        
                        <Popable content='Add cost item' position="right" strictPosition={true}backgroundColor='#FF9F1D' action="hover"> 
                        <Button icon={<Icon name='add-circle-outline' type="materialicons" size={option(WIDTH).iconSize2}/>} type="clear"
                            onPress={addRow_Cost}/></Popable>
                    </View>
                </DataTable>     
            </View>
            }

            <View style={{alignItems:"center"}}>
                <Text style={[style(WIDTH).text_billing,{paddingTop:WIDTH*0.05}]}>Selling</Text>
                <View style={style(WIDTH).container_icon}></View>
                <View style={{justifyContent: 'flex-start', flexDirection:'row', alignItems:'center', width:'100%'}} >
                    <Text style={[style(WIDTH).fontSize2, {fontWeight: 'bold', paddingLeft: WIDTH*0.015, paddingRight: WIDTH*0.01}]}>Invoice Date:</Text> 
                    <TextInput style={[style(WIDTH).container_view_tb_short,style(WIDTH).fontSize2]} value={invoice_date_str} onFocus ={()=>{
                        set_calander_defaultDate(invoice_date || new Date());
                        set_calander_attribute('invoice_date');
                        set_last_dt('');
                        set_showCalander(true);
                        set_calanderMessage('Select Invoice Date');}}/>
                    <Text style={[style(WIDTH).fontSize2, {fontWeight: 'bold', paddingLeft: WIDTH*0.05, paddingRight: WIDTH*0.01}]}>Currency:</Text> 
                    <View style={style(WIDTH).container_icon}></View>
                    <Controller rules={{required: true}} name="billing.selling_currency" control={control_formBilling} render={({ field: { onChange, onBlur, value } }) => (
                        <Picker style={[style(WIDTH).picker_billing,style(WIDTH).fontSize2, style(WIDTH).picker, {width:WIDTH*0.06}]} onValueChange={onChange} onBlur={onBlur} value={value}>
                            <Picker.Item label="RM" value="RM" />
                            <Picker.Item label="EUR" value="EUR" />
                            <Picker.Item label="SGD" value="SGD" />
                            <Picker.Item label="USD" value="USD" />
                        </Picker>)}/>
                </View>  

                <View style={style(WIDTH).container_icon}></View>

                <DataTable>
                    <DataTable.Header style={style(WIDTH).dataTable_header_border}>
                        <View style={[style(WIDTH).dataTable_header,{flex:3.8}]}><Text style={[style(WIDTH).dataTable_content_billing, {fontWeight:'bold'}]}>Description</Text></View>
                        <View style={[style(WIDTH).dataTable_header,{flex:2}]}><Text style={[style(WIDTH).dataTable_content_billing, {fontWeight:'bold'}]}>Category</Text></View>
                        <View style={[style(WIDTH).dataTable_header,{flex:0.5}]}><Text style={[style(WIDTH).dataTable_content_billing, {fontWeight:'bold'}]}>Qty.</Text></View>
                        <View style={[style(WIDTH).dataTable_header,{flex:1}]}><Text style={[style(WIDTH).dataTable_content_billing, {fontWeight:'bold'}]}>UOM</Text></View>
                        <View style={[style(WIDTH).dataTable_header,{flex:1}]}><Text style={[style(WIDTH).dataTable_content_billing, {fontWeight:'bold'}]}>Unit Price</Text></View>
                        <View style={[style(WIDTH).dataTable_header, { flex: 0.6 }]}><Text style={[style(WIDTH).dataTable_content_billing, { fontWeight: 'bold' }]}>Tax</Text></View>
                        <View style={[style(WIDTH).dataTable_header,{flex:1}]}><Text style={[style(WIDTH).dataTable_content_billing, {fontWeight:'bold'}]}>Amount</Text></View>
                        <View style={[style(WIDTH).dataTable_header,{flex:1}]}></View>
                    </DataTable.Header>
                    {contentTableBilling_Selling}
                    
                    <View style={{alignSelf:"center", flexDirection:'row'}}>
                        <Picker style={[style(WIDTH).picker_billing_add_row, style(WIDTH).fontSize2, style(WIDTH).picker, {alignSelf:'center'}]} 
                          onValueChange={set_num_selling_row} value={num_selling_row}>
                            <Picker.Item label="1" value="1" />
                            <Picker.Item label="3" value="3" />
                            <Picker.Item label="5" value="5" />
                            <Picker.Item label="10" value="10" />
                            <Picker.Item label="15" value="15" />
                            <Picker.Item label="20" value="20" />
                        </Picker>         
                        <Popable content='Add selling item' position="right" strictPosition={true} backgroundColor='#FF9F1D' action="hover"> 
                        <Button icon={<Icon name='add-circle-outline' type="materialicons" size={option(WIDTH).iconSize2}/>} type="clear"
                            onPress={addRow_Selling}/></Popable>
                    </View>
                </DataTable>
                
                <View style={style(WIDTH).container_icon} />
                <Text style={[style(WIDTH).text_billing,{paddingTop:WIDTH*0.05}]}>P/L</Text>
                <View style={style(WIDTH).xscontainer} />
                <Text style={style(WIDTH).fontSize1}>*update when save</Text>
                <View style={style(WIDTH).container_icon} />
                    {grossProfit}
            </View>

            <View style={{alignItems: 'flex-end'}}>
                <ButtonPaper
                    style={style(WIDTH).button_orange}
                    labelStyle={[style(WIDTH).button_label_white, style(WIDTH).fontSize2]} 
                    onPress={handleSubmit_formBilling(onSubmit_Billing)}
                >
                    Save
                </ButtonPaper>
                <Text style={style(WIDTH).fontSize1}>*System will tell if save is successful, else please fill up all information.</Text>
            </View>
           
            {/* <View style={{alignItems: 'flex-end', paddingTop: WIDTH*0.01}}> 
                <Button title='Save' onPress={handleSubmit_formBilling(onSubmit_Billing)} containerStyle={styles.containerStyle} buttonStyle={styles.buttonStyle}/>           
            </View> */}
        </View>
    );
      
    const Tab_Edoc = () => {
        const SelectEdoc = async() => {
            if(typeof(shipmentID) === "undefined")
                return; 

            let file = await DocumentPicker.getDocumentAsync({copyToCacheDirectory:true, multiple:false, type:'*/*'});
            if(file.size > MAX_FILE_SIZE_BYTE)
            {
                MessageBox("Error", "File size greater than 10 MB is not allowed to upload.");
                return;
            }

            ShowLoading();
            let formData = new FormData();
            formData.append('shipmentID', shipmentID);
            formData.append('file', file.file);
            const token = window.localStorage.getItem('token');
            let url = API_URL + 'salesorder/upload';
            const headers = { Authorization: `Bearer ${token}` };
            const res = await axios({
                method:'post',
                url: url, 
                headers: headers,
                data: formData
            }).then(data => {
                let newIndex = typeof(getValues_formEdocs("edocs")) == "undefined"?
                0: getValues_formEdocs("edocs").length;
                setValue_formEdocs("edocs[" + newIndex + "].desc", "");
                setValue_formEdocs("edocs[" + newIndex + "].path", data.data.result.path);
                MakeContentEdocs();   
            }).catch(err => {
                MessageBox("Error", "Fail to upload file");
                console.log(err);
            }).finally(()=>{
                HideLoading();
            });
        }
        
        return(
        <View style={{ flex: 1}} pointerEvents={is_completed || is_deleted? "none":"auto"}>
            <DataTable style={{width: WIDTH/2,padding:'2%'}}>
                <DataTable.Header style={style(WIDTH).dataTable_header_border}>
                <View style={[style(WIDTH).dataTable_header,{flex:4}]}><Text style={[style(WIDTH).dataTable_content,style(WIDTH).fontSize2, {fontWeight:'bold'}]}> Description</Text></View>
                <DataTable.Title style={{flex: 1}}></DataTable.Title>
                </DataTable.Header>
                {content_edoc}
                <View style={{alignItems:"center"}}>
                    <Popable content='Upload Edoc' position="right" strictPosition={true} backgroundColor='#FF9F1D' action="hover"> 
                    <Button icon={<Icon name='add-circle-outline' type="materialicons" size={option(WIDTH).iconSize2}/>} type="clear"
                         onPress={SelectEdoc}/></Popable>
                </View>
                <View style={{alignItems: 'flex-end'}}> 
                <ButtonPaper onPress={handleSubmit_formEdocs(onSubmit_Edocs)} 
                    style={[style(WIDTH).button_orange, {marginTop:WIDTH*0.02}]}
                    labelStyle={[style(WIDTH).button_label_white, style(WIDTH).fontSize2]}>Save</ButtonPaper>    
                <Text style={style(WIDTH).fontSize1}>*System will tell if save is successful, else please fill up all information.</Text>
                </View>     
            </DataTable>                  
        </View>);
    };

    const renderScene = SceneMap({
        tab_details: Tab_Details, tab_do: Tab_DO,
        tab_billing: Tab_Billing, tab_edoc: Tab_Edoc
    });
      
    const renderTabBar = props => (
        <TabBar
            {...props}
            indicatorStyle={{backgroundColor:'black', height:WIDTH*0.001}}
            labelStyle={[{color:'black'},style(WIDTH).fontSize2]}
            inactiveColor='white'
            activeColor='black'
            style={style(WIDTH).tab_style}
        />
    );

    return (                
        <View style={{paddingTop: '1%', paddingLeft: '1%', paddingBottom: '2.5%'}}>
            <View style={[
                is_completed && style().table_row_completed,
                is_deleted && style().table_row_deleted,
                is_unlock_request && style().table_row_unlock_request]}>
                <View style={{justifyContent: 'flex-start', flexDirection:'row'}}>
                    <View style={{flex:2}}><Text style={[style(WIDTH).fontSize4,{fontWeight: 'bold'}]}>Shipment ID: {shipmentID} {is_completed? "(Completed)":""}</Text></View>
                    {!is_deleted?
                    <View style={{flex:1, justifyContent: 'flex-end', flexDirection:'row'}} >
                        <Popable content='Download Invoice' position="left" backgroundColor='#FF9F1D' action="hover"> 
                        <Button icon={<Icon name='print' type="fontawesome" size={option(WIDTH).iconSize2}/>} type="clear"
                            onPress={()=>{
                                invoice_path != ""? DownloadFile(invoice_path): MessageBox("Error?", "Invalid invoice path");}}/>
                               </Popable>
                        {is_completed?<>
                            <View style={style(WIDTH).container_icon} /> 
                        <Popable content='Duplicate S.O.' position="left" backgroundColor='#FF9F1D' action="hover"> 
                        <Button icon={<Icon name='copy' type="font-awesome" size={option(WIDTH).iconSize2}/>} type="clear"
                            onPress={()=>{showDuplicateSoForm();}}/></Popable></>:null} 
                        {!is_completed?
                        (<>
                            <View style={style(WIDTH).container_icon} /> 
                            <Popable content='Mark as completed' position="left" backgroundColor='#FF9F1D' action="hover"> 
                            <Button icon={<Icon name='done-outline' type="materialicon"  size={option(WIDTH).iconSize2}/>} type="clear"
                                onPress={()=>{ completeSalesOrder(true);}}/></Popable>
                            <View style={style(WIDTH).container_icon} /> 
                            <Popable content='Delete S.O.' position="left" backgroundColor='#FF9F1D' action="hover"> 
                            <Button icon={<Icon name='page-delete' type="foundation" color='red' size={option(WIDTH).iconSize2}/>} type="clear"
                                onPress={()=>{
                                    if(counter_delete == 0) 
                                    {
                                        set_counter_delete(counter_delete + 1);
                                        MessageBox("Warning", "You are deleting the sales order. If this is your intention, kindly click the delete button again.");
                                    }    
                                    else deleteSalesOrder();}}/></Popable>
                        </>
                        )
                        : (<>
                            <View style={style(WIDTH).container_icon} /> 
                            <Popable content='Unlock' position="left" backgroundColor='#FF9F1D' action="hover"> 
                            <Button icon={<Icon name='unlock' type="feather" size={option(WIDTH).iconSize2}/>} type="clear"
                                onPress={()=>{requestUnlock();}}/></Popable>
                        </>)}                                            
                    </View> : null}
                </View>   
                
                {is_unlock_request? 
                <View style={{justifyContent: 'flex-start', flexDirection:'row'}} >
                    <Text style={style(WIDTH).fontSize2}>Unlock Requestor: {unlock_requestor}</Text> 
                    {is_admin? <>
                        <View style={style(WIDTH).container_icon} /> 
                        <Popable content='Approve' position="top" backgroundColor='#FF9F1D' action="hover"> 
                        <Button icon={<Icon name='check' type="antdesign" size={option(WIDTH).iconSize1}/>} type="clear" 
                            style={{backgroundColor:'#11CD01'}}
                            onPress={()=>{requestUnlock();}}/></Popable>
                        <View style={style(WIDTH).container_icon} /> 
                        <Popable content='Reject' position="top" backgroundColor='#FF9F1D' action="hover"> 
                        <Button icon={<Icon name='close' type="antdesign" size={option(WIDTH).iconSize1}/>} type="clear"
                            style={{backgroundColor:'#ff2100'}}
                            onPress={()=>{rejectUnlockRequest();}}/></Popable>
                    </>: null}                    
                </View> : null}      

                {is_deleted? <>
                    <View style={style(WIDTH).container_icon} /> 
                    <View style={style(WIDTH).container_row2} >
                    <Text style={style(WIDTH).fontSize2}>Deleted by: {last_edit_name}</Text> 
                    {is_admin? <>
                    <View style={style(WIDTH).container_icon}></View>
                    <Popable content='Approve' position="top" backgroundColor='#FF9F1D' action="hover">
                    <Button icon={<Icon name='check' type="antdesign" size={option(WIDTH).iconSize1}/>} type="clear" 
                        style={{backgroundColor:'#11CD01'}}
                        onPress={()=>{
                            if(counter_delete == 0) 
                            {
                                set_counter_delete(counter_delete + 1);
                                MessageBox("Warning", "You are deleting the sales order. If this is your intention, kindly click the delete button again.");
                            }    
                            else deleteSalesOrder();}}/></Popable>
                    <View style={style(WIDTH).container_icon}></View>
                    <Popable style={{zIndex:1000}} content='Reject' position="top" backgroundColor='#FF9F1D' action="hover">
                    <Button icon={<Icon name='close' type="antdesign" size={option(WIDTH).iconSize1}/>} type="clear"
                        style={{backgroundColor:'#ff2100'}}
                        onPress={()=>{undoDeleteSalesOrder();}}/></Popable></>: null}                    
                </View></>: null}      
            </View>   
            <TabView style={{marginTop:'2%'}}
                renderTabBar={renderTabBar}
                navigationState={{ index, routes }}
                renderScene={renderScene}
                onIndexChange={setIndex}
                initialLayout={{ width: useWindowDimensions().width }}
                uppercase={false}
                swipeEnabled={false}
            />
            <DatePickerModal
                mode="single"
                visible={showCalander}
                onDismiss={onDismiss}
                date={calander_defaultDate}      
                onConfirm={onConfirm}
                validRange={{
                    // startDate: new Date()
                }}
                onChange={doubleClickToSelect} // same props as onConfirm but triggered without confirmed by user
                saveLabel="Select Date" // optional
                uppercase={false} // optional, default is true
                label={calanderMessage} // optional
                animationType="fade" // optional, default is 'slide' on ios/android and 'none' on web
            />    
            <Overlay isVisible={state_showOverlay} onBackdropPress={hideOverlay} animationType='fade' overlayStyle={{width:WIDTH*0.95, height:'95%'}}>   
                <View style={style(WIDTH).overlayCloseButton}><Icon name='close' type="antdesign" size={option(WIDTH).iconSize3} onPress={hideOverlay}/></View>                     
                <ScrollView
                    contentContainerStyle={{flex:1}} 
                    showsHorizontalScrollIndicator={false} 
                    showsVerticalScrollIndicator={false} 
                    focusable={false}
                >
                    <Client_list selectContact={1} is_client={0} sendDataToParent={sendDataToParent}/>
                </ScrollView>                    
            </Overlay>     

            <Overlay isVisible={show_FormDO} onBackdropPress={hideCreateDoForm} animationType='fade' overlayStyle={{width:WIDTH*0.8, height:'95%'}}>   
                <View style={style(WIDTH).overlayCloseButton}><Icon name='close' type="antdesign" size={option(WIDTH).iconSize3} onPress={hideCreateDoForm}/></View>                     
                <ScrollView
                    contentContainerStyle={{flex:1}} 
                    showsHorizontalScrollIndicator={false} 
                    showsVerticalScrollIndicator={false}
                    focusable={false} 
                >
                    <DeliveryOrder_Edit mode={DoMode} doID={edit_id_do} shipmentID={shipmentID}
                        reference={getValues_formDetails('referenceNo')}
                        pickupDate={getValues_formDetails('etd')}
                        sendDataToParent={receiveData_CreateDO}
                        pickupFrom={shipper} deliverTo={consignee} />
                </ScrollView>                    
            </Overlay>     

            <Overlay isVisible={show_duplicateSoForm} onBackdropPress={hideDuplicateSoForm} animationType='fade' overlayStyle={{width:WIDTH*0.5, height:'95%'}}>   
                <View style={style(WIDTH).overlayCloseButton}><Icon name='close' type="antdesign" size={option(WIDTH).iconSize3} onPress={hideDuplicateSoForm}/></View>                     
                <ScrollView
                    contentContainerStyle={{flex:1}} 
                    showsHorizontalScrollIndicator={false} 
                    showsVerticalScrollIndicator={false} 
                    focusable={false}
                >
                    <SalesOrder_Duplicate data={param_duplicateSO} sendDataToParent={receiveData_DuplicateSO}/>
                </ScrollView>                    
            </Overlay>     
        </View>
    );
}

export default SalesOrder_View

