import React, { useState, useEffect, useRef } from 'react';
import { Image,View, Text, ScrollView, TextInput, Pressable } from 'react-native';
import axios from 'axios';
import { API_URL, BASE_URL } from '../config/config';
import { ITEM_PER_TABLE_PAGE } from '../constants';
import { DataTable, Button as ButtonPaper} from 'react-native-paper';
import { style, option } from '../styles/style.js';
import { useHover, Hoverable } from 'react-native-web-hooks';

import prev from '../assets/prev-arrow.png';
import next from '../assets/next-arrow.png';
import { Button, Icon, Overlay } from 'react-native-elements';
import { getWebsiteWidth } from '../components/utilities';
import Client_Create from './Client_create.js';
import Address_create from './Address_create';
import Address_edit from './Address_edit';
import { Popable } from 'react-native-popable';
import Client_Edit from './Client_edit';


function Client_List(params) {
    const modeSelectContact = (typeof(params.selectContact) != "undefined" && params.selectContact)? true:false;
    const modeSelectAddress = (typeof(params.selectAddress) != "undefined" && params.selectAddress)? true:false;
    // const modeSelectAddress = (typeof(params.selectClient) != "undefined" && params.selectClient)? true:false;
    const [WIDTH, set_WIDTH] = useState(getWebsiteWidth());
    const [isClient, set_isClient] = useState(params.is_client);
    const [clientID, set_clientID] = useState(null);
    const [addressID, set_addressID] = useState(null);
    const msgBox = useRef(null);

    const initialClient = [{
        id:'',
        name:'',
        contact:'',
        email:'',
        fax:'',
        address:'',
        term:'',
        code:''
    }]

    // for client table
    const [client, setClient] = useState(initialClient);
    const [clientList, setClientList] = useState('');

    // for address table
    const [address_visible, set_address_visible] = useState(false);
    const [addresses, set_addresses] = useState(null);
    const hide_address_table = () => {
        set_address_visible(false);
    }
    
    // for searching
    const [showSearch, setShowSearch] = useState(0);
    const [searchParams, setSearchParams] = useState([]);
    
    // for pagination
    const [tablePage, setCurrentPage] = useState({totalPages:0, currentPage:0});
    const [totalItems, setTotalItems] = useState(0);
    const [isFirst, setFirst] = useState(1);
    const [isLast, setLast] = useState(0);

    const isHovered = useHover(ref);
    const ref = useRef(null);

    const [Name, setName] = useState('');
    const [Contact, setContact] = useState('');
    const [Email, setEmail] = useState('');
    const [Fax, setFax] = useState('');
    const [Address, setAddress] = useState('');
    const [Term, setTerm] = useState('')
    const [Code, setCode] = useState('');

    const [isAdmin, setAdmin] = useState(false);

    let pageTimer;

    // redirect to edit page when click the row
    const handleClickRow_client = async (id, name, code, address) => {
        if(modeSelectContact) {
            if(params.sendDataToParent)
                params.sendDataToParent({id: id, name:name, code:code, address:address});
            else
                MessageBox("Error", "params.sendDataToParent not provided")
        } else {
            set_clientID(id);
            set_address_visible(true);
        }
        // show table
        // update address book
        // const handleClickRow = async (id, name, code, address) => {

        // if(modeSelectClient)
        // {
        //     if(params.sendDataToParent)
        //         params.sendDataToParent({id: id, name:name, code:code, address:address});
        //     else
        //         MessageBox("Error", "params.sendDataToParent not provided")
        // }
        // else
        //     window.location.href = `${BASE_URL}client/${id}`;

    }
    const handleClickRow_address = async (id, name, address) => {
        if(modeSelectAddress) {
            if(params.sendDataToParent)
                params.sendDataToParent({id: id, name:name, address:address});
            else
                MessageBox("Error", "params.sendDataToParent not provided")
        }
    }

  


    // useEffect(() => {
    //     window.localStorage.getItem('role') != 1? setAdmin(true): setAdmin(false);
    //     window.addEventListener('resize', () => { set_WIDTH(getWebsiteWidth()); });
    //     onLoad();  
    // }, clientID)

    // Overlay Client create
    const [state_showOverlay, set_state_showOverlay] = useState(false);
    const showOverlay = () => { set_state_showOverlay(true); };
    const hideOverlay = () => { set_state_showOverlay(false); };
    const sendDataToParent = async(params) => {
        if(params)
        {    
            set_state_showOverlay(false);
            getClient();
        }
    }   
     
    // Overlay Client edit
    const [state_showOverlay_clientEdit, set_state_showOverlay_clientEdit] = useState(false);
    const showOverlay_clientEdit = (id) => { set_clientID(id); set_state_showOverlay_clientEdit(true); };
    const hideOverlay_clientEdit = () => { set_state_showOverlay_clientEdit(false); };
    const sendDataToParent_clientEdit = async(params) => {
        if(params.refresh) {    
            getClient();
        }
        else if (params) {
            set_state_showOverlay_clientEdit(false);
            getClient();
        }
    }    

    // Overlay address add
    const [state_showOverlay_address, set_state_showOverlay_address] = useState(false);
    const showOverlay_address = () => { set_state_showOverlay_address(true); };
    const hideOverlay_address = () => { set_state_showOverlay_address(false); };
    const sendDataToParent_address = async(params) => {
        if(params.refresh) {    
            getClient();
        }
        else if (params) {
            set_state_showOverlay_address(false);
            getClient();
        }
    }    

    // Overlay address edit
    const [state_showOverlay_addressEdit, set_state_showOverlay_addressEdit] = useState(false);
    const showOverlay_addressEdit = (id) => { set_addressID(id); set_state_showOverlay_addressEdit(true); };
    const hideOverlay_addressEdit = () => { set_state_showOverlay_addressEdit(false); };
    const sendDataToParent_addressEdit = async(params) => {
        if(params.refresh) {    
            getClient();
        }
        else if (params) {
            set_state_showOverlay_addressEdit(false);
            getClient();
        }
    }    

    const getClient = async(_params) => {
        let queryParams = {};
        for(const sp in searchParams){
            if(searchParams[sp] != '')
                queryParams['filter[' + sp + ']'] = searchParams[sp];
        }
        
        queryParams['size'] = ITEM_PER_TABLE_PAGE;
        queryParams['page'] = 0;
        if(_params && _params.page){
            queryParams['page'] = _params.page;
        }
        queryParams['filter[' + 'is_client' + ']'] = params.is_client;

        window.API_CALL_GET('client', queryParams).then(
          function(data) {        
            let clientData = data.dataList;
            let numOfClient = clientData.length;
            let totalPage = data.totalPages;
            let currentPage = data.currentPage;

            setTotalItems(data.totalItems);
            setCurrentPage({totalPages: totalPage, currentPage: currentPage});

            if((currentPage+1) == totalPage){
                setLast(1)
            }else{
                setLast(0)
            }

            currentPage == 0? setFirst(1):setFirst(0);
            // console.log(`number of client: ${numOfClient}`);
            // console.log(`total pages: ${totalPage} current page: ${currentPage}`)
            let _client = [];
            for(let a = 0; a < numOfClient; a++){
                let address = clientData[a].address1 + ', ';
                if(typeof(clientData[a].address2) && clientData[a].address2 != "") 
                    address += clientData[a].address2 + ', ';
                address += clientData[a].zipcode + ' ' + clientData[a].city + ', ' + clientData[a].state + ', ' + clientData[a].country;
                _client.push({
                    id:clientData[a].clientID,
                    name:clientData[a].name,
                    contact:clientData[a].contact,
                    email:clientData[a].email,
                    fax:clientData[a].fax,
                    address:address,
                    term:clientData[a].term,
                    code:clientData[a].code,
                    is_deleted:clientData[a].is_deleted,
                    address_book: clientData[a].addressbook
                });
            }
            setClient(_client);
          },
          function(error) { MessageBox("Error", error); }
        );
    }

      // refresh address book table
      useEffect(() => {
        if(!clientID) return;
        let address_book = null;
        for (let i=0; i < client.length; i++)
            if(clientID == client[i].id) {
                address_book = client[i].address_book;
                break;
            }
        if(!address_book) return;

        for(let a = 0; a < address_book.length; a++){
            let address = address_book[a].address1 + ', ';
            if(typeof(address_book[a].address2) && address_book[a].address2 != "") 
                address += address_book[a].address2 + ', ';
            address += address_book[a].zipcode + ' ' + address_book[a].city + ', ' + address_book[a].state + ', ' + address_book[a].country;
            address_book[a].address = address;
        }

        let clist = address_book.map((item)=>{
            return(
                <Hoverable key={item.id}>
                {isHovered => (
                    <Pressable key={item.id} 
                        onPress={() => !item.is_deleted? handleClickRow_address(item.id, item.name, item.address): null} 
                    // onPress={() => handleClickRow(localState.id, localState.name, localState.code, localState.address)} 
                    style={[style(WIDTH).table_row, isHovered && style(WIDTH).hover, (item.is_deleted && isAdmin) && style(WIDTH).table_row_deleted]}>
                        <DataTable.Row key={item.id}>
                            <View style={style(WIDTH).dataTable_content}><Text style={style(WIDTH).dataTable_content_flex}>{item.name || '-'}</Text></View>
                            <View style={style(WIDTH).dataTable_content}><Text style={style(WIDTH).dataTable_content_flex}>{item.contact || '-'}</Text></View>
                            <View style={style(WIDTH).dataTable_content}><Text style={style(WIDTH).dataTable_content_flex}>{item.email || '-'}</Text></View>
                            <View style={style(WIDTH).dataTable_content}><Text style={style(WIDTH).dataTable_content_flex}>{item.fax || '-'}</Text></View>
                            <View style={[style(WIDTH).dataTable_content, {flex:2}]}><Text style={style(WIDTH).dataTable_content_flex}>{item.address || '-'}</Text></View>
                            <View style={[{alignItems:'flex-end', flexDirection:'row', alignSelf:'center', flex:0.3}]}>
                                <Popable content='Edit' position="left" backgroundColor='#FF9F1D' action="hover"> 
                                <Button icon={<Icon name='square-edit-outline' type="material-community" size={option(WIDTH).iconSize2}/>} type="clear"
                                    onPress={() =>{ showOverlay_addressEdit(item.id)}}/></Popable>
                            </View>
                        </DataTable.Row>
                    </Pressable>
                )}
                </Hoverable>
            )
        })
        set_addresses(clist);
    }, [client, clientID]);

    // refresh client table
    useEffect(() => {
        let clist = client.map((localState)=>{
            return(localState.is_deleted && !isAdmin? null:(
                <Hoverable key={localState.id}>
                {isHovered => (
                    <Pressable key={localState.id} 
                        onPress={() => !localState.is_deleted? handleClickRow_client(localState.id, localState.name, localState.code, localState.address): null} 
                    // onPress={() => handleClickRow(localState.id, localState.name, localState.code, localState.address)} 
                    style={[style(WIDTH).table_row, isHovered && style(WIDTH).hover, (localState.is_deleted && isAdmin) && style(WIDTH).table_row_deleted]}>
                        <DataTable.Row key={localState.id}>
                            <View style={style(WIDTH).dataTable_content}><Text style={style(WIDTH).dataTable_content_flex}>{localState.name || '-'}</Text></View>
                            <View style={style(WIDTH).dataTable_content}><Text style={style(WIDTH).dataTable_content_flex}>{localState.contact || '-'}</Text></View>
                            <View style={style(WIDTH).dataTable_content}><Text style={style(WIDTH).dataTable_content_flex}>{localState.email || '-'}</Text></View>
                            <View style={style(WIDTH).dataTable_content}><Text style={style(WIDTH).dataTable_content_flex}>{localState.fax || '-'}</Text></View>
                            <View style={[style(WIDTH).dataTable_content, {flex:2}]}><Text style={style(WIDTH).dataTable_content_flex}>{localState.address || '-'}</Text></View>
                            <View style={style(WIDTH).dataTable_content}><Text style={style(WIDTH).dataTable_content_flex}>{localState.term || '-'}</Text></View>
                            <View style={style(WIDTH).dataTable_content}><Text style={style(WIDTH).dataTable_content_flex}>{localState.code || '-'}</Text></View>
                            <View style={[{alignItems:'flex-end', flexDirection:'row', alignSelf:'center', flex:0.5}]}>
                                <Popable content='Edit' position="left" backgroundColor='#FF9F1D' action="hover"> 
                                <Button icon={<Icon name='square-edit-outline' type="material-community" size={option(WIDTH).iconSize2}/>} type="clear"
                                    onPress={() =>{ showOverlay_clientEdit(localState.id)}}/></Popable>
                            </View>
                        </DataTable.Row>
                    </Pressable>
                )}
                </Hoverable>
            ))
        })
        setClientList(clist);
    },[client, WIDTH]);

    useEffect(() => {
        window.localStorage.getItem('role') != 1? setAdmin(true): setAdmin(false);
        window.addEventListener('resize', () => { set_WIDTH(getWebsiteWidth()); });
        onLoad();  
    }, [])

    useEffect(() => {
        if(isClient != params.is_client)
        {
            set_isClient(params.is_client)
            onLoad();
        }
    }, [params])

    const onLoad = () => {
        getClient();
    }

    const clearFilter = () => {
        setName('');
        setContact('');
        setAddress('');
        setEmail('');
        setFax('');
        setCode('');
        setTerm('');
        setSearchParams([]);
    }

    const onChangeValue = (key, value) => {
        searchParams[key] = value;
    }

    const onChangePage = (value) => {
        if(value != '' && (value <= tablePage.totalPages) && value > 0){
            clearTimeout(pageTimer);
            pageTimer = setTimeout(() => {          
                getClient({ page:value-1 });
            }, 800);
        }
    }

    const previousPage = () => {
        if(tablePage.totalPages > 1) {
            tablePage.currentPage = tablePage.currentPage - 1;
            setLast(0);
            tablePage.currentPage == 0? setFirst(1):setFirst(0)
            // if(tablePage.currentPage == 0) setFirst(1);
            getClient({ page:tablePage.currentPage });
        }
    }

    const nextPage = () => {
        if(tablePage.totalPages > 1) {
            tablePage.currentPage = tablePage.currentPage + 1;
            setFirst(0);
            (tablePage.currentPage+1) == tablePage.totalPages? setLast(1):setLast(0)
            getClient({ page:tablePage.currentPage });
        }
    }

    return (
        <View style={style(WIDTH).container}>
            <DataTable>
                <View style={style(WIDTH).container_row}>
                    <Popable content='Refresh' position="left" backgroundColor='#FF9F1D' action="hover">
                    <Button icon={<Icon name='refresh' type="simplelineicons" size={option(WIDTH).iconSize2}/>} type="clear" style={{padding: '5%'}} 
                        onPress={getClient} /></Popable>
                    <View style={style(WIDTH).container_icon}></View>
                    <Popable content='Search' position="left" backgroundColor='#FF9F1D' action="hover">
                    <Button icon={<Icon name='search1' type="antdesign" size={option(WIDTH).iconSize2}/>} type="clear" style={{padding: '5%'}}
                        onPress={()=>setShowSearch(!showSearch)}/></Popable> 
                    <View style={style(WIDTH).container_icon}></View>
                    <Popable content='Create' position="left" backgroundColor='#FF9F1D' action="hover">
                    <Button icon={<Icon name='addfile' type="antdesign" size={option(WIDTH).iconSize2}/>} type="clear" style={{padding: '5%'}}
                        onPress={()=>{ showOverlay();}}/></Popable>
                </View>

                <View style={[style(WIDTH).container_column, {marginTop:'2%'}]}>      
                    { showSearch? 
                        (<View style={style(WIDTH).container_search_input}>
                            <ButtonPaper
                                style={style(WIDTH).button_orange} 
                                labelStyle={style().button_label_white} 
                                onPress={clearFilter}
                            >
                                <Text style={style(WIDTH).fontSize2}>Clear Filter</Text>
                            </ButtonPaper>
                            <DataTable.Header style={style(WIDTH).dataTable_header2}>
                                <View style={style(WIDTH).scontainer}>
                                    <TextInput
                                        style={[style(WIDTH).searchInput,style(WIDTH).fontSize2]}
                                        value={Name}
                                        onChangeText={(value) => {
                                            onChangeValue("name",value);
                                            setName(value);
                                        }}
                                        placeholder="Name"
                                    />
                                </View>

                                <View style={style(WIDTH).scontainer}>
                                    <TextInput
                                        style={[style(WIDTH).searchInput,style(WIDTH).fontSize2]}
                                        value={Contact}
                                        onChangeText={(value) => {
                                            onChangeValue("contact",value);
                                            setContact(value);
                                        }}
                                        placeholder="Contact"
                                    />
                                </View>

                                <View style={style(WIDTH).scontainer}>
                                    <TextInput
                                        style={[style(WIDTH).searchInput,style(WIDTH).fontSize2]}
                                        value={Email}
                                        onChangeText={(value) => {
                                            onChangeValue("email",value);
                                            setEmail(value);
                                        }}
                                        placeholder="Email"
                                    />
                                </View>

                                <View style={style(WIDTH).scontainer}>
                                    <TextInput
                                        style={[style(WIDTH).searchInput,style(WIDTH).fontSize2]}
                                        value={Fax}
                                        onChangeText={(value) => {
                                            onChangeValue("fax",value);
                                            setFax(value);
                                        }}
                                        placeholder="Fax"
                                    />
                                </View>

                                <View style={style(WIDTH).scontainer}>
                                    <TextInput
                                        style={[style(WIDTH).searchInput,style(WIDTH).fontSize2]}
                                        value={Address}
                                        onChangeText={(value) => {
                                            onChangeValue("address",value);
                                            setAddress(value);
                                        }}
                                        placeholder="Address"
                                    />
                                </View>

                                <View style={style(WIDTH).scontainer}>
                                    <TextInput
                                        style={[style(WIDTH).searchInput,style(WIDTH).fontSize2]}
                                        value={Term}
                                        onChangeText={(value) => {
                                            onChangeValue("term",value);
                                            setTerm(value);
                                        }}
                                        placeholder="Term"
                                    />
                                </View>

                                <View style={style(WIDTH).scontainer}>
                                    <TextInput
                                        style={[style(WIDTH).searchInput,style(WIDTH).fontSize2]}
                                        value={Code}
                                        onChangeText={(value) => {
                                            onChangeValue("code",value);
                                            setCode(value);
                                        }}
                                        placeholder="Code"
                                    />
                                </View>
                            </DataTable.Header>
                            <ButtonPaper 
                                style={style(WIDTH).button_orange} 
                                labelStyle={style().button_label_white}  
                                onPress={() => {getClient();}}> 
                                    <Text style={style(WIDTH).fontSize2}>Search</Text>
                            </ButtonPaper>
                        </View>):null
                    }
                </View>
                        
                <DataTable.Header style={[style(WIDTH).dataTable_header_border, {marginTop:'2%'}]}>
                    <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>Name</Text></View>
                    <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>Contact</Text></View>
                    <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>Email</Text></View>
                    <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>Fax</Text></View>
                    <View style={[style(WIDTH).dataTable_header, {flex:2}]}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>Address</Text></View>
                    <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>Terms</Text></View>
                    <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>Code</Text></View>
                    <View style={[style(WIDTH).dataTable_header, {flex:0.5}]}></View>
                </DataTable.Header>
                
                {clientList? clientList:null}

                <View style={style().container_first}>
                    <Text style={[style(WIDTH).text_grey, style(WIDTH).text_total_item, style(WIDTH).fontSize2]}>{`Total items: ${totalItems}`}</Text>
                    <View style={style(WIDTH).container_second}>
                        <Pressable 
                            style={isFirst? style(WIDTH).paginationButtonDisabled:style(WIDTH).paginationButton} 
                            onPress={previousPage} 
                            disabled={isFirst}
                        >
                            <Image source={prev} resizeMode='contain' style={[style(WIDTH).pagination_arrow,isFirst && style().disableTint]} />
                        </Pressable>
                    
                        <Text style={[style(WIDTH).text_grey,style(WIDTH).text_page, style(WIDTH).fontSize2]}>
                            {`Page `}
                            <Text style={[style().text_current_page, style(WIDTH).fontSize3]}>{`${tablePage.totalPages == 0? tablePage.currentPage:tablePage.currentPage+1}`}</Text>
                            {` of ${tablePage.totalPages}`}
                        </Text>
                        
                        <Pressable 
                            style={isLast? style(WIDTH).paginationButtonDisabled: style(WIDTH).paginationButton} 
                            onPress={nextPage}
                            disabled={isLast}
                        >
                            <Image source={next} resizeMode='contain' style={[style(WIDTH).pagination_arrow,isLast && style().disableTint]} />
                        </Pressable>
                    </View>
                
                    <View style={style(WIDTH).container_third}>
                        <Text style={[style(WIDTH).text_grey,style(WIDTH).text_page, style(WIDTH).fontSize2]}>Go to page: </Text>
                        <TextInput
                            style={[style(WIDTH).fontSize2, style(WIDTH).go_to_input]}
                            onChangeText={(value) => onChangePage(value)}
                            placeholder={`${tablePage.totalPages == 0? tablePage.currentPage:tablePage.currentPage+1}`}
                        />
                    </View>
                </View>
            </DataTable>
            <Overlay isVisible={state_showOverlay} onBackdropPress={hideOverlay} animationType='fade' overlayStyle={{width:WIDTH*0.5, maxHeight:'95%'}}>  
                <View style={style(WIDTH).overlayCloseButton}><Icon name='close' type="antdesign" backgroundColor='white' size={option(WIDTH).iconSize3} onPress={hideOverlay}/></View>                     
                <ScrollView
                    contentContainerStyle={{flex:1}} 
                    showsHorizontalScrollIndicator={false} 
                    showsVerticalScrollIndicator={false} 
                    focusable={false}
                >
                    <Client_Create sendDataToParent={sendDataToParent} isClient={isClient}/>
                </ScrollView>                    
            </Overlay>  

            <Overlay isVisible={state_showOverlay_clientEdit} onBackdropPress={hideOverlay_clientEdit} animationType='fade' overlayStyle={{width:WIDTH*0.5, maxHeight:'95%'}}>  
                <View style={style(WIDTH).overlayCloseButton}><Icon name='close' type="antdesign" backgroundColor='white' size={option(WIDTH).iconSize3} onPress={hideOverlay_clientEdit}/></View>                     
                <ScrollView
                    contentContainerStyle={{flex:1}} 
                    showsHorizontalScrollIndicator={false} 
                    showsVerticalScrollIndicator={false} 
                    focusable={false}
                >
                    <Client_Edit sendDataToParent={sendDataToParent_clientEdit} isClient={isClient} clientID={clientID}/>
                </ScrollView>                    
            </Overlay>  

            <Overlay isVisible={address_visible} onBackdropPress={hide_address_table} animationType='fade' overlayStyle={{width:WIDTH*0.95, height:'95%'}}>  
                <View style={style(WIDTH).overlayCloseButton}><Icon name='close' type="antdesign" backgroundColor='white' size={option(WIDTH).iconSize3} onPress={hide_address_table}/></View>                            
                    <Text style={[style(WIDTH).create_title, style(WIDTH).fontSize4, {paddingLeft: WIDTH*0.01, paddingTop:WIDTH*0.005}]}>Addresses</Text>

                <DataTable style={{marginTop:WIDTH*0.01}}>
                <DataTable.Header style={[style(WIDTH).dataTable_header_border]}>
                    <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>Name</Text></View>
                    <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>Contact</Text></View>
                    <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>Email</Text></View>
                    <View style={style(WIDTH).dataTable_header}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>Fax</Text></View>
                    <View style={[style(WIDTH).dataTable_header, {flex:2}]}><Text style={[style(WIDTH).dataTable_content,{fontWeight:'bold'}]}>Address</Text></View>
                    <View style={{flex:0.3}}></View>
                </DataTable.Header>
                {addresses}
                <View style={{alignItems:"center"}}>
                        <Popable content='Add address' position="right" strictPosition={true}backgroundColor='#FF9F1D' action="hover"> 
                        <Button icon={<Icon name='add-circle-outline' type="materialicons" size={option(WIDTH).iconSize2}/>} type="clear"
                            onPress={showOverlay_address}/></Popable>
                    </View>
                </DataTable>
            </Overlay>   

            <Overlay isVisible={state_showOverlay_address} onBackdropPress={hideOverlay_address} animationType='fade' overlayStyle={{width:WIDTH*0.5, maxHeight:'95%'}}>  
                <View style={style(WIDTH).overlayCloseButton}><Icon name='close' type="antdesign" backgroundColor='white' size={option(WIDTH).iconSize3} onPress={hideOverlay_address}/></View>                     
                <ScrollView
                    contentContainerStyle={{flex:1}} 
                    showsHorizontalScrollIndicator={false} 
                    showsVerticalScrollIndicator={false} 
                    focusable={false}
                >
                    <Address_create sendDataToParent={sendDataToParent_address} clientID={clientID}/>
                </ScrollView>                    
            </Overlay>  

            <Overlay isVisible={state_showOverlay_addressEdit} onBackdropPress={hideOverlay_addressEdit} animationType='fade' overlayStyle={{width:WIDTH*0.5, maxHeight:'95%'}}>  
                <View style={style(WIDTH).overlayCloseButton}><Icon name='close' type="antdesign" backgroundColor='white' size={option(WIDTH).iconSize3} onPress={hideOverlay_addressEdit}/></View>                     
                <ScrollView
                    contentContainerStyle={{flex:1}} 
                    showsHorizontalScrollIndicator={false} 
                    showsVerticalScrollIndicator={false} 
                    focusable={false}
                >
                    <Address_edit sendDataToParent={sendDataToParent_addressEdit} addressID={addressID}/>
                </ScrollView>                    
            </Overlay> 
        </View>
    )
}

export default Client_List
